<template>
  <div>
    <div>
        <v-card
          flat
          style="background-color: var(--v-background-base) !important"
        >
          <v-toolbar color="greyBase" class="tooly" dark flat style="height: 100px">
            <v-btn icon @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>
              <b>Haulier Manager</b>
            </v-toolbar-title>
          </v-toolbar>
          <v-card
            class="mx-auto"
            :max-width="!$vuetify.breakpoint.mobile ? '96.5vw' : '100%'"
            :style="{
              'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
              'border-radius': !$vuetify.breakpoint.mobile ? '25px 25px 0 0' : '0px',
            }"
            style="overflow-y: hidden;"
          >
            <v-card-text
              :style="{
                height: !$vuetify.breakpoint.mobile ? '96vh' : '90vh',
                'overflow-y': 'hidden',
              }"
              class="pt-0 px-0 pb-0"
            >
              <v-tabs
                height="44"
                v-show="showTabs"
                v-model="selectedTab"
                icons-and-text
                grow
              >
                <!-- <v-tab href="#tracking" v-if="$store.state.user.id == 69">
                    Tracking
                    <v-icon small>track_changes</v-icon>
                </v-tab> -->
                <v-tab href="#jobs">
                  Jobs
                  <v-icon small>hub</v-icon>
                </v-tab>
                <v-tab href="#chatPortal">
                  Chat Portal
                  <v-icon small>question_answer</v-icon>
                </v-tab>
                <v-tab href="#dataCapturing">
                  Data Capturing
                  <v-icon small>analytics</v-icon>
                </v-tab>
                <v-tab href="#botBuilder">
                  Bot Templates
                  <v-icon small>smart_toy</v-icon>
                </v-tab>
                <v-tab href="#fleet">
                  Fleet Management
                  <v-icon small>local_shipping</v-icon>
                </v-tab>
                <v-tab href="#locations">
                  POI's
                  <v-icon small>map</v-icon>
                </v-tab>
              </v-tabs>
      <!-- </v-col>
      
      <v-col col="12" sm="12"> -->
        
      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="tracking">
          <Tracking/>
        </v-tab-item>
        <v-tab-item value="jobs">
          <Jobs/>
        </v-tab-item>
        <v-tab-item value="chatPortal">
          <ChatPortal/>
        </v-tab-item>
        <v-tab-item value="dataCapturing">
          <DataCapturing/>
        </v-tab-item>
        <v-tab-item value="botBuilder">
          <BotBuilder/>
        </v-tab-item>
        <v-tab-item value="fleet">
          <Fleet/>
        </v-tab-item>
        <v-tab-item value="locations">
          <POI/>
        </v-tab-item>
      </v-tabs-items>
      </v-card-text>
      </v-card>
      </v-card>
    </div>
  </div>
  
  </template>
  <script>
    import Jobs from '../components/Trucks/Jobs.vue'
    import ChatPortal from '../components/Trucks/ChatPortal.vue'
    import DataCapturing from '../components/Trucks/DataCapturing.vue'
    import POI from '../components/Trucks/POI.vue'
    import Fleet from '../components/Trucks/FleetManagement.vue'
    import BotBuilder from '../components/Trucks/DriverBotBuilder.vue'
    import Tracking from '../components/Trucks/TrackingServices/TrackingScreen.vue'
  export default {
    components: {
      Jobs,
      ChatPortal,
      DataCapturing,
      BotBuilder,
      POI,
      Fleet,
      Tracking
  },
    data: () => ({
      showTabs: true,
      loaded: false,
      trailerTab: false,
      vehicleTab: false,
      selectedTab:null,
      params: {
        limit: 12,
        offset: 0,
        search: null,
      },
      updateKey: 0,
      activeTab: 0,
    }),
    watch: {
      selectedTab: {
        immediate: true,
        handler( val ) {
          if ( val === 'trailers' ) {
            this.trailerTab = true
          } else {
            this.trailerTab = false
          }
          if ( val === 'vehicles' ) {
            this.vehicleTab = true
          } else {
            this.vehicleTab = false
          }
        },
      }
    },
  };
  </script>
  
  <style scoped>
  .dense-list-item {
    height: 60px;
  }
  .tooly ::v-deep .v-toolbar__content {  
    height: 35px !important;
  }
  
  </style>
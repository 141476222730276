<template>
    <v-card class="mapCard">
        <gmap-map
            ref="mapRef"
            key="mapKey"
            class="gMap"
            :center="center"
            :zoom="zoom"
            map-type-id="terrain"
            style="width: 100%; height: 100%;"
            :options="{
                scrollwheel: true,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
            }"
        >
            <gmap-marker
                :clickable="true"
                :icon="{
                    url: require('../../../../public/icons/truck.svg'),
                    scaledSize: {width: 50, height: 50},
                }"
                @click="toggleInfoWindow(m)"
                @dblclick="viewMarker(m)"
                :position="{
                    lat: truckPosition.lat,
                    lng: truckPosition.lng,
                }"
            />
            <gmap-polyline
                v-if="route"
                :path="route"
                :options="{
                    strokeColor: 'red',
                    strokeOpacity: 0.7,
                    strokeWeight: 3,
                }"
            />
        </gmap-map>
    </v-card>
</template>
<script>
import { getGoogleMapsAPI } from "gmap-vue";

export default {
    data: () => ({
        center: {
            lat: -33.48808586,
            lng: 19.62930204,
        },
        truckPosition: {
            lat: -33.48808586,
            lng: 19.62930204,
        },
        zoom: 7,
        scrollwheel: false,
        route: null,
    }),
    computed: {
        google: getGoogleMapsAPI,
    },
    async created() {
        this.getRoute();
    },
    methods: {
        async getRoute() {
            const directionsService = new this.google.maps.DirectionsService();
            const origin = new this.google.maps.LatLng(-33.48808586, 19.62930204);
            const destination = new this.google.maps.LatLng(-33.9321, 18.8602);
            const request = {
                origin,
                destination,
                travelMode: "DRIVING",
            };
            directionsService.route(request, (result, status) => {
                if (status === "OK") {
                    console.log('Got something back: ', result)
                    this.route = result.routes[0].overview_path;
                } else {
                    console.error("Directions request failed due to " + status);
                }
            });
        },
    },
};
</script> 

<style scoped>

.mapCard {
  position: absolute;
  bottom: 0;
  border-radius: 16px;
  height: 60vh;
  width: 50vw;
  right: 0;
}

.truck {
    color: #df2a2a
}
</style>
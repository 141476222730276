var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"tooly",staticStyle:{"height":"100px"},attrs:{"color":"greyBase","dark":"","flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_c('b',[_vm._v("Haulier Manager")])])],1),_c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow-y":"hidden"},style:({
            'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '0px',
            'border-radius': !_vm.$vuetify.breakpoint.mobile ? '25px 25px 0 0' : '0px',
          }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '96.5vw' : '100%'}},[_c('v-card-text',{staticClass:"pt-0 px-0 pb-0",style:({
              height: !_vm.$vuetify.breakpoint.mobile ? '96vh' : '90vh',
              'overflow-y': 'hidden',
            })},[_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTabs),expression:"showTabs"}],attrs:{"height":"44","icons-and-text":"","grow":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"href":"#jobs"}},[_vm._v(" Jobs "),_c('v-icon',{attrs:{"small":""}},[_vm._v("hub")])],1),_c('v-tab',{attrs:{"href":"#chatPortal"}},[_vm._v(" Chat Portal "),_c('v-icon',{attrs:{"small":""}},[_vm._v("question_answer")])],1),_c('v-tab',{attrs:{"href":"#dataCapturing"}},[_vm._v(" Data Capturing "),_c('v-icon',{attrs:{"small":""}},[_vm._v("analytics")])],1),_c('v-tab',{attrs:{"href":"#botBuilder"}},[_vm._v(" Bot Templates "),_c('v-icon',{attrs:{"small":""}},[_vm._v("smart_toy")])],1),_c('v-tab',{attrs:{"href":"#fleet"}},[_vm._v(" Fleet Management "),_c('v-icon',{attrs:{"small":""}},[_vm._v("local_shipping")])],1),_c('v-tab',{attrs:{"href":"#locations"}},[_vm._v(" POI's "),_c('v-icon',{attrs:{"small":""}},[_vm._v("map")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":"tracking"}},[_c('Tracking')],1),_c('v-tab-item',{attrs:{"value":"jobs"}},[_c('Jobs')],1),_c('v-tab-item',{attrs:{"value":"chatPortal"}},[_c('ChatPortal')],1),_c('v-tab-item',{attrs:{"value":"dataCapturing"}},[_c('DataCapturing')],1),_c('v-tab-item',{attrs:{"value":"botBuilder"}},[_c('BotBuilder')],1),_c('v-tab-item',{attrs:{"value":"fleet"}},[_c('Fleet')],1),_c('v-tab-item',{attrs:{"value":"locations"}},[_c('POI')],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
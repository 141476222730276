<template>
    <v-card>
      <v-toolbar>
        <span class="headline">Edit Fine</span>
  
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="areYouSureDelete = true">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn :loading="loadCircular" icon @click="updateFine()">
          <v-icon>save</v-icon>
        </v-btn>
  
        <v-btn @click="$emit('close')" icon>
          <v-icon color="red">close</v-icon>
        </v-btn>
      </v-toolbar>
  
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field label="Reason for Fine" v-model="fine.reasonForFine"  />
            </v-col>
            <v-col cols="10" md="10" sm="10">
              <v-text-field label="Location of Infringement" v-model="fine.locationOfFine" />
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-text-field label="Postal Code" v-model="fine.postalCode" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="6" sm="6">
              <v-text-field label="Issuer of Fine" v-model="fine.fineIssuer" />
            </v-col>
            <v-col cols="2" md="2" sm="2">
              <v-autocomplete
                ref="currencySelect"
                hide-no-data
                hide-selected
                label="Currency"
                :items="countries"
                item-text="currencyCode"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                return-object
                v-model="fine.selectedCurrency"
                @change="fine.currency = fine.selectedCurrency.currencyCode"
              >
                <template v-slot:selection="data">
                  <v-avatar size="32" class="mr-2" v-if="data.item">
                    <v-img
                      v-if="data.item.iso2"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                  <span v-if="data.item">
                    {{ data.item.currencyCode }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="data.item.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{data.item.currencyCode}}
                  </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="4" sm="4">
              <v-text-field label="Penalty Amount" v-model="fine.penaltyAmount" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fine.dateOfFine"
                    label="Date of Infringement"
                    prepend-inner-icon="today"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fine.dateOfFine"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString().substr(0, 10)
                  "
                  min="1970-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
            
            <v-col cols="6" md="6" sm="6" >
              <v-container>
              <v-switch
              class="ml-0 pl-0"
              :label="fine.isPaid ? 'Fine has been Paid' : 'Fine not Paid'"
              :error="!fine.isPaid"
              v-model="fine.isPaid"
              color="success"
              >
  
              </v-switch>
            </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-dialog max-width="400" v-model="areYouSureCancel">
          <v-card height="150" >
            <v-toolbar> <v-subheader style="text-align: center">Are you sure you want to exit without saving? Your unsaved progress will be lost. </v-subheader></v-toolbar>
            <span class="d-flex justify-center mt-7">
              <v-btn @click="areYouSureCan('yes')" class="mr-5"  >YES</v-btn>
            <v-btn @click="areYouSureCan('no')" class="ml-5" color="primary">NO</v-btn>
            </span>
          </v-card>
        </v-dialog>

        <v-dialog max-width="400" v-model="areYouSureDelete">
          <v-card height="150" >
            <v-toolbar> <v-subheader style="text-align: center">Are you sure you want to delete this file? </v-subheader></v-toolbar>
            <span class="d-flex justify-center mt-7">
              <v-btn @click="areYouSureDel('yes')" class="mr-5"  >YES</v-btn>
            <v-btn @click="areYouSureDel('no')" class="ml-5" color="primary">NO</v-btn>
            </span>
          </v-card>
        </v-dialog>

        <v-snackbar v-model="errorMessage"
          :timeout="4000"
          color="primary"
        >
          An error occurred while saving. Please try again
      </v-snackbar>
    </v-card>
  </template>
  
  <script>

  export default {

    props: ["driver", "fine", "countries"],
      components: {
  
      },
    data: () => ({
      activePicker: null,
      date: null,
      menu: false,
      isPaid: false,
      errorMessage: false,
      loadCircular: false,
      areYouSureDelete: false,
      areYouSureCancel: false,
    }),
  
    async created() {
    },
    watch: {
      menu(val) {
        val && setTimeout(() => (this.activePicker = "YEAR"));
      },

    },
    methods: {
      save(date) {
        this.$refs.menu.save(date);
      },

      areYouSureDel(val) {
        if(val == 'yes') {
          this.deleteFine()
        }
        else {
          this.areYouSureDelete = false
        }
      },
      areYouSureCan(val) {
        if(val == 'yes') {
          this.$emit('cancel')
        }
        else {
          this.areYouSureCancel = false
        }
      },

      async deleteFine() {
        this.fine.isDeleted = true
        this.fine.isActive = false
        let result = await this.$API.updateFine(this.fine)
        if(result) {
          this.$emit('delete', this.fine.id)
        }
        else {
          this.errorMessage = true
        }
      },
      
      async updateFine() {
        this.loadCircular = true
        let result = await this.$API.updateFine(this.fine)
        if(result) {
          this.loadCircular = false
          this.$emit('close')
        }
        else {
          this.loadCircular = false
          this.errorMessage = true
        }
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  
<template>
  <v-card style="height: calc(96vh - 84px);">
    <v-row justify="end">
        <v-col cols="12" sm="12" md="8" lg="4">
        <el-input
            id="search"
            suffix-icon="el-icon-search"
            v-model="search"
            clearable
            placeholder="Search"
          >
            <template slot="prepend">
              <el-button @click="addDrivers()"
                ><i class="el-icon-plus"></i
              ></el-button>
            </template>
          </el-input>
      </v-col>
    </v-row>
    <v-data-table
        height="77vh"
        id="vehicleTable"
        style="cursor: pointer"
        :search="search"
        :calculate-widths="true"
        :items="driverData.data"
        :single-expand="true"
        :loading="loading"
        :headers="headers"
        :options="{
          page: params.offset + 1,
          itemsPerPage: params.limit,
          pageStart: params.offset + 1,
          pageStop: Math.round(driverData.total / this.params.limit),
          pageCount: Math.round(driverData.total / this.params.limit),
          itemsLength: driverData.total
        }"
      >
      </v-data-table>

</v-card>
</template>

<script>
import dateFormat from "dateformat";
export default {
    data: () => ({
        search: null,
        loading: false,
        driverData: {},
        params: {
            limit: 20,
            offset: 0,
            search: null,
        },
        headers:[
            {
                text: 'Driver',
                align: 'center',
                value: 'driverName'
            },
            {
                text: 'Type',
                align: 'center',
                value: 'category'
            },
            {
                text: 'Input',
                align: 'center',
                value: 'data'
            },
            {
                text: 'Time',
                align: 'center',
                value: 'createdAt'
            }
        ]
    }),
    async created() {
        this.getDriverData();
        this.$socket.send(
              JSON.stringify({
                action: "join",
                room: "waBot_Data_" + this.$store.state.currentOrg.id,
                user: {
                  id: this.$store.state.user.id,
                  name:
                    this.$store.state.user.firstname +
                    " " +
                    this.$store.state.user.surname,
                  avatar: this.$store.state.user.avatar,
                  company: this.$store.state.currentOrg.name,
                },
              })
            );
    },
    mounted() {
    this.$socket.onopen = () => {
      this.$socket.send(
        JSON.stringify({
          action: "join",
          room: "waBot_Data_" + this.$store.state.currentOrg.id,
          user: {
            id: this.$store.state.user.id,
            name:
              this.$store.state.user.firstname +
              " " +
              this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
    };
    this.$socket.onmessage = (data) => { 
      let result = JSON.parse(JSON.stringify(JSON.parse(data.data)))
      this.newMessageHandler(result);
    };
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
    methods: {
        async newMessageHandler(data) {
            const parsedData = JSON.parse(data);
            const message = JSON.parse(parsedData.data);
            console.log('📧Webhook Received', message)
            let newMessage = JSON.parse(JSON.stringify(message))
            newMessage.createdAt ? newMessage.createdAt = this.formatDate(newMessage.createdAt).dateTime : null
            newMessage.driverName = newMessage.transportDriver.user.firstname + " " +  newMessage.transportDriver.user.surname
            newMessage.data = this.formatData(newMessage.category, newMessage.data)
            newMessage.category = this.formatCategory(newMessage.category)
            this.driverData.data.unshift(newMessage)
            await this.$API.updateDriverData(message)
        },
        async getDriverData() {
            this.loading = true;
            let result = await this.$API.getDriverData()
            if(result) {
                result.data.map((item) => {
                    item.createdAt = this.formatDate(item.createdAt).dateTime
                    item.driverName = item.transportDriver.user.firstname + " " +  item.transportDriver.user.surname
                    item.data = this.formatData(item.category, item.data)
                    item.category = this.formatCategory(item.category)
                })
                this.driverData = result;
            }
            this.loading = false;
        },
        formatData(category, data) {
            let result = data
            switch (category) {
                case 'kilometers':
                    result = data + ' Km'
                    break;
                case 'containerNumber':
                    result = data
                    break;
                case 'sealNumber' :
                    result = data
                    break;
                default:
                    result = data
            }
            return result
        },




        formatCategory(category) {
            let result = category
            switch(category) {
                case 'kilometers':
                    result = 'Kilometers (Km)'
                    break;
                case 'containerNumber':
                    result = 'Container Number'
                    break;
                case 'sealNumber' :
                    result = 'Seal Number'
                    break;
                default:
                    result = category
            }
            return result
        },



        formatDate(date) {
          let isoFormat = null;
          let fullDate = null;
          let dateTime = null;
          let dayOfWeek = null;
          let shorterDate = null;
          let time = null;
          let dayFullDate = null;
          if (date) {
            isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
            dayFullDate = dateFormat(new Date(date), "ddd, mmmm dS, yyyy");
            fullDate = dateFormat(new Date(date), "mmmm dS, yyyy");
            dateTime = dateFormat(new Date(date), "dd-mm-yyyy @ HH:MM");
            dayOfWeek = dateFormat(new Date(date), "ddd");
            shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
            time = dateFormat(new Date(date), "HH:MM");
          }
          return {
            isoFormat,
            fullDate,
            dateTime,
            dayOfWeek,
            shorterDate,
            time,
            dayFullDate,
          };
        },
    }





    }
</script>
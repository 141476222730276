<template>
    <div>
        <v-card class="mainCard">
            <v-card class="bodyCard">
                <v-row no-gutters class="px-3">
                    <!-- FIRST & SECOND COL -->
                    <v-col cols="12" md="12" sm="12" class="pb-0 pt-3">
                        <v-row>
                            <!-- FIRST COL -->
                            <v-col v-if="showDriverCol" cols="12" md="2" sm="2" class="pt-1 pr-0">
                                <!-- DRIVER DROP AREA -->
                                <v-card class="transportUnitDropBox tile" outlined raised v-if="firstCol == 'hauliers'" :style="chosenUnit.length ? {'background-color': '#6fa58568 !important',} : ''">
                                    <span v-if="!chosenUnit.length && transporterTabs != 'contractors'" class="centerText">Drop Transport Unit Here</span>
                                    <span v-if="!chosenContractor.length &&transporterTabs == 'contractors'" class="centerText">Drop Contractor Here</span>
                                    <v-list nav dense v-if="transporterTabs == 'internal'">
                                        <draggable class="tranportsUnitDropZone" :list="chosenUnit" :animation="200" ghost-class="ghost-card" group="tranportsUnit">
                                            <v-list-item :key="unit.id" three-line outlined color="green" v-for="unit in chosenUnit" style="text-align: left">
                                                <v-list-item-avatar size="45">
                                                    <img v-if="unit.transportDrivers[0].user.avatar" :src="unit.transportDrivers[0].user.avatar" />
                                                    <v-icon v-if="!unit.transportDrivers[0].user.avatar">person</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ unit.transportDrivers[0].user.firstname }}
                                                        {{ unit.transportDrivers[0].user.surname }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-if="unit.transportTrailers.length > 0">
                                                        <v-icon small>rv_hookup</v-icon>
                                                        {{ unit.transportTrailers.length }}
                                                        {{ unit.transportTrailers[0].type }}
                                                        <span v-if="unit.transportTrailers.length > 1">
                                                            trailers
                                                        </span>
                                                        <span v-if="unit.transportTrailers.length === 1">
                                                            trailer
                                                        </span>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else>
                                                        <v-icon>rv_hookup</v-icon> No trailers connected
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        <div v-if="unit.distanceFromEmpty && chosenBooking[0]">
                                                            <v-icon small color="green">location_on</v-icon>
                                                            {{ unit.distanceFromEmpty }} from empty pickup
                                                        </div>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                    <v-list nav dense v-if="transporterTabs == 'contractors'">
                                        <draggable class="tranportsUnitDropZone" :list="chosenContractor" :animation="200" ghost-class="ghost-card" group="contractor">
                                            <v-list-item :key="contractor.id" three-line outlined color="green" v-for="contractor in chosenContractor.filter(x => x.id != $store.state.currentOrg.id)" style="text-align: left;">
                                                <v-list-item-avatar class="mt-5">
                                                    <img v-if="contractor.icon" :src="contractor.icon" />
                                                    <template slot="placeholder">
                                                        <v-icon style="opacity: 0.5" size="35">
                                                            business
                                                        </v-icon>
                                                    </template>
                                                    <v-icon v-if="!contractor.icon">business</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ contractor.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </v-card>

                                <!-- LIST OF DRIVERS AND CONTRACTORS -->
                                <v-card outlined class="transportUnits tile" v-if="firstCol == 'hauliers'">

                                    <v-toolbar flat color="greyRaised" class="haulierTab" height="40">
                                        <v-tabs class="tabby" dense height="38" v-model="transporterTabs">
                                            <v-tab class="transparent" href="#internal" style="font-size: smaller">Internal</v-tab>
                                            <v-tab class="transparent" href="#contractors" style="font-size: smaller">Contractors</v-tab>
                                        </v-tabs>
                                        <v-spacer></v-spacer>
                                        <v-btn plain icon>
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-tabs-items v-model="transporterTabs">
                                        <!-- COMPANY DRIVERS -->
                                        <v-tab-item value="internal">
                                            <v-card tile style="
                                                        overflow-y: scroll;
                                                        height: 75vh;
                                                        background-color: #282828 !important;">
                                                <v-list nav dense :key="transportUnitsKey">
                                                    <draggable class="haulierList" :list="chosenBooking[0] ? sortedUnits : units" :animation="200" ghost-class="ghost-card" group="tranportsUnit">
                                                        <v-list-item class="transparent mb-0 px-0" two-line dense v-for="unit in chosenBooking[0] ? sortedUnits : units" :key="unit.id" :style="unit.bestUnit && chosenBooking[0] ? {'background-color':'#6fa58568 !important',}: ''" color="green">
                                                            <v-list-item-avatar size="35">
                                                                <img v-if="unit.transportDrivers[0].user.avatar" :src="unit.transportDrivers[0].user.avatar" />
                                                                <v-icon v-if="!unit.transportDrivers[0].user.avatar">local_shipping</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title style="font-size: small">
                                                                    {{unit.transportDrivers[0].user.firstname}}
                                                                    {{unit.transportDrivers[0].user.surname}}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle v-if="unit.transportTrailers.length > 0">
                                                                    <v-icon small>rv_hookup</v-icon>
                                                                    {{ unit.transportTrailers.length }}
                                                                    {{ unit.transportTrailers[0].type }}
                                                                    <span v-if="unit.transportTrailers.length > 1">
                                                                        trailers
                                                                    </span>
                                                                    <span v-if="unit.transportTrailers.length === 1">
                                                                        trailer
                                                                    </span>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else>
                                                                    <v-icon>rv_hookup</v-icon> No trailers
                                                                    connected
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle>
                                                                    <div v-if="unit.distanceFromEmpty && chosenBooking[0]">
                                                                        <v-icon small color="green">location_on</v-icon>
                                                                        {{ unit.distanceFromEmpty }} from empty pickup
                                                                    </div>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </draggable>
                                                </v-list>
                                            </v-card>
                                        </v-tab-item>

                                        <!-- CONTRACTORS -->
                                        <v-tab-item value="contractors">
                                            <v-card tile style="overflow-y: scroll;height: 73vh;background-color: #282828 !important;">
                                                <v-list nav dense>
                                                    <draggable class="haulierList" :list="contractors" :animation="200" ghost-class="ghost-card" :group="transporterTabs == 'contractors' ? 'contractor' : 'transUnits'">
                                                        <v-list-item three-line outlined v-for="contractor in contractors.filter(x => x.id != $store.state.currentOrg.id)" :key="contractor.id" color="green">
                                                            <v-list-item-avatar size="45">
                                                                <img v-if="contractor.icon" :src="contractor.icon" />
                                                                <template slot="placeholder">
                                                                    <v-icon style="opacity: 0.5" size="45">
                                                                        business
                                                                    </v-icon>
                                                                </template>
                                                                <v-icon v-if="!contractor.icon">business</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ contractor.name }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </draggable>
                                                </v-list>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </v-col>

                            <!-- SECOND COL -->
                            <v-col cols="12" :md="showDriverCol ? 10 : 12" :sm="showDriverCol ? 10 : 12" class="pb-0 pt-2" style="contain: content">
                                <!-- LIST OF BOOKINGS -->
                                <v-btn class="hideDriverButton" @click="showDriverCol = !showDriverCol">
                                    <v-icon>{{showDriverCol ? 'expand_more' : 'expand_less'}}</v-icon>
                                </v-btn>
                                <v-card outlined class="jobListings tile">
                                    <TransportDispatch :chosenUnit="chosenUnit" @assign-driver="createContainerHaulage" />
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            <!-- <v-tab-item value="poiTesting" style="height: inherit">
              <v-card>
                <PoiTesting />
              </v-card>
            </v-tab-item> -->
            <!-- <v-tab-item value="activeJobs">
                    <v-card>
                        <ActiveJobs />
                    </v-card>
                </v-tab-item>

                <v-tab-item value="bookings">
                    <v-card>
                        <Bookings />
                    </v-card>
                </v-tab-item>

                <v-tab-item value="reporting">
                    <v-card>
                        <Reporting />
                    </v-card>
                </v-tab-item>

                <v-tab-item value="subcontractors">
                    <v-card>
                        <Subcontracts />
                    </v-card>
                </v-tab-item>`
            </v-tabs-items> -->

        </v-card>
    <v-dialog width="1200px" v-model="bookingCard" overlay-opacity="0.9">
        <v-card v-if="bookingCard" height="600px" style="background-color: var(--v-greyRaised-base) !important">
            <v-row class="d-flex justify-center" dense>
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">tag</v-icon> <br />
                    <!-- <span style="font-size: x-small">Customer Ref: <b>{{ bookingDetails.customerReference }}</b></span> <br /> -->
                    <span style="font-size: x-small">CTO Number: <b>{{ bookingDetails.ctoNo }}</b>
                    </span>
                    <br />
                    <span style="font-size: x-small">Customer Container Ref:
                        <b>{{ bookingDetails.customerContainerRef }}</b>
                    </span>
                    <br />
                    <span style="font-size: x-small">Seal No: <b>{{ bookingDetails.sealNo }}</b>
                    </span>
                    <br />
                </v-col>
                <v-divider vertical inset />
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">outbound</v-icon><br />
                    <span style="font-size: small">
                        {{ bookingDetails.booking.shipperName }}</span><br />
                    <span style="font-size: x-small">Shipper</span>
                </v-col>
                <v-divider vertical />
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">fast_forward</v-icon><br />
                    <span style="font-size: small">
                        {{ bookingDetails.booking.agentName }}</span><br />
                    <span style="font-size: x-small">Forwarder</span>
                </v-col>
                <v-divider vertical />
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">local_shipping</v-icon><br />
                    <span style="font-size: small">
                        <!-- {{ bookingDetails.transporter }} -->
                        WERDA CARGO
                    </span>
                    <br />
                    <span style="font-size: x-small">Haulier</span>
                </v-col>
                <v-divider vertical />
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">directions_boat_filled</v-icon><br />
                    <span style="font-size: small">
                        {{ bookingDetails.booking.mainCarriageVesselName }}</span><br />
                    <span style="font-size: x-small">Vessel</span>
                </v-col>
                <v-divider vertical />
                <v-col cols="12" md="2" sm="2" class="text-center">
                    <v-icon class="mt-2 mb-2">assignment</v-icon><br />
                    <!-- <span
              v-if="bookingDetails.secondLoadingPoint"
              style="font-size: small"
            >
              {{ bookingDetails.ppecbBookingLoadPoints[0].containerType }}
              {{ bookingDetails.ppecbBookingLoadPoints[0].unitOfMeasure }}</span
            ><br v-if="bookingDetails.ppecbBookingLoadPoints.length == 1" /> -->
                    <!-- <span
              v-if="bookingDetails.ppecbBookingLoadPoints.length == 2"
              style="font-size: small"
            >
              {{
                bookingDetails.ppecbBookingLoadPoints[0].quantity +
                bookingDetails.ppecbBookingLoadPoints[1].quantity
              }}
              {{
                bookingDetails.ppecbBookingLoadPoints[0].unitOfMeasure
              }}S</span
            ><br v-if="bookingDetails.ppecbBookingLoadPoints.length == 2" />
            <span
              v-if="bookingDetails.ppecbBookingLoadPoints.length > 2"
              style="font-size: small"
            >
              {{ bookingDetails }}</span
            ><br v-if="bookingDetails.ppecbBookingLoadPoints.length > 2" /> -->
                    <span style="font-size: small">40ft Reefer </span>
                    <span style="font-size: x-small">Haul</span>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="4" sm="4">
                    <v-card style="background-color: var(--v-greyRaised-base) !important; margin: 2vh 0 0 1vw;" tile flat>
                        <v-subheader>Haulage Instructions</v-subheader>
                        <v-card-text>
                            <b>Cold Treatment:</b>
                            <span v-if="bookingDetails.isReeferContainer">
                                <v-icon style="margin: 0 0 0 0.5vw" size="20" color="success">check_circle</v-icon>
                            </span>
                            <span v-if="!bookingDetails.isReeferContainer">
                                <v-icon style="margin: 0 0 0 0.5vw" size="20" color="primary">cancel</v-icon>
                            </span>
                            <br />
                            <b>Genset:</b>
                            <span v-if="bookingDetails.isGensetRequired">
                                <v-icon style="margin: 0 0 0 0.5vw" size="20" color="success">check_circle</v-icon>
                            </span>
                            <span v-if="!bookingDetails.isGensetRequired">
                                <v-icon style="margin: 0 0 0 0.5vw" size="20" color="primary">cancel</v-icon>
                            </span>
                            <!-- <br />
                <b>Transhipment:</b>
                <span v-if="bookingDetails.isTranshipment">
                  <v-icon style="margin: 0 0 0 0.5vw" size="20" color="success"
                    >check_circle</v-icon
                  >
                </span>
                <span v-if="!bookingDetails.isTranshipment">
                  <v-icon style="margin: 0 0 0 0.5vw" size="20" color="primary"
                    >cancel</v-icon
                  >
                </span> -->
                            <!-- <br />
                <b>Vents:</b>
                <span
                  v-if="
                    bookingDetails.ventSetting !== 'Closed' &&
                    bookingDetails.ventSetting !== 'CLOSED'
                  "
                >
                  {{ bookingDetails.ventSetting }} CBM
                </span>
                <span
                  v-if="
                    bookingDetails.ventSetting == 'Closed' ||
                    bookingDetails.ventSetting == 'CLOSED'
                  "
                >
                  {{ bookingDetails.ventSetting }}
                </span> -->
                            <!-- <br />
                <b>Product: </b>
                <span v-if="bookingDetails.productName">
                  {{ bookingDetails.productName }} ({{
                    bookingDetails.ppecbBookingProducts[0].productCode
                  }})
                </span>
                <br />
                <b>Regime Code:</b>
                <span v-if="bookingDetails.regimeCode">
                  {{ bookingDetails.regimeCode }}
                </span>
                <br /> -->
                            <!-- Description: <span v-if="bookingDetails.regimeData"> {{ bookingDetails.regimeData.description }}
                thennntntjk jjehtjhejhjjTt TThe aisskj  Rgggw TRHTe  dds The tttth Theheiu THHhheh THhehhiii Teyhehjhja
                </span> <br /> -->
                            <!-- <b>Load Specification:</b>
                <span v-if="bookingDetails.regimeData">
                  {{ bookingDetails.regimeData.loadSpecification }}
                </span>
                <br />
                <b>Regime Instruction:</b>
                <span v-if="bookingDetails.regimeData">
                  {{ bookingDetails.regimeData.recipeDescription }}
                </span>
                <br />
                <b>Max Product Temp:</b>
                <span v-if="bookingDetails.regimeData">
                  {{ bookingDetails.regimeData.maxProductTemp }}°C
                </span>
                <br />
                <b>Min Product Temp:</b>
                <span v-if="bookingDetails.regimeData">
                  {{ bookingDetails.regimeData.minProductTemp }}°C
                </span>
                <br />
                <b>Set Point Temp:</b>
                <span v-if="bookingDetails.regimeData">
                  {{ bookingDetails.regimeData.setPointTemp }}°C
                </span>
                <br /> -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import draggable from "vuedraggable";
import ActiveJobs from "./TransportJobs/ActiveJobs.vue";
import { getGoogleMapsAPI } from "gmap-vue";
import TransportDispatch from "../Bookings/TransportDispatch.vue";
import Reporting from "./TransportJobs/Reporting.vue";
import Subcontracts from "./TransportJobs/Subcontracts.vue";
// import PoiTesting from "./TransportJobs/PoiTesting.vue";
export default {
    components: {
        draggable,
        // ActiveJobs,
        // Bookings,
        TransportDispatch,
        // Reporting,
        // Subcontracts,
        // PoiTesting,
    },
    data: () => ({
        firstCol: "hauliers",
        showDriverCol: true,
        allUnits: [],
        clients: [],
        activeJobs: [],
        contractors: [],
        sortedUnits: [],
        loadingUnits: [],
        revealUnit: false,
        transportUnitsKey: 0,
        stepper: 1,
        transporterTabs: null,
        searchShippers: false,
        shippersSearching: "",
        bookingCard: false,
        legs: [],
        fullRoute: [],
        driverTab: null,
        jobTabs: null,
        clientBookings: 0,
        disableTabs: false,
        referenceInput: "",
        bookingDetails: [],
        units: [],
        chosenUnit: [],
        chosenContractor: [],
        chosenBooking: [],
        bookings: [],
        summed: 0,
        mapOptions: {
            scrollwheel: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        },
        params: {
            limit: 10,
            offset: 0,
            search: "",
        },
        center: {
            lat: -33.48808586,
            lng: 19.62930204,
        },
        colors: [
            "#ff6666",
            "#00aaff",
            "#0becb8",
            "#9068af",
            "#006bc3",
            "#f37aaf",
            "#f9f871",
            "#ffb96f",
            "#006d66",
            "#00b673",
        ],
        loadingCreate: false,
        selectedContainers: null,
    }),
    watch: {
        transporters(val) {
            if (val.length > 0) {
                this.transportUnitsKey++;
            }
        },

        clientBookings(val) {
            console.log(val);
        },

        chosenUnit(val) {
            if (val) {
                console.log('ChosenUnit', val)
            }
            if (val.length > 1) {
                this.units.push(val[1]);
                this.chosenUnit.splice(1, 1);
            }
        },

        chosenBooking(val) {
            if (val.length > 1) {
                if (this.chosenBooking[1].booking.shipperName == this.clientBookings) {
                    this.bookings.push(val[1]);
                }
                this.chosenBooking.splice(1, 1);
            }
            if (val.length > 0) {
                if (val[0].emptyPoi) {
                    this.center = {
                        lat: val[0].emptyPoi.lat,
                        lng: val[0].emptyPoi.lng,
                    };
                }
                this.sortBestTransporters();
                this.getRoute();
            } else {
                this.disableTabs = false;
            }
        },

        bookings: {
            handler(newValue) {
                const currentTab = this.clients.find(
                    (tab) => tab.exporterCode === this.clientBookings
                );
                if (this.bookings.length == 0) {
                    // create a setTimeout to wait for the data to be loaded
                    setTimeout(() => {
                        if (this.bookings.length != 0) {
                            if (currentTab) {
                                currentTab.count = newValue.length;
                            }
                        }
                    }, 1000);
                } else {
                    if (currentTab) {
                        currentTab.count = newValue.length;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        google: getGoogleMapsAPI,

        PoiMarkers() {
            let markers = [];
            if (this.chosenBooking[0].emptyPoi) {
                markers.push(this.chosenBooking[0].emptyPoi);
            }
            if (this.chosenBooking[0].fullLoadingPoi) {
                markers.push(this.chosenBooking[0].fullLoadingPoi);
            }
            if (this.chosenBooking[0].secondLoadingPoi) {
                markers.push(this.chosenBooking[0].secondLoadingPoi);
            }
            if (this.chosenBooking[0].portOfLoadingPoi) {
                markers.push(this.chosenBooking[0].portOfLoadingPoi);
            }
            return markers;
        },

        favoriteClients() {
            return this.clients.filter((client) => {
                return client.isFavorite;
            });
        },
    },
    async created() {
        this.contractors = await this.$API.getContractors(
            this.$store.state.currentOrg.id
        );

        this.units = await this.$API.getAvailableUnits();
        this.allUnits = this.units;
        this.$socket.send(
            JSON.stringify({
                action: "join",
                room: "haulage_" + 2507,
                user: {
                    id: this.$store.state.user.id,
                    name: this.$store.state.user.firstname +
                        " " +
                        this.$store.state.user.surname,
                    avatar: this.$store.state.user.avatar,
                    company: this.$store.state.currentOrg.name,
                },
            })
        );

        this.$API.getJobCountByOrg().then((clients) => {
            this.clients = clients;
            if (this.clients != null && this.clients.length > 0) {
                this.clientBookings = 0;
                this.getJobsByOrg(
                    this.clients[0].shipperName,
                    this.clients[0].noContainers
                );
            }
        });
        this.activeJobs = await this.$API.getEmptyPickups();
    },
    mounted() {
        this.$socket.onopen = () => {
            this.$socket.send(
                JSON.stringify({
                    action: "join",
                    room: "haulage_" + this.$store.state.currentOrg.id,
                    user: {
                        id: this.$store.state.user.id,
                        name: this.$store.state.user.firstname +
                            " " +
                            this.$store.state.user.surname,
                        avatar: this.$store.state.user.avatar,
                        company: this.$store.state.currentOrg.name,
                    },
                })
            );
        };
        this.$socket.onmessage = (data) => {
            let message = JSON.parse(data.data);
            let obj = JSON.parse(message);
            let result = JSON.parse(obj.data);
            this.whatsappStatusUpdate(result);
        };
    },

    unmounted() {
        delete this.$socket.onmessage;
    },

    methods: {
        async getRoute() {
            if (
                this.chosenBooking[0]?.emptyPoi &&
                this.chosenBooking[0]?.portOfLoadingPoi
            ) {
                let start = {
                    lat: this.chosenBooking[0].emptyPoi.lat,
                    lng: this.chosenBooking[0].emptyPoi.lng,
                };
                let end = {
                    lat: this.chosenBooking[0].portOfLoadingPoi.lat,
                    lng: this.chosenBooking[0].portOfLoadingPoi.lng,
                };
                let waypoints = [];

                if (this.chosenBooking[0].fullLoadingPoi) {
                    waypoints.push({
                        location: new this.google.maps.LatLng(
                            parseFloat(this.chosenBooking[0].fullLoadingPoi.lat),
                            parseFloat(this.chosenBooking[0].fullLoadingPoi.lng)
                        ),
                        stopover: true,
                    });
                }
                if (this.chosenBooking[0].secondLoadingPoi) {
                    waypoints.push({
                        location: new this.google.maps.LatLng(
                            parseFloat(this.chosenBooking[0].secondLoadingPoi.lat),
                            parseFloat(this.chosenBooking[0].secondLoadingPoi.lng)
                        ),
                        stopover: true,
                    });
                }
                const directionsService = new this.google.maps.DirectionsService();
                const origin = new this.google.maps.LatLng(
                    parseFloat(start.lat),
                    parseFloat(start.lng)
                );
                const destination = new this.google.maps.LatLng(
                    parseFloat(end.lat),
                    parseFloat(end.lng)
                );
                this.legs = [];
                const request = {
                    origin,
                    destination,
                    waypoints: waypoints ? waypoints : [],
                    travelMode: "DRIVING",
                };

                directionsService.route(request, (result, status) => {
                    if (status === "OK") {
                        console.log("Got something back: ", result);
                        let colorIndex = 0;
                        this.legs = result.routes[0].overview_path;
                        this.fullRoute = result.routes[0].legs;
                        result.routes[0].legs.forEach((leg) => {
                            leg.steps.forEach((step) => {
                                this.legs.push(step);
                                step.color = this.colors[colorIndex % this.colors.length];
                            });
                            colorIndex++;
                        });
                    } else {
                        console.error("Directions request failed due to " + status);
                    }
                });
            } else {
                this.legs = [];
                return;
            }
        },

        async sortBestTransporters() {
            let allUnits = [];
            this.sortedUnits = [];
            allUnits = this.units;
            this.loadingUnits = true;
            this.sortedUnits = this.units;

            if (this.chosenBooking.length > 0) {
                let bestUnits = allUnits.filter((unit) => {
                    if (
                        unit.transportTrailers[0] &&
                        (unit.transportTrailers[0].type === "Flatbed" ||
                            unit.transportTrailers[0].type === "Skeleton")
                    ) {
                        return unit;
                    }
                });
                allUnits = bestUnits;

                const directionsService = new this.google.maps.DirectionsService();
                const routePromises = allUnits.map((unit) => {
                    const origin = new this.google.maps.LatLng(
                        parseFloat(unit.lat),
                        parseFloat(unit.lng)
                    );
                    const destination = new this.google.maps.LatLng(
                        parseFloat(this.chosenBooking[0].emptyPoi.lat),
                        parseFloat(this.chosenBooking[0].emptyPoi.lng)
                    );
                    const request = {
                        origin,
                        destination,
                        travelMode: "DRIVING",
                    };

                    return new Promise((resolve) => {
                        directionsService.route(request, (result, status) => {
                            if (status === "OK") {
                                unit.distanceFromEmpty = result.routes[0].legs[0].distance.text;
                                unit.valueOfDistance = result.routes[0].legs[0].distance.value;
                            } else {
                                unit.distanceFromEmpty = "N/A";
                                unit.valueOfDistance = Number.POSITIVE_INFINITY;
                            }
                            resolve(); // Resolve the promise when the route is processed
                        });
                    });
                });

                // Wait for all route promises to resolve
                Promise.all(routePromises).then(() => {
                    const closestSkeleton = allUnits
                        .filter((unit) => unit.transportTrailers[0].type === "Skeleton")
                        .reduce(
                            (closest, unit) => {
                                return closest.valueOfDistance < unit.valueOfDistance ?
                                    closest :
                                    unit;
                            }, { valueOfDistance: Number.POSITIVE_INFINITY }
                        );

                    const closestFlatbed = allUnits
                        .filter((unit) => unit.transportTrailers[0].type === "Flatbed")
                        .reduce(
                            (closest, unit) => {
                                return closest.valueOfDistance < unit.valueOfDistance ?
                                    closest :
                                    unit;
                            }, { valueOfDistance: Number.POSITIVE_INFINITY }
                        );

                    allUnits.sort((a, b) => {
                        if (a.transportTrailers[0].type !== b.transportTrailers[0].type) {
                            if (a.transportTrailers[0].type === "Skeleton") {
                                return -1; // a is a skeleton, b is a flatbed, a comes before b
                            } else if (b.transportTrailers[0].type === "Skeleton") {
                                return 1; // b is a skeleton, a is a flatbed, b comes before a
                            } else {
                                return 0; // Types are different, maintain their original order
                            }
                        } else {
                            return a.valueOfDistance - b.valueOfDistance; // Sort by distance
                        }
                    });

                    // Set the bestUnit property for the closest skeleton and flatbed
                    closestSkeleton.bestUnit = true;
                    closestFlatbed.bestUnit = true;
                });
            }
            this.sortedUnits = allUnits;
            this.loadingUnits = false;
        },

        async getTransportUnits() {
            this.units = await this.$API.getTransportUnits();
            this.allUnits = this.units;
        },

        async getJobsByOrg(shipperName, noContainers) {
            this.bookings = [];
            this.bookings = await this.$API.getJobsByOrg({
                params: {
                    search: shipperName,
                    limit: 20,
                },
            });
        },

        whatsappStatusUpdate(payload) {
            if (payload.status) {
                if (payload.status == "Accepted") {
                    this.activeJobs.find(
                        (job) => job.orderNumber == payload.orderNumber
                    ).status = "ACCEPTED";
                } else if (payload.status == "Rejected") {
                    this.activeJobs.find(
                        (job) => job.orderNumber == payload.orderNumber
                    ).status = "REJECTED";
                } else if (payload.status == "Picked Up") {
                    this.activeJobs.find(
                        (job) => job.orderNumber == payload.orderNumber
                    ).status = "PICKED UP";
                } else if (payload.status == "Delivered") {
                    this.activeJobs.find(
                        (job) => job.orderNumber == payload.orderNumber
                    ).status = "DELIVERED";
                }
            } else if (payload.rejectReason) {
                this.activeJobs.find(
                    (job) => job.orderNumber == payload.orderNumber
                ).rejectReason = payload.rejectReason;
                if (this.clientBookings == payload.exporterCode) {
                    this.activeJobs.find(
                        (job) => job.orderNumber == payload.orderNumber
                    ).jobCache.status = "REJECTED";
                    this.bookings.unshift(
                        this.activeJobs.find(
                            (job) => job.orderNumber == payload.orderNumber
                        ).jobCache
                    );
                }
            }
        },

        async createContainerHaulage(newContainerHaulage) {
            this.allUnits.push(JSON.parse(JSON.stringify(this.chosenUnit[0])))
            this.chosenUnit = [];
        },

        selectedUnit() {
            this.selected = true;
            this.revealUnit = true;
        },
    },
};
</script>

<style scoped>
.mainCard {
    height: 96vh;
}

.transparent {
    background-color: transparent !important;
}

.tile {
    border-radius: 16px;
    background-color: #282828 !important;
}

.bodyCard {
    height: inherit;
}

.transportUnits {
    contain: content;
    height: 80.5vh;
}

.hideDriverButton {
    background-color: var(--v-greyBase-base) !important;
    position: absolute;
    left: 0px;
    z-index: 5;
    top: 50%;
    transform: translateX(-50%) translateY(80%);
    rotate: 90deg;
    border-radius: 16px 16px 0 0;
    height: 15px !important;
    min-height: 15px !important;
    padding: 0;
    margin: 0;
}

.transportUnitDropBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7vh;
    margin: 0.5vh 0 1.5vh 0;
    position: relative;
    contain: content;
}

.tranportsUnitDropZone {
    height: 8vh;
    width: 15vw;
    margin: 0 0 0 0;
    position: relative;
    display: flex;
    place-content: center;
}

.centerText {
    position: absolute;
    pointer-events: none;
    text-align: center;
    font-size: 1.15rem;
    color: var(--v-primaryText-base);
    opacity: 0.1;
}

.jobListings {
    contain: content;
    height: 89vh;
    margin: 0 0 0 0;
    position: relative;
}

.haulierList {
    height: 60vh;
    cursor: pointer;
}

.activeJobs {
    contain: content;
    height: 80.5vh;
    margin: 0 0 0 0;
    position: relative;
}

.haulierTab ::v-deep div.v-toolbar__content {
    padding: 0 5px 0 10px;
}

.tabby ::v-deep .v-tabs-bar__content {
    background-color: transparent !important;
}

.ghost-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 2px solid #ff6666;
    border-radius: 5px;
    height: auto;
}
</style>

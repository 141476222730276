<template>
  <v-card style="height: calc(96vh - 84px);">
    <v-row justify="end">
        <v-col cols="12" sm="12" md="8" lg="4">
        <el-input
            id="search"
            suffix-icon="el-icon-search"
            v-model="search"
            clearable
            placeholder="Search"
          >
            <template slot="prepend">
              <el-button @click="addDrivers()"
                ><i class="el-icon-plus"></i
              ></el-button>
            </template>
          </el-input>
      </v-col>
    </v-row>
    <v-data-table
        height="77vh"
        id="vehicleTable"
        style="cursor: pointer"
        :search="search"
        :calculate-widths="true"
        :items="drivers.data"
        :single-expand="true"
        :loading="loading"
        :headers="headers"
        @click:row="showDriver"
        :options="{
          page: params.offset + 1,
          itemsPerPage: params.limit,
          pageStart: params.offset + 1,
          pageStop: Math.round(drivers.total / this.params.limit),
          pageCount: Math.round(drivers.total / this.params.limit),
          itemsLength: drivers.total
        }"
      >
      <template v-slot:[`item.user.firstname`]="{ item }">
        {{ item.user.firstname + " " +  item.user.surname }} 
      </template>
      <template v-slot:[`item.isContractor`]="{ item }">
        {{ item.isContractor ? 'Contractor' : item.isPermanent ? 'Permanent' : 'Temporary' }}
      </template>
      </v-data-table>

      <v-dialog v-model="addDriverCard" width="60vw">
        <v-card height="auto">
          <v-toolbar flat dense>
            <v-toolbar-title>Add Driver</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingDriverSave" :disabled="cannotSaveYet" icon @click="addNewDriver()">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="addDriverCard = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
              <div :key="avatarKey" style="height: 24vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-avatar  size="200" class="avatar-uploader" v-if="!userAvatar" @click="openFileExplorer('imageUpload')">
                  <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                </v-avatar>
                <v-avatar size="200" class="avatar-uploaded" v-if="userAvatar" @click="openFileExplorer('imageUpload')">
                  <v-img :src="userAvatar"></v-img>
                </v-avatar>
                <input
                    id="imageUpload"
                    type="file"
                    hidden
                    accept="image/jpeg, image/png"
                    @change="onFileChanged($event, 'imageUpload')"
                  />
                  <span class="mt-5">Required Fields</span>
              </div>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field dense outlined hide-details class="mb-4" label="First Name" persistent-placeholder placeholder="Enter First Name" v-model="addDriver.user.firstname"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Nickname" persistent-placeholder placeholder="Enter Nickname" v-model="addDriver.nickname"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="ID number" persistent-placeholder placeholder="Enter ID number" v-model="addDriver.user.idNumber"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field dense outlined hide-details class="mb-4" label="Last Name" persistent-placeholder placeholder="Enter Last Name" v-model="addDriver.user.surname"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Cell Number" persistent-placeholder placeholder="Enter Cell Number" v-model="addDriver.user.mobileNumber"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Passport Number" persistent-placeholder placeholder="Enter Passport Number" v-model="addDriver.passportNo"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" class="d-flex align-center " style="flex-direction: column;" >
                  <v-radio-group v-model="addDriver.employment" class="mt-0" row hide-details>
                    <v-radio style="scale: 0.9; opacity: 0.8" label="Permanent" value="Permanent" />
                    <v-radio style="scale: 0.9; opacity: 0.8" label="Temporary" value="Temporary" />
                    <v-radio style="scale: 0.9; opacity: 0.8" label="Subcontractor" value="Subcontractor" />
                  </v-radio-group>
                  <v-checkbox class="mt-5" style="scale: 0.9; opacity: 0.8" hide-details label="Driver has Whatsapp" v-model="addDriver.hasWhatsapp"></v-checkbox>
                </v-col>
              </v-row>
              </v-col>
              <v-col cols="12" sm="6" >
                <span style="text-align: center; display: block; contain: content;">
                  Documents
                  <v-btn x-small style="position: absolute; right: 0; top: 0" icon v-if="documents.length > 0" @click="openFileExplorer('driverDocumentUpload')">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </span>
                    <v-divider></v-divider>
                  <div v-if="documents.length == 0" style="height: 19.5vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                    <v-btn @click="openFileExplorer('driverDocumentUpload')" icon class="docAdd" v-if="documents.length == 0"><v-icon>post_add</v-icon></v-btn>
                  </div>
                  <input
                      id="driverDocumentUpload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                      @change="fileSubjectSelect($event, 'driverDocumentUpload')"
                    />
                  <div style="height: 19.5vh; contain: content; overflow-y: scroll;" v-if="documents.length > 0">
                    <v-list width="100%" dense style="column-count: 2;" v-if="documents.length > 0">
                      <v-list-item v-for="(document, index) in documents" :key="index">
                        <v-list-item-icon>
                          <v-img
                                class="ml-2"
                                style="height: 28px; width: 30px"
                                :src="getIcon(document.name)"
                              />
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{ document.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ document.subject ? document.subject : '' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                      </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                <span style="display: block; text-align: center;">
                  Dates
                </span>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- START DATE -->
                    <v-menu v-model="startMenuCreate" ref="create_start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Date Started" persistent-placeholder placeholder="Enter Start Date" v-model="addDriver.dateStarted"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_start_menu', 'dateStarted')" v-model="addDriver.dateStarted" :active-picker.sync="activePicker" :max="maxDate" min="1980-01-01"/>
                    </v-menu>
                    <!-- LICENSE DATE -->
                    <v-menu v-model="licenseMenuCreate" ref="create_license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="License Expiry" persistent-placeholder placeholder="Enter License Expiry" v-model="addDriver.licenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_license_menu', 'licenseExpiry')" v-model="addDriver.licenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- PDP DATE -->
                    <v-menu v-model="pdpMenuCreate" ref="create_pdp_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="PDP Expiry" persistent-placeholder placeholder="Enter PDP Expiry" v-model="addDriver.pdpExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_pdp_menu', 'pdpExpiry')" v-model="addDriver.pdpExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- PORT ACCESS DATE -->
                    <v-menu v-model="portAccessMenuCreate" ref="create_portAccess_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Port-Access Expiry" persistent-placeholder placeholder="Enter Port-Access Expiry" v-model="addDriver.portAccessExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_portAccess_menu', 'portAccessExpiry')" v-model="addDriver.portAccessExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>

                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- END DATE -->
                    <v-menu v-model="endMenuCreate" ref="create_end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Date End" persistent-placeholder placeholder="Enter End Date" v-model="addDriver.dateEnded"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_end_menu', 'dateEnded')" v-model="addDriver.dateEnded" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                    <!-- PASSPORT DATE -->
                    <v-menu v-model="passportMenuCreate" ref="create_passport_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Passport Expiry" persistent-placeholder placeholder="Enter Passport Expiry" v-model="addDriver.passportExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_passport_menu', 'passportExpiry')" v-model="addDriver.passportExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- MEDICAL DATE -->
                    <v-menu v-model="medicalMenuCreate" ref="create_medical_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Medical Expiry" persistent-placeholder placeholder="Enter Medical Expiry" v-model="addDriver.medicalExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_medical_menu', 'medicalExpiry')" v-model="addDriver.medicalExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                    <!-- INDUCTION DATE -->
                      <v-menu v-model="inductionMenuCreate" ref="create_induction_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Induction Expiry" persistent-placeholder placeholder="Enter Induction Expiry" v-model="addDriver.inductionExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('create_induction_menu', 'inductionExpiry')" v-model="addDriver.inductionExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                      <!-- MEDICAL DATE -->
                      <v-menu v-model="visaMenuCreate" ref="create_visa_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-on="on" v-bind="attrs" :disabled="!addDriver.visa" dense outlined hide-details class="mb-4" label="Visa Expiry" persistent-placeholder placeholder="Enter Visa Expiry" v-model="addDriver.visaExpiry"/>
                        </template>
                      <v-date-picker @change="saveTheDate('create_visa_menu', 'visaExpiry')" v-model="addDriver.visaExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <v-checkbox style="opacity: 0.8" dense class="ma-0" hide-details v-model="addDriver.visa" label="Visa"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <!-- MEDICAL DATE -->
                    <v-menu v-model="hazChemMenuCreate" ref="create_hazChem_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-on="on" v-bind="attrs" :disabled="!addDriver.hazChem" dense outlined hide-details class="mb-4" label="HazChem Expiry" persistent-placeholder placeholder="Enter HazChem Expiry" v-model="addDriver.hazChemExpiry"/>
                        </template>
                      <v-date-picker @change="saveTheDate('create_hazChem_menu', 'hazChemExpiry')" v-model="addDriver.hazChemExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <v-checkbox style="opacity: 0.8" dense class="ma-0" hide-details v-model="addDriver.hazChem" label="HazChem"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog v-model="driverCard" width="60vw">
        <v-card height="auto" v-if="driverProfile">
          <v-toolbar flat dense>
            <v-toolbar-title v-if="driverProfile">{{driverProfile.user.firstname + ' ' + driverProfile.user.surname}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="!editing" icon @click="editDriver()"><v-icon>edit</v-icon></v-btn>
            <v-btn v-if="editing" @click="cancelEdit()" icon><v-icon>edit_off</v-icon></v-btn>
            <v-btn v-if="editing" :loading="loadingDelete" icon @click="areYouSure = true"><v-icon>delete</v-icon></v-btn>
            <v-btn v-if="editing" :loading="loadingDriverUpdate" :disabled="cannotUpdateYet" icon @click="updateDriver()">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="driverCard = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
              <div :key="avatarKey" style="height: 24vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-avatar  size="200" class="avatar-uploader" v-if="!driverProfile.user.avatar" @click="editing ? openFileExplorer('imageUpdate') : ''">
                  <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                </v-avatar>
                <v-avatar size="200" class="avatar-uploaded" v-if="driverProfile.user.avatar" @click="editing ? openFileExplorer('imageUpdate') : ''">
                  <v-img :src="updateAvatar ? updateAvatar : driverProfile.user.avatar"></v-img>
                </v-avatar>
                <input
                    id="imageUpdate"
                    type="file"
                    hidden
                    accept="image/jpeg, image/png"
                    @change="onFileChanged($event, 'imageUpdate')"
                  />
                  <span class="mt-5">Required Fields</span>
                </div>
                <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="First Name" persistent-placeholder placeholder="Enter First Name" v-model="driverProfile.user.firstname"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Nickname" persistent-placeholder placeholder="Enter Nickname" v-model="driverProfile.nickname"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="ID number" persistent-placeholder placeholder="Enter ID number" v-model="driverProfile.user.idNumber"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Last Name" persistent-placeholder placeholder="Enter Last Name" v-model="driverProfile.user.surname"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Cell Number" persistent-placeholder placeholder="Enter Cell Number" v-model="driverProfile.user.mobileNumber"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Passport Number" persistent-placeholder placeholder="Enter Passport Number" v-model="driverProfile.passportNo"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" class="d-flex align-center " style="flex-direction: column;" >
                  <v-checkbox :disabled="!editing" class="mt-5" style="scale: 0.9; opacity: 0.8" hide-details label="Driver has Whatsapp" v-model="driverProfile.hasWhatsapp"></v-checkbox>
                </v-col>
              </v-row>
              </v-col>
              <v-col cols="12" sm="6" >
                <span style="text-align: center; display: block; contain: content;">
                  Documents
                  <v-btn :disabled="!editing" x-small style="position: absolute; right: 0; top: 0" icon v-if="driverProfile.transportDriverDocs && driverProfile.transportDriverDocs.length > 0" @click="openFileExplorer('driverDocumentUpload')">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </span>
                    <v-divider></v-divider>
                    <v-progress-linear indeterminate v-if="loadingDocs"></v-progress-linear>
                  <div v-if="driverProfile.transportDriverDocs && driverProfile.transportDriverDocs.length == 0" style="height: 19.5vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                    <v-btn :disabled="!editing" @click="openFileExplorer('driverDocumentUpload')" icon class="docAdd" v-if="driverProfile.transportDriverDocs && driverProfile.transportDriverDocs.length == 0"><v-icon>post_add</v-icon></v-btn>
                  </div>
                  <input
                      id="driverDocumentUpload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                      @change="fileSubjectSelect($event, 'driverDocumentUpload')"
                    />
                  <div style="height: 19.5vh; contain: content; overflow-y: scroll;" v-if="driverProfile.transportDriverDocs && driverProfile.transportDriverDocs.length > 0">
                    <v-list width="100%" dense style="column-count: 2;" v-if="driverProfile.transportDriverDocs && driverProfile.transportDriverDocs.length > 0" :key="docuKey">
                      <v-list-item v-for="(document, index) in driverProfile.transportDriverDocs" :key="index">
                        <v-list-item-icon>
                          <v-img
                                class="ml-2"
                                style="height: 28px; width: 30px"
                                :src="getIcon(document.name)"
                              />
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{ document.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ document.subject ? document.subject : '' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                      </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                <span style="display: block; text-align: center;">
                  Dates
                </span>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- START DATE -->
                    <v-menu v-model="startMenu" ref="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Date Started" persistent-placeholder placeholder="Enter Start Date" v-model="formatDate(driverProfile.dateStarted).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('start_menu', 'dateStarted')" v-model="driverProfile.dateStarted" :active-picker.sync="activePicker" :max="maxDate" min="1980-01-01"/>
                    </v-menu>
                    <!-- LICENSE DATE -->
                    <v-menu v-model="licenseMenu" ref="license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="License Expiry" persistent-placeholder placeholder="Enter License Expiry" v-model="formatDate(driverProfile.licenseExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('license_menu', 'licenseExpiry')" v-model="driverProfile.licenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- PDP DATE -->
                    <v-menu v-model="pdpMenu" ref="pdp_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="PDP Expiry" persistent-placeholder placeholder="Enter PDP Expiry" v-model="formatDate(driverProfile.pdpExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('pdp_menu', 'pdpExpiry')" v-model="driverProfile.pdpExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- PORT ACCESS DATE -->
                    <v-menu v-model="portAccessMenu" ref="portAccess_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Port-Access Expiry" persistent-placeholder placeholder="Enter Port-Access Expiry" v-model="formatDate(driverProfile.portAccessExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('portAccess_menu', 'portAccessExpiry')" v-model="driverProfile.portAccessExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>

                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- END DATE -->
                    <v-menu v-model="endMenu" ref="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Date End" persistent-placeholder placeholder="Enter End Date" v-model="formatDate(driverProfile.dateEnded).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('end_menu', 'dateEnded')" v-model="driverProfile.dateEnded" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                    <!-- PASSPORT DATE -->
                    <v-menu v-model="passportMenu" ref="passport_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Passport Expiry" persistent-placeholder placeholder="Enter Passport Expiry" v-model="formatDate(driverProfile.passportExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('passport_menu', 'passportExpiry')" v-model="driverProfile.passportExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    <!-- MEDICAL DATE -->
                    <v-menu v-model="medicalMenu" ref="medical_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Medical Expiry" persistent-placeholder placeholder="Enter Medical Expiry" v-model="formatDate(driverProfile.medicalExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('medical_menu', 'medicalExpiry')" v-model="driverProfile.medicalExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                      <!-- INDUCTION DATE -->
                      <v-menu v-model="inductionMenu" ref="induction_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Induction Expiry" persistent-placeholder placeholder="Enter Induction Expiry" v-model="formatDate(driverProfile.inductionExpiry).isoFormat"/>
                      </template>
                      <v-date-picker @change="saveTheDate('induction_menu', 'inductionExpiry')" v-model="driverProfile.inductionExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                      <!-- MEDICAL DATE -->
                      <v-menu v-model="visaMenu" ref="visa_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :readonly="!editing" v-on="on" v-bind="attrs" :disabled="!driverProfile.visa" dense outlined hide-details class="mb-4" label="Visa Expiry" persistent-placeholder placeholder="Enter Visa Expiry" v-model="formatDate(driverProfile.visaExpiry).isoFormat"/>
                        </template>
                      <v-date-picker @change="saveTheDate('visa_menu', 'visaExpiry')" v-model="driverProfile.visaExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <v-checkbox :disabled="!editing" style="opacity: 0.8" dense class="ma-0" hide-details v-model="driverProfile.visa" label="Visa"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <!-- MEDICAL DATE -->
                    <v-menu v-model="hazChemMenu" ref="hazChem_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :readonly="!editing" v-on="on" v-bind="attrs" :disabled="!driverProfile.hazChem" dense outlined hide-details class="mb-4" label="HazChem Expiry" persistent-placeholder placeholder="Enter HazChem Expiry" v-model="formatDate(driverProfile.hazChemExpiry).isoFormat"/>
                        </template>
                      <v-date-picker @change="saveTheDate('hazChem_menu', 'hazChemExpiry')" v-model="driverProfile.hazChemExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-0">
                    <v-checkbox :disabled="!editing" style="opacity: 0.8" dense class="ma-0" hide-details v-model="driverProfile.hazChem" label="HazChem"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="areYouSure" width="20vw">
      <v-card height="auto">
        <v-card-title style="text-align: center; display: block"
          >Are you sure?</v-card-title
        >
        <v-card-subtitle class="pb-2" style="text-align: center; display: block"
          >You are about to delete this Driver Profile.</v-card-subtitle
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary darken-1" text @click="areYouSure = false"
            >Cancel</v-btn
          >
          <v-btn color="tertiary darken-1" text @click="deleteDriver"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="fileDetailsDialog" width="20vw" persistent>
        <v-card height="16vh">
          <v-toolbar flat dense class="d-flex justify-center">
            <v-toolbar-title>File Details Drivers</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pb-0 d-flex align-center" style="flex-direction: column;">
          <v-select v-model="docSubject" class="mt-2" label="Select File Subject" outlined hide-details dense :items="['ID', 'Drivers License', 'Passport', 'PDP', 'HazChem', 'Medical', 'Visa', 'Other']"></v-select>
          <v-btn @click="acceptSubject()" :disabled="!docSubject" color="edit" class="mt-3">Accept</v-btn>
        </v-card-text>
        </v-card>
      </v-dialog>


  </v-card>
</template>

<script>
import dateFormat from "dateformat";

export default {
  data: () => ({
    search: '',
    maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
    loading: false,
    addDriverCard: false,
    driverCard: false,
    avatarKey: 0,
    photoFile: null,
    editing: false,
    loadingDriverSave: false,
    docuKey: 0,
    docSubject: null,
    loadingDriverUpdate: false,
    loadingDocs: false,
    loadingDelete: false,
    fileDetailsDialog: false,
    inductionMenu: false,
    areYouSure: false,
    licenseMenu: false,
    startMenu: false,
    pdpMenu: false,
    visaMenu: false,
    medicalMenu: false,
    hazChemMenu: false,
    portAccessMenu: false,
    passportMenu: false,
    endMenu: false,
    inductionMenuCreate: false,
    licenseMenuCreate: false,
    startMenuCreate: false,
    pdpMenuCreate: false,
    visaMenuCreate: false,
    medicalMenuCreate: false,
    hazChemMenuCreate: false,
    portAccessMenuCreate: false,
    passportMenuCreate: false,
    endMenuCreate: false,
    userAvatar: null,
    updatePhotoFile: null,
    updateAvatar: null,
    documents: [],
    drivers: [],
    driverProfile: null,
    originalDriver: null,
    storeEvent: null,
    storeRef: null,
    activePicker: null,
    addDriver: {
      user: {},
      transportDriverDocs: [],
      employment: 'Permanent',
      hasWhatsapp: false,
      hazChem: false,
    },
    headers: [
    {
      text: 'Name',
      align: 'center',
      sortable: false,
      value: 'user.firstname',
    },
    {
      text: 'Nickname',
      align: 'center',
      sortable: false,
      value: 'nickname',
    },
    {
      text: 'Mobile',
      align: 'center',
      sortable: false,
      value: 'user.mobileNumber',
    },
    {
      text: 'ID Number',
      align: 'center',
      sortable: false,
      value: 'user.idNumber',
    },
    {
      text: 'Passport Number',
      align: 'center',
      sortable: false,
      value: 'passportNo',
    },
    {
      text: 'Employment',
      align: 'center',
      sortable: false,
      value: 'isContractor',
    },
    ],
    params: {
      limit: 12,
      offset: 0,
      search: null,
    }
  }),
  async created() {
    this.getDrivers()
  },
  computed: {
    cannotSaveYet() {
      let result = true;
      if(this.addDriver.user.firstname &&
        this.addDriver.user.surname &&
        this.addDriver.nickname &&
        this.addDriver.user.mobileNumber &&
        this.addDriver.user.idNumber) {
          result = false;
        }
      return result;
    },
    cannotUpdateYet() {
      let result = true;
      for(let key in this.driverProfile) {
        if(this.driverProfile[key] != this.originalDriver[key]) {
          if(this.driverProfile.user.firstname &&
            this.driverProfile.user.surname &&
            this.driverProfile.nickname &&
            this.driverProfile.user.mobileNumber &&
            this.driverProfile.user.idNumber) {
            result = false;
          }
        }
      }
      return result;
    },
  },
  watch: {
    startMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    licenseMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    pdpMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    visaMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    hazChemMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    endMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    passportMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    medicalMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    portAccessMenu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    inductionMenu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    inductionMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    licenseMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    startMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    pdpMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    visaMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    medicalMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    hazChemMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    portAccessMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    passportMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    endMenuCreate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },

  },
  methods: {
//////////// ADD DRIVER //////////// ADD DRIVER //////////// ADD DRIVER //////////// ADD DRIVER //////////// ADD DRIVER ////////////
    addDrivers(){
      this.addDriverCard = true
    },
    saveTheDate(reff, variable) {
      this.$refs[reff].save(this.addDriver[variable]);
    },
    cancelEdit() {
      this.driverProfile = JSON.parse(JSON.stringify(this.originalDriver))
      this.editing = false
    },
    async addNewDriver() {
      this.loadingDriverSave = true;
      let obj = {
        ...this.addDriver,
        isContractor: this.addDriver.employment == 'Subcontractor' ? true : false,
        isPermanent: this.addDriver.employment == 'Permanent' ? true : false,
        isAvailable: true,
        isLiveChat: false,
      }
      let result = await this.$API.createDriver(obj);
      if(result) {
        this.$message.success('Driver added successfully!');
        let driver = {
          ...result.driver,
          user: result.user,
          transportDriverDocs: result.transportDriverDocs ? result.transportDriverDocs : [],
        }
        this.drivers = {
          total: this.drivers.total + 1,
          data: [
            ...this.drivers.data,
            driver,
          ]
        }
        this.addDriverCard = false;
        this.loadingDriverSave = false;
        this.addDriver = {
          user: {},
          transportDriverDocs: [],
          employment: 'Permanent',
        };
        if(this.documents.length > 0) {
          let docUp = this.uploadDocuments(driver.id);
          if(docUp) {
            this.$message.success('Documents uploaded successfully!');
            this.documents = [];
          }
        }
        if(this.userAvatar) {
          let avatarUp = this.photoUploaded(this.photoFile, driver.user.id);
          if(avatarUp) {
            this.$message.success('Avatar uploaded successfully!');
            this.photoFile = null;
            this.userAvatar = null;
          }
        }
      } else {
        this.$message.error('Something went wrong when trying to add a new driver.');
      }
    },
    async getDrivers() {
      this.loading = true;
      this.drivers = await this.$API.getDrivers(this.params);
      this.loading = false
    },

    async getDriverDocs(id) {
      this.loadingDocs = true;
      let docs = await this.$API.getDriverDocs(id);
      if(docs) {
        this.drivers.data.map((driver) => {
          if(driver.id == id) {
            driver.transportDriverDocs = docs;
          }
        })
        this.driverProfile.transportDriverDocs = docs;
        this.originalDriver.transportDriverDocs = docs
        this.loadingDocs = false;
      }
    },

    async removeDocument(document) {
      if(this.driverCard) {
        this.driverProfile.transportDriverDocs = this.driverProfile.transportDriverDocs.filter((doc) => {
            return doc.name != document.name;
          })
          this.docuKey++;
        let result = await this.$API.deleteTransportDoc({docId: document.id, itemId: this.driverProfile.id, type: 'drivers'});
        if(result) {
          this.$message.success('Document deleted successfully!');
        }
      } else {
        this.documents = this.documents.filter((doc) => {
          return doc.name != document.name;
        })
        this.docuKey++;
      }
    },

    async deleteDriver() {
      this.loadingDelete = true;
      this.areYouSure = false;
      let result = await this.$API.deleteDriver({driverId: this.driverProfile.id, userId: this.driverProfile.user.id});
      if(result) {
        this.$message.success('Driver deleted successfully!');
        this.drivers.data = this.drivers.data.filter((driver) => {
          return driver.id != this.driverProfile.id;
        })
        this.driverCard = false;
        this.driverProfile = null;
        this.loadingDelete = false;

      }
    },
    async updateDriver() {
      this.loadingDriverUpdate = true;
      let result = await this.$API.updateDriver(this.driverProfile);
      if(result) {
        this.$message.success('Driver updated successfully!');
        this.editing = false;
        this.loadingDriverUpdate = false;
        this.driverCard = false
        this.drivers.data = this.drivers.data.map((driver) => {
          if(driver.id == result.id) {
            return {
              ...result,
              user: result.user,
            }
          } else {
            return driver;
          }
        })
      }
      if(this.documents.length > 0) {
        let docUp = this.uploadDocuments(this.driverProfile.id);
        if(docUp) {
          this.$message.success('Documents uploaded successfully!');
          this.documents = [];
        }
      }
      if(this.updateAvatar) {
        let avatarUp = this.photoUploaded(this.updatePhotoFile, this.driverProfile.user.id);
        if(avatarUp) {
          this.$message.success('Avatar uploaded successfully!');
          this.updatePhotoFile = null;
          this.updateAvatar = null;
        }
      }
      this.driverProfile = null
    },
    showDriver(click, row){
      this.driverProfile = row.item
      this.originalDriver = JSON.parse(JSON.stringify(row.item))
      this.driverCard = true
      this.getDriverDocs(row.item.id);
    },
    fileSubjectSelect(event, ref) {
      this.fileDetailsDialog = true;
      this.storeEvent = event.target.files[0];
      this.storeRef = ref;
    },
    acceptSubject() {
      this.onFileChanged(this.storeEvent, this.storeRef)
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    editDriver() {
      this.editing = true;
    },
  //////////// UPLOAD PHOTO //////////// UPLOAD PHOTO //////////// 
    async photoUploaded(file, userId) {
      let url = await URL.createObjectURL(file);
      this.toDataUrl(url, (data) => {
        this.$API.uploadDriverAvatar({
              name: file.name,
              data: data,
              type: file.type,
              userId: userId,
          });
      });
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
              callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async uploadDocuments(driverId) {
      if(this.documents.length > 0) {
        this.documents.forEach((doc) => {
          this.toDataUrl(doc.url, (data) => {
          this.uploadTheDocs({
              name: doc.name,
              data: data,
              type: doc.type,
              subject: doc.subject,
              itemId: driverId,
              bucketRef: 'drivers'
          });
      });
        })
      }
    },
    async uploadTheDocs(obj) {
      let result = await this.$API.uploadTransportDocs(obj)
      return result
    },
    openFileExplorer(ref) {
      document.getElementById(ref).click();
    },
    async onFileChanged(event, ref) {
      if(ref == 'driverDocumentUpload'){
        let doc = event
        console.log(this.docSubject)
        if(doc) {
        let docFileURL = await URL.createObjectURL(doc);
          const reader = new FileReader()
          reader.onload = () => {
            let obj = {
              name: doc.name,
              data: reader.result,
              type: doc.type,
              url: docFileURL,
              subject: this.docSubject.toString(),
            }
            this.documents.push(obj)
            this.driverProfile.transportDriverDocs && this.driverProfile.transportDriverDocs.length > 0 ? this.driverProfile.transportDriverDocs.push(obj) :
            this.driverProfile.transportDriverDocs = [obj]
          }
          reader.readAsDataURL(doc)
          this.docuKey++;
          this.storeEvent = null;
          this.storeRef = null;
          this.fileDetailsDialog = false;
        }
      } else if(ref == 'imageUpdate') {
        this.updatePhotoFile = event.target.files[0]
        if (this.updatePhotoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.updateAvatar = reader.result
            this.avatarKey++
          }
          reader.readAsDataURL(this.updatePhotoFile)
        }
      }else{
        this.photoFile = event.target.files[0]
        if (this.photoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.userAvatar = reader.result
            this.avatarKey++
          }
          reader.readAsDataURL(this.photoFile)
        }
      }

    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      let dayFullDate = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
        dayFullDate = dateFormat(new Date(date), "ddd, mmmm dS, yyyy");
        fullDate = dateFormat(new Date(date), "mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dd-mm-yyyy @ HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
        time = dateFormat(new Date(date), "HH:MM");
      }
      return {
        isoFormat,
        fullDate,
        dateTime,
        dayOfWeek,
        shorterDate,
        time,
        dayFullDate,
      };
    },
  },
}
</script>

<style scoped>
.avatar-uploader  {
  border: 1px dashed #d9d9d9;
  border-radius: 200px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploaded {
  border: 1px solid #d9d9d9;
  border-radius: 200px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: var(--v-primary-base);
}
.avatar-uploader-icon {
  color: white;
  opacity: 0.1;
}
.avatar-uploader:hover .avatar-uploader-icon {
  color: var(--v-primary-base);
  opacity: 0.6;
}
.docAdd {
  scale: 2.5;
  opacity: 0.1;
}

.docAdd:hover {
  opacity: 0.6;
}
</style>
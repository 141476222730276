<template>
  <div style="background: var(--v-component-base) !important" class="pt-1">
      <v-row justify="end" class="mb-2 mt-1">
        <v-col cols="12" sm="12" md="8" lg="4" class="py-0">
          <el-input
            id="search"
            suffix-icon="el-icon-search"
            v-model="params.search"
            clearable
            placeholder="Search"
          >
            <template slot="prepend">
              <el-button @click="addVehicles"
                ><i class="el-icon-plus"></i
              ></el-button>
            </template>
          </el-input>
        </v-col>
      </v-row>
      <v-card flat style="overflow-y: scroll"> 
        <v-data-table
          height="72vh"
          id="vehicleTable"
          style="cursor: pointer"
          :search="params.search"
          :calculate-widths="true"
          :items="vehicles.data"
          :single-expand="true"
          :loading="loading"
          :headers="headers"
          @click:row="revealVehicle"
          :options="{
            page: params.offset + 1,
            itemsPerPage: params.limit,
            pageStart: params.offset + 1,
            pageStop: Math.round(vehicles.total / this.params.limit),
            pageCount: Math.round(vehicles.total / this.params.limit),
            itemsLength: vehicles.total
          }"
        >
        </v-data-table>
      </v-card>

    <!-- VIEW VEHICLE -->
    <v-dialog overlay-opacity="0.9"  v-model="revealVehicleCard" width="60vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card flat height="60vh">
        <v-toolbar flat dense >
          <v-toolbar-title>{{vehicle.registration}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="editingCard" @click="saveVehicleUpdate" :disabled="cannotUpdateYet" :loading="savingUpdate" icon>
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn v-if="!editingCard && selectedTab == 'vehicleProfile'" @click="editingCard = true" icon>
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn v-if="editingCard && selectedTab == 'vehicleProfile'" :loading="loadingDelete" @click="deleteVehicle()" icon color="danger">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn v-if="editingCard && selectedTab == 'vehicleProfile'" @click="cancelEdit()" icon>
            <v-icon>edit_off</v-icon>
          </v-btn>
          <v-btn v-if="selectedTab == 'fines'" @click="addFine = true" class="mr-4" outlined color="primary">
            <v-icon left>add</v-icon>
            Add Fine
          </v-btn>
          <v-btn @click="closeVehicleCard" icon>
            <v-icon color="red">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tabs grow class="tabs" v-model="selectedTab" style="font-size: 10px" show-arrows>
          <v-tab href="#vehicleProfile" class="tab">
            Vehicle
          </v-tab>
          <v-tab href="#fines" class="tab">
            Fines
          </v-tab>
          <!-- VEHICLE -->
          <v-tab-item value="vehicleProfile">
            <v-card style="overflow-x: none" flat tile>
              <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" sm="6">
                  <span style="text-align: center; display: block; contain: content;">
                      Photos
                    </span>
                  <v-divider></v-divider>
                  <div :key="imageKey" style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center;">
                    <div style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;" class="mr-1">
                      <v-avatar  width="14vw" height="20vh" class="avatar-uploader" v-if="!vehicle.photoOfFront" @click="editingCard ? openFileExplorer('frontImageUpdate') : ''">
                        <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                      </v-avatar>
                      <v-avatar width="14vw" height="20vh" class="avatar-uploaded" v-if="vehicle.photoOfFront" @click="editingCard ? openFileExplorer('frontImageUpdate') : ''">
                        <v-img :src="vehicle.photoOfFront"></v-img>
                      </v-avatar>
                      <input id="frontImageUpdate" type="file" hidden accept="image/jpeg, image/png" @change="onFileChanged($event, 'frontImageUpload')"/>
                    </div>
                    <div style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;" class="ml-1">
                      <v-avatar  width="14vw" height="20vh" class="avatar-uploader" v-if="!vehicle.photoOfSide" @click="editingCard ? openFileExplorer('sideImageUpdate') : ''">
                        <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                      </v-avatar>
                      <v-avatar width="14vw" height="20vh" class="avatar-uploaded" v-if="vehicle.photoOfSide" @click="editingCard ? openFileExplorer('sideImageUpdate') : ''">
                        <v-img :src="vehicle.photoOfSide"></v-img>
                      </v-avatar>
                      <input id="sideImageUpdate" type="file" hidden accept="image/jpeg, image/png" @change="onFileChanged($event, 'sideImageUpload')"/>
                    </div>
                  </div>
                    <span style="text-align: center; display: block; padding: 0 0 0 0;">Required</span>
                    <v-divider class="mb-3"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="6" md="6" sm="6" class="px-1">
                        <v-text-field :readonly="!editingCard"  class="pb-3" hide-details label="Registration" placeholder="Enter Registration" v-model="vehicle.registration" persistent-placeholder dense outlined/>
                        <v-text-field :readonly="!editingCard"  class="pb-3" hide-details label="Make" placeholder="Enter Make" v-model="vehicle.make" persistent-placeholder dense outlined/>
                        <v-text-field :readonly="!editingCard"  class="pb-3" hide-details label="Year" placeholder="Enter Year" v-model="vehicle.year" persistent-placeholder dense outlined/>
                      </v-col>
                      <v-col cols="6" md="6" sm="6" class="px-1">
                        <v-select :items="['TRUCK']" :readonly="!editingCard" class="pb-3" hide-details label="Type" placeholder="Enter Type" v-model="vehicle.type" persistent-placeholder dense outlined/>
                        <v-text-field :readonly="!editingCard" class="pb-3" hide-details label="Model" placeholder="Enter Model" v-model="vehicle.model" persistent-placeholder dense outlined/>
                        <v-text-field :readonly="!editingCard" class="pb-3" hide-details label="Odometer" placeholder="Enter Odometer" v-model="vehicle.odometer" persistent-placeholder dense outlined/>
                      </v-col>
                      <v-col cols="12" md="12" sm="12" class="px-1">
                        <v-text-field :readonly="!editingCard" hide-details label="VIN" placeholder="Enter VIN" v-model="vehicle.vin" persistent-placeholder dense outlined/>
                      </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span style="text-align: center; display: block; contain: content;">
                      Documents
                      <v-btn :disabled="!editingCard" x-small style="position: absolute; right: 0; top: 0" icon v-if="vehicle.transportVehicleDocs && vehicle.transportVehicleDocs.length > 0" @click="openFileExplorer('documentUpload')">
                        <v-icon>post_add</v-icon>
                      </v-btn>
                    </span>
                        <v-progress-linear v-if="loadingVehicleDocs" indeterminate></v-progress-linear>
                        <v-divider v-if="!loadingVehicleDocs"></v-divider>
                      <div v-if="!vehicle.transportVehicleDocs || vehicle.transportVehicleDocs.length == 0" style="height: 22vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                        <v-btn :disabled="!editingCard" @click="openFileExplorer('documentUpload')" icon class="docAdd" v-if="!vehicle.transportVehicleDocs || vehicle.transportVehicleDocs.length == 0"><v-icon>post_add</v-icon></v-btn>
                      </div>
                      <input
                          id="documentUpload"
                          type="file"
                          hidden
                          accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                          @change="fileSubjectSelect($event, 'documentUpload')"
                        />
                      <div style="height: 22vh; contain: content; overflow-y: scroll;" v-if="vehicle.transportVehicleDocs && vehicle.transportVehicleDocs.length > 0">
                        <v-list width="100%" dense style="column-count: 2;" :key="docuKey">
                          <v-list-item v-for="(document, index) in vehicle.transportVehicleDocs" :key="index">
                            <v-list-item-icon>
                              <v-img
                                    class="ml-2"
                                    style="height: 28px; width: 30px"
                                    :src="getIcon(document.name)"
                                  />
                            </v-list-item-icon>
                            <v-list-item-content>
                            <v-list-item-title>
                              {{ document.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ document.subject ? document.subject : '' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                          </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                    <span style="display: block; text-align: center;">
                      Dates
                    </span>
                    <v-divider></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" class="px-2 mt-3">
                        <!-- START DATE -->
                        <v-menu v-model="viewLicenseMenu" ref="license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :readonly="!editingCard" v-on="editingCard ? on : ''" v-bind="attrs" dense outlined hide-details class="mb-3" label="Truck License Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="vehicle.truckLicenseExpiry"/>
                          </template>
                          <v-date-picker @change="saveTheDate('license_menu', 'truckLicenseExpiry')" v-model="vehicle.truckLicenseExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                        </v-menu>
                        <!-- LICENSE DATE -->
                        <v-menu v-model="viewOperatorMenu" ref="operator_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :readonly="!editingCard" v-on="editingCard ? on : ''" v-bind="attrs" dense outlined hide-details class="mb-3" label="Operator License Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="vehicle.operatorLicenseExpiry"/>
                          </template>
                          <v-date-picker @change="saveTheDate('operator_menu', 'operatorLicenseExpiry')" v-model="vehicle.operatorLicenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                        </v-menu>                  </v-col>
                      <v-col cols="12" sm="6" class="px-2 mt-3">
                        <!-- END DATE -->
                        <v-menu v-model="viewCrossBorderMenu" ref="crossBorder_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :readonly="!editingCard" v-on="editingCard ? on : ''" v-bind="attrs" dense outlined hide-details class="mb-3" label="Cross Border Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="vehicle.crossBorderExpiry"/>
                          </template>
                          <v-date-picker @change="saveTheDate('crossBorder_menu', 'crossBorderExpiry')" v-model="vehicle.crossBorderExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                        </v-menu>
                        <!-- PASSPORT DATE -->
                        <v-menu v-model="viewLastServiceMenu" ref="lastService_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :readonly="!editingCard" v-on="editingCard ? on : ''" v-bind="attrs" dense outlined hide-details class="mb-3" label="Last Service Date" persistent-placeholder placeholder="Enter Last Service Date" v-model="vehicle.lastServiceDate"/>
                          </template>
                          <v-date-picker @change="saveTheDate('lastService_menu', 'lastServiceDate')" v-model="vehicle.lastServiceDate" :active-picker.sync="activePicker"  min="1980-01-01"/>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="12" sm="12" >
                        <span style="display: block; text-align: center;">
                          Trackers
                        </span>
                        <v-divider class="mb-3"></v-divider>
                        <v-chip-group column class="chipSelect">
                          <v-chip :disabled="!editingCard" @click="(vehicle.locationTracker = !vehicle.locationTracker)"
                           pill :color="vehicle.locationTracker ? 'success' : 'grey'" class="mx-1">
                           <v-icon left>location_on</v-icon>Location 
                          </v-chip>
                          <v-chip :disabled="!editingCard" @click="(vehicle.fuelTracker = !vehicle.fuelTracker)" 
                          pill :color="vehicle.fuelTracker ? 'success' : 'grey'"  class="mx-1">
                          <v-icon left>local_gas_station</v-icon>Fuel
                          </v-chip>
                          <v-chip :disabled="!editingCard" @click="(vehicle.speedTracker = !vehicle.speedTracker)" 
                          pill :color="vehicle.speedTracker ? 'success' : 'grey'"  class="mx-1">
                          <v-icon left>speed</v-icon>Speed 
                          </v-chip>
                          <v-chip :disabled="!editingCard" @click="(vehicle.otherTracker = !vehicle.otherTracker)" 
                          pill :color="vehicle.otherTracker ? 'success' : 'grey'"  class="mx-1">
                          <v-icon left>track_changes</v-icon>Other
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                </v-col>
              </v-row>
              </v-card-text>
          </v-card>
          </v-tab-item>
          <!-- FINES -->
          <v-tab-item value="fines">
            <v-card style="overflow-x: none" flat tile :loading="loadingFines">
              <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" >
                      <v-list max-height="640" min-height="640" dense v-if="vehicle.fines">
                        <v-list-item-group>
                          <v-list-item
                            three-line
                            outlined
                            dense
                            v-for="fine in vehicle.fines"
                            :key="fine.id"
                            @click="editFineCard(fine)"
                          >
                            <v-list-item-avatar size="55">
                              <v-icon large>receipt_long</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="font-weight-bold">{{fine.reasonForFine}}</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-icon small>today</v-icon> {{ fine.dateOfFine }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <v-icon small>location_on</v-icon>
                                {{ fine.locationOfFine }}, {{ fine.postalCode }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <span v-if="fine.isPaid" class="pr-9"
                              ><v-icon color="green">done</v-icon> Paid</span>
                            <span v-if="!fine.isPaid" class="pr-9">
                              <v-icon color="red">close</v-icon> Not Paid</span>
                            <span class="font-weight-bold">
                              {{ fine.currency }}
                            </span>
                            <span> {{ fine.penaltyAmount }} </span>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      
        <v-dialog v-model="addFine" max-width="800px">
          <AddFines @close="addFine = false" @save="saveFine" :vehicle="vehicle" :driver="vehicle.transportUnit && vehicle.transportUnit.transportDrivers[0] ? vehicle.transportUnit.transportDrivers[0] : null" :countries="countries" />
        </v-dialog>

         <v-dialog v-model="editFine" max-width="800px">
          <EditFine @close="editFine = false" :fine="fine" :countries="countries" @save="editFine = false"  />
        </v-dialog>
      </v-card>
    </v-dialog>

    <!-- ADD VEHICLE -->
    <v-dialog v-model="addVehicle" persistent width="60vw" transition="dialog-transition" style="overflow-x: none">
      <v-card dark style="overflow-x: none" flat>
        <v-toolbar flat dense>
            <v-toolbar-title>
              Add Vehicle
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn plain depressed elevation="0" :loading="saving" :disabled="saving" @click="createVehicle">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn plain depressed elevation="0" :disabled="saving" @click="closeDialog">
              <v-icon color="red">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <span style="text-align: center; display: block; contain: content;">
                  Photos
                </span>
              <v-divider></v-divider>
              <div :key="imageKey" style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center;">
                <div style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;" class="mr-1">
                  <v-avatar  width="14vw" height="20vh" class="avatar-uploader" v-if="!frontOfVehicle" @click="openFileExplorer('frontImageUpload')">
                    <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                  </v-avatar>
                  <v-avatar width="14vw" height="20vh" class="avatar-uploaded" v-if="frontOfVehicle" @click="openFileExplorer('frontImageUpload')">
                    <v-img :src="frontOfVehicle"></v-img>
                  </v-avatar>
                  <input id="frontImageUpload" type="file" hidden accept="image/jpeg, image/png" @change="onFileChanged($event, 'frontImageUpload')"/>
                </div>
                <div style="height: 22vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;" class="ml-1">
                  <v-avatar  width="14vw" height="20vh" class="avatar-uploader" v-if="!sideOfVehicle" @click="openFileExplorer('sideImageUpload')">
                    <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                  </v-avatar>
                  <v-avatar width="14vw" height="20vh" class="avatar-uploaded" v-if="sideOfVehicle" @click="openFileExplorer('sideImageUpload')">
                    <v-img :src="sideOfVehicle"></v-img>
                  </v-avatar>
                  <input id="sideImageUpload" type="file" hidden accept="image/jpeg, image/png" @change="onFileChanged($event, 'sideImageUpload')"/>
                </div>
              </div>
                <span style="text-align: center; display: block; padding: 0 0 0 0;">Required</span>
                <v-divider class="mb-3"></v-divider>
                <v-row no-gutters>
                  <v-col cols="6" md="6" sm="6" class="px-1">
                    <v-text-field class="pb-3" hide-details label="Registration" placeholder="Enter Registration" v-model="newVehicle.registration" persistent-placeholder dense outlined/>
                    <v-text-field class="pb-3" hide-details label="Make" placeholder="Enter Make" v-model="newVehicle.make" persistent-placeholder dense outlined/>
                    <v-text-field class="pb-3" hide-details label="Year" placeholder="Enter Year" v-model="newVehicle.year" persistent-placeholder dense outlined/>
                  </v-col>
                  <v-col cols="6" md="6" sm="6" class="px-1">
                    <v-text-field class="pb-3" hide-details label="Type" placeholder="Enter Type" v-model="newVehicle.type" persistent-placeholder dense outlined/>
                    <v-text-field class="pb-3" hide-details label="Model" placeholder="Enter Model" v-model="newVehicle.model" persistent-placeholder dense outlined/>
                    <v-text-field class="pb-3" hide-details label="Odometer" placeholder="Enter Odometer" v-model="newVehicle.odometer" persistent-placeholder dense outlined/>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="px-1">
                    <v-text-field hide-details label="VIN" placeholder="Enter VIN" v-model="newVehicle.vin" persistent-placeholder dense outlined/>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <span style="text-align: center; display: block; contain: content;">
                  Documents
                  <v-btn x-small style="position: absolute; right: 0; top: 0" icon v-if="documents.length > 0" @click="openFileExplorer('documentUpload')">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </span>
                    <v-divider></v-divider>
                  <div v-if="documents.length == 0" style="height: 22vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                    <v-btn @click="openFileExplorer('documentUpload')" icon class="docAdd" v-if="documents.length == 0"><v-icon>post_add</v-icon></v-btn>
                  </div>
                  <input
                      id="documentUpload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                      @change="fileSubjectSelect($event, 'documentUpload')"
                    />
                  <div style="height: 22vh; contain: content; overflow-y: scroll;" v-if="documents.length > 0">
                    <v-list width="100%" dense style="column-count: 2;" v-if="documents.length > 0" :key="docuKey">
                      <v-list-item v-for="(document, index) in documents" :key="index">
                        <v-list-item-icon>
                          <v-img
                                class="ml-2"
                                style="height: 28px; width: 30px"
                                :src="getIcon(document.name)"
                              />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                              {{ document.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ document.subject ? document.subject : '' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                          </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                <span style="display: block; text-align: center;">
                  Dates
                </span>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-3">
                    <!-- START DATE -->
                    <v-menu v-model="licenseMenu" ref="license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-3" label="Truck License Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="newVehicle.truckLicenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('license_menu', 'truckLicenseExpiry')" v-model="newVehicle.truckLicenseExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                    <!-- LICENSE DATE -->
                    <v-menu v-model="operatorMenu" ref="operator_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-3" label="Operator License Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="newVehicle.operatorLicenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('operator_menu', 'operatorLicenseExpiry')" v-model="newVehicle.operatorLicenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-3">
                    <!-- END DATE -->
                    <v-menu v-model="crossBorderMenu" ref="crossBorder_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-3" label="Cross Border Expiry" persistent-placeholder placeholder="Enter Expiry Date" v-model="newVehicle.crossBorderExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('crossBorder_menu', 'crossBorderExpiry')" v-model="newVehicle.crossBorderExpiry" :active-picker.sync="activePicker" min="1980-01-01"/>
                    </v-menu>
                    <!-- PASSPORT DATE -->
                    <v-menu v-model="lastServiceMenu" ref="lastService_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-3" label="Last Service Date" persistent-placeholder placeholder="Enter Last Service Date" v-model="newVehicle.lastServiceDate"/>
                      </template>
                      <v-date-picker @change="saveTheDate('lastService_menu', 'lastServiceDate')" v-model="newVehicle.lastServiceDate" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" >
                    <span style="display: block; text-align: center;">
                      Trackers
                    </span>
                    <v-divider class="mb-3"></v-divider>
                    <v-chip-group column class="chipSelect">
                      <v-chip @click="(newVehicle.location = !newVehicle.location)"
                       pill :color="newVehicle.location ? 'success' : 'grey'" class="mx-1">
                       <v-icon left>location_on</v-icon>Location 
                      </v-chip>
                      <v-chip @click="(newVehicle.fuel = !newVehicle.fuel)" 
                      pill :color="newVehicle.fuel ? 'success' : 'grey'"  class="mx-1">
                      <v-icon left>local_gas_station</v-icon>Fuel
                      </v-chip>
                      <v-chip @click="(newVehicle.speed = !newVehicle.speed)" 
                      pill :color="newVehicle.speed ? 'success' : 'grey'"  class="mx-1">
                      <v-icon left>speed</v-icon>Speed 
                      </v-chip>
                      <v-chip @click="(newVehicle.other = !newVehicle.other)" 
                      pill :color="newVehicle.other ? 'success' : 'grey'"  class="mx-1">
                      <v-icon left>track_changes</v-icon>Other
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileDetailsDialog" width="20vw" persistent>
        <v-card height="16vh">
          <v-toolbar flat dense class="d-flex justify-center">
            <v-toolbar-title>File Details</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pb-0 d-flex align-center" style="flex-direction: column;">
          <v-select v-model="docSubject" class="mt-2" label="Select File Subject" outlined hide-details dense :items="['Vehicle License', 'Operator License', 'Service Invoice', 'Cross-Border Document', 'Other']"></v-select>
          <v-btn @click="acceptSubject()" :disabled="!docSubject" color="edit" class="mt-3">Accept</v-btn>
        </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddFines from "../Dialogs/AddFines.vue";
import EditFine from "../Dialogs/EditFine.vue";
export default {
  components: {
    AddFines,
    EditFine
  },
  data: () => ({
    // VIEW VEHICLE
    editingCard: false,
    revealVehicleCard: false,
    selectedTab: 'vehicleProfile',
    vehicle: {},
    originalVehicle: {},
    loadingVehicleDocs: false,
    loadingFines: false,
    savingUpdate: false,
    addFine: false,
    editFine: false,
    viewImageKey: 0,
    countries: null,
    fine: null,
    loadingDelete: false,
    // ADD VEHICLE
    saving: false,
    licenseMenu: false,
    operatorMenu: false,
    crossBorderMenu: false,
    addVehicle: false,
    lastServiceMenu: false,
    fileDetailsDialog: false,
    viewLicenseMenu: false,
    viewOperatorMenu: false,
    viewCrossBorderMenu: false,
    viewLastServiceMenu: false,
    frontOfVehicle: null,
    sideOfVehicle: null,
    docSubject: null,
    storeEvent: null,
    storeRef: null,
    frontPhotoFile: null,
    sidePhotoFile: null,
    imageKey: 0,
    docuKey: 0,
    activePicker: 'YEAR',
    documents: [],
    newVehicle: {
      locationTracker: false,
      fuelTracker: false,
      speedTracker: false,
      otherTracker: false,
      type: 'TRUCK',
      transportVehicleDocs: [],
    },

    // TABLE
    loading: true,
    vehicles: {
      total: 0,
      data: []
    },
    params: {
      limit: 12,
      offset: 0,
      search: null,
      filter: { isActive: [true] }
    },
    headers: [
      {
        text: "Type",
        value: "type",
        align: "center",
      },
      {
        text: "Registration",
        value: "registration",
        align: "center",
      },
      {
        text: "Make",
        value: "make",
        align: "center",
      },
      {
        text: "Model",
        value: "model",
        align: "center",
      },
      {
        text: "Year",
        value: "year",
        align: "center",
      },
      {
        text: "Odometer",
        value: "odometer",
        align: "center",
      },
      {
        text: "Last Service Date",
        value: "lastServiceDate",
        type: "date",
        align: "center",
      },
      {
        text: "VIN",
        value: "vin",
        align: "center",
      },
    ],
  }),
  computed: {
    cannotSaveYet() {
      let result = true;
      if(this.newVehicle.registration) {
          result = false;
        }
      return result;
    },
    cannotUpdateYet() {
      let result = true;
      for(let key in this.vehicle) {
        if(this.vehicle[key] != this.originalVehicle[key]) {
          if(this.vehicle.registration != '' && this.vehicle.registration != null && this.vehicle.registration != ' ') {
            result = false;
          }
        }
      }
      return result;
    },
  },
  watch: {
    licenseMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    operatorMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    crossBorderMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    lastServiceMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    viewLicenseMenu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    viewOperatorMenu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    viewCrossBorderMenu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    viewLastServiceMenu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  async created() {
    this.vehicles = await this.$API.getVehicles({
      params: this.params,
    })
    this.loading = false
    this.countries = await this.$API.searchCountriesBasic({});
  },
  methods: {
    editFineCard(item) {
      this.fine = item;
      this.editFine = true;
    },
    async saveFine(result) {
      this.vehicle.fines.push(result);
      this.addFine = false;
    },
    addVehicles() {
      this.addVehicle = true
    },
    async removeDocument(document) {
      if(this.revealVehicleCard) {
        this.vehicle.transportVehicleDocs = this.vehicle.transportVehicleDocs.filter((doc) => {
            return doc.name != document.name;
          })
          this.docuKey++;
        let result = await this.$API.deleteTransportDoc({docId: document.id, itemId: this.vehicle.id, type: 'vehicles'});
        if(result) {
          this.$message.success('Document deleted successfully!');
        }
      } else {
        this.documents = this.documents.filter((doc) => {
          return doc.name != document.name;
        })
        this.docuKey++;
      }
    },
    async revealVehicle(click, row) {
      this.originalVehicle = JSON.parse(JSON.stringify(row.item))
      this.loadingVehicleDocs = true
      this.loadingFines = true
      this.revealVehicleCard = true,
      this.vehicle = row.item
      this.getVehicleDocuments(this.vehicle.id)
      this.getAllVehicleFines(this.vehicle.id)
    },
    async getVehicleDocuments(id) {
      let result = await this.$API.getVehicleDocs(id)
      if(result) {
        this.vehicle.transportVehicleDocs = result.docs
        this.vehicle.photoOfFront = result.photoOfFront ? result.photoOfFront : null
        this.vehicle.photoOfSide = result.photoOfSide ? result.photoOfSide : null
      }
      this.docuKey++;
      this.loadingVehicleDocs = false
    },
    async getAllVehicleFines(id) {
      let result = await this.$API.getVehicleFines(id)
      if(result) {
        this.vehicle.fines = result
      }
      this.loadingFines = false
    },
    closeVehicleCard() {
      this.revealVehicleCard = false;
      this.vehicle = {};
    },
    cancelEdit() {
      this.vehicle = JSON.parse(JSON.stringify(this.originalVehicle))
      this.editingCard = false
    },
    acceptSubject() {
      this.onFileChanged(this.storeEvent, this.storeRef)
    },
    closeDialog() {
      this.addVehicle = false;
      this.newVehicle = {
        transportVehicleDocs: []
      }
      this.documents = []
      this.frontOfVehicle = null
      this.sideOfVehicle = null
    },
    saveTheDate(reff, variable) {
      this.revealVehicleCard ? this.$refs[reff].save(this.vehicle[variable]) :
      this.$refs[reff].save(this.newVehicle[variable]);
    },
    async deleteVehicle() {
      this.loadingDelete = true
      let deletedVehicle = await this.$API.updateVehicle({
        id: this.vehicle.id,
        isActive: false,
        isDeleted: true
      })
      if(deletedVehicle) {
        this.loadingDelete = false
        this.revealVehicleCard = false
        this.$message({
          type: "success",
          message: "Vehicle Deleted!",
        });
        this.vehicles.data = this.vehicles.data.filter((vehicle) => {
          return vehicle.id != this.vehicle.id
        })
      } else {
        this.loadingDelete = false
        this.$message({
          type: "error",
          message: "Vehicle could not be deleted!",
        });
      }
    },
    async saveVehicleUpdate() {
      this.savingUpdate = true
      let updatedVehicle = await this.$API.updateVehicle(this.vehicle)
      if(updatedVehicle) {
        if(this.documents.length > 0) {
          let docUp = await this.uploadDocuments(this.vehicle.id);
          if(docUp) {
            this.$message.success('Documents uploaded successfully!');
            this.documents = [];
          }
        }
        if(this.frontOfVehicle) {
          let frontUp = await this.photoUploaded(this.frontPhotoFile, this.vehicle.id, 'photoOfFront')
          if(frontUp) {
            this.$message.success('Photo of Vehicle uploaded successfully!');
            this.frontOfVehicle = null;
          }
        }
        if(this.sideOfVehicle) {
          let sideUp = await this.photoUploaded(this.sidePhotoFile, this.vehicle.id, 'photoOfSide')
          if(sideUp) {
            this.$message.success('Photo of Vehicle uploaded successfully!');
            this.sideOfVehicle = null;
          }
        }
      }
    },
    async createVehicle() {
      this.saving = true
      let obj = {
        ...this.newVehicle,
        hasTracker: this.newVehicle.location || this.newVehicle.fuel || this.newVehicle.speed || this.newVehicle.other ? true : false,
        outstandingFines: 0
      }
      let createdVehicle = await this.$API.createVehicle(obj)
      if(createdVehicle == 'Already Exists') {
        this.$message({
          type: "error",
          message: "A Vehicle with that registration already exists!",
        });
        this.saving = false
        return
      } else {
        this.$message({
          type: "success",
          message: "Vehicle Created!",
        });
        this.vehicles.data.push(createdVehicle)
        this.newVehicle = {
          transportVehicleDocs: []
        }
        this.saving = false
        this.addVehicle = false
        if(this.documents.length > 0) {
          let docUp = await this.uploadDocuments(createdVehicle.id);
          if(docUp) {
            this.$message.success('Documents uploaded successfully!');
            this.documents = [];
          }
        }
        if(this.frontOfVehicle) {
          let frontUp = await this.photoUploaded(this.frontPhotoFile, createdVehicle.id, 'photoOfFront')
          if(frontUp) {
            this.$message.success('Photo of Vehicle uploaded successfully!');
            this.frontOfVehicle = null;
          }
        }
        if(this.sideOfVehicle) {
          let sideUp = await this.photoUploaded(this.sidePhotoFile, createdVehicle.id, 'photoOfSide')
          if(sideUp) {
            this.$message.success('Photo of Vehicle uploaded successfully!');
            this.sideOfVehicle = null;
          }
        }
        this.frontOfVehicle = null
        this.sideOfVehicle = null
        this.addVehicle = false
        this.documents = []
        this.frontPhotoFile = null
        this.sidePhotoFile = null
      }
    },
    async uploadDocuments(vehicleId) {
      if(this.documents.length > 0) {
        this.documents.forEach((doc) => {
          this.toDataUrl(doc.url, async (data) => {
            await this.uploadTheDocs({
              name: doc.name,
              data: data,
              type: doc.type,
              subject: doc.subject,
              itemId: vehicleId,
              bucketRef: 'vehicles'
          });
        });
      })}
    },
    async photoUploaded(file, vehicleId, type) {
      let url = await URL.createObjectURL(file);
      this.toDataUrl(url, async (data) => {
        await this.uploadThePhotos({
              name: file.name,
              data: data,
              type: file.type,
              itemId: vehicleId,
              ref: type,
              bucketRef: 'vehicles'
          });
      });
    },
    async uploadTheDocs(obj) {
      let result = await this.$API.uploadTransportDocs(obj)
      return result
    },
    async uploadThePhotos(obj) {
      let result = await this.$API.uploadTransportPhotos(obj)
      return result
    },
    openFileExplorer(ref) {
      document.getElementById(ref).click();
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    fileSubjectSelect(event, ref) {
      this.fileDetailsDialog = true;
      this.storeEvent = event.target.files[0];
      this.storeRef = ref;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
              callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async onFileChanged(event, ref) {
      if(ref == 'documentUpload'){
        let doc = event
        if(doc) {
        let docFileURL = await URL.createObjectURL(doc);
          const reader = new FileReader()
          reader.onload = () => {
            let obj = {
              name: doc.name,
              data: reader.result,
              type: doc.type,
              url: docFileURL,
              subject: this.docSubject.toString(),
            }
            this.documents.push(obj)
            this.revealVehicleCard ? this.vehicle.transportVehicleDocs.push(obj) : this.newVehicle.transportVehicleDocs.push(obj)
          }
          reader.readAsDataURL(doc)
          this.docuKey++
          this.storeEvent = null;
          this.storeRef = null;
          this.fileDetailsDialog = false;
        }
      }else if(ref == 'frontImageUpload') {
        this.frontPhotoFile = event.target.files[0]
        if (this.frontPhotoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.revealVehicleCard ? this.vehicle.photoOfFront = reader.result : this.frontOfVehicle = reader.result 
            this.imageKey++
            this.viewImageKey++
          }
          reader.readAsDataURL(this.frontPhotoFile)
        }
      }else{
        this.sidePhotoFile = event.target.files[0]
        if (this.sidePhotoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.revealVehicleCard ? this.vehicle.photoOfSide = reader.result : this.sideOfVehicle = reader.result 
            this.imageKey++
            this.viewImageKey++
          }
          reader.readAsDataURL(this.sidePhotoFile)
        }
      }
    },

  },
};
</script>

<style scoped>
.avatar-uploader  {
  border: 1px dashed #d9d9d9ab;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploaded {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: var(--v-primary-base);
}
.avatar-uploader-icon {
  color: white;
  opacity: 0.1;
}
.avatar-uploader:hover .avatar-uploader-icon {
  color: var(--v-primary-base);
  opacity: 0.6;
}
.docAdd {
  scale: 2.5;
  opacity: 0.1;
}
.chipSelect ::v-deep .v-slide-group__content {
  justify-content: center;
}

.docAdd:hover {
  opacity: 0.6;
}
  </style>

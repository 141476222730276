<template>
  <v-card outlined class="outerBox" color="component">
    <v-row no-gutters style="height: inherit">
      <!-- #1 - CONTACTS -->
      <v-col cols="12" md="4" sm="4" lg="4" style="height: inherit">
        <v-card tile class="contactBox">
          <!-- #1.1 - SearchBar -->
            <v-toolbar dense color="greyRaised" flat>
              <v-text-field
                outlined
                dense
                placeholder="Search"
                prepend-inner-icon="search"
                append-outer-icon="filter_list"
                class="searchBar"
                hide-details
                v-model="searchContact"
              >
            </v-text-field>
            </v-toolbar>
          <!-- #1.2 - Contact List -->
          <v-card-text class="contactList py-0 px-1" >
            <v-list dense style="height: inherit" :key="contactsListKey">
              <v-list-item
                @click="getMessages(contact)"
                v-for="contact in contactList"
                dense
                :key="contact.id"
                class="mx-0 px-0">
                
                <v-list-item-avatar>
                  <v-badge v-if="contact.user.avatar"
                    bordered
                    overlap
                    :color="contact.active ? 'success' : 'primary'"
                    dot
                    offset-x="15"
                    offset-y="12"
                  >
                    <v-avatar v-if="contact.user.avatar" size="40">
                      <v-img style="scale: 1" :src="contact.user.avatar"></v-img>
                    </v-avatar>
                  </v-badge>

                  <v-avatar v-else>
                    <v-badge 
                    bordered
                    overlap
                    :color="contact.active ? 'success' : 'primary'"
                    dot
                    offset-x="15"
                    offset-y="12"
                  >
                    <v-icon large> person </v-icon>
                  </v-badge>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ contact.user.firstName }} {{ contact.user.surname }}
                  </v-list-item-title>
                  <v-list-item-subtitle >
                    <span v-if="contact.lastMessage != null && contact.lastMessage.messageType == 'inbound'">
                      <span v-if="contact.lastMessage.message && ['text_message','simple_button_message', 'radio_button_message', 'quick_reply_message'].includes(contact.lastMessage.type)">
                        {{ contact.user.firstName + ': ' + contact.lastMessage.message.trim()}} 
                      </span>
                      <span v-else-if="contact.lastMessage.type == 'media_message'">
                        {{ contact.user.firstName }}: <v-icon small>image</v-icon> <span v-if="contact.lastMessage.message"> {{ contact.lastMessage.message }}</span> <span v-else>Photo</span>
                      </span>
                      <span v-else>
                        {{ contact.user.firstName }}:
                      </span>
                    </span>
                    <span v-else-if="contact.lastMessage != null && contact.lastMessage.messageType == 'outbound'">
                    <v-icon small :color="contact.lastMessage.read ? 'edit' : contact.lastMessage.failed ? 'primary' : 'greyContrast'">
                      {{ contact.lastMessage.sent && contact.lastMessage.delivered ? 'done_all' : contact.lastMessage.sent && contact.lastMessage.read ? 'done_all' : contact.lastMessage.failed ? 'highlight_off' : 'done'}}
                    </v-icon>
                      <span v-if="contact.lastMessage?.message && ['text_message','simple_button_message', 'radio_button_message', 'quick_reply_message'].includes(contact.lastMessage.type)">
                      {{ contact.lastMessage.message.length > 40 ? contact.lastMessage.message.substring(0, 40) + '...' : contact.lastMessage.message}}
                    </span>
                    <span v-else-if="contact.lastMessage.type == 'media_message'">
                      <v-icon small>image</v-icon> <span v-if="contact.lastMessage.message"> {{ contact.lastMessage.message }}</span> <span v-else>Photo</span>
                    </span>
                  </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-subtitle >
                    <i v-if="contact.lastMessage != null" style="position: absolute; right: 0.5vw; top: 1.5vh; font-size: 12px; color: grey;">
                      <span v-if="formatTime(contact.lastMessage.createdAt).date == formatTime(new Date()).date">
                        {{ formatTime(contact.lastMessage.createdAt).time }}
                      </span>
                      <span v-else>
                        {{ formatTime(contact.lastMessage.createdAt).date }}
                      </span>
                    </i>
                    <v-badge
                      v-if="contact.unreadMessages && contact.unreadMessages > 0"
                      style="position: absolute; right: 1.6vw; bottom: 1vh;"
                      color="success"
                      :content="contact.unreadMessages"
                    ></v-badge>
                  </v-list-item-subtitle>
                </v-list-item-action>
                <div style="background-color: #var(--v-greyContrast-base) !important; width: 100%; height: 1px; position: absolute; left: 0; bottom: 1px; opacity: 0.1; "></div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- #2 - CHAT AREA -->
      <v-col v-if="chatView" cols="12" md="8" sm="8" lg="8" style="height: inherit">
        <v-card class="chatArea" flat tile>
          <!-- #2.2 - Messages Area -->
          <v-toolbar style="z-index: 3;" flat dense color="greyRaised">
              <v-btn icon plain>
                <v-icon size="30">first_page</v-icon>
              </v-btn>
              <v-avatar v-if="selectedContact && selectedContact.user" size="40">
                <v-img :src="selectedContact.user.avatar"></v-img>
              </v-avatar>
              <v-subheader v-if="selectedContact != null"
                >{{ selectedContact.user.firstName }}
                {{ selectedContact.user.lastName }}</v-subheader>
              <v-spacer></v-spacer>
              <v-menu bottom offset-y transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-on="on" v-bind="attrs" plain icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list dense class="pa-0">
                    <v-list-item @click="hideBotMessages = !hideBotMessages">
                      <v-list-item-content>
                        <v-list-item-title>Hide Bot Messages</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="d-flex justify-center align-center my-0">
                        <v-simple-checkbox hide-details v-model="hideBotMessages"></v-simple-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-toolbar>

          <v-card class="chatMessages" flat tile color="greyContrast" id="chatList" ref="chatList">
            <div v-if="canLoadMore" class="loadingMore">
              <div v-if="isLoadingMore">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </div>
            <div class="sentinel" id="sentinel"></div>
            <v-list :key="messagesKey">
                    <v-list-item v-for="(item, index) in messagesList" :key="index" style="content-visibility: auto;">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" v-if="showDate(item, index)" style="width: 100%;" class="px-0 my-2 d-flex justify-center align-center">
                            <v-chip>{{ displayDate(item.createdAt) }}</v-chip>
                        </v-col>
                        <!-- MESSAGES RECEIVED -->
                        <v-col cols="12" sm="6" md="6" style="contain: content;" class="pa-0 my-4" >
                          <!-- RECEIVED == TEXT Message -->
                          <div class="receivedTail" v-if="item.messageType == 'inbound' && ['text_message', 'radio_button_message', 'quick_reply_message', 'simple_button_message'].includes(item.type)"></div>
                            <v-card v-if="item.messageType == 'inbound' && ['text_message', 'radio_button_message', 'quick_reply_message', 'simple_button_message'].includes(item.type)" class="chatReceived">
                              <v-card-text style="font-size:larger; color: white;" class="py-2">
                                <span style="font-weight: 600;" v-if="item.title">{{ item.title}}</span><br v-if="item.title"/>
                                <span v-for="(word, index) in processedText(item.message)" :key="index" :style="word.style">{{ word.text }}</span>
                              </v-card-text>
                              <span style="position: absolute; right: 0.6vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ formatTime(item.createdAt).shortTime  }}</span>
                            </v-card>
                              <!-- RECEIVED ==  IMAGE Message -->
                            <v-card v-if="item.messageType == 'inbound' && item.type == 'media_message'" class="imageReceived" max-width="320px">
                              <div style="padding: 0.4vh 0.4vh 0 0.4vh" v-if="item.image">
                                <v-img v-if="item.image" style="border-radius: 10px" :src="item.image" max-width="4 50px" max-height="350px" @click="viewImage(item.image)"></v-img><br>
                              </div>
                              <div v-if="!item.image" style="height: 9.5vw; width: auto; display: flex; place-content: center;" >
                                  <v-icon large>image</v-icon>
                                </div>
                              <v-card-text style="padding: 0vh 0.8vh 1vh 0.8vh">
                                <span v-if="item.message"> {{ item.message }}</span>
                                <span v-if="!item.image && !item.message">
                                <span v-for="(word, index) in processedText(`_Something Went Wrong_`)" :key="index" :style="word.style">{{ word.text }}</span>
                                </span>
                              </v-card-text>
                              <span style="position: absolute; right: 0.6vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ formatTime(item.createdAt).shortTime  }}</span>
                            </v-card>
                            <v-card v-if="item.messageType == 'inbound' && ['document_message'].includes(item.type)" class="imageSent" max-width="320px">
                            <div>
                              <v-list-item >
                                <v-list-item-action
                                  class="ml-0 pl-0"
                                  style="width: 20px"
                                >
                                  <v-img
                                    class="ml-2"
                                    style="height: 38px; width: 40px"
                                    :src="getIcon(item.fileName)"
                                  />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      style="font-size: 14px"
                                    >
                                      {{ item.fileName }}
                                    </v-list-item-title>
                                    <!-- <v-list-item-subtitle style="font-size: 12px">
                                      <v-icon small>description</v-icon>
                                      {{ item.fileSize }}
                                    </v-list-item-subtitle> -->
                                    <v-list-item-subtitle style="font-size: 12px">
                                      <v-icon small>today</v-icon>
                                      {{ item.updatedAt }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                            <v-card-text style="padding: 0vh 0.8vh 1vh 0.8vh">
                              <span v-if="item.message"> {{ item.message }}</span>
                            </v-card-text>
                            <span>
                              <v-icon size="20" style="position: absolute; right: 0.55vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <v-icon size="20" style="position: absolute; right: 0.3vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <span style="position: absolute; right: 1.5vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ formatTime(item.createdAt).shortTime }}</span>
                            </span>
                          </v-card>
                            <!-- RECEIVED ==  AUDIO/VOICENOTE Message -->
                            <!-- <v-card v-if="item.received && item.type == 'audio'" class="audioReceived">
                              <v-card-text>
                                  <v-btn id="play" v-if="receivedAudioId != item.id" @click="playReceivedAudio(item.id)"  icon style="margin: -0.4vh 0 0 0.5vh"><v-icon size="50">play_arrow</v-icon></v-btn>
                                  <v-btn id="pause" v-if="receivedAudioId == item.id" @click="pauseReceivedAudio(item.id)"  icon style="margin: -0.4vh 0 0 0.5vh"><v-icon size="50">pause</v-icon></v-btn>
                                  <v-container style="position: absolute; left: 3vw; ;top: 1.4vh; width: 16vw">
                                    <v-progress-linear :key="audioReceivedProgress" background-color="white" :id="'progress-'+item.id" color="primary" :value="item.progress"></v-progress-linear> 
                                  </v-container>
                                  <span :key="receivedDuration" style="position: absolute; right: 1.5vw; bottom: 0">{{ item.duration }}</span>
                                  <span :key="receivedCurrentTime" style="position: absolute; left: 3.7vw; bottom: 0">{{ item.currentTime }}</span>
                                
                                <audio :id="'audioReceived-' + item.id" @loadedmetadata="loadAudioReceived(item.id)" controls hidden preload="metadata">
                                  <source :src="item.audio" type="audio/mpeg">
                                </audio>
                              </v-card-text>
                              <span style="position: absolute; right: 0.6vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ formatTime(item.createdAt).shortTime  }}</span>
                            </v-card> -->
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          class="d-flex justify-end align-end pa-0 my-4"
                          style="flex-direction: column; contain:content"
                        >
                        <!-- SENT == TEXT Message -->
                        <div>
                        <div class="sentTail" v-if="item.messageType == 'outbound' && ['text_message', 'radio_button_message', 'quick_reply_message', 'simple_button_message'].includes(item.type)"></div>
                          <v-card v-if="['text_message', 'radio_button_message', 'quick_reply_message', 'simple_button_message'].includes(item.type) && (item.sentNow || item.messageType == 'outbound') " class="textSent">
                            <v-card-text style="font-size:larger; color: rgb(47, 47, 47); white-space: pre-line" class="py-2">
                              <b v-if="item.headerText">{{ item.headerText + '\n' }}</b>
                              <span v-for="(word, index) in processedText(item.message.trim())" :key="index" :style="word.style">{{ word.text }}</span>
                            </v-card-text>
                            <span>
                              <v-icon size="22" style="position: absolute; right: 0.3vw; bottom: 0.6vh;" :color="item.read ? 'edit' : item.failed ? 'primary' : 'greyContrast'">
                                {{ item.sent && item.delivered ? 'done_all' : item.sent && item.read ? 'done_all' : item.failed ? 'highlight_off' : 'done'}}
                              </v-icon>
                              <span style="margin-top: 1vh; position: absolute; right: 1.8vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ formatTime(item.createdAt).shortTime }}</span>
                            </span>
                            </v-card>
                            <v-card width="auto" height="auto" min-height="5vh"   v-if="item.menuTitle" class="normalButton">
                              <v-icon :color="'#3797cb'" class="mr-1">list</v-icon>
                              <b style="font-size:larger;"> {{ item.menuTitle }}</b>
                            </v-card>
                            <v-card width="auto" height="auto" min-height="5vh" class="chosenButton"
                              v-if="currentChat[index + 1] && currentChat[index + 1].responseWamid && currentChat[index + 1].responseWamid == item.wamid && item.type == 'radio_button_message'">
                              <b style="font-size:larger;"> <v-icon>touch_app</v-icon> {{ currentChat[index + 1].title }}</b>
                            </v-card>
                            <v-card width="auto" height="auto" min-height="5vh"  v-if="item.button1Text" :class="currentChat[index + 1] && currentChat[index + 1].message && currentChat[index + 1].message == item.button1Text ? 'chosenButton' : 'normalButton'" >
                              <b style="font-size:larger;">{{ item.button1Text }}</b>
                            </v-card>
                            <v-card width="auto" height="auto" min-height="5vh"  v-if="item.button2Text" :class="currentChat[index + 1] && currentChat[index + 1].message && currentChat[index + 1].message == item.button2Text ? 'chosenButton' : 'normalButton'" >
                              <b style="font-size:larger;">{{ item.button2Text }}</b>
                            </v-card>
                            <v-card width="auto" height="auto" min-height="5vh"  v-if="item.button3Text" :class="currentChat[index + 1] && currentChat[index + 1].message && currentChat[index + 1].message == item.button3Text ? 'chosenButton' : 'normalButton'" >
                              <b style="font-size:larger;">{{ item.button3Text }}</b>
                            </v-card>
                          </div>
                            <!-- SENT == IMAGE Message -->
                            <!-- <v-card v-if="item.sent && item.type == 'image/jpeg' || item.sentNow && item.type == 'image/jpeg'" class="imageSent" max-width="320px">
                              <div style="padding: 0.4vh 0.4vh 0 0.4vh">
                              <v-img style="border-radius: 10px" @click="openImage(item.image)" :src="item.image" max-width="450px" max-height="350px"></v-img><br>
                            </div>
                            <v-card-text style="padding: 0vh 0.8vh 1vh 0.8vh">
                              <span v-if="item.message"> {{ item.message }}</span>
                            </v-card-text>
                            <span>
                              <v-icon size="20" style="position: absolute; right: 0.55vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <v-icon size="20" style="position: absolute; right: 0.3vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <span style="position: absolute; right: 1.5vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ item.createdAt.split(' ')[1].substring(0, 5) }}</span>
                            </span>
                          </v-card> -->
                          <!-- SENT == FILE Message -->
                          <!-- SENT == AUDIO/VOICENOTE Message -->
                          <!-- <v-card v-if="item.sent && item.type == 'audio' || item.sentNow && item.type == 'audio'" class="audioSent">
                            <v-card-text>
                                <v-btn id="play" v-if="sentAudioId != item.id" @click="playSentAudio(item.id)"  icon style="margin: -0.4vh 0 0 0.5vh"><v-icon size="50">play_arrow</v-icon></v-btn>
                                <v-btn id="pause" v-if="sentAudioId == item.id" @click="pauseSentAudio(item.id)"  icon style="margin: -0.4vh 0 0 0.5vh"><v-icon size="50">pause</v-icon></v-btn>
                                <v-container style="position: absolute; left: 3vw; ;top: 1.4vh; width: 16vw">
                                  <v-progress-linear :key="audioSentProgress" background-color="white" :id="'progress-'+item.id" color="primary" :value="item.progress"></v-progress-linear> 
                                </v-container>
                                <span :key="sentDuration" style="position: absolute; right: 1.5vw; bottom: 0">{{ item.duration }}</span>
                                <span :key="sentCurrentTime" style="position: absolute; left: 3.7vw; bottom: 0">{{ item.currentTime }}</span>
                              
                              <audio :id="'audioSent-' + item.id" @loadedmetadata="loadAudioSent(item.id)" controls hidden preload="metadata">
                                <source :src="item.audio" type="audio/mpeg">
                              </audio>
                            </v-card-text>
                            <span>
                              <v-icon size="20" style="position: absolute; right: 0.55vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <v-icon size="20" style="position: absolute; right: 0.3vw; bottom: 0.6vh; opacity: 0.6 ">done</v-icon>
                              <span style="position: absolute; right: 1.5vw; bottom: 0.3vh; font-size: smaller; opacity: 0.6">{{ item.timestamp.split(' ')[1].substring(0, 5) }}</span>
                            </span>
                          </v-card> -->
                          <!-- SENT == Sender Info -->
                          <v-tooltip top v-if="(item.sent && item.sender) || item.sentNow">
                            <template v-slot:activator="{ on }">
                              <v-avatar v-on="on" style="top:0; position:absolute; margin: 0 0 0 0" size="35" v-if="item.sentNow && $store.state.user.avatar != null ">
                                <v-img :src="$store.state.user.avatar">
                                  <template slot="placeholder">
                                    <div style="height: 35px; width: 35px; border-radius: 50%; background-color: #ea6d07 !important ">
                                      <span class="white--text text-h6">{{ $store.state.user.firstname.charAt(0) + $store.state.user.surname.charAt(0)}}</span>
                                    </div>
                                  </template>
                                </v-img>
                              </v-avatar>
                              <v-avatar v-on="on" style="top:0; position:absolute; margin: 0 0 0 0" size="35" v-if="item.sentNow && $store.state.user.avatar == null">
                                <span class="white--text text-h6">{{ $store.state.user.firstname.charAt(0) + $store.state.user.surname.charAt(0)}}</span>
                              </v-avatar>
                              <v-avatar v-on="on" style="top:0; position:absolute; margin: 0 0 0 0" size="35" v-if="item.sent && item.sender && item.sender.avatar != null">
                                <v-img :src="item.sender.avatar" :alt="`${item.sender.firstName ? item.sender.firstName.charAt(0) : item.sender.firstname ? item.sender.firstname.charAt(0) : ''}`">
                                  <template slot="placeholder">
                                    <div style="height: 35px; width: 35px; border-radius: 50%; background-color: #ea6d07 !important ">
                                      <span class="white--text text-h6">{{ `${item.sender.firstName ? item.sender.firstName.charAt(0) : item.sender.firstname ? item.sender.firstname.charAt(0) : ''}`}}</span>
                                    </div>
                                  </template>
                                </v-img>
                              </v-avatar>
                              <v-avatar v-on="on" style="top:0; position:absolute; margin: 0 0 0 0; background-color: #ea6d07 !important" size="35" v-if="item.sent && item.sender && item.sender.avatar == null">
                                <span class="white--text text-h6">{{ `${item.sender.firstName ? item.sender.firstName.charAt(0) : item.sender.firstname ? item.sender.firstname.charAt(0) : ''}`}}</span>
                              </v-avatar>
                            </template>
                            <b>{{ item.sender.firstName ?? item.sender.firstname }} {{ item.sender.surname }}</b> 
                            <br>
                            <i>{{ item.sender.emailAddress }}</i>
                          
                            </v-tooltip>
                            <v-avatar color="edit" style="top:0; position:absolute; margin: 0 0 0 0" size="35" v-if="item.sent && !item.senderId">
                                <v-icon>smart_toy</v-icon>
                              </v-avatar>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <div style="width: 100%; height: 7vh; display: flex; justify-content: end; align-items: center" v-if="sendingTemplate">
                      <span class="mr-5"><i>Sending Template...</i></span>
                      <v-progress-circular indeterminate style="margin: 0 2.6vw 0 0"></v-progress-circular>
                    </div>
            </v-list>
            <div v-if="!selectedContact.active" :key="noLoginKey" style="display: flex; justify-content: center; align-items: center; width: auto; height: auto">
              <v-card outlined  style="position: fixed; bottom: 6.5vh;  " color="primary">
                <v-card-text class="d-flex justify-center align-center py-1" > 
                  <span>Driver inactive for 24h. Send a login message to continue chat.</span>
                </v-card-text>
              </v-card>
            </div>
            <div v-if="chatLoading" style="height: 100%; width: 100%;" class="d-flex justify-center align-center ">
              <v-progress-circular size="60" indeterminate></v-progress-circular>
            </div>

            <v-btn v-if="scrollToBottomButton" :key="scrollToBottomKey" color="primary" style="position: fixed; bottom: 6.5vh; right: 10px; left: auto" fab small @click="scrollToBottom()">
              <v-icon>expand_more</v-icon>
            </v-btn>
          </v-card>
          <!-- #2.3 - Send Message Area  -->
          <div  :key="textBoxKey">
          <v-card tile class="messageBox" color="greyRaised">
                <v-btn plain icon class="attach" @click="attachmentDrawer = !attachmentDrawer">
                  <v-icon size="30">attach_file</v-icon>
                </v-btn>
                <v-textarea
                  solo
                  auto-grow
                  rows="1"
                  hide-details  
                  placeholder="Type a message"
                  class="textArea"
                  v-model="selectedContact.textArea"
                /> 
                <v-btn :loading="loadingSent" fab color="edit" class="send" @click="sendMessage">
                  <v-icon class="pl-1" size="30">send</v-icon>
                </v-btn>
              </v-card>
            </div>
            <!-- <div v-if="!selectedContact.active" :key="loginRequestKey">
              <v-card tile class="messageBox justify-center align-center" color="greyRaised">
                <v-btn :loading="sendingLoginRequest" color="primary" @click="sendLoginRequest()">Send Login Request</v-btn>
              </v-card>
            </div> -->
          <!-- #2.4 - Attachments -->
          <transition name="attachment">
            <v-card flat v-if="attachmentDrawer" class="attachments" >
              <input id="fileUpload" 
              multiple 
              type="file" 
              hidden 
              @change="addFile" 
              />
              <!-- <v-tooltip color="edit" right transition="fade-transition">
              <template v-slot:activator="{ on }">
              <v-btn
                @click="chooseFiles"
                v-on="on"
                fab
                color="edit"
                height="5vh"
                width="5vh"
                class="mb-2"
              >
                <v-icon>description</v-icon>
              </v-btn>
            </template>
            <span>Send Files</span>
            </v-tooltip>
              <input
                multiple
                id="imageUpload"
                type="file"
                hidden
                accept="image/gif, image/jpeg, image/png"
              />
              <v-tooltip color="primary" right transition="fade-transition">
              <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="chooseImages()"
                fab
                color="primary"
                height="5vh"
                width="5vh"
                class="mb-2"
              >
                <v-icon>image</v-icon>
              </v-btn>
              </template>
            <span>Send Image</span>
            </v-tooltip> -->
            <v-tooltip color="tertiary" right transition="fade-transition">
              <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="chooseTemplate()"
                fab
                color="tertiary"
                height="5vh"
                width="5vh"
                class="mb-2"
              >
                <v-icon>question_answer</v-icon>
              </v-btn>
              </template>
            <span>Templates</span>
            </v-tooltip>
            </v-card>
          </transition>
          <!-- #2.5 - Location Input -->
          <transition name="dialogInput">
            <v-card v-if="attachmentDrawer.locationDialog" class="location">
              <v-btn @click="send('location')" color="primary" small fab style="position: absolute; right: 1vw; top: 1vh; z-index: 3"><v-icon>close</v-icon></v-btn>
              <v-card class="poiSelect">
                <v-card-subtitle class="d-flex justify-center">
                  Navigation
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field
                    outlined
                    dense
                    clearable
                    placeholder="Search for a location"
                    append-icon="search"
                    style="width: 21vw"
                    :loading="searchingPois"
                    v-model="searchPois"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top open-delay="250">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="countryCollision"
                            icon
                            @click="filterPois"
                            v-on="on"
                            style="bottom: 0.7vh"
                          >
                            <v-icon>filter_alt</v-icon>
                          </v-btn>
                        </template>
                        <span>Filter POI's</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <v-card outlined elevation="0" style="height: 51vh; width: 20vw; contain: content; margin: -1vh 0 0 0" >
                      <v-list style="width: auto">
                        <v-list-item-group>
                          <v-progress-circular
                            v-if="loadingPoiList"
                            class="loadingPoi"
                            indeterminate
                            color="white"
                            size="40"
                          ></v-progress-circular>
                          <v-list-item
                            dense
                            two-line
                            v-for="poi in poiList"
                            :key="poi.id"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{
                                poi.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                poi.functionality
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :value="poi"
                                v-model="selectedMarkers"
                                color="primary"
                              ></v-checkbox>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                  </v-card>
                </v-card-text>
              </v-card>
              <v-card class="mapCard">      
                <gmap-map
                  ref="mapRef"
                  key="mapKey"
                  class="gMap"
                  :center="center"
                  :zoom="zoom"
                  map-type-id="terrain"
                  style="width: 100%; height: 100%; z-index: 2"
                  :options="{
                    scrollwheel: scrollwheel,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: false,
                  }"
                >
                </gmap-map>
              </v-card>
            </v-card>
          </transition>
          <!-- 
          <transition name="dialogInput">
            <v-card v-if="fileDialog" class="fileDialog" flat color="greyBase">
              <v-toolbar style="display: flex; justify-content: center; background-color:rgb(58, 58, 58) !important" flat >
                <span style="font-size: x-large">{{ currentFile[0].name}}</span>
              </v-toolbar>
              <v-btn icon large @click="send('file')" style="position: absolute; right: 1vh; top: 0.8vh; "><v-icon large>close</v-icon></v-btn>
              <v-card class="fileSelection" flat >
                <v-card class="filePreview" flat>
                      <v-list>
                            <v-list-item 
                            v-for="doc in currentFile"
                            :key="doc.id"
                            style="display: flex; justify-content: center; align-items: center; flex-direction: column"
                            >
                                    <v-img
                                      style="height: 140px; width: 140px; margin: 5vh 0 3vh 0"
                                      :src="getIcon(doc.name)"
                                    />
                                    <span style="font-size: large;" class="mb-3"><v-icon class="mr-2">description</v-icon>{{ doc.fileSize }}</span>
                                      <span style="font-size: large;"><v-icon >today</v-icon>
                                      {{  doc.updatedAt }}
                                    </span>
                            </v-list-item>
                          </v-list>
              </v-card>
            </v-card>
            <v-card class="fileText" flat>
              <span style="display: flex; align-items: center"> 
                <v-textarea
                  solo
                  auto-grow
                  :key="fileMessageKey"
                  rows="1"
                  hide-details  
                  placeholder="Type a message"
                  style="width: 40vw;"
                  class="textArea"
                  background-color="#636161"
                  v-model="currentFile[0].message"
                /> 
              </span>
            </v-card>
            <v-card class="fileMultiple" flat>
              <v-list style="display: flex; justify-content: center; align-items: center; flex-direction: row">
                <v-card class="documentScroll" flat >
                <v-list-item 
                  v-for="doc in files"
                  @click="send('viewFile', {value: doc}), fileMessageKey++"
                  :key="doc.id"
                  class="ma-0 py-0 pl-0 pr-2"
                  >
                  <v-card class="littleFile" :color="doc.id == currentFile[0].id ? 'success' : 'none'">
                    <v-img  style="height: 56px; width: 54px;"  :src="getIcon(doc.name)"/>
                  </v-card>
                </v-list-item>
              </v-card >
              <input id="fileUploadMore" 
              multiple 
              type="file" 
              hidden 
              @change="addMoreFiles" 
              />
              <v-hover v-slot="{ hover }" >
                <v-card @click="addMore" :elevation="hover ? 10 : 2" class="littleFile">
                  <v-icon x-large>add</v-icon>
                </v-card>
              </v-hover>
              </v-list>
              <v-btn fab color="edit" style="height: 65px; width: 65px; align-self: center; position: absolute; right: 1vw;" @click="sendStuff">
                  <v-icon class="pl-1" size="40">send</v-icon>
                </v-btn>
            </v-card>
            </v-card>
          </transition> -->
          <v-dialog v-model="templateView" width="30vw" class="ma-0" persistent>
            <v-card v-if="templateView" height="50vh" max-height="50vh" style="overflow: hidden">
              <v-toolbar dense flat>
                <v-toolbar-title>Select a Template</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="templateView = false, currentTemplate = {}"><v-icon>close</v-icon></v-btn>
              </v-toolbar>
              <v-card-text style="overflow-y: scroll; height:calc(50vh - 48px - 52px)">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-list dense :loading="loadingTemplates">
                        <v-list-item v-for="(template, index) in templates" :key="index" @click="currentTemplate = template" :class="currentTemplate.name == template.name ? 'chosenTemplate pr-0' : 'pr-0'">
                            <v-list-item-icon class="mr-4">
                                <span>{{ index + 1 }}</span>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ template.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ template.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-center align-center">
                <v-btn color="tertiary" :loading="sendingTemplateLoad" :disabled="Object.keys(currentTemplate).length == 0" @click="sendTemplate()">Send Template</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- #2.6 - Open Image Dialog -->
          <v-dialog v-model="previewImage" width="60vw">
            <v-card class="imageDialog" height="auto" tile>
              <v-img
                :src="viewingUrl"
                contain
                max-width="100%"
                max-height="100%"
              ></v-img>
            </v-card>
          </v-dialog>
      </v-card>
      </v-col>
      <v-col v-if="noChatSelected" cols="12" md="8" sm="8" lg="8" style="height: inherit">
        <v-card  outlined tile color="greyBase" class="chatPlaceholder" >
          <v-card-text class="text-center" style="margin: 30% 0 70% 0 ">
            <v-icon size="55px" class="mb-3">chat</v-icon>
            <h3 class="font-weight-bold">No Chat Selected</h3>
            <p>Select a chat to start messaging</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="loadingChat" cols="12" md="8" sm="8" lg="8" style="height: inherit">
        <v-card  outlined tile color="greyBase" class="chatPlaceholder" >
          <v-card-text class="text-center" style="margin: 30% 0 70% 0 ">
            <v-progress-circular size="60" class="mb-12" color="white" indeterminate></v-progress-circular>
            <h3 class="font-weight-bold mb-2" >Loading Your Messages With {{ selectedUser.firstName }}</h3>
            <p>This should only take a second...</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import dateFormat from "dateformat";
import { getGoogleMapsAPI } from "gmap-vue";
import filesize from "file-size" 

export default {
  data: () => ({
    date: new Date(),
    selectedDriver: null,
    viewingUrl: null,
    previewImage: false,
    hideBotMessages: false,
    sendingTemplateLoad: false,
    sendingTemplate: false,
    scrollToBottomButton: false,
    loadingTemplates: false,
    showChat: true,
    noLogin: true,
    sendingLoginRequest: false,
    chatLoading: false,
    templateView: false,
    noChatSelected: true,
    loadingSent: false,
    searchContact: "",
    selectedContact: null,
    selectedUser: null,
    attachmentDrawer: false,
    chatView: false,
    messages: [],
    currentTemplate: {},
    templates: [],
    loadingChat: false,
    contacts: [],
    currentChat: [],
    sent: [],
    sendingMessage: "",
    receivedInterval: null,
    sentInterval: null,
    scrollToBottomKey: 0,
    fileMessageKey: 0,
    newMessageKey: 0,
    messagesKey: 0,
    contactsListKey: 0,
    lastMessageKey: 0,
    noLoginKey: 0,
    textBoxKey: 0,
    receivedKey: 0,
    sentKey: 0,
    loginRequestKey: 0,
    sentDuration: 0,
    sentCurrentTime: 0,
    statusBadgeKey: 0,
    receivedAudioId: 0,
    sentAudioId: 0,
    playingSentAudio: 0,
    receivedDuration: 0,
    receivedCurrentTime: 0,
    playingReceivedAudio: 0,
    audioReceivedProgress: 0,
    audioSentProgress: 0,
    attachmentCard: false,
    selectedImage: "",
    progress: 0,
    chatOpen: false,
    chooseLocation: false,
    files: [],
    images: [],
    location: [],
    center: {
      lat: -33.48808586,
      lng: 19.62930204,
    },
    params: {
      limit: 20,
      offset: 0,
    },
    storedScroll: 0,
    canLoadMore: true,
    listEndObserver: null,
    isLoadingMore: false,
    zoom: 8,
  }),
  async created() {
    this.getDriverContacts();
    this.$socket.send(
      JSON.stringify({
        action: "join",
        room: `${process.env.NODE_ENV == 'production' ? 'waBot_Messages_': 'waBot_Messages_test_' }` + this.$store.state.currentOrg.id,
        user: {
          id: this.$store.state.user.id,
          name:
            this.$store.state.user.firstname +
            " " +
            this.$store.state.user.surname,
          avatar: this.$store.state.user.avatar,
          company: this.$store.state.currentOrg.name,
        },
      })
    );
  },
  mounted() {

    
    this.$socket.onopen = () => {
      this.$socket.send(
        JSON.stringify({
          action: "join",
          room: `${process.env.NODE_ENV == 'production' ? 'waBot_Messages_': 'waBot_Messages_test_' }` + this.$store.state.currentOrg.id,
          user: {
            id: this.$store.state.user.id,
            name:
              this.$store.state.user.firstname +
              " " +
              this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
    };
    this.$socket.onmessage = (data) => {
      try {
        if(data.data) {
        let message = JSON.parse(data.data)
        let payload = JSON.parse(message)
        if(payload.type == 'text') {
          this.handleMessage(payload);
        }
      }
      } catch(e) {
        // ignore error
      }
    };
  },
  beforeDestroy() {
    let node = document.getElementById('chatList');
    if(node) {
        node.removeEventListener('scroll', this.checkScroll);
    }
},

  beforeUnmount() {
    sessionStorage.setItem('contacts', JSON.stringify(this.contacts))
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  destroyed() {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
  },
  computed: {
    google: getGoogleMapsAPI,

    contactList() {
      let result = []
      if(this.contacts.length > 0) {
        result = this.contacts
        if(this.searchContact != "") {
          result = this.contacts.filter((contact) => contact.user.firstName.toLowerCase().includes(this.searchContact.toLowerCase()) || contact.user.surname.toLowerCase().includes(this.searchContact.toLowerCase()))
        }
      }
        return result 
      },

    messagesList() {
      let result = []
      if(this.currentChat.length > 0) {
        result = this.currentChat
      }
      if(this.hideBotMessages) {
        result = result.filter((message) => {
          if(message.messageType == 'outbound' && message.sender) {
            return true
          } else if(message.messageType == 'inbound' && !message.responseWamid){
            return true
          } else {
            return false
          }
        })
      }
      return result
    },
  },

  watch: {

  },
  methods: {

    // Incoming Requests

    async getDriverContacts() {
      this.loadingContacts = true
      let storedContacts = sessionStorage.getItem('contacts')
      if(storedContacts) {
        storedContacts = JSON.parse(JSON.stringify(storedContacts))
        this.contacts = JSON.parse(storedContacts)
      } else {
        let result = await this.$API.getDriverContacts();
        if(result) {
          this.contacts = result
        }
      }
      this.loadingContacts = false
      if(this.contacts.length > 0) {
        this.loadChats()
      }
    },

    async getMessages(contact) {
      this.selectedUser = contact.user
      this.loadingChat = true;
      this.chatLoading = true;
      this.canLoadMore = true;
      this.noChatSelected = false;
      if (this.selectedContact && this.selectedContact.id == contact.id && this.selectedContact.messages) {
        this.noChatSelected = false;
        this.loadingChat = false
        this.chatLoading = false;
        this.chatView = true
        this.$nextTick().then(() => {
          this.setUpInterSectionObserver();
          this.scrollToBottom()
        });
      } else {
        this.currentChat = []
        let driver = this.contacts.find((driver) => driver.id == contact.id)
        if (driver && driver.messages && driver.messages.length > 0) {
          this.selectedContact = driver;
          this.currentChat = this.selectedContact.messages
          this.chatLoading = false;
          this.loadingChat = false
          this.chatView = true
          this.$nextTick().then(() => {
            this.setUpInterSectionObserver();
            this.scrollToBottom()
          });
          this.$nextTick(() => {
              let node = document.getElementById('chatList');
              if(node) {
                  node.addEventListener('scroll', this.checkScroll);
              }
          });
        } else {
          let result = await this.$API.getMessages(contact.id, {params: this.params});
          if (result) {
            if (driver) {
              driver.messages = result.messages
              this.selectedContact = driver
            } else {
              this.selectedContact = result
            }
            this.currentChat = this.selectedContact.messages
            this.loadingChat = false
            this.chatLoading = false;
            this.chatView = true
            this.$nextTick().then(() => {
              this.setUpInterSectionObserver();
              this.scrollToBottom()
            });
            this.$nextTick(() => {
                let node = document.getElementById('chatList');
                if(node) {
                    node.addEventListener('scroll', this.checkScroll);
                }
            });
          } else {
            this.$message.error("Error loading messages");
          }
        }
      }
    },

    async loadChats() {
      for (let i = 0; i < this.contacts.length; i++) {
        let result = await this.$API.getMessages(this.contacts[i].id, {params: this.params});
        if (result) {
          this.contacts[i] = result
        }
      }
        this.contactsListKey++
    },

    async chooseTemplate() {
      this.templateView = true
      this.loadingTemplates = true
      if(this.templates.length == 0) {
        let result = await this.$API.getWhatsappTemplates()
        this.stringy(result, 'Whatsapp Templates')
        if (result && result.length > 0) {
          this.templates = [...result]
        }
      }
      this.loadingTemplates = false
    },


    // Incoming Websockets

    async handleMessage(message) {
      const userId = message.id;
      const result = JSON.parse(message.data);
      const driver = this.contacts.find(driver => driver.id === userId);
      if (!driver) {
        return;
      }
      if ((result.notification && driver.messages?.length > 0) || (result.logout && driver.active !== false)) {
        this.handleNotification(result, userId)
      } else {
        console.log('------------Message Received--------------\n', result);
        if (this.selectedContact && this.selectedContact.id === userId && result.messageType) {
          if (result.messageType === 'outbound' && result.senderId == this.$store.state.user.id) {
            const lastMessageIndex = driver.messages.findIndex(message =>
              message.messageType === 'outbound' && message.senderId && message.senderId == this.$store.state.user.id && message.sentNow
            );
            if (lastMessageIndex !== -1) {
              console.log('Message sent by user', lastMessageIndex)
              driver.messages.splice(lastMessageIndex, 1);
              this.selectedContact.messages.splice(lastMessageIndex, 1);
              this.sentKey++
            }
          }
          driver.messages.push(result);
          this.selectedContact = driver;
          this.messagesKey++;
          if (result.type === 'quick_reply_message' && result.message === 'START') {
            this.selectedContact.active = true;
            this.noLoginKey++;
            this.textBoxKey++;
            this.loginRequestKey++;
            this.sentKey++;
          }
          if (result.messageType === 'outbound' && result.waBotTemplateId && result.classification.includes('template')) {
            this.selectedContact.active = true;
            this.sendingTemplate = false;
            this.sentKey++;
          }
          this.contactsListKey++;
        } else if (!this.selectedContact) {
          if (driver.messages && driver.messages.length > 0) {
            driver.messages.push(result);
            driver.lastMessage = result;
          } else {
            driver.messages = [result];
            driver.lastMessage = result;
          }
          if (result.type === 'quick_reply_message') {
            driver.active = true;
            this.noLoginKey++;
            this.textBoxKey++;
            this.loginRequestKey++;
          }
          this.contactsListKey++;
        }
        this.scrollToBottom();
      }
    },

    handleNotification(notify, userId) {
      const driver = this.contacts.find(driver => driver.id === userId);
      console.log('Step 1: Driver', driver)
      if (notify.notification && driver.messages.length > 0) {
        console.log('Step 2: Is Notification', notify)
          let field = {}
          switch(notify.notifyType) {
            case 'delivered' :
              field.delivered = true;
              break;
            case 'read' :
              field.read = true;
              break;
            case 'sent' :
              field.sent = true;
              break;
            case 'failed' :
              field.failed = true;
              field.sent = false;
              field.delivered = false;
              field.read = false
              break;
          }
          // findIndex of message
          const messageUpdate = driver.messages.findIndex(message => {
            return message.id == notify.id
          });
          if(messageUpdate !== -1) {
          driver.messages[messageUpdate] = {...driver.messages[messageUpdate], ...field}
          console.log('Step 3: Message Update', driver.messages[messageUpdate])
          this.messagesKey++;
        }
      } else if (notify.logout && driver.active !== false) {
        driver.active = false;
        this.statusBadgeKey++;
      }
    },

    handleMedia(message) {},
    
    async messagesRead(id) {
      let data = {
        transportDriverId: id,
        unread: false,
      }
      this.$API.messagesRead(data);
      for(let driver of this.contact) {
        if(driver.id == id) {
          driver.unread = 0;
        }
      }
    },


    // Outgoing

    async sendTemplate() {
      this.sendingTemplateLoad = true
      this.sendingTemplate = true
      let payload = {
      to: {
        id: this.selectedContact.id,
        nickname: this.selectedContact.nickname,
        organisationId: this.selectedContact.organisationId,
        user: this.selectedContact.user,
      },
      template: this.currentTemplate,
      }
      let result = await this.$API.sendTemplate(payload)
      if(result) {
        this.templateView = false
      }
      this.sendingTemplateLoad = false
      this.sendingTemplate = false
    },

    async sendLoginRequest() {
      this.sendingLoginRequest = true
      let sent = await this.$API.sendMessage({
          message: 'loginRequest',
          loginRequest: true,
          driver: {
            id: this.selectedContact.id,
            user: this.selectedContact.user,
            nickname: this.selectedContact.nickname,
            organisationId: this.selectedContact.organisationId
          },
        });
        if(sent) {
          this.$message.success("Login Request Sent");
        } else {
          this.$message.error("Error sending login request");
        }
        this.sendingLoginRequest = false
    },

    async sendMessage() {
      try {
        this.loadingSent = true;
        this.playSentMessage();
        let payload = {
            timestamp: this.formatTime(this.date).time,
            messageType: 'outbound',
            message: this.selectedContact.textArea,
            sent: 1,
            senderId: this.$store.state.user.id,
            sentNow: true,
            sender: {
              id: this.$store.state.user.id,
              firstName: this.$store.state.user.firstname,
              surname: this.$store.state.user.surname,
              emailAddress: this.$store.state.user.emailAddress ? this.$store.state.user.emailAddress : "",
              avatar: this.$store.state.user.avatar,
            },
            type: "text_message",
          };
          
          let contact = this.contacts.find((driver) => driver.id == this.selectedContact.id)
          contact.messages.push(payload)
          this.selectedContact.messages = contact.messages
          console.log(contact.messages)
          this.loadingSent = false
          let message = JSON.parse(JSON.stringify(this.selectedContact.textArea))
          this.$nextTick(() => {
            this.scrollToBottom()
            this.selectedContact.textArea = "";
          });
          let driver = {
            id: this.selectedContact.id,
            nickname: this.selectedContact.nickname,
            organisationId: this.selectedContact.organisationId,
            user: {
              id: this.selectedContact.user.id,
              firstName: this.selectedContact.user.firstName,
              surname: this.selectedContact.user.surname,
              emailAddress: this.selectedContact.user.emailAddress ? this.selectedContact.user.emailAddress : "",
              avatar: this.selectedContact.user.avatar,
            },
          }
          let sent = await this.$API.sendMessage({
            message: message,
            driver: driver,
            user: {
              firstname: this.$store.state.user.firstname,
              surname: this.$store.state.user.surname,
              emailAddress: this.$store.state.user.emailAddress ? this.$store.state.user.emailAddress : "",
              id: this.$store.state.user.id,
              organisationId: this.$store.state.currentOrg.id,
            },
          });
          if(sent) {
            this.selectedContact.messages[this.selectedContact.messages.length - 1].sent = 1;
          }
        } catch(e) {
          console.log(e)
        } finally {
          this.loadingSent = false;
          sessionStorage.setItem('contacts', JSON.stringify(this.contacts))
        }
    },


    // MEDIA
    loadAudioSent(id) {
        let audio = document.getElementById(`audioSent-${id}`);
        for(let message of this.messages) {
        if(message.id == id) {
          message.duration = (audio.duration / 100).toString().substring(0, 4).replace('.', ':')
          message.currentTime = '0:00'
          this.sentDuration++;
        }
      }
    },

    loadAudioReceived(id) {
      let audio = document.getElementById(`audioReceived-${id}`);
      for(let message of this.messages) {
        if(message.id == id) {
          message.duration = (audio.duration / 100).toString().substring(0, 4).replace('.', ':')
          message.currentTime = '0:00'
          this.receivedDuration++;
        }
      }
    },

    playNotification() {
      var audioFile = new Audio('https://notificationsounds.com/storage/sounds/file-sounds-1085-definite.mp3');
      audioFile.play();
    },

    playSentMessage() {
      var audioFile = new Audio('https://notificationsounds.com/storage/sounds/file-sounds-954-all-eyes-on-me.mp3');
      audioFile.play();
    },

    playSentAudio(id) {
        this.sentAudioId = id;
        this.playingSentAudio = id;
        let audio = document.getElementById(`audioSent-${id}`);
        audio.play();
    },

    pauseSentAudio(id) {
        this.sentAudioId = 0;
        let audio = document.getElementById(`audioSent-${id}`);
        audio.pause();
    },

    playReceivedAudio(id) {
        this.receivedAudioId = id;
        this.playingReceivedAudio = id;
        let audio = document.getElementById(`audioReceived-${id}`);
        audio.play();
    },
    
    pauseReceivedAudio(id) {
        this.receivedAudioId = 0;
        let audio = document.getElementById(`audioReceived-${id}`);
        audio.pause();
    },

    // TOOLS

    setUpInterSectionObserver() {
      let options = {
        root: document.getElementById('chatList'),
        margin: "20px",
      };
      this.listEndObserver = new IntersectionObserver(
        this.handleIntersection,
        options
      );
      this.listEndObserver.observe(document.getElementById('sentinel'));
    },

    handleIntersection([entry]) {
      if (entry.isIntersecting) {
        console.log("sentinel intersecting");
      }
      if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
        this.loadMore();
      }
    },

    recordScrollPosition() {
      let node = document.getElementById('chatList');
      this.storedScroll =
        node.scrollHeight - node.scrollTop;
    },
    restoreScrollPosition() {
      let node = document.getElementById('chatList');
      node.scrollTop =
        node.scrollHeight - this.storedScroll;
    },
    checkScroll() {
        let node = document.getElementById('chatList');
        if(node) {
            this.scrollToBottomButton = node.scrollTop !== node.scrollHeight - node.clientHeight;
        }
    },

    async loadMore() {
      try {
        this.isLoadingMore = true;
        this.recordScrollPosition();
        this.params.offset = this.selectedContact.messages.length;
        this.params.limit = 20;
        let result = await this.$API.getMessages(this.selectedContact.id, {params: this.params});
        if(result) {
          console.log('gotData', result)
          this.selectedContact.messages.unshift(...result.messages);
          this.isLoadingMore = false;
          this.$nextTick(() => {
          this.restoreScrollPosition();
        });
          this.canLoadMore = result.messages.length == 20;
          sessionStorage.setItem('contacts', JSON.stringify(this.contacts))
        }
        this.params.offset = 0;
      } catch(e) {
        this.canLoadMore = false;
        this.isLoadingMore = false;
        console.log(e)
      } finally {
        this.isLoadingMore = false;
      }
    },

    scrollToBottom() {
    this.$nextTick(() => {
      let scroll = document.getElementById('chatList')
      scroll.scrollTop = scroll.scrollHeight;
    });
    },

    formatTime(timestamp) {
      if(!timestamp) {
        return {}
      }
      let obj = {}
      obj.isoFormat = dateFormat(new Date(timestamp), "yyyy-mm-dd");
      obj.dayFullDate = dateFormat(new Date(timestamp), "ddd, mmmm dS, yyyy");
      obj.fullDate = dateFormat(new Date(timestamp), "mmmm dS, yyyy");
      obj.dateTime = dateFormat(new Date(timestamp), "dd-mm-yyyy @ HH:MM");
      obj.dayOfWeek = dateFormat(new Date(timestamp), "dddd");
      obj.shorterDate = dateFormat(new Date(timestamp), "mmm dS, yyyy");
      obj.time = dateFormat(timestamp, "h:MM TT");
      obj.shortTime = dateFormat(timestamp, "HH:MM");
      obj.date = dateFormat(timestamp, "dd mmm yyyy");
      return obj;
    },

    displayDate(date) {
      let testDate = this.formatTime(date).date
      let today = this.formatTime(new Date()).date
      let yesterday = this.formatTime(new Date(new Date().setDate(new Date().getDate() - 1))).date
      let twoDays = this.formatTime(new Date(new Date().setDate(new Date().getDate() - 2))).date
      let threeDays = this.formatTime(new Date(new Date().setDate(new Date().getDate() - 3))).date
      let fourDays = this.formatTime(new Date(new Date().setDate(new Date().getDate() - 4))).date
      let fiveDays = this.formatTime(new Date(new Date().setDate(new Date().getDate() - 5))).date

      if(testDate == today) {
        return 'Today'
      } else if(testDate == yesterday) {
        return 'Yesterday'
      } else if(testDate == twoDays || testDate == threeDays || testDate == fourDays || testDate == fiveDays) {
        return this.formatTime(date).dayOfWeek
      } else {
        return testDate
      }
    },

    showDate(item, index) {
      let result = false
      if(index === 0) {
        result = true
      }else if(this.messagesList[index - 1] && this.messagesList[index - 1].createdAt && item.createdAt) {
        let previous = this.messagesList[index - 1]
        let previousDate = this.formatTime(previous.createdAt).date
        let currentDate = this.formatTime(item.createdAt).date
        if(previousDate != currentDate) {
          result = true
        }
      }
      return result
    },

    stringy(obj, message) {
            // let stuff = { ...obj }
            let result = JSON.parse(JSON.stringify(obj));
            if (message) {
                console.log(message, result)
            } else {
                console.log(result)
            }
    },

    processedText(original) {
      if(original) {
        if(original.includes(`\n\n_From`)) {
          original = original.replace(`\n\n_From`, `\n_From`)
        }
        const lines = original.split('\n')
        let isInsideFromPattern = false;
      const processedLines = lines.map((line, lineIndex) => {
        const words = line.match(/\S+|\s/g) || [];
        let isInsideBold = false;
        let isInsideItalic = false;
        const processedWords = words.map((word, wordIndex) => {
          if (word === '\n') {
            return { text: '\n', style: {} };
          }
          if (word.startsWith('*')) {
            isInsideBold = true;
            word = word.slice(1);
          }
          if (isInsideBold) {
            if (word.endsWith('*')) {
              isInsideBold = false;
              return { text: word.slice(0, -1), style: { fontWeight: 'bold' } };
            } else {
              return { text: word, style: { fontWeight: 'bold' } };
            }
          }
          if (word.startsWith('_')) {
            isInsideItalic = true;
            word = word.slice(1);
          }
          if (isInsideItalic) {
            if (word.endsWith('_')) {
              isInsideItalic = false;
              return { text: word.slice(0, -1), style: { fontStyle: 'italic' } };
            } else {
              return { text: word, style: { fontStyle: 'italic' } };
            }
          }
          return { text: word, style: {} };
        });

        if (line.includes('_From')) {
            let test = line.split(' ')
            if(test.length > 2 && test.length < 6) {
                isInsideFromPattern = true;
          }
        } else {
          isInsideFromPattern = false;
        }

        if (!isInsideFromPattern) {
        if (lineIndex < lines.length - 1) {
          processedWords.push({ text: '\n', style: {} });
        }
        return processedWords;
      } else {
        return [];
      }
      });
      
      return processedLines.flat();
    } else {
      return [{text: '', style: {}}]
    }
    },

    viewImage(imageUrl) {
      this.viewingUrl = imageUrl
      this.previewImage = true
    },

    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },

    addMore() {
      document.getElementById("fileUploadMore").click();
    },

    addFile(files) {
        let fileList = Array.from(files.target.files)
        for(let i = 0; i < fileList.length; i++) {
          fileList[i].id = i + 1;
          fileList[i].message = null;
          fileList[i].fileSize = filesize(fileList[i].size).human('si');
          fileList[i].updatedAt = JSON.parse(JSON.stringify(this.formatTime(fileList[i].lastModified).date + " " + "@" + " " + this.formatTime(fileList[i].lastModified).time));
        }
    },

    addMoreFiles(files) {
      let fileList = Array.from(files.target.files)
      let idStart = this.files.length
      for(let i = 0; i < fileList.length; i++) {
        fileList[i].id = idStart + i + 1;
        fileList[i].message = null;
        fileList[i].fileSize = filesize(fileList[i].size).human('si');
        fileList[i].updatedAt = JSON.parse(JSON.stringify(this.formatTime(fileList[i].lastModified).date + " " + "@" + " " + this.formatTime(fileList[i].lastModified).time));
      }
      
    },

    chooseImages() {
      document.getElementById("imageUpload").click();
    },

  },
};
</script>

<style scoped>

.loadingMore {
  text-align: center;
}

.sentinel {
  height: 20px;
  width: 100%;
}
.outerBox {
  width: 100%;
  height: calc(96vh - 44px);
  background-color: var(--v-greyRaised-base) !important;
  
}
.contactList.v-card__text{
  height: calc(96vh - 48px - 44px) !important; 
  overflow-y: scroll; 
  
}
.sentTail {
  z-index: 3;
  content: ' '; 
  position: absolute; 
  width: 0; 
  height: 0; 
  right: 1.8vw; 
  top: 0.2vh; 
  bottom: auto; 
  border: 14px solid; 
  border-color: rgb(255, 248, 239) transparent transparent transparent;
}
.receivedTail {
  z-index: 3;
  content: ' '; 
  position: absolute; 
  width: 0; 
  height: 0; 
  left: 0px; 
  right: auto; 
  top: 0.2vh; 
  bottom: auto; 
  border: 14px solid; 
  border-color: #3797cb transparent transparent transparent;
}
.contactBox {
  margin: 0 0 0 0;
  height: inherit;
  left: 0;
  contain: content;
  background-color: var(--v-greyRaised-base) !important;
}

.normalButton {
  color: #3797cb;
  background-color: rgb(255, 248, 239) !important; 
  margin: 0.3vh 2.5vw 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chosenButton {
  color: rgb(255, 248, 239);
  background-color: #3797cb !important; 
  margin: 0.3vh 2.5vw 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CHAT */
.chatArea {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: inherit;
  contain: content;
}

.messageList {
  overflow-y: auto;
  padding: 0 0.5vw 0 0.5vw;
}
.chosenTemplate {
    background-color: #74d87b2c !important;
}
.chatMessages { 
  max-height: inherit;
  min-height: inherit;
  height: inherit;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0 0.5vw 0 0.5vw;
  background-image: linear-gradient(to bottom, #1e998930, #19928230, #148b7c30, #0f857530, #087e6f30, #007e7530, #007f7b30, #007f8130, #00869430, #008da830, #0c92ba30, #3797cb30);

}

.messageBox {
  padding: 1vh 0 1vh 0;
  width: 100%;
  min-height: 6vh;
  max-height: 15vh;
  display: flex;
  align-items: center;
  z-index: 6;
}

.textArea {
  min-height: 5vh;
  max-height: 13vh;
  border-radius: 12px;
  overflow-y: auto;
}

.attach {
  rotate: 45deg;
  transform: scaleX(-1);
  margin: 0 1vw 0.6vh 1vw;
}




.send {
  height: 45px;
  width: 45px;
  margin: 0 1vw 0.6vh 1vw;
}

.imageSent {
  position: relative;
  background-color: #3797cb !important;
  text-align: left;
  border-radius: 12px;
  padding: 0;
  width: fit-content;
  margin-top: 0.6vh;
  margin-bottom: 0.6vh;
}

.audioSent {
  position: relative;
  background-color: #3797cb !important;
  text-align: left;
  border-radius: 12px;
  padding: 0;
  width: 20vw;
  height: 6vh;
  margin: 0.6vh 0 0.6vh 0;
}
.textSent {
  z-index: 2;
  position: relative;
  background-color: rgb(255, 248, 239) !important;
  border-radius: 12px;
  padding: 0 2.7vw 0 0;
  color:rgb(47, 47, 47);
  max-width: 40vw;
  width: fit-content;
  margin: 0.2vh 2.5vw 0.2vh 0;
}

.chatReceived {
  z-index: 2;
  position: relative;
  background-color: #3797cb !important;
  padding: 0 2.7vw 0 0;
  border-radius: 12px;
  max-width: 40vw;
  margin: 0.2vh 0 0.2vh 0.5vw;
  max-width: 40vw;
  width: fit-content;
}

.imageReceived {
  position: relative;
  background-color: #3797cb !important;
  text-align: left;
  border-radius: 12px;
  width: fit-content;
  max-width: 40vw;
  margin: 0.2vh 0 0.2vh 0;
  padding: 0;
  min-width: 12vw;
  min-height: 12vw;
}


.audioReceived {
  position: relative;
  background-color: #3797cb !important;
  text-align: left;
  border-radius: 12px;
  padding: 0;
  width: 20vw;
  height: 6vh;
  margin: 0.6vh 0 0.6vh 0;
}

.searchBar {
  border-radius: 16px;
}

.attachments {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 7vh;
  border-radius: 12px;
  margin-left: 0.5vw;
  width: 3vw;
  height: auto;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0) !important;
  padding-top: 1vh;
}
.location {
  position: absolute;
  bottom: 0;
  border-radius: 25px;
  height: 70vh;
  width: 58vw;
  margin: 0 4vw 0 4.1vw;
  z-index: 2;
}

.fileDialog {
  position: absolute;
  bottom: 0;
  height: 81vh;
  width: 63vw;
  margin: 0 0 0 0;
  z-index: 3;
}
.scroller {
  height: 100%;
  scroll-behavior: smooth;
}
.fileSelection {
  width: 100%;
  height: 50vh;
  /* background-color:  rgba(2, 2, 2, 2) !important; */
  background-color:  rgba(0, 0, 0, 0) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreview {
  width: 20vw;
  height: 32vh;
  overflow-x: hidden;
  overflow-y: hidden;  
  background-color: #3d3d3d !important 
}

.fileText {
  width: 100%;
  height: 10vh;
  background-color:  rgba(0, 0, 0, 0) !important;
  /* background-color: #fff !important; */
  display: flex;
  justify-content: center;
}

.fileMultiple {
  width: 100%;
  height: 16vh;
  background-color:  rgba(0, 0, 0, 0) !important;
  /* background-color: black !important; */
  display: flex;
  justify-content: center;
}

.littleFile {
  border-width: 2px;
  border-style: dashed;
  border-color: #9c9999;
  max-height: 88px;
  min-height: 88px;
  min-width: 82px;
  max-width: 82px;
  flex-grow: 1;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  background-color:  rgba(0, 0, 0, 0) !important;
}

.documentScroll {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  flex-direction: row; 
  overflow-x: scroll; 
  max-width: 880px; 
  min-width: 89px; 
  padding: 0 0.5vw 0 3vw;
  background-color:  rgba(0, 0, 0, 0) !important;
}

.poiSelect {
  position: absolute;
  border-radius: 25px;
  height: 70vh;
  width: 22vw;
}
.mapCard {
  position: absolute;
  bottom: 8vh;
  border-radius: 16px;
  height: 57vh;
  width: 35vw;
  right: 1vw;
}




/* TRANSITIONS */
.attachment-leave-active {
  transition: 0.5s;
}
.attachment-enter-active {
  transition: 0.5s;
}
.attachment-enter {
  transform: translateY(200%);
}
.attachment-leave-to {
  transform: translateY(200%);
}

.dialogInput-leave-active {
  transition: 0.3s;
}
.dialogInput-enter-active {
  transition: 0.3s;
}
.dialogInput-enter {
  transform: translateY(100%);
}
.dialogInput-leave-to {
  transform: translateY(100%);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"height":"calc(96vh - 84px)"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"4"}},[_c('el-input',{attrs:{"id":"search","suffix-icon":"el-icon-search","clearable":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend"},[_c('el-button',{on:{"click":function($event){return _vm.addDrivers()}}},[_c('i',{staticClass:"el-icon-plus"})])],1)],2)],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"height":"77vh","id":"vehicleTable","search":_vm.search,"calculate-widths":true,"items":_vm.driverData.data,"single-expand":true,"loading":_vm.loading,"headers":_vm.headers,"options":{
          page: _vm.params.offset + 1,
          itemsPerPage: _vm.params.limit,
          pageStart: _vm.params.offset + 1,
          pageStop: Math.round(_vm.driverData.total / this.params.limit),
          pageCount: Math.round(_vm.driverData.total / this.params.limit),
          itemsLength: _vm.driverData.total
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
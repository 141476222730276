<template>
    <v-card class="bodyCard">
        <v-card-text style="height:inherit;" class="pb-1">
            <v-row style="height:30%">
                <v-col cols="12" sm="6">
                    <v-card class="tile top">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    map
                                </v-icon>
                                Locations
                        </v-subheader>
                        <v-divider></v-divider>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card class="tile top">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    map
                                </v-icon>
                                Locations
                        </v-subheader>
                        <v-divider></v-divider>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card class="tile top">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    map
                                </v-icon>
                                Locations
                        </v-subheader>
                        <v-divider></v-divider>
                    </v-card>
                </v-col>
            </v-row>
            <!-- Bottom Summary -->
            <v-row style="height:70%">
                <v-col cols="12" sm="3" class="bottom">
                    <v-card class="tile bottom noScroll">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    people
                                </v-icon>
                                Drivers
                                <v-spacer></v-spacer>
                                <v-btn icon><v-icon>search</v-icon></v-btn>
                                <v-btn icon><v-icon>add</v-icon></v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-progress-linear indeterminate v-if="loadingDrivers"></v-progress-linear>
                        <div class="scroller">
                            <v-list>
                                <v-list-item>

                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="bottom">
                    <v-card class="tile bottom">
                        <v-subheader style="font-size: 16px">
                            <v-img src="../../../../public/icons/truck-flatbed.svg" class="filter-orange mr-2" max-height="24px" max-width="24px" />
                            Vehicles
                            <v-spacer></v-spacer>
                            <v-btn icon><v-icon>search</v-icon></v-btn>
                            <v-btn icon><v-icon>add</v-icon></v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-progress-linear indeterminate v-if="loadingVehicles"></v-progress-linear>
                        <div class="scroller">
                            
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="bottom">
                    <v-card class="tile bottom">
                        <v-subheader style="font-size: 16px">
                            <v-img src="../../../../public/icons/trailer.svg" max-height="24px" max-width="24px" class="mr-2 filter-orange" />
                            Trailers
                            <v-spacer></v-spacer>
                            <v-btn icon><v-icon>search</v-icon></v-btn>
                            <v-btn icon><v-icon>add</v-icon></v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-progress-linear indeterminate v-if="loadingTrailers"></v-progress-linear>
                        <div class="scroller">
                            
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="bottom">
                    <v-card class="tile bottom">
                        <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">
                                local_shipping
                            </v-icon>
                            Units
                            <v-spacer></v-spacer>
                            <v-btn icon><v-icon>search</v-icon></v-btn>
                            <v-btn icon><v-icon>add</v-icon></v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-progress-linear indeterminate v-if="loadingUnits"></v-progress-linear>
                        <div class="scroller">
                            
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
 export default {

    data: () => ({
        drivers: [],
        vehicles: [],
        trailers: [],
        units: [],
        loadingDrivers: false,
        loadingVehicles: false,
        loadingTrailers: false,
        loadingUnits: false,
    }),
    async created() {
    this.getDrivers();
    this.getVehicles();
    this.getTrailers();
    this.getTransportUnits();
    },
    methods: {
        async getDrivers() {
            this.loadingDrivers = true;
            this.drivers = await this.$API.getDrivers();
            this.loadingDrivers = false;
        },
        async getVehicles() {
            this.loadingVehicles = true;
            this.vehicles = await this.$API.getVehicles();
            this.loadingVehicles = false;
        },
        async getTrailers() {
            this.loadingTrailers = true;
            this.trailers = await this.$API.getTrailers();
            this.loadingTrailers = false;
        },
        async getTransportUnits() {
            this.loadingUnits = true;
            this.units = await this.$API.getTransportUnits();
            this.loadingUnits = false;
        }
    }
 }
</script>

<style scoped>

.bodyCard {
    /* background-color: var(--v-greyRaised-base) !important; */
    height: calc(96vh - 84px);
    width: 100%;
}

.tile {
    background-color: var(--v-greyBase-base) !important;
    border-radius: 16px;
}
.bottom {
    height: 100%;
}

.top {
    height: 100%;
}
.filter-orange {
    filter: brightness(0) saturate(100%) invert(75%) sepia(43%) saturate(564%) hue-rotate(338deg) brightness(97%) contrast(99%);
}
.noScroll {
    overflow-y: hidden;
}
.scroller {
    overflow-y: scroll;
    height: calc(100% - 48px);
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mapCard"},[_c('gmap-map',{key:"mapKey",ref:"mapRef",staticClass:"gMap",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
            scrollwheel: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false,
        }}},[_c('gmap-marker',{attrs:{"clickable":true,"icon":{
                url: require('../../../../public/icons/truck.svg'),
                scaledSize: {width: 50, height: 50},
            },"position":{
                lat: _vm.truckPosition.lat,
                lng: _vm.truckPosition.lng,
            }},on:{"click":function($event){return _vm.toggleInfoWindow(_vm.m)},"dblclick":function($event){return _vm.viewMarker(_vm.m)}}}),(_vm.route)?_c('gmap-polyline',{attrs:{"path":_vm.route,"options":{
                strokeColor: 'red',
                strokeOpacity: 0.7,
                strokeWeight: 3,
            }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card style="background-color: var(--v-toolbar-base) !important; height: calc(96vh - 44px); width: 100%;">
        <v-card-text style="height:inherit;" class="pb-1">
            <!-- Templates -->
            <v-row style="height: 100%;">
                <v-col cols="12" sm="2" class="px-1 py-1">
                    <v-card class="tile">
                        <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">
                                forum
                            </v-icon>
                            Templates
                            <v-spacer></v-spacer>
                            <v-tooltip left open-delay="300">
                                <template v-slot:activator="{on}">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     <v-btn v-on="on" icon @click="newTemplate = true"><v-icon>add</v-icon></v-btn>
                                </template>
                                Add Template
                            </v-tooltip>
                        </v-subheader>
                        <v-progress-linear v-if="loadingTemplates" indeterminate></v-progress-linear>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item v-for="(template, index) in templates" :key="index" @click="switchTemplates(template)" :class="currentTemplate.name == template.name ? 'chosenTemplate pr-0' : 'pr-0'">
                                <v-list-item-icon class="mr-4">
                                    <span v-if="!template.cannotDelete">{{ index }}</span>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ template.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ template.description }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <div>
                                        <v-tooltip top open-delay="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-on="on" v-bind="attrs" style="font-size: 20px;" v-if="template.active" color="tertiary">
                                                    check_circle</v-icon>
                                            </template>
                                            <span>Template Active</span>
                                        </v-tooltip>
                                        <v-tooltip top open-delay="200">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-icon v-on="on" v-bind="attrs" style="font-size: 20px;" v-if="!template.active" color="grey">
                                                    unpublished</v-icon>
                                            </template>
                                            <span>Template Inactive</span>
                                        </v-tooltip>
                                        <v-menu offset-x>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn icon small class="ml-1" v-on="on" v-bind="attrs">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card style="background-color: var(--v-greyRaised-lighten1) !important;">
                                                <v-list dense>
                                                    <v-list-item @click="configTemp(template)">
                                                        <v-icon left style="font-size: 20px;">settings</v-icon>
                                                        Configure
                                                    </v-list-item>
                                                    <!-- <v-list-item @click="startPreview(template)"> 
                                                        <v-icon left style="font-size: 20px;">visibility</v-icon>
                                                        Preview
                                                    </v-list-item> -->
                                                    <v-list-item @click="duplicateTemplate(template)" v-if="!template.cannotDelete">
                                                        <v-icon left style="font-size: 20px;">content_copy</v-icon>
                                                        Duplicate
                                                    </v-list-item>
                                                    <v-list-item @click="deleteTemplate(template, index)" v-if="!template.cannotDelete">
                                                        <v-icon left style="font-size: 20px;">delete</v-icon>
                                                        Delete
                                                    </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="7" class="px-1 py-1">
                    <v-card class="tile" style="overflow-y: hidden;">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    edit_note
                                </v-icon>
                                <span v-if="currentTemplate.name">Template: <b>{{ currentTemplate.name }}</b></span>
                                <span v-else>Select a Template</span>
                                <v-spacer></v-spacer>
                                <v-btn v-if="!viewResponse" @click="saveAll(currentTemplate.id)" :disabled="saveDisabled" color="edit" :loading="loadingMessageSave" :key="disabledKey">SAVE</v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-row no-gutters style="height: calc(100% - 48px);">
                            <v-col cols="12" sm="5">
                                <v-card flat tile class="innerTile" style="border-right: 1px solid hsla(0,0%,100%,.12); overflow-y: scroll; contain: content;" >
                                    <v-subheader style="font-size: 16px">
                                        Messages
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="newMessageDialog = true" :disabled="!currentTemplate.name"><v-icon>add</v-icon></v-btn>
                                    </v-subheader>
                                    <v-progress-linear v-if="getMessagesLoading" indeterminate></v-progress-linear>
                                    <v-list dense v-if="currentTemplate && currentTemplate.messageGroups" :key="messagesKey">
                                        <draggable
                                          style="height: inherit; width: inherit"
                                          :list="currentTemplate.messageGroups"
                                          :animation="200"
                                          ghost-class="ghost-card"
                                          group="messages"
                                          @change="groupValueChange"
                                        >
                                        <div v-for="(group, idx) in currentTemplate.messageGroups" :key="idx">
                                            <div v-for="(message, index) in group.messages" :key="index">
                                                <v-list-item
                                                  @click="selectMessage(group, message)"
                                                  :class="getClass(message)"
                                                  :style="getStyle(message)"
                                                  >
                                                    <div class="my-0 mr-2">
                                                        <v-icon v-if="message.groupOrder > 1" style="margin-right: 10px">subdirectory_arrow_right</v-icon>
                                                        <span style="font-weight: 600; opacity: 0.7;">{{ message.groupOrder == 1 ? `${group.groupId}` : `${group.groupId}.${message.groupOrder -1}` }}</span>
                                                        <v-icon color="tertiary" right style="font-size: 18px; opacity: 0.7;" v-if="message.body">check_circle_outline</v-icon>
                                                        <v-icon color="primary" right style="font-size: 18px; opacity: 0.7;" v-if="!message.body">highlight_off</v-icon>
                                                    </div>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ message.title }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <i v-if="message.body">{{ message.body }}</i>
                                                            <i v-else style="color: var(--v-primary-base) !important; opacity: 0.7;">Message has no body</i>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <div>
                                                            <v-btn v-if="addCommentDisabled(message.buttons)" icon x-small class="mr-2" @click="addButtonResponse(group, message)"><v-icon>add_comment</v-icon></v-btn>
                                                            <v-btn icon color="primary" x-small @click="removeMessage(group, message, index)"><v-icon>close</v-icon></v-btn>
                                                        </div>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </div>
                                        </div>
                                        </draggable>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="7" style="contain: content;">
                                <v-card flat tile class="innerTile" style="overflow-y: scroll; contain: content; padding-bottom: 10vh;">
                                    <!-- HEADERS -->
                                    <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                        <b>Header</b> <i class="ml-3">{{currentTemplate.waRef == 'mainMenu' ? '(Mandatory)' : '(Mandatory for messages with buttons)'}}</i>
                                    </v-subheader>
                                    <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Add a title to the top of the message</span>
                                    <v-text-field class="mx-4 mt-3" dense outlined v-if="!viewResponse"
                                        v-model="currentMessage.header" maxlength="60" counter @change="saveDisabledCheck" 
                                    />
                                    <v-text-field class="mx-4 mt-3" dense outlined v-if="viewResponse"
                                        v-model="responseMessage.header" maxlength="60" counter @change="saveDisabledCheck"
                                    />


                                    <!-- BODY -->
                                    <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                        <b>Body</b> <i class="ml-3">(Mandatory)</i>
                                    </v-subheader>
                                    <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Enter the text for your message.</span>
                                    <v-textarea class="mx-4 mt-3" outlined maxlength="1024" counter v-if="!viewResponse"
                                        v-model="currentMessage.body" @change="saveDisabledCheck" auto-grow
                                    />
                                    <v-textarea class="mx-4 mt-3" outlined maxlength="1024" counter v-if="viewResponse"
                                        v-model="responseMessage.body" @change="saveDisabledCheck" auto-grow
                                    />
                                    <v-menu>
                                        <template v-slot:activator="{ on }">
                                            <v-btn plain v-on="on" class="variableSelect">
                                                <v-icon left>add</v-icon>Add Variable</v-btn>
                                        </template>
                                        <v-list dense style="column-count: 3;">
                                            <v-list-item v-for="(item, index) in variables" :key="index" @click="copyToClipboard(item)">
                                                <v-list-item-icon class="mr-3">
                                                    <v-icon small>content_copy</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                <v-list-item-subtitle>ex: {{ item.example }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                    <!-- FOOTER -->
                                    <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                        <b>Footer</b> <i class="ml-3">{{currentTemplate.waRef == 'mainMenu' ? '(Mandatory)' : '(Optional)'}}</i>
                                    </v-subheader>
                                    <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Add a short line of text to the bottom of your message template.</span>
                                    <v-text-field class="mx-4 mt-3" dense outlined v-if="!viewResponse"
                                        v-model="currentMessage.footer" maxlength="60" counter @change="saveDisabledCheck"
                                    />
                                    <v-text-field class="mx-4 mt-3" dense outlined v-if="viewResponse"
                                        v-model="responseMessage.footer" maxlength="60" counter @change="saveDisabledCheck"
                                    />

                                    <!-- BUTTONS -->
                                    <div v-if="currentTemplate.waRef != 'mainMenu'">
                                        <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                            <b>Buttons</b> <i class="ml-3">(Optional)</i> <i class="ml-3">(Max 3)</i>
                                        </v-subheader>
                                        <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Create buttons that let drivers respond to your message.</span>
                                        <br>
                                        <v-btn :disabled="addButtonDisabled || (currentMessage.expectedTextResponse)"
                                        class="mx-4 mt-3" color="greyContrast" @click="addButton"><v-icon left>add</v-icon>Add Button</v-btn>
                                        <v-card outlined min-height="10vh" class="mx-4 mt-3 mb-4" style="background-color: var(--v-greyRaised-base) !important;">
                                            <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                                Quick Reply
                                            </v-subheader>
                                            <v-list v-if="!viewResponse && currentMessage.buttons">
                                                <draggable style="height: inherit; width: inherit"
                                                :list="currentMessage.buttons"
                                                :animation="200"
                                                ghost-class="ghost-card"
                                                group="buttons"
                                                >
                                                <div v-for="(button, index) in currentMessage.buttons" :key="index">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-4">
                                                            <v-icon>drag_handle</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content style="height: 7.5vh;" class="pb-0 pt-0">
                                                                <v-text-field autofocus label="Button Text" persistent-placeholder v-model="button.buttonText" counter maxlength="20" outlined dense  @change="saveDisabledCheck"/>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-btn icon color="primary" @click="removeButton(index)"><v-icon>close</v-icon></v-btn>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-btn :color="buttonActionCount(button).count > 0 ? 'edit' : ''" plain class="buttonFunction" @click="buttonActions(button,index)"><v-icon left>add</v-icon>{{button.actions ? buttonActionCount(button).count > 1 ? `${buttonActionCount(button).count} ACTIONS` : `1 ACTION` : 'ADD ACTION'}}</v-btn>
                                                </div>
                                                </draggable>
                                            </v-list>
                                        </v-card>
                                    </div>
                                    <!-- Menu Items -->
                                    <div v-if="currentTemplate.waRef == 'mainMenu'">
                                        <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                            <b>Manu Items</b> <i class="ml-3">(Mandatory)</i> <i class="ml-3">(Max 10)</i>
                                        </v-subheader>
                                        <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Create buttons that let drivers respond to your message.</span>
                                        <br>
                                        <v-btn :disabled="addButtonDisabled || (currentMessage.expectedTextResponse)"
                                        class="mx-4 mt-3" color="greyContrast" @click="addButton"><v-icon left>add</v-icon>Add Menu Item</v-btn>
                                        <v-card outlined min-height="10vh" class="mx-4 mt-3 mb-4" style="background-color: var(--v-greyRaised-base) !important;">
                                            <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                                Menu Item
                                            </v-subheader>
                                            <v-list v-if="!viewResponse && currentMessage.buttons">
                                                <draggable style="height: inherit; width: inherit"
                                                :list="currentMessage.buttons"
                                                :animation="200"
                                                ghost-class="ghost-card"
                                                group="buttons"
                                                >
                                                <div v-for="(button, index) in currentMessage.buttons" :key="index">
                                                    <v-list-item>

                                                        <v-list-item-content style="height: 7.5vh;" class="pb-0 pt-0">
                                                                <v-text-field autofocus label="Menu Item Title" hide-details persistent-placeholder v-model="button.menuTitle" counter maxlength="20" outlined dense  @change="saveDisabledCheck"/>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-list-item>

                                                        <v-list-item-content style="height: 7.5vh;" class="pb-0 pt-0">
                                                                <v-text-field autofocus label="Menu Item Description"  persistent-placeholder v-model="button.menuDescription" counter maxlength="60" outlined dense  @change="saveDisabledCheck"/>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-btn :color="buttonActionCount(button).count > 0 ? 'edit' : ''" plain class="buttonFunction" @click="buttonActions(button,index)"><v-icon left>add</v-icon>{{button.actions && button.actions.length > 0 ? buttonActionCount(button).count > 1 ? `${buttonActionCount(button).count} ACTIONS` : `1 ACTION` : 'ADD ACTION'}}</v-btn>
                                                </div>
                                                </draggable>
                                            </v-list>
                                        </v-card>
                                    </div>
                                    <!-- Expected Responses -->
                                    <div v-if="currentTemplate.waRef != 'mainMenu'">
                                        <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                            <b>Expected Response</b> <i class="ml-3">(Optional)</i> <i class="ml-3">(Max 1)</i>
                                        </v-subheader>
                                        <span style="font-size: 14px; opacity: 0.7; margin: 0 0 0 16px">Add expected responses to capture specific data from your driver.</span>
                                        <br>
                                        <v-card outlined min-height="10vh" class="mx-4 mt-3 mb-4" style="background-color: var(--v-greyRaised-base) !important;">
                                            <v-subheader style="font-size: 16px; height: 30px; padding-top: 10px;">
                                                Type
                                            </v-subheader>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="2">
                                                    <v-card class="expectedTypes" >
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{on}">
                                                                <v-btn v-on="on"  plain>
                                                                    <v-icon x-large>text_fields</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Text Message
                                                        </v-tooltip>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" sm="10" class="d-flex align-center justify-center">
                                                    <v-select 
                                                        label="Expected Text Response" 
                                                        v-model="currentMessage.expectedTextResponse" 
                                                        :disabled="currentMessage.buttons && currentMessage.buttons.length > 0"
                                                        clearable 
                                                        dense 
                                                        outlined 
                                                        hide-details 
                                                        class="mr-4" 
                                                        :items="['Container Number', 'Seal Number', 'Kilometers']" />
                                                </v-col>
                                                <!-- <v-col cols="12" sm="2">
                                                    <v-card class="expectedTypes">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{on}">
                                                                <v-btn v-on="on"  plain>
                                                                    <v-icon x-large>image</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Image
                                                        </v-tooltip>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" sm="10" class="d-flex align-center justify-center">
                                                    <v-select 
                                                        label="Expected Text Response" 
                                                        v-model="currentMessage.expectedTextResponse" 
                                                        clearable 
                                                        dense 
                                                        outlined 
                                                        hide-details 
                                                        class="mr-4" 
                                                        :items="['Container Number', 'Seal Number', 'Odometer', 'Container']" />
                                                </v-col> -->
                                                <!-- <v-col cols="12" sm="2">
                                                    <v-card class="expectedTypes">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{on}">
                                                                <v-btn v-on="on"  plain>
                                                                    <v-icon x-large>gps_fixed</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Location
                                                        </v-tooltip>
                                                </v-card>
                                                </v-col>
                                                <v-col cols="12" sm="10" class="d-flex align-center justify-center">
                                                        <v-select label="Expected Location " v-model="currentMessage.expectedTextResponse" clearable dense outlined hide-details class="mr-4" :items="['Container Number', 'Seal Number', 'Kilometers']"></v-select>
                                                    </v-col> -->
                                            </v-row>
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="px-1 py-1">
                    <v-card class="tile">
                        <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    chat
                                </v-icon>
                                Message Preview
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-card flat tile style="height: calc(100% - 48px); width: 100%; contain: content; background-color: var(--v-greyContrast-base) !important; overflow-y: scroll;" class="pt-4" :key="previewChatKey">
                            <div v-if="previewChat.length > 0" style="margin-bottom: 30px"> 
                                 <div v-for="(message, index) in previewChat" :key="index">
                                    <div style="display:flex; flex-direction: column; contain: content; margin: 1vh 0 0 1vw; width: fit-content;">
                                        <div style="content: ' '; position: absolute; width: 0; height: 0; left: 3px; right: auto; top: 0px; bottom: auto; border: 22px solid; border-color: rgb(255, 248, 239) transparent transparent transparent;"></div>
                                        <v-card class="mx-4" flat style="background-color: rgb(255, 248, 239) !important; min-height: 3.3vh; word-wrap: break-word; min-width: 4vw; width: auto; max-width: 85%; border-radius: 12px !important; position: relative; padding: 10px 10px 18px 10px;">
                                            <span v-if="message.header" style="color: rgb(47, 47, 47); font-size: 16px;"><b>{{ message.header }}</b></span><br v-if="message.header">
                                            <span style="color: rgb(37, 37, 37); white-space: pre-line;"  v-for="(word, index) in formatMessage(message.body.trim())" :key="index" :style="word.style">{{ word.text }}</span>
                                        <!-- <span v-if="message.body" style="color: rgb(37, 37, 37); white-space: pre-line;">{{ formatMessage(message.body) }}</span> -->
                                        <br>
                                        <span v-if="message.footer" style="color: rgba(67, 67, 67, 0.449);padding-right: 65px;">{{ message.footer }}</span>
                                        <span style="color: rgba(67, 67, 67, 0.449); position: absolute; right: 10px; bottom: 0px;">09:52</span>
                                        </v-card>
                                        <v-card v-if="message.buttons && message.buttons.length > 0" :class="previewChat[index + 1] && previewChat[index + 1].title == message.buttons[0].buttonText ? 'btnSelect button' : 'button btnPlain'">
                                            <v-icon :color="'#3797cb'" class="mr-1" v-if="currentTemplate.waRef == 'mainMenu' && message.buttons[0].buttonText == 'Main Menu'">list</v-icon><span><b>{{ message.buttons[0].buttonText }}</b></span>
                                        </v-card>
                                        <v-card v-if="message.buttons && message.buttons.length > 1" :class="previewChat[index + 1] && previewChat[index + 1].title == message.buttons[1].buttonText ? 'btnSelect button' : 'button btnPlain'">
                                            <span><b>{{ message.buttons[1].buttonText }}</b></span>
                                        </v-card>
                                        <v-card v-if="message.buttons && message.buttons.length > 2" :class="previewChat[index + 1] && previewChat[index + 1].title == message.buttons[2].buttonText ? 'btnSelect button' : 'button btnPlain'">
                                            <span><b>{{ message.buttons[2].buttonText }}</b></span>
                                        </v-card>
                                    </div>
                                    <div style="display:flex; justify-content: center; align-items: flex-end; flex-direction: column; contain: content; margin: 1vh 0vw 0.5vw 0; width: initial; position: relative; right: 0;" 
                                        v-if="previewChat[index + 1] && previewChat[index + 1].title">
                                        <div style="content: ' '; position: absolute; width: 0; height: 0; right: 5px; top: 0px; bottom: auto; border: 22px solid; border-color: #3797cb transparent transparent transparent;"></div>
                                        <v-card class="mx-4" style="background-color: #3797cb !important; min-height: 3vh; min-width: 4vw; width: fit-content; border-radius: 12px !important; position: relative; padding: 10px;" flat>
                                            <span style="color: rgb(255, 254, 254); padding-right: 65px" v-if="previewChat[index + 1] && previewChat[index + 1].title">{{previewChat[index + 1].title}}</span>
                                            <span style="color: rgb(216, 216, 216); position: absolute; right: 10px; bottom: 0px;">09:54</span>
                                        </v-card>
                                    </div>
                                    <div style="display:flex; justify-content: center; align-items: flex-end; flex-direction: column; contain: content; margin: 1vh 0vw 0.5vw 0; width: initial; position: relative; right: 0;" 
                                        v-if="previewChat[index] && previewChat[index].expectedTextResponse">
                                        <div style="content: ' '; position: absolute; width: 0; height: 0; right: 5px; top: 0px; bottom: auto; border: 22px solid; border-color: #3797cb transparent transparent transparent;"></div>
                                        <v-card class="mx-4" style="background-color: #3797cb !important; min-height: 3vh; min-width: 4vw; width: fit-content; border-radius: 12px !important; position: relative; padding: 10px;" flat>
                                            <span style="color: rgb(255, 254, 254); padding-right: 65px" v-if="previewChat[index] && previewChat[index].expectedTextResponse">{{exampleResponse(previewChat[index].expectedTextResponse)}}</span>
                                            <span style="color: rgb(216, 216, 216); position: absolute; right: 10px; bottom: 0px;">09:54</span>
                                        </v-card>
                                    </div>
                                </div>
                            </div>
                            <v-card v-if="currentTemplate.waRef == 'mainMenu' && currentMessage.buttons && currentMessage.buttons.length > 0 && currentMessage.buttons[0].buttonText == 'Main Menu'" class="menuPreview">
                                <v-toolbar flat>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="2">
                                            <v-icon size="20" style="opacity: 0.6">close</v-icon>    
                                        </v-col>
                                        <v-col cols="12" sm="8" style="text-align: center">
                                            <div style="position: fixed; top: 6px; left: 50%; right: auto; transform: translate(-50%, 0); height: 3px; width: 30px; background-color: grey; border-radius: 16px"></div>
                                            <span style="opacity: 0.8">Main Menu</span>
                                        </v-col>
                                        <v-col cols="12" sm="2"></v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-card-text class="pt-0" style="height: 90%">
                                    <v-list class="pt-0">
                                        <v-list-item v-for="i in 10" :key="i" dense>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Log Kilometers
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Update my current Kilometers.
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon>radio_button_unchecked</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <span style="position: absolute; left: 50%; right: auto; transform: translate(-50%, 0); bottom: 5px; font-size:small">Tap to select an item</span>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="nameDialogChange" width="30vw" overlay-opacity="0.3">
            <v-card class="pb-3" style="background-color: var(--v-greyRaised-lighten1) !important; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-subheader>
                    <b>New Template</b>
                    <v-btn @click="cancelTemplateChange" style="position: absolute; right: 10px; top: 8px" icon color="primary"><v-icon>close</v-icon></v-btn>
                </v-subheader>
                <v-text-field @keypress.enter="focusKey++, focusKey2++" :key="focusKey" :autofocus="focusKey == 0" v-model="currentTemplate.name" style="width: 90%;" outlined dense hide-details class="mx-4 mb-4" label="Template Name" persistent-placeholder placeholder="Give your template a name"></v-text-field>
                <v-textarea @keypress.enter="nameDialogChange = false, saveTemplateConfig()" :autofocus="focusKey >= 1" :key="focusKey2" v-model="currentTemplate.description" style="width: 90%; height: 90px;" no-resize outlined dense class="mx-4 templateDescription" label="Template Description" maxlength="244" counter persistent-placeholder placeholder="Add a short description"></v-textarea>
                <v-btn color="edit" width="fit-content" @click="nameDialogChange = false, saveTemplateConfig()" :loading="loadingDialogSave">OK</v-btn>
            </v-card>
        </v-dialog>
        
        <!-- ADD A NEW TEMPLATE -->
        <v-dialog v-model="newTemplate" width="30vw" overlay-opacity="0.3">
            <v-card class="pb-3" style="background-color: var(--v-greyRaised-lighten1) !important; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-subheader>
                    <b>New Template</b>
                    <v-btn @click="newTemplate = false, focusKey = 0" style="position: absolute; right: 10px; top: 8px" icon color="primary"><v-icon>close</v-icon></v-btn>
                </v-subheader>
                <v-text-field @keypress.enter="focusKey++, focusKey2++" :key="focusKey" :autofocus="focusKey == 0" v-model="addTemplate.name" style="width: 90%;" outlined dense hide-details class="mx-4 mb-4" label="Template Name" persistent-placeholder placeholder="Give your template a name"></v-text-field>
                <v-textarea @keypress.enter="addNewTemplate()" :autofocus="focusKey >= 1" :key="focusKey2" v-model="addTemplate.description" style="width: 90%; height: 90px;" no-resize outlined dense class="mx-4 templateDescription" label="Template Description" maxlength="244" counter persistent-placeholder placeholder="Add a short description"></v-textarea>
                <v-btn color="edit" width="fit-content" @click="addNewTemplate()" :loading="loadingDialogSave">Save</v-btn>
            </v-card>
        </v-dialog> 

        <!-- ADD A NEW MESSAGE -->
        <v-dialog v-model="newMessageDialog" width="20vw" overlay-opacity="0.3">
            <v-card class="pb-3" style="background-color: var(--v-greyRaised-lighten1) !important; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-subheader>
                    <b>New Message</b>
                    <v-btn @click="newMessageDialog = false" style="position: absolute; right: 10px; top: 8px" icon color="primary"><v-icon>close</v-icon></v-btn>
                </v-subheader>
                <v-text-field autofocus @keypress.enter="addNewMessage()" v-model="newMessage.title" style="width: 90%;" maxlength="30" counter outlined dense class="mx-4 mb-4" label="Message Title" persistent-placeholder placeholder="Give your message a title"></v-text-field>
                <v-btn color="edit" width="fit-content" @click="addNewMessage()">Save</v-btn>
            </v-card>
        </v-dialog>


        <!-- ADD A RESPONSE MESSAGE -->
        <v-dialog v-model="responseMessageDialog" width="20vw" overlay-opacity="0.3" >
            <v-card class="pb-3" style="background-color: var(--v-greyRaised-lighten1) !important; display: flex; justify-content: center; align-items: center; flex-direction: column;" @keypress.enter="!responseButtonTitle || !selectedButton ? '' : createResponseMessage()">
                <v-subheader>
                    <b>Select a button to respond to:</b>
                </v-subheader>
                <div v-for="(button, index) in selectedMessage.buttons" :key="index" class="mb-1">
                    <v-btn :disabled="checkIfAvailable(button.buttonText)" style="min-width: 6vw;" :class="selectedButton && selectedButton.buttonText == button.buttonText ? 'btnSelect button' : 'button btnPlain'" @click="selectedButton = button">
                        <span style="font-size: 16px;"><b>{{ button.buttonText }}</b></span>
                    </v-btn>
                </div>
                <v-btn class="mt-8" color="#3797cb" :disabled="!selectedButton" width="fit-content" @click="createResponseMessage()">Save</v-btn>
            </v-card>
        </v-dialog>

        <v-dialog v-model="buttonActionsDialog" width="70vw">                           
            <v-card height="auto">
                <v-toolbar dense flat>
                    <v-toolbar-title>Button Actions</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="buttonActionsDialog = false"><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row :key="actionsKey">
                        <v-col cols="12" sm="4">
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    local_shipping
                                </v-icon>
                                Vehicle
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'vehicle')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'vehicle', false)"
                                                :color="checkButtonActions('vehicle', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'vehicle', true)"
                                                :color="checkButtonActions('vehicle', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-subheader style="font-size: 16px">
                                <v-img src="../../../public/icons/trailer.svg" max-height="24px" max-width="24px" class="mr-2 filter-orange" />
                                Trailer
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'trailer')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'trailer', false)"
                                                :color="checkButtonActions('trailer', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'trailer', true)"
                                                :color="checkButtonActions('trailer', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">
                                person
                            </v-icon>
                                Driver
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'driver')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'driver', false)"
                                                :color="checkButtonActions('driver', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'driver', true)"
                                                :color="checkButtonActions('driver', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        </v-col>
                        <v-col cols="12" sm="4">
                        <v-subheader style="font-size: 16px">
                                <v-img src="../../../public/icons/container.svg" max-height="24px" max-width="24px" class="mr-2 filter-orange" />
                                Container
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'container')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'container', false)"
                                                :color="checkButtonActions('container', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'container', true)"
                                                :color="checkButtonActions('container', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">
                                widgets
                            </v-icon>
                                Cargo
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'cargo')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'cargo', false)"
                                                :color="checkButtonActions('cargo', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'cargo', true)"
                                                :color="checkButtonActions('cargo', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                            <v-list-item dense class="d-flex pr-0">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle >
                                    Loading Status
                                </v-list-item-subtitle>
                                <v-list-item-action class="ma-0" style="width: 100%; display: flex; justify-content: end;">
                                    <v-menu offset-y >
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          class="primaryText--text"
                                          :color="checkButtonActions('loadingStatus').value === true ? getStatusColor(currentButton.actions.find(x => x.type == 'loadingStatus').name, 'loadingStatus') : 'grey'"
                                          v-on="on"
                                          style="border: none"
                                        >
                                          {{ currentButton.actions.length > 0 ? currentButton.actions.find(x => x.type == 'loadingStatus')?.name ? currentButton.actions.find(x => x.type == 'loadingStatus').name : 'Set Status' : 'Set Status' }}
                                          <v-icon small right>expand_more</v-icon>
                                        </v-chip>
                                      </template>
                                      <v-list style="overflow: hidden" dense>
                                        <v-list-item
                                          v-for="(
                                            status, index
                                          ) in loadingStatuses"
                                          :key="index"
                                          style="height: 35px"
                                          @click="addButtonAction(status, 'loadingStatus')"
                                        >
                                          <v-list-item-action>
                                            <v-icon :color="status.color"
                                              >fiber_manual_record</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              {{ status.text }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">
                                list_alt
                            </v-icon>
                                Booking
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item dense class="d-flex" v-for="(item, index) in actions.filter(x => x.type == 'booking')" :key="index">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle>
                                    {{item.name}}
                                </v-list-item-subtitle>
                                    <v-list-item-action class="ma-0">
                                        <v-btn-toggle>
                                            <v-btn x-small @click="addButtonAction(item, 'booking', false)"
                                                :color="checkButtonActions('booking', item).value === false ? 'primary' : 'grey'">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <v-btn x-small @click="addButtonAction(item, 'booking', true)"
                                                :color="checkButtonActions('booking', item).value === true ? 'tertiary' : 'grey'">
                                                <v-icon small>check</v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-list-item-action>
                            </v-list-item>
                            <v-list-item dense class="d-flex pr-0">
                                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                                    <div class="dotty"></div>    
                                </v-list-item-icon>
                                <v-list-item-subtitle >
                                    Set Booking Status
                                </v-list-item-subtitle>
                                <v-list-item-action class="ma-0" style="width: 100%; display: flex; justify-content: end;">
                                    <v-menu offset-y >
                                      <template v-slot:activator="{ on }">
                                        <v-chip
                                          class="primaryText--text"
                                          :color="checkButtonActions('status').value === true ? getStatusColor(currentButton.actions.find(x => x.type == 'status').name) : 'grey'"
                                          v-on="on"
                                          style="border: none"
                                        >
                                          {{ currentButton.actions.length > 0 ? currentButton.actions.find(x => x.type == 'status')?.name ? currentButton.actions.find(x => x.type == 'status').name : 'Set Status' : 'Set Status' }}
                                          <v-icon small right>expand_more</v-icon>
                                        </v-chip>
                                      </template>
                                      <v-list style="overflow: hidden" dense>
                                        <v-list-item
                                          v-for="(
                                            status, index
                                          ) in transportStatuses"
                                          :key="index"
                                          style="height: 35px"
                                          @click="addButtonAction(status, 'status')"
                                        >
                                          <v-list-item-action>
                                            <v-icon :color="status.color"
                                              >fiber_manual_record</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              {{ status.text }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="configDialog" width="auto">
            <v-card height="60vh" style="contain: content;" v-if="currentTemplate && currentTemplate.name">
                <v-toolbar flat>
                        <v-list-item dense style="width: auto; min-width: auto; max-width: min-content">
                            <v-list-item-content>
                            <v-list-item-title>
                                {{currentTemplate.name}}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: x-small;" v-if="currentTemplate.description.toString().length < 120">
                                {{currentTemplate.description}}
                            </v-list-item-subtitle >
                            <v-list-item-subtitle style="font-size: x-small;" v-if="currentTemplate.description.toString().length > 120">
                                <span v-for="(word, index) in currentTemplate.description.split(' ').splice(0, 20)" :key="index">
                                    {{ word }}
                                </span>
                            </v-list-item-subtitle >
                            <v-list-item-subtitle style="font-size: x-small;" v-if="currentTemplate.description.toString().length > 120">
                                <span v-for="(word, index) in currentTemplate.description.split(' ').splice(20)" :key="index">
                                    {{ word }}
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="nameDialogChange = true">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        </v-list-item>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="currentTemplate.waRef" label="Whatsapp Ref" v-if="$store.state.user.id == 69" hide-details></v-text-field>
                    <v-checkbox
                        class="mr-5 ml-5"
                        hide-details
                        v-if="$store.state.user.id == 69"
                        v-model="currentTemplate.cannotDelete"
                        :label="`Cannot Delete`"
                    ></v-checkbox>
                    <v-btn color="edit" :loading="loadingConfigSave" @click="saveTemplateConfig">SAVE</v-btn>
                    <v-btn color="primary" icon @click="configDialog = false"><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    person
                                </v-icon>
                                Drivers
                                <v-spacer></v-spacer>
                                <!-- <v-btn plain class="pa-0">Select All</v-btn> -->
                        </v-subheader>
                        <v-divider v-if="!loadingDrivers"></v-divider>
                        <v-progress-linear v-if="loadingDrivers" indeterminate></v-progress-linear>
                        <div style="height: calc(60vh - 96px); contain: content; overflow-y: scroll">
                            <v-list>
                                <v-list-item dense v-for="(driver, index) in drivers" :key="index">
                                    <v-list-item-icon>
                                        <v-icon>person</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{driver.user.firstname}} {{ driver.user.surname }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-simple-checkbox color="primary" v-model="driver.selected"></v-simple-checkbox>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="8" style="contain: content;">
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    settings
                                </v-icon>
                                Settings
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-subheader style="font-size: 16px">
                                Schedule
                        </v-subheader>
                        <div style="display: flex;">
                            <v-time-picker
                                style="scale: 0.8; margin:-20px -30px 0 -10px"
                                v-model="currentTemplate.scheduledTime"
                                landscape
                                scrollable
                                format="24hr"
                            ></v-time-picker>
                            <div style="display: flex; flex-direction: column;">
                                <div style="display: flex; flex-direction: row;" :key="dayKey" v-if="currentTemplate && currentTemplate.days">
                                    <v-chip :color="currentTemplate.days.includes('Every Day') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Every Day')">Every Day</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Mon') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Mon')">Mon</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Tue') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Tue')">Tue</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Wed') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Wed')">Wed</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Thu') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Thu')">Thu</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Fri') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Fri')">Fri</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Sat') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Sat')">Sat</v-chip>
                                    <v-chip :color="currentTemplate.days.includes('Sun') ? 'tertiary' : ''" class="mr-1" @click="configDaySelect('Sun')">Sun</v-chip>
                                </div>
                                <v-checkbox label="First Message" hide-details></v-checkbox>
                                <v-checkbox label="Persistent" hide-details></v-checkbox>
                            </div>
                        </div>
                        <v-btn 
                            style="position: absolute; left: 50%; right: auto; transform: translate(-50%, 0); bottom: 40px"
                            :class="currentTemplate.active ? '' : 'inactive'" 
                            :color="currentTemplate.active ? 'edit' : 'grey'"
                            @click="currentTemplate.active = !currentTemplate.active"
                            >
                            <v-icon left v-if="currentTemplate.active">check</v-icon>
                            {{currentTemplate.active ? 'Active' : 'Inactive'}}
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
    },
    data: () => ({
        newTemplate: false,
        newMessageDialog: false,
        selectedStatus: {},
        loadingDialogSave: false,
        loadingMessageSave: false,
        loadingConfigSave: false,
        loadingDrivers: false,
        configDialog: false,
        previewDialog: false,
        tempId: 1,
        focusKey: 0,
        nameDialogChange: false,
        dayKey: 0,
        focusKey2: 1,
        messagesKey: 0,
        previewChatKey: 0,
        actionsKey: 0,
        disabledKey: 0,
        responseMessageDialog: false,
        buttonActionsDialog: false,
        getMessagesLoading: false,
        loadingTemplates: false,
        viewResponse: false,
        selectedButton: null,
        previewMessages: [],
        previewTemplate: {},
        viewingResponseMessage: {},
        originalTemplate: {},
        responseButtonTitle: '',
        selectedMessage: {},
        selectedGroup: {},
        saveDisabled: true,
        responseMessage: {},
        newMessage: {
            title: '',
            header: '',
            body: '',
            footer: '',
            buttons: [],
        },
        addTemplate: {
            name: '',
            description: '',
            messages: [],
        },
        drivers: [],
        loadingStatuses: [
            {
                text: "Waiting to Load",
                value: "WATING_TO_LOAD",
                color: "blue-grey",
            },
            {
                text: "Loading",
                value: "LOADING",
                color: "blue lighten-1",
            },
            {
                text: "Partially Loaded",
                value: "PARTIALLY_LOADED",
                color: "success ",
            },
            {
                text: "Fully Loaded",
                value: "FULLY_LOADED",
                color: "tertiary",
            },
            {
                text: 'Waiting for Inspector',
                value: 'WAITING_FOR_INSPECTOR',
                color: 'lime darken-3'
            },
            {
                text: 'No Release',
                value: 'NO_RELEASE',
                color: 'primary'
            }
        ],
        actions: [
            // vehicles
                {
                    name: 'Tyre Tread in Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Tyre Pressure in Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Tyres has no Cracks or Cuts',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Oil Level In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Fuel Level In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Head Lights In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Turn Lights In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Reverse Lights In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Tail Lights In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'Break Lights In Order',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'License Disk Valid',
                    value: null,
                    type: 'vehicle'
                },
                {
                    name: 'No Damages',
                    value: null,
                    type: 'vehicle'
                },
                // trailers
                {
                    name: 'Tyre Tread in Order',
                    value: null,
                    type: 'trailer'
                },
                {
                    name: 'Tyre Pressure in Order',
                    value: null,
                    type: 'trailer'
                },
                {
                    name: 'Tyres has no Cracks or Cuts',
                    value: null,
                    type: 'trailer'
                },
                {
                    name: 'Registration Confirmed',
                    value: null,
                    type: 'trailer',
                },
                {
                    name: 'Hitch and Coupler In Order',
                    value: null,
                    type: 'trailer',
                },
                {
                    name: 'Tail Lights In Order',
                    value: null,
                    type: 'trailer',
                },
                {
                    name: 'Reverse Lights In Order',
                    value: null,
                    type: 'trailer',
                },
                {
                    name: 'Break Lights In Order',
                    value: null,
                    type: 'trailer',
                },
                {
                    name: 'Has Loadbars',
                    value: null,
                    type: 'trailer',
                },
                // drivers
                {
                    name: 'Checked In',
                    value: null,
                    type: 'driver',
                }, 
                {
                    name: 'Drivers License Valid',
                    value: null,
                    type: 'driver',
                }, 
                // containers
                {
                    name: 'Collected',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Seal No Confirmed',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Container No Confirmed',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Container Inspected',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Container Weighed',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Vents Set',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Temp Device Confirmed',
                    value: null,
                    type: 'container',
                },
                {
                    name: 'Genset Confirmed',
                    value: null,
                    type: 'container',
                },
                // cargo
                {
                    name: 'Collected',
                    value: null,
                    type: 'cargo',
                },
                // booking
                {
                    name: 'Documents Received',
                    value: null,
                    type: 'booking',
                } 
            ],
        templates: [
            // {
            //   "id": 1,
            //   "name": "Test Template",
            //   "description": "Used for testing all the components",
            //   "active": false,
            //   "scheduled": 0,
            //   "scheduledTime": '07:00',
            //   "days" : "Every Day",
            //   "organisationId": 2507,
            //   "messages": [
            //     {
            //       "title": "Confirm Vehicle Registration",
            //       "header": "Vehicle Insection: Registration",
            //       "body": "Hey {{driverName}}! \nPlease confirm if this is your vehicle Registration: \n{{vehicleRegistration}}",
            //       "footer": "",
            //       "buttons": [
            //         {
            //           "buttonText": "Yes",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Registration Confirmed",
            //                 "value": true
            //               }
            //             ]
            //           }
            //         },
            //         {
            //           "buttonText": "No",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Registration Confirmed",
            //                 "value": false
            //               }
            //             ]
            //           }
            //         }
            //       ]
            //     },
            //     {
            //       "title": "Confirm Odometer",
            //       "header": "Vehicle Inspection: Odometer",
            //       "body": "Please Confirm your odometer reading.",
            //       "footer": "This is where an expected reply would come in.",
            //       "buttons": []
            //     },
            //     {
            //       "title": "Check Tyres",
            //       "header": "Vehicle Inspection: Tyres",
            //       "body": "Please Check that your All of your vehicles' tyres Air Pressure and Tread are in order",
            //       "footer": "",
            //       "buttons": [
            //         {
            //           "buttonText": "All Good!",
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Tyres In order",
            //                 "value": true
            //               }
            //             ]
            //           },
            //           "next": {}
            //         },
            //         {
            //           "buttonText": "There is a problem",
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Tyres In order",
            //                 "value": false
            //               }
            //             ]
            //           },
            //           "next": {
            //             "title": "Issue with Tyres",
            //             "header": "Vehicle Inspection: Tyres",
            //             "body": "Please Select which issue you are having with your tyres",
            //             "footer": "",
            //             "buttons": [
            //               {
            //                 "buttonText": "Tread",
            //                 "next": {}
            //               },
            //               {
            //                 "buttonText": "Pressure",
            //                 "next": {}
            //               }
            //             ]
            //           }
            //         }
            //       ]
            //     },
            //     {
            //       "title": "Check Oil",
            //       "header": "Vehicle Inspection: Tyres",
            //       "body": "Please Check that your oil level is good.",
            //       "footer": "",
            //       "buttons": [
            //         {
            //           "buttonText": "All good!",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Oil Level In Order",
            //                 "value": true
            //               }
            //             ]
            //           }
            //         },
            //         {
            //           "buttonText": "Oil level not enough",
            //           "next": {
            //             "title": "Go get oil",
            //             "header": "",
            //             "body": "Okay! Please head to the yard to get an oil top-up.",
            //             "footer": "",
            //             "buttons": []
            //           },
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Oil Level In Order",
            //                 "value": false
            //               }
            //             ]
            //           }
            //         },
            //         {
            //           "buttonText": "There is a problem",
            //           "next": {}
            //         }
            //       ]
            //     },
            //     {
            //       "title": "Check Front Lights",
            //       "header": "Vehicle Inspection: Front Lights",
            //       "body": "Please check that your dims and brights are good.",
            //       "footer": "",
            //       "buttons": [
            //         {
            //           "buttonText": "All good!",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Front Lights In Order",
            //                 "value": true
            //               }
            //             ]
            //           }
            //         },
            //         {
            //           "buttonText": "Dims not good",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Front Lights In Order",
            //                 "value": false
            //               }
            //             ]
            //           }
            //         },
            //         {
            //           "buttonText": "Brights not good",
            //           "next": {},
            //           "actions": {
            //             "vehicle": [
            //               {
            //                 "name": "Front Lights In Order",
            //                 "value": false
            //               }
            //             ]
            //           }
            //         }
            //       ]
            //     },
            //     {
            //       "title": "Check tail and break lights",
            //       "header": "Vehicle Inspection: Tail and Break lights",
            //       "body": "Please check that your tail lights and break lights are good.",
            //       "footer": "",
            //       "buttons": [
            //         {
            //           "buttonText": "All Good!",
            //           "next": {}
            //         },
            //         {
            //           "buttonText": "Problem with break lights",
            //           "next": {}
            //         },
            //         {
            //           "buttonText": "Problem with tail lights",
            //           "next": {}
            //         }
            //       ]
            //     }
            //   ]
            // }
        ],
        transportStatuses: [
          {
            text: "Pending",
            value: "PENDING",
            color: "orange",
          },
          // {
          //     text: 'Booking Confirmed',
          //     value: 'BOOKING_CONFIRMED',
          //     color: 'green lighten-1'
          // },
          {
            text: "Pre-Allocated",
            value: "PRE_ALLOCATED",
            color: "green darken-2",
          },
          {
            text: "Allocated",
            value: "ALLOCATED",
            color: "lime darken-3",
          },
          {
            text: "Instr. Sent",
            value: "INSTRUCTION_SENT",
            color: "blue-grey",
          },
          {
            text: "En route Empty",
            value: "EN_ROUTE_EMPTY",
            color: "deep-orange lighten-3",
          },
          {
            text: "At Empty Depot",
            value: "AT_EMPTY",
            color: "deep-orange lighten-2",
          },
          {
            text: "Empty Out",
            value: "EMPTY_PICKUP",
            color: "deep-orange lighten-1",
          },
          {
            text: "En route LP-1",
            value: "EN_ROUTE_LP1",
            color: "teal darken-1",
          },
          {
            text: "At LP-1",
            value: "AT_LP1",
            color: "teal lighten-1",
          },
          {
            text: "En route LP-2",
            value: "EN_ROUTE_LP2",
            color: "cyan darken-1",
          },
          {
            text: "At LP-2",
            value: "AT_LP2",
            color: "cyan lighten-1",
          },
          {
            text: "En route WB",
            value: "EN_ROUTE_WB",
            color: "blue darken-1",
          },
          {
            text: "AT WB",
            value: "AT_WB",
            color: "blue lighten-1",
          },
          {
            text: "En route Plugin Depot",
            value: "EN_ROUTE_DEPOT",
            color: "purple darken-1",
          },
          {
            text: "At Plugin Depot",
            value: "AT_DEPOT",
            color: "purple lighten-1",
          },
          {
            text: "En route Port",
            value: "EN_ROUTE_PORT",
            color: "blue darken-1",
          },
          {
            text: "At Port",
            value: "AT_PORT",
            color: "blue darken-2",
          },
          {
            text: "Gate In",
            value: "FULL_DROP_OFF",
            color: "success",
          },
        ],
        currentTemplate: {},
        currentMessage: {},
        currentButton: {},
        variables: [
            {  
                text: 'Driver Name',
                value: 'driverName',
                example: 'John Doe'
            },
            {
                text: 'Vehicle Registration',
                value: 'vehicleRegistration',
                example: 'CL 12345'
            },
            {
                text: 'Your Company',
                value: 'organisation',
                example: 'CompanyABC'
            },
            {
                text: 'Trailer Registration',
                value: 'trailerRegistration',
                example: 'CJ 54321'
            },
            {  
                text: 'CTO Number',
                value: 'ctoNo',
                example: 'LL123456/01'
            },
            {
                text: 'Carrier Ref Num',
                value: 'carrierReferenceNumber',
                example: '23737123'
            },
            {
                text: 'Customer Container Ref',
                value: 'customerContainerRef',
                example: 'Box 1'
            },
            {
                text: 'Shipper Ref Num',
                value: 'shipperReferenceNumber',
                example: 'B123543'
            },
            {
                text: 'Empty Pickup',
                value: 'empty',
                example: 'Sati Container Services'
            },
            {
                text: 'Loading Point 1',
                value: 'first',
                example: 'Hexkoel Pty Ltd'
            },
            {
                text: 'Loading Point 2',
                value: 'second',
                example: 'CFC Killarney'
            },
            {
                text: 'DropOff Point',
                value: 'dropOff',
                example: 'Transnet Port Terminals'
            },
            {
                text: 'Container Type',
                value: 'containerTypeCode',
                example: '42R1'
            },
            {
                text: 'Contents',
                value: 'contents',
                example: 'Oranges'
            },
            {
                text: 'Regime Code',
                value: 'regimeCode',
                example: 'C025'
            },
            {
                text: 'Set Point Temp',
                value: 'setPointTemp',
                example: '-0.50'
            },
            {
                text: 'Vent Setting',
                value: 'vents',
                example: 'Closed'
            },
            {
                text: 'Verification Method',
                value: 'verificationMethod',
                example: 'Method 2'
            },
            {
                text: 'Genset Required',
                value: 'gensetRequired',
                example: 'Yes'
            },
        ]
    }),
    watch: {
        'addTemplate.description': {
            immediate: true,
            handler(val) {
                this.addTemplate.description = val.trim()
            }
        },
        'templates': {
            immediate: true,
            handler(val) {
            }
        },
    },
    async created() {
        this.getTemplates()
    },
    computed: {

        addButtonDisabled() {
            let value = false
            if (this.viewResponse) {
                if (this.responseMessage.buttons && this.responseMessage.buttons.length == 3) {
                    value = true
                } else {
                    value = false
                }
            } else if (this.currentMessage.buttons && this.currentMessage.buttons.length == 3) {
                    value = true
            } else if(this.currentMessage.expectedTextResponse){
                    value = true
            } else {
                value = false
            }
            return value
        },
        previewChat() {
            let chat = []
            console.log('check 0')
            if(this.currentTemplate && this.currentTemplate.messageGroups && this.currentTemplate.messageGroups.length > 0) {
                console.log('check 1')
                if(this.selectedGroup && this.selectedGroup.messages && this.selectedGroup.messages.length > 0) {
                    console.log('check 2')
                    if(this.selectedMessage && this.selectedMessage.groupId) {
                        console.log('check 3')
                        chat = [this.selectedMessage]
                        let index = this.selectedGroup.messages.findIndex(x => x.id == this.selectedMessage.id)
                        if(index > 0) {
                            console.log('check 4')
                            if(this.selectedMessage.groupOrder !== 1) {
                                console.log('check 5')
                                let currentMessage = this.selectedMessage
                                getAncestors(currentMessage, index, this.selectedGroup.messages)   
                            }
                        }
                    }
                }
            }
            function getAncestors(msg, idx, msgs) {
                let currentMessage = msg
                let index = idx
                let messages = msgs
                let parentGroupOrder = currentMessage.groupOrder - 1
                let parentMessage = messages[index - 1]
                if(parentMessage && parentMessage.groupOrder == parentGroupOrder) {
                    chat.unshift(parentMessage)
                    currentMessage = parentMessage
                    index = messages.findIndex(x => x.id == parentMessage.id)
                    if(index != 0 || parentMessage.groupOrder != 1) {
                        getAncestors(currentMessage, index, messages)
                    } else {
                        return
                    }
                } else if(parentMessage && parentMessage.groupOrder != parentGroupOrder) {
                    index = index - 1
                    getAncestors(currentMessage, index, messages)
                } else {
                    return
                }
            }
            return chat
        },
    },




    methods: {
        saveDisabledCheck() {
          let result = [];
            if(!this.currentTemplate || !this.currentTemplate.messageGroups || this.currentTemplate.messageGroups == 0 ) {
                result.push('No messages')
            } else {
                for (const group of this.currentTemplate.messageGroups) {
                    if (!group.messages || group.messages.length == 0) {
                        result.push('No messages')
                    } else {
                        for (const message of group.messages) {
                            if (!message.body || message.body.trim() == '') {
                                result.push('No message body')
                            } else {
                                if (message.buttons && message.buttons.length > 0) {
                                    if(!message.header || message.header.trim() == '') {
                                        result.push('No message header when using buttons')
                                    }
                                    for (const button of message.buttons) {
                                        if (!button.buttonText || button.buttonText.trim() == '') {
                                            result.push('No button text')
                                        }
                                    }
                                }
                            }
                        }
                    } 
              }
            }
            if(this.originalTemplate && JSON.stringify(this.originalTemplate) == JSON.stringify(this.currentTemplate)) {
                result.push('No changes')
                console.log(this.originalTemplate)
                console.log(this.currentTemplate)
            }
            console.log(result)
            if(result.length > 0) {
                this.saveDisabled = true
            } else {
                this.saveDisabled = false
            }
        },
        async saveAll(templateId) {
            this.loadingMessageSave = true
            let find = this.templates.find(x => x.id == templateId)
            let result = await this.$API.updateWhatsappTemplate(find)
            if(result) {
                this.$message({
                    type: 'success',
                    message: 'Template Saved'
                })
                let reload = await this.$API.getTemplateMessages(templateId)
                if(reload && reload.length > 0) {
                    find = reload
                    this.originalTemplate = JSON.parse(JSON.stringify(find))
                }
            } else {
                this.$message({
                    type: 'error',
                    message: 'Error Saving Template'
                })
            }
            this.loadingMessageSave = false
            this.saveDisabled = true
        }, 
        getStatusColor(status, type) {
            let find
            if(type && type == 'loadingStatus') {
                find = this.loadingStatuses.find((x) => x.text == status);
            } else {
                find = this.transportStatuses.find((x) => x.text == status);
            }
            return find ? find.color : "grey";
        },
        //////////// TEMPLATES //////////// TEMPLATES //////////// TEMPLATES //////////// TEMPLATES //////////// TEMPLATES ////////////
        async switchTemplates(template) {
            this.getMessagesLoading = true
            let find = this.templates.find(x => x.id == template.id)
            if(find && find.messageGroups && find.messageGroups.length > 0) {
                this.currentMessage = {}
                this.selectedMessage = {}
                this.currentTemplate = template
                this.getMessagesLoading = false
            } else if(find && (!find.messageGroups || find.messageGroups.length == 0)){
                let result = await this.$API.getTemplateMessages(template.id)
                if(result && result.length > 0) {
                    find.messageGroups = result
                    this.currentTemplate = find
                    this.currentMessage = {}
                    this.selectedMessage = {}
                } else if(result && result.length == 0) {
                    find.messageGroups = []
                    this.currentMessage = {}
                    this.selectedMessage = {}
                    this.currentTemplate = template
                    this.getMessagesLoading = false  
                } else{
                    this.$message({
                        type: 'error',
                        message: 'Error Getting Template Messages'
                    })
                }
            }
            this.originalTemplate = JSON.parse(JSON.stringify(this.currentTemplate))
            this.viewingResponseMessage = {}
            this.viewResponse = false
            this.getMessagesLoading = false
        },
        async addNewTemplate() {
            if (this.addTemplate.name != '' && this.addTemplate.description != '') {
                let template = { ...this.addTemplate, active: false, scheduled: false }
                this.loadingDialogSave = true
                let result = await this.$API.createWhatsappTemplate(template)
                result.messageGroups = []
                // let result = template //THIS IS A TEMPORARY FIX
                this.stringy(result, 'Created Template')
                this.templates.push(result)
                this.$nextTick(() => {
                    this.currentTemplate = this.templates[this.templates.length - 1]
                    this.newTemplate = false
                    this.focusKey = 0
                    this.loadingDialogSave = false
                    this.addTemplate = {
                        name: '',
                        description: '',
                        messageGroups: [],
                    }
                })
                if (result) {
                    this.$message({
                        type: 'success',
                        message: 'Template Created'
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: 'Error Creating Template'
                    })
                }
            }
        },
        async deleteTemplate(template, index) {
            let temp = this.templates.find(x => x.id == template.id)
            if (temp) {
                let result = await this.$API.deleteWhatsappTemplate(template.id)
                this.stringy(result, 'Delete Template') 
                if(result) {
                this.templates.splice(index, 1)
                this.clearData()
                }
            }
        },
        groupValueChange() {
            this.currentTemplate.messageGroups.forEach((x, i) => {
                x.groupId = i + 1
            })
            this.messagesKey++
        },
        duplicateTemplate(template) {
            let temp = JSON.parse(JSON.stringify(template))
            temp.id = this.templates.length + 1
            temp.name = temp.name + ' (Copy)'
            this.templates.push(temp)
            this.$nextTick(() => {
                this.currentTemplate = this.templates[this.templates.length - 1]
            })  
        },
        configDaySelect(day) {
            if(day == 'Every Day') {
                this.currentTemplate.days = ['Every Day']
            }
            else if(this.currentTemplate.days.includes(day)) {
                this.currentTemplate.days.includes('Every Day') ? this.currentTemplate.days.splice(this.currentTemplate.days[0], 1) : null
                this.currentTemplate.days.splice(this.currentTemplate.days.indexOf(day), 1)
            } else {
                this.currentTemplate.days.includes('Every Day') ? this.currentTemplate.days.splice(this.currentTemplate.days[0], 1) : null
                this.currentTemplate.days.push(day)
                this.currentTemplate.days.length == 7 ? this.currentTemplate.days = ['Every Day'] : null
            }
            this.dayKey++
        },
        configTemp(template) {
            this.currentTemplate = template
            this.currentTemplate.days = template.days ? (Array.isArray(template.days) ? template.days : template.days.split(', ')) : [];
            this.originalTemplate = JSON.parse(JSON.stringify(template))
            this.configDialog = true
            this.getDrivers(template)
        },
        async saveTemplateConfig() {
            this.loadingConfigSave = true
            let weekLength = this.currentTemplate.days.length
            this.currentTemplate.scheduled = weekLength > 0 ? true : false
            this.currentTemplate.days = weekLength > 1 ? this.currentTemplate.days.join(', ') : weekLength > 0 ? this.currentTemplate.days[0] : null
            console.log(this.currentTemplate)
            let drivers = this.drivers.filter(x => x.selected == true)
            let obj = {
                template: this.currentTemplate,
                drivers: drivers
            }
            let result = await this.$API.updateWhatsappTemplateConfig(obj)
            if(result) {
                this.$message({
                    type: 'success',
                    message: 'Template Config Saved' 
                })
            } else {
                this.$message({
                    type: 'error',
                    message: 'Error Saving Template Config'
                })
            }
            this.loadingConfigSave = false
        },
        cancelTemplateChange() {
            this.currentTemplate.name = this.originalTemplate.name
            this.currentTemplate.description = this.originalTemplate.description
            this.nameDialogChange = false 
        },
        //////////// MESSAGES //////////// MESSAGES //////////// MESSAGES //////////// MESSAGES //////////// MESSAGES ////////////

        exampleResponse(expected) {
            let response = ''
            switch(expected) {
                case 'Kilometers' :
                    response = '454432'
                    break;
                case 'Container Number' :
                    response = 'MSCU1234567'
                    break;
                case 'Seal Number' :
                    response = '0062078'
                    break;
                default:
                    response = 'Expected Response'
                    break;
            }
            return response
        },

        selectMessage(group, message) {
            this.stringy({group, message}, 'Selected Message')
            this.previewChatKey++
            this.selectedGroup = group
            this.selectedMessage = message
            this.currentMessage = message
            this.viewResponse = false
            
        },
        addNewMessage() {
            if (this.newMessage.title != '') {
                let groups = JSON.parse(JSON.stringify(this.currentTemplate.messageGroups))
                if(groups && groups.length > 0) {
                    let lastGroupId = groups[groups.length - 1].groupId
                    let newMessage = {
                        ...this.newMessage,
                        groupId: lastGroupId + 1,
                        groupOrder: 1,
                        id: `TempId-${this.tempId}`
                    }
                    this.tempId++
                    groups.push({
                        groupId: lastGroupId + 1,
                        messages: [newMessage]
                    })
                } else {
                    let newMessage = {
                        ...this.newMessage,
                        groupId: 1,
                        groupOrder: 1,
                        id: `TempId-${this.tempId}`
                    }
                    this.tempId++
                    groups.push({
                        groupId: 1,
                        messages: [newMessage]
                    })
                }
                this.currentTemplate = JSON.parse(JSON.stringify(this.currentTemplate))
                this.currentTemplate.messageGroups = groups
                this.selectedGroup = this.currentTemplate.messageGroups[groups.length - 1]
                this.currentMessage = this.selectedGroup.messages[0]
                this.selectedMessage = this.selectedGroup.messages[0]
                this.newMessageDialog = false
                this.newMessage.title = ''
                this.previewChatKey++
            }
        },
        // TODO: Remove Messages
        
        async removeMessage(group, message, index) {
          const messagesToRemove = [message];
          let nextIndex = index + 1;

          while (nextIndex < group.messages.length) {
            const nextMessage = group.messages[nextIndex];
        
            if (nextMessage.groupOrder <= message.groupOrder) {
              break;
            }
        
            messagesToRemove.push(nextMessage);
            nextIndex++;
          }
      
          for (let i = messagesToRemove.length - 1; i >= 0; i--) {
            const msg = messagesToRemove[i];
            const msgIndex = group.messages.indexOf(msg);
            if (msgIndex >= 0) {
              group.messages.splice(msgIndex, 1);
            }
          }
          let remove = messagesToRemove.filter(x => !x.id.toString().includes('TempId'))
          if(remove && remove.length > 0) {
              let result = await this.$API.deleteTemplateMessages({remove: remove, templateId: this.currentTemplate.id})
              if(result) {
                this.$message({
                    type: 'success',
                    message: 'Message Removed'
                })
              } else {
                this.$message({
                    type: 'error',
                    message: 'Error Removing Message'
                })
              }
            }
          
        },
        getClass(message) {
            let result = ''
            if(message.groupOrder == 1) {
                result = 'mainMessageClass'
            } else {
                result = 'responseMessageClass'
            }
            if(message.id == this.currentMessage.id) {
                result = 'chosenTemplate'
            }
            return result
        },
        getStyle(message) {
            if(!message.groupOrder) {
                return ''
            }
            let groupOrder = message.groupOrder
            let indent = (groupOrder - 1) * 10
            let result = {
                marginLeft: `${indent}px`
            }
            return result
        },
        //////////// BUTTONS //////////// BUTTONS //////////// BUTTONS //////////// BUTTONS //////////// BUTTONS //////////// 
        addButtonAction(item, type, val) {
            if (this.currentButton.actions && this.currentButton.actions.length > 0) {
                if(type != 'status' && type != 'loadingStatus') {
                    let find = this.currentButton.actions.find(x => x.name == item.name && x.type == type)
                    if(find) {
                        if(find.value == val) {
                        this.currentButton.actions.splice(this.currentButton.actions.indexOf(find), 1)
                        } else {
                            this.currentButton.actions[this.currentButton.actions.indexOf(find)].value = val
                        }
                    } else {
                        this.currentButton.actions.push({ ...item, value: val });
                    }
            } else if(type == 'status'){
                let find = this.currentButton.actions.find(x => x.type == type)
                if(find) {
                    if(find.model != item.value) {
                        this.currentButton.actions[this.currentButton.actions.indexOf(find)] = {value: true, type: 'status', model: item.value, name: item.text }
                    }
                } else {
                    this.currentButton.actions.push({
                        type: 'status',
                        name: item.text,
                        model: item.value,
                        value: true
                    })
                }
            } else {
                let find = this.currentButton.actions.find(x => x.type == type)
                if(find) {
                    if(find.model != item.value) {
                        this.currentButton.actions[this.currentButton.actions.indexOf(find)] = {value: true, type: 'loadingStatus', model: item.value, name: item.text }
                    }
                } else {
                    this.currentButton.actions.push({
                        type: 'loadingStatus',
                        name: item.text,
                        model: item.value,
                        value: true
                    })
                }
            }
            } else if(type == 'status') {
                this.currentButton.actions = [
                    {
                        type: 'status',
                        name: item.text,
                        model: item.value,
                        value: true
                    }
                ]
            } else if(type == 'loadingStatus') {
                this.currentButton.actions = [
                    {
                        type: 'loadingStatus',
                        name: item.text,
                        model: item.value,
                        value: true
                    }
                ]
            } else {
                // If type not found, create it
                this.currentButton.actions = [
                    {
                        ...item,
                        value: val
                    }
                ]
            }
            this.actionsKey++;
            this.stringy(this.currentButton, 'Action')
            this.saveDisabledCheck()
        },

        removeButton(index) {
            let button = this.currentMessage.buttons[index]
            if(button && !button.nextId) {
                this.currentMessage.buttons.splice(index, 1)
            } else if(button && button.nextId) {
                let message = this.selectedGroup.messages.find(x => x.id == button.nextId)
                if(message) {
                    let index = this.selectedGroup.messages.findIndex(x => x.id == message.id)
                    this.removeMessage(this.selectedGroup, message, index)
                }
            }
            
        },
        addButton() {
            let obj = {
                buttonText: '',
                next: {}
            }
            if(this.currentMessage.buttons.length > 0) {
                obj.order = this.currentMessage.buttons[this.currentMessage.buttons.length - 1].order + 1
            } else {
                obj.order = 1
            }
            this.currentMessage.buttons.push(obj)
            
        },
        buttonActions(button, index) {
            this.currentButton = button
            this.buttonActionsDialog = true
        },

        //////////// RESPONSES //////////// RESPONSES //////////// RESPONSES //////////// RESPONSES //////////// RESPONSES //////////// 
        logThis() {
            this.stringy(this.selectedGroup, 'Selected Message')
        },

        checkIfAvailable(buttonText) {
            let response = false
            if (this.selectedMessage.buttons.length > 0) {
                this.selectedMessage.buttons.forEach(x => {
                    if (!x.nextId) {
                        return
                    }
                    if (x.buttonText == buttonText) {
                        response = true
                    }
                })
            }
            return response
        },
        expandGroup(group, index) {
            if(group.expanded) {
                group.expanded = false
            } else {
                group.expanded = true
            }
        },

        addButtonResponse(group, message) {
            this.selectedMessage = message
            this.selectedGroup = group
            this.responseMessageDialog = true
            
        },
        createResponseMessage() {
            if(Object.keys(this.currentTemplate).length > 0 && this.currentTemplate.messageGroups.length > 0) {
                let group = this.currentTemplate.messageGroups.find(x => x.groupId == this.selectedGroup.groupId)
                let {order, groupOrder, selectedMessageIndex} = this.getGroupOrder(group, this.selectedMessage, this.selectedButton)
                let obj = {
                    id: `TempId-${this.tempId}`,
                    title: this.selectedButton.buttonText,
                    isResponse: true,
                    groupId: group.groupId,
                    groupOrder: parseInt(groupOrder),
                    order: order,
                    header: '',
                    body: '',
                    footer: '',
                    buttons: []
                }
                group.messages.splice(selectedMessageIndex + 1, 0, obj)
                this.tempId++
                this.responseMessageDialog = false
            this.selectedButton.nextId = obj.id
            this.$nextTick( () => {
                this.selectedButton = null
            })
        }
        
        },
        getGroupOrder(group, selectedMessage, selectedButton) {
            group = JSON.parse(JSON.stringify(group))
            selectedMessage = JSON.parse(JSON.stringify(selectedMessage))
            selectedButton = JSON.parse(JSON.stringify(selectedButton))
            const selectedMessageIndex = group.messages.findIndex(message => message.id === selectedMessage.id);
            const nextMessageIndex = selectedMessageIndex + 1;

            let order = 1;
            let groupOrder = 1;

            if (selectedButton) {
                const selectedButtonIndex = selectedMessage.buttons.findIndex(button => button.id === selectedButton.id);
            
                if (selectedButtonIndex !== -1) {
                    order = selectedButtonIndex + 1;
                    groupOrder = selectedMessage.groupOrder + 1;
                }
            } else {
                order = selectedMessage.buttons.length + 1;
                groupOrder = selectedMessage.groupOrder;
            }
            group.messages.forEach((message, index) => {
                if (index === selectedMessageIndex) return;
            
                if (message.groupOrder === groupOrder && message.order >= order) {
                    message.order += 1;
                } else if (message.groupOrder > groupOrder) {
                    message.groupOrder += 1;
                }
            });
            return {order, groupOrder, selectedMessageIndex}
            
        },

        clearResponseDialog() {
            this.responseMessageDialog = false
            this.responseButtonTitle = ''
            this.selectedButton = null
        },
        //////////// GET DATA //////////// GET DATA //////////// GET DATA //////////// GET DATA //////////// GET DATA //////////// 
        async getTemplates() {
            this.loadingTemplates = true
            let result = await this.$API.getWhatsappTemplates()
            this.stringy(result, 'Whatsapp Templates')
            if (result && result.length > 0) {
                let cannotDelete = result.filter(x => x.cannotDelete == true)
                let canDelete = result.filter(x => x.cannotDelete == false)
                this.templates = [...cannotDelete, ...canDelete]
            }
            this.loadingTemplates = false
            
        },
        async getDrivers(templateId) {
            this.loadingDrivers = true 
            let result = await this.$API.getDrivers()
            if(result) {
                result.data.forEach(x => {
                    x.selected = true
                })
                this.drivers = result.data
            }
            await this.getTemplateConfig(templateId)
            this.loadingDrivers = false
        },

        async getTemplateConfig(template) {
            this.loadingConfig = true
            let result = await this.$API.getWhatsappTemplateConfig(template.id)
            if(result) {
                let driverIds = result.map(x => x.transportDriverId)
                this.drivers.forEach(x => {
                    if(driverIds.includes(x.id)) {
                        x.selected = true
                    } else {
                        x.selected = false
                    }
                })
            }
        },

        //////////// TOOLS //////////// TOOLS //////////// TOOLS //////////// TOOLS //////////// TOOLS //////////// 
        copyToClipboard(selection) {
            navigator.clipboard.writeText(`{{${selection.value}}}`)
            this.$message({
                type: 'success',
                message: 'Copied to clipboard'
            })
        },
        checkButtonActions(type, item) {
            if (this.currentButton.actions && this.currentButton.actions.filter(x => x.type == type).length > 0) {
                if(type == 'status' || type == 'loadingStatus') {
                    let find = this.currentButton.actions.find(x => x.type == type)
                    if(find) {
                        return {value: true, status: this.selectedStatus}
                    } else {
                        return {value: false, status: {name: null, value: null, type: type}}
                    }
                } else {
                    let result = this.currentButton.actions.filter(x => x.type == type).find(x => x.name == item.name)
                    if (result) {
                        return result
                    } else {
                        return { name: item.name, value: null, type: type }
                    }
                }
            } else if(type == 'status' || type == 'loadingStatus') {
                return {value: false, status: {name: null, value: null, type: type}}
            } else{
                return { name: item.name, value: null, type: type }
            }
        },
        buttonActionCount(button) {
            let count = 0
            if (button.actions) {
                button.actions.length > 0 ? count = button.actions.length : count = 0
            }
            return { count: count }
        },
        formatMessage(body) {
            let formattedMessage = body
            this.variables.forEach(variable => {
                formattedMessage = formattedMessage.replaceAll(`{{${variable.value}}}`, variable.example)
            })
            let styled = this.processedText(formattedMessage)
            return styled
        },
        processedText(original) {
          if(original) {
            const lines = original.split('\n')
            const processedLines = lines.map((line, lineIndex) => {
            const words = line.match(/\S+|\s/g) || [];
            let isInsideBold = false;
            let isInsideItalic = false;
            const processedWords = words.map((word, wordIndex) => {
              if (word === '\n') {
                return { text: '\n', style: {} };
              }
              if (word.startsWith('*')) {
                isInsideBold = true;
                word = word.slice(1);
              }
              if (isInsideBold) {
                if (word.endsWith('*')) {
                  isInsideBold = false;
                  return { text: word.slice(0, -1), style: { fontWeight: 'bold' } };
                } else {
                  return { text: word, style: { fontWeight: 'bold' } };
                }
              }
              if (word.startsWith('_')) {
                isInsideItalic = true;
                word = word.slice(1);
              }
              if (isInsideItalic) {
                if (word.endsWith('_')) {
                  isInsideItalic = false;
                  return { text: word.slice(0, -1), style: { fontStyle: 'italic' } };
                } else {
                  return { text: word, style: { fontStyle: 'italic' } };
                }
              }
              return { text: word, style: {} };
            });
            if (lineIndex < lines.length - 1) {
              processedWords.push({ text: '\n', style: {} });
            }
            return processedWords;
          });
          
          return processedLines.flat();
        } else {
          return [{text: '', style: {}}]
        }
        },
        stringy(obj, message) {
            // let stuff = { ...obj }
            let result = JSON.parse(JSON.stringify(obj));
            if (message) {
                console.log(message, result)
            } else {
                console.log(result)
            }
        },
        addCommentDisabled(buttons) {
            if (buttons.length > 0) {
                let result = false
                buttons.forEach(x => {
                    if (x.buttonText != '') {
                        if (!x.nextId) {
                            result = true
                        }
                    }
                })
                return result
            } else {
                return false
            }
        },
        clearData() {
            this.currentMessage = {}
            this.viewResponse = false
            this.viewingResponseMessage = {}
            this.responseButtonTitle = ''
            this.selectedMessage = {}
            this.responseMessage = {}
            this.focusKey = 0
            this.focusKey2 = 1
            this.newMessage = {
                title: '',
                header: '',
                body: '',
                footer: '',
                buttons: [],
            }
        }
    }
}
</script>

<style scoped lang="scss">
.dotty {
    height: 5px;
    width: 5px;
    background-color: white;
    opacity: 0.7;
    border-radius: 50%;
}

.filter-orange {
    filter: brightness(0) saturate(100%) invert(75%) sepia(43%) saturate(564%) hue-rotate(338deg) brightness(97%) contrast(99%);
}

.btnSelect {
    background-color: #3797cb !important;
    color: #ffffff !important;
}

.btnPlain {
    background-color: rgb(255, 248, 239) !important;
    color: #3797cb;
}

.button {
    height: 3.5vh;
    width: 85%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 3px 16px 0px 16px;
}

.expanded {
    rotate: 180deg;
}
.collapsed {
    rotate: 0deg
}

.tile {
    background-color: var(--v-greyRaised-base) !important;
    height: 90vh;
    width: 100%;
}

.innerTile {
    background-color: var(--v-greyRaised-base) !important;
    height: calc(90vh - 48px);
    width: 100%;
}

.variableSelect {
    margin: -55px 0 0 10px
}

.buttonFunction {
    margin: -55px 0 0 50px
}

.templateDescription ::v-deep textarea {
    height: 70px;
}
.mainMessageClass {
    background-color: #8d989e25 !important;
}
.responseMessageClass {
    background-color: #8d989e1b !important;

}
.chosenTemplate {
    background-color: #74d87b2c !important;
}

.expectedTypes {
    height: 7vh;
    width: 7vh;
    background-color: var(--v-greyRaised-lighten1) !important;
    margin: 10px 0 10px 1vw;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuPreview {
    height: 70%; 
    width: 100%; 
    position: fixed; 
    bottom: 0; 
    background-color: var(--v-greyRaised-base) !important;
    border-radius: 20px 20px 0 0;
}
.inactive {
    opacity: 0.5
}
</style>

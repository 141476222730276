<template>
    <!-- <v-card> -->
        <v-row>
            <!-- Active Jobs Table -->
<!---->     <v-col cols="12" md="9" sm="9">                
                    <v-data-table 
                    :headers="headers"
                    :items="activeJobs"
                    :loading="loadingData"
                    style="cursor: pointer"
                    @click:row="bookingClick"
                    height="75vh"
                    >
                    <template v-slot:[`item.loadPoints`]="{ item }">
                      <v-chip small color="primary"> 1) {{formatDepotName(item.bookingItem.ppecbBookingLoadPoints.length,item.bookingItem.depot,1) }}</v-chip>
                      <v-chip v-if="item.bookingItem.ppecbBookingLoadPoints.length == 2" small color="primary" style="margin: 0.2vh 0 0 0">
                         2) {{formatDepotName(item.bookingItem.ppecbBookingLoadPoints.length,item.bookingItem.depot,2) }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip small color="edit"> {{item.status}}</v-chip>
                    </template>
                    <!-- <template v-slot:[]>
                      <div>
                        
                      </div>
                    </template> -->

                    </v-data-table>
            </v-col>
            <!-- Job Details Card -->
<!---->     <v-col cols="12" md="3" sm="3">
                <v-card color="greyBase" class="jobInformation">
                  <v-toolbar flat color="greyBase" style="height: 5vh; margin: 0 0 1vh 0">
                    <v-card-subtitle style="margin: -2vh 0 0 -1vw"><b>Job Information</b></v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="viewTrackerCard" plain v-on="on" icon style="position: absolute; top: 0.2vh; right: 1vw">
                        <v-icon>map</v-icon>
                      </v-btn>
                      </template>
                      <span>View Tracker</span>
                  </v-tooltip>
                  </v-toolbar> 
                      <v-row no-gutters>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Shipping Line" v-model="selectBooking.bookingItem.ppecbBooking.shippingLine" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Voyage Number" v-model="selectBooking.bookingItem.ppecbBooking.voyageNumber" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Port of Loading" v-model="selectBooking.portOfLoading.name" v-if="selected && selectBooking.portOfLoading" />
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Port of Loading" v-model="selectBooking.bookingItem.ppecbBooking.loadPort" v-if="selected && !selectBooking.portOfLoading" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Vessel" v-model="selectBooking.bookingItem.ppecbBooking.vesselName" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Shipping-Line Reference" v-model="selectBooking.bookingItem.shippingLineReference" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Port of Discharge" v-model="selectBooking.portOfDischarge.name" v-if="selected && selectBooking.portOfDischarge"></v-text-field>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Port of Discharge" v-model="selectBooking.bookingItem.finalPortOfDischarge" v-if="selected && !selectBooking.portOfDischarge"></v-text-field> 
                        </v-col>
                      </v-row>  
                      <v-divider style="margin: -1vh 0 0 0"></v-divider>
                        <v-subheader style="margin: -1vh 0 0 0"><b>Cargo:</b></v-subheader>
                      <v-row no-gutters>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Product" v-model="selectBooking.productName" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Container Type" v-model="selectBooking.bookingItem.ppecbBookingLoadPoints[0].containerType " v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Quantity" v-model="selectBooking.bookingItem.ppecbBookingLoadPoints[0].quantity " v-if="selected && loadPoints == 1"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Quantity" v-model="totalQuantity().quantity" v-if="selected && loadPoints == 2"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Loading Date" v-model="formatTime(selectBooking.bookingItem.ppecbBookingLoadPoints[0].loadingDate).datetime " v-if="selected"/>
                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Cargo VGM" v-model="selectBooking.bookingItem.cargoVGM" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Container Number" v-model="selectBooking.bookingItem.containerAndSealNumber" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Unit of Measure" v-model="selectBooking.bookingItem.ppecbBookingLoadPoints[0].unitOfMeasure" v-if="selected"/>
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Loading Date" v-model="formatTime(selectBooking.bookingItem.ppecbBookingLoadPoints[1].loadingDate).datetime " v-if="selected && loadPoints == 2"/>
                        </v-col>
                      </v-row>
                      <v-divider style="margin: -1vh 0 0 0"></v-divider>
                        <v-subheader style="margin: -1vh 0 0 0"><b>Instructions:</b></v-subheader>
                      <v-row no-gutters>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Temp Code" v-model="selectBooking.regimeData.code" v-if="selected" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Vent Setting" v-model="selectBooking.bookingItem.ventSetting" v-if="selected" />
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Shipping Temp" v-model="selectBooking.regimeData.recipeDescription" v-if="selected" />
                          <v-text-field style="margin: -0.8vh 0.5vw 0 0.5vw" persistent-placeholder readonly outlined dense label="Special Instructions" v-model="selectBooking.bookingItem.specialInstruction" v-if="selected" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                        <span style="margin: 0 0 0 0.5vw" v-if="selected && selectBooking.bookingItem.isGensetRequired">  GenSet: <v-chip small  style="width:2vw; margin: -0.8vh 0.5vw 0 0.5vw" color="success"> <v-icon small>check</v-icon></v-chip></span>
                        <span style="margin: 0 0 0 0.5vw" v-if="selected && !selectBooking.bookingItem.isGensetRequired"> GenSet: <v-chip small  style="width:2vw; margin: -0.8vh 0.5vw 0 0.5vw" color="primary"> <v-icon small>close</v-icon></v-chip></span>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                        <span style="margin: 0 0 0 0.5vw" v-if="selected && selectBooking.bookingItem.isColdTreatment">  Steri:   <v-chip small  style="width:2vw ;margin: -0.8vh 0.5vw 0 0.5vw" color="success"> <v-icon small>check</v-icon></v-chip></span>
                        <span style="margin: 0 0 0 0.5vw" v-if="selected && !selectBooking.bookingItem.isColdTreatment"> Steri:   <v-chip small  style="width:2vw ;margin: -0.8vh 0.5vw 0 0.5vw" color="primary"> <v-icon small>close</v-icon></v-chip></span>

                        </v-col>
                      </v-row>
                </v-card>
            </v-col>
      <v-dialog v-model="viewTracker" max-width="850">
        <v-card height="525" style="overflow-y: hidden">
            <ViewTracker />
              </v-card>
            </v-dialog>
            
        </v-row>
</template>

<script>
import dateFormat from "dateformat";
import ViewTracker from "../Dialogs/ViewTracker.vue";

export default {
    props: [],
    components: {
        ViewTracker,
    },
    data: () => ({
        activeJobs: [],
        selectBooking: {},
        loadPoints: 0,
        selected: false,
        viewTracker: false,
        loadingData: true,
        headers: [
        {
        text: "Ref",
        value: "reference",
        align: "left",
      },
      {
        text: "Loading-Ref",
        value: "bookingItem.loadingInstructionNumber",
        align: "left",
      },
      {
        text: "Shipper",
        value: "exporter.fboName",
        align: "left",
      },
      {
        text: "Forwader",
        value: "forwarder.fboName",
        align: "left",
      },
      {
        text: "Haul",
        value: "haul",
        align: "left",
      },
      {
        text: "Empty",
        value: "emptyPickup",
        align: "left",
      },
      {
        text: "LoadPoints",
        value: "loadPoints",
        align: "left",
      },
      {
        text: "ETA",
        value: "bookingItem.eta",
        align: "left",
      },
      {
        text: "Dropoff",
        value: "bookingItem.ppecbBooking.loadPort",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "left",
      },
      {
        text: "Manage",
        value: "manage",
        align: "left",
      }

        ]

    }),
    async created() {
      this.activeJobs = await this.$API.getActiveJobs()
      this.loadingData = false
    },
    watch: {

    },
    methods: {
      formatDepotName(count, depot, position) {
      if (count === 1) {
        if (depot.at(-1) === ",") {
          depot = depot.slice(0, -1);
          return depot;
        } else {
          return depot;
        }
      }
      if (count === 2 && position == 1) {
        let name = `${depot.split(",")[0] + "," + depot.split(",")[1]}`;
        return name;
      }
      if (count === 2 && position == 2) {
        if (depot.split(",").length === 3) {
          let name = `${depot.split(",")[2]}`;
          return name;
        }
        if (depot.split(",").length === 4) {
          let name = `${depot.split(",")[2] + "," + depot.split(",")[3]}`;
          return name;
        }
      }
      if (count === 3 && position == 1) {
        let name = `${depot.split(",")[0] + "," + depot.split(",")[1]}`;
        return name;
      }
      if (count === 3 && position == 2) {
        let name = `${depot.split(",")[2] + "," + depot.split(",")[3]}`;
        return name;
      }
      if (count === 3 && position == 3) {
        let name = `${depot.split(",")[4] + "," + depot.split(",")[5]}`;
        return name;
      }
    },

    viewTrackerCard() {
      this.viewTracker = true
    },

    formatTime(dateTime) {
      let time = dateFormat(dateTime, "hh:MM", true);
      let date = dateFormat(dateTime, "dd mmm yyyy");
      let datetime = dateFormat(dateTime, "dd mmm yyyy @ hh:MM", true);
      return { time, date, datetime };
    },

    totalQuantity() {
      let num1 = this.selectBooking.bookingItem.ppecbBookingLoadPoints[0].quantity;
      let num2 = this.selectBooking.bookingItem.ppecbBookingLoadPoints[1].quantity;
      let quantity = parseInt(num1) + parseInt(num2);
      return {quantity};
    },

    bookingClick(item) {
      this.selected = true
      if(item.bookingItem.ppecbBookingLoadPoints.length == 2){
        this.loadPoints = 2
      }
      if(item.bookingItem.ppecbBookingLoadPoints.length == 1){
        this.loadPoints = 1
      }
      if(item.bookingItem.ppecbBookingLoadPoints.length == 3){
        this.loadPoints = 3
      }
      this.selectBooking = item
      // console.log(this.selectBooking)
    },

}
}
</script>

<style scoped>

.dataTable {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
}

.jobInformation {
    height: 80vh;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
}


</style>
<template>
  <v-card tile style="contain: content; height: calc(96vh - 84px)">
    <v-row no-gutters height="inherit">
      <v-col cols="12" md="9" sm="9" class="pl-4 pr-3">
        <!--          BUTTONS             -->
        <v-row no-gutters class="pt-3">
          <v-col
            cols="12"
            sm="3"
            md="3"
            class="pt-0 pb-1 pr-4 d-flex align-end"
          >
            <v-text-field
              hide-details
              style="width: 100%; padding: 0; margin: 0"
              dense
              label="Unit Name"
              placeholder="Unit Name"
              v-model="chosenAlias"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-0"> </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            class="py-0 d-flex justify-start align-end"
          >
            <v-tooltip
              top
              open-delay="300"
              v-if="!addTrailer && $store.state.user.id === 69"
            >
              <template v-slot:activator="{ on }">
                <v-btn small @click="createLinkedTrailers" plain v-on="on">
                  <v-icon color="edit">join_inner</v-icon>
                  <span> Create Link</span>
                </v-btn>
              </template>
              <span>Create SuperLinks</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            class="py-0 d-flex justify-end align-center"
          >
            <v-btn
              small
              v-if="changeUnit"
              icon
              @click="clearUnit"
              color="primary"
              plain
              class=""
            >
              <v-icon>close</v-icon>
            </v-btn>

            <v-btn
              small
              v-if="changeUnit"
              icon
              @click="areYouSure = true"
              color="primary"
              plain
              class=""
              :loading="loadingDelete"
              :disabled="assignedDisable"
            >
              <v-icon>delete</v-icon>
            </v-btn>

            <v-btn
              small
              v-if="changeUnit"
              @click="updateUnit"
              :loading="loadingUpdate"
              color="tertiary"
              :disabled="assignedDisable"
            >
              Update Unit
            </v-btn>

            <v-btn
              small
              v-else
              @click="createUnit"
              color="tertiary"
              :loading="loadingCreate"
            >
              Create Unit
            </v-btn>
          </v-col>
        </v-row>
        <!--        SLOTS          -->
        <v-row class="mt-0">
          <!--        DRIVER SLOT       -->
          <v-col
            cols="12"
            md="3"
            sm="3"
            class="px-1 pt-0"
            style="display: grid; justify-items: center; align-items: center"
          >
            <v-card outlined class="itemSlot tile" raised>
              <v-icon class="centerIcon" v-if="chosenDriver.length == 0"
                >person</v-icon
              >
              <v-list nav dense class="listContainer">
                <draggable
                  class="dropZone"
                  :list="chosenDriver"
                  :animation="200"
                  ghost-class="ghost-card"
                  group="drivers"
                  :disabled="
                    chosenUnit ? (assignedDisable ? true : false) : false
                  "
                >
                  <v-list-item
                    :key="driver.id"
                    two-line
                    color="green"
                    v-for="driver in chosenDriver"
                  >
                    <v-list-item-avatar size="50" v-if="driver.user.avatar">
                      <img :src="driver.user.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-avatar size="50" v-else>
                      <v-icon large>person</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ driver.user.firstname }}
                        {{ driver.user.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="remove(driver, 'driver')"
                        color="red"
                        plain
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-card>
          </v-col>

          <!--        VEHICLE SLOT       -->
          <v-col
            cols="12"
            md="3"
            sm="3"
            class="px-1 pt-0"
            style="display: grid; justify-items: center; align-items: center"
          >
            <v-card outlined class="itemSlot tile" raised>
              <v-icon class="centerIcon" v-if="chosenVehicle.length == 0"
                >local_shipping</v-icon
              >
              <v-list nav dense class="listContainer">
                <draggable
                  class="dropZone"
                  :list="chosenVehicle"
                  :animation="200"
                  ghost-class="ghost-card"
                  group="vehicles"
                  :disabled="
                    chosenUnit ? (assignedDisable ? true : false) : false
                  "
                >
                  <v-list-item
                    :key="vehicle.id"
                    two-line
                    color="green"
                    v-for="vehicle in chosenVehicle"
                  >
                    <v-list-item-avatar size="55" v-if="vehicle.photoOfFront">
                      <img :src="vehicle.photoOfFront" />
                    </v-list-item-avatar>
                    <v-list-item-avatar size="50" v-else>
                      <v-icon large>local_shipping</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ vehicle.registration }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ vehicle.make + " " + vehicle.model }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="remove(vehicle, 'vehicle')"
                        color="red"
                        plain
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-card>
          </v-col>

          <!--        TRAILER 1 SLOT      -->
          <v-col
            cols="12"
            md="3"
            sm="3"
            class="px-1 pt-0"
            style="display: grid; justify-items: center; align-items: center"
          >
            <v-card outlined class="itemSlot tile" raised>
              <v-avatar
                v-if="chosenTrailer.length == 0"
                class="centerIcon"
                size="60"
                ><v-img src="/icons/trailer.svg"></v-img
              ></v-avatar>
              <v-list nav dense class="listContainer">
                <draggable
                  class="dropZone"
                  :list="chosenTrailer"
                  :animation="200"
                  ghost-class="ghost-card"
                  group="trailers"
                  :disabled="
                    chosenUnit ? (assignedDisable ? true : false) : false
                  "
                >
                  <v-list-item
                    :key="trailer.id"
                    two-line
                    color="green"
                    v-for="trailer in chosenTrailer"
                  >
                    <v-list-item-avatar size="50" style="contain: content">
                      <v-img
                        style="scale: 0.8; position: absolute"
                        src="/icons/trailer.svg"
                      />
                      <v-icon
                        v-if="!trailer.registration && trailer.alias"
                        style="
                          position: absolute;
                          top: -8px;
                          right: -18px;
                          scale: 0.8;
                        "
                        >add</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-if="trailer.registration">
                        {{ trailer.registration }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-if="!trailer.registration && trailer.alias"
                      >
                        {{ trailer.alias }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ trailer.type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="remove(trailer, 'trailer')"
                        color="red"
                        plain
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-card>
          </v-col>

          <!--        GENSETS      -->
          <v-col cols="12" sm="3" md="3" class="px-1 pt-0">
            <v-card outlined class="itemSlot tile" raised>
              <v-icon class="centerIcon" v-if="chosenGenset.length == 0">
                bolt
              </v-icon>
              <v-list nav dense class="listContainer">
                <draggable
                  class="dropZone"
                  :list="chosenGenset"
                  :animation="200"
                  ghost-class="ghost-card"
                  group="gensets"
                  :disabled="
                    chosenUnit ? (assignedDisable ? true : false) : false
                  "
                >
                  <v-list-item
                    :key="genset.id"
                    two-line
                    outlined
                    color="green"
                    v-for="genset in chosenGenset"
                  >
                    <v-list-item-avatar size="50">
                      <v-icon large>bolt</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ genset.serialNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="genset.alias">
                        {{ genset.alias }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="remove(genset, 'genset')"
                        color="red"
                        plain
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <!--        LISTS       -->
        <v-row class="mt-0">
          <!--        DRIVERS       -->
          <v-col cols="12" md="3" sm="3" class="px-1">
            <v-card outlined class="listCard tile">
              <v-toolbar dense flat class="toolbars transparent">
                <v-subheader class="subheaders">Drivers</v-subheader>
                <v-spacer></v-spacer>
                <v-text-field
                  autofocus
                  v-if="driverSearch"
                  class="searchPopOut"
                  v-model="searchDrivers"
                  hide-details
                />
                <v-btn
                  @click="(driverSearch = !driverSearch), (searchDrivers = '')"
                  icon
                  plain
                >
                  <v-icon>{{ driverSearch ? "search_off" : "search" }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card class="scroll transparent" flat>
                <v-list nav dense class="lists transparent" :key="driverKey">
                  <v-list-item-group>
                    <draggable
                      class="listDropZone"
                      :list="driverList"
                      :animation="200"
                      ghost-class="ghost-card"
                      group="drivers"
                      :disabled="chosenUnit && assignedDisable ? true : false"
                    >
                      <v-list-item
                        two-line
                        v-for="driver in driverList"
                        :key="driver.id"
                        color="green"
                        dense
                      >
                        <v-list-item-avatar
                          size="55"
                          v-if="driver.user.avatar"
                          class="my-0"
                        >
                          <img :src="driver.user.avatar" />
                        </v-list-item-avatar>
                        <v-list-item-avatar size="55" v-else class="my-0">
                          <v-icon large>person</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ driver.user.firstname }}
                            {{ driver.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="driver.nickname">
                            {{ driver.nickname }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </draggable>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-card>
          </v-col>

          <!--        VEHICLES       -->
          <v-col cols="12" md="3" sm="3" class="px-1">
            <v-card outlined class="listCard tile">
              <v-toolbar dense flat class="toolbars transparent">
                <v-subheader class="subheaders">Vehicles</v-subheader>
                <v-spacer></v-spacer>
                <v-select
                  v-if="vehicleFilter"
                  v-model="vehicleFilterChosen"
                  :items="vehicleTypes"
                  :disabled="staticFilterV"
                  hide-details
                  class="filterSelect"
                />
                <v-text-field
                  autofocus
                  v-if="vehicleSearch"
                  class="searchPopOut"
                  v-model="searchVehicles"
                  hide-details
                />
                <v-btn
                  v-if="!vehicleFilter"
                  @click="
                    (vehicleSearch = !vehicleSearch), (searchVehicles = '')
                  "
                  icon
                  plain
                >
                  <v-icon>{{ vehicleSearch ? "search_off" : "search" }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="!vehicleSearch"
                  @click="
                    (vehicleFilter = !vehicleFilter),
                      (vehicleFilterChosen = null)
                  "
                  plain
                  icon
                  :disabled="staticFilterV"
                >
                  <v-icon>{{
                    vehicleFilter ? "filter_alt_off" : "filter_alt"
                  }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card class="scroll transparent" flat>
                <v-list nav dense class="lists transparent" :key="vehicleKey">
                  <v-list-item-group>
                    <draggable
                      class="listDropZone"
                      :list="vehicleList"
                      :animation="200"
                      ghost-class="ghost-card"
                      group="vehicles"
                      :disabled="chosenUnit && assignedDisable ? true : false"
                    >
                      <v-list-item
                        two-line
                        v-for="vehicle in vehicleList"
                        :key="vehicle.id"
                        color="green"
                        dense
                      >
                        <v-list-item-avatar
                          size="55"
                          v-if="vehicle.photoOfFront"
                          class="my-0"
                        >
                          <img :src="vehicle.photoOfFront" />
                        </v-list-item-avatar>
                        <v-list-item-avatar size="55" v-else class="my-0">
                          <v-icon large>local_shipping</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ vehicle.registration }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ vehicle.make + " " + vehicle.model }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </draggable>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-card>
          </v-col>

          <!--        TRAILERS       -->
          <v-col cols="12" md="3" sm="3" class="px-1">
            <v-card outlined class="listCard tile">
              <v-toolbar dense flat class="toolbars transparent">
                <v-subheader class="subheaders">Trailers</v-subheader>
                <v-spacer></v-spacer>
                <v-select
                  autofocus
                  v-if="trailerFilter"
                  v-model="trailerFilterChosen"
                  :items="trailerTypes"
                  :disabled="staticFilterT"
                  hide-details
                  class="filterSelect"
                />
                <v-text-field
                  autofocus
                  v-if="trailerSearch"
                  class="searchPopOut"
                  v-model="searchTrailers"
                  hide-details
                />
                <v-btn
                  v-if="!trailerFilter"
                  @click="
                    (trailerSearch = !trailerSearch), (searchTrailers = '')
                  "
                  icon
                  plain
                >
                  <v-icon>{{ trailerSearch ? "search_off" : "search" }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="!trailerSearch"
                  @click="
                    (trailerFilter = !trailerFilter),
                      (trailerFilterChosen = null)
                  "
                  plain
                  icon
                  :disabled="staticFilterT"
                >
                  <v-icon>{{
                    trailerFilter ? "filter_alt_off" : "filter_alt"
                  }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card class="scroll transparent" flat>
                <v-list nav dense class="lists" :key="trailerKey">
                  <v-list-item-group>
                    <draggable
                      class="listDropZone"
                      :list="trailerList"
                      :animation="200"
                      ghost-class="ghost-card"
                      group="trailers"
                      :disabled="chosenUnit && assignedDisable ? true : false"
                    >
                      <v-list-item
                        two-line
                        v-for="trailer in trailerList"
                        :key="trailer.id"
                        color="green"
                        dense
                      >
                        <v-list-item-avatar size="50" class="my-0">
                          <v-img
                            style="scale: 0.8; position: absolute"
                            src="/icons/trailer.svg"
                          />
                          <v-icon
                            v-if="!trailer.registration && trailer.alias"
                            style="
                              position: absolute;
                              top: -8px;
                              right: -18px;
                              scale: 0.8;
                            "
                            >add</v-icon
                          >
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-if="trailer.registration">
                            {{ trailer.registration }}
                          </v-list-item-title>
                          <v-list-item-title
                            v-if="!trailer.registration && trailer.alias"
                          >
                            {{ trailer.alias }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ trailer.type }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="trailer.alias">
                          <v-menu offset-x offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-list-item>
                                <v-list-item-title @click="viewLink(trailer)"
                                  >View</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-title @click="editLink(trailer)"
                                  >Edit</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-title
                                  @click="unlinkTrailers(trailer)"
                                  >Unlink</v-list-item-title
                                >
                              </v-list-item>
                            </v-card>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </draggable>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-card>
          </v-col>
          <!--        GENSETS        -->
          <v-col cols="12" md="3" sm="3" class="px-1">
            <v-card outlined class="listCard tile">
              <v-toolbar dense flat class="toolbars transparent">
                <v-subheader class="subheaders">Genset</v-subheader>
                <v-spacer></v-spacer>
                <v-text-field
                  autofocus
                  v-if="gensetSearch"
                  class="searchPopOut"
                  v-model="searchGensets"
                  hide-details
                />
                <v-btn
                  @click="(gensetSearch = !gensetSearch), (searchGensets = '')"
                  icon
                  plain
                >
                  <v-icon>{{ gensetSearch ? "search_off" : "search" }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card class="scroll transparent" flat>
                <v-list nav dense class="lists" :key="gensetKey">
                  <v-list-item-group>
                    <draggable
                      class="listDropZone"
                      :list="gensetList"
                      :animation="200"
                      ghost-class="ghost-card"
                      group="gensets"
                      :disabled="chosenUnit && assignedDisable ? true : false"
                    >
                      <v-list-item
                        two-line
                        v-for="genset in gensetList"
                        :key="genset.id"
                        color="green"
                        dense
                      >
                        <v-list-item-avatar size="55" class="my-0">
                          <v-icon large>bolt</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ genset.serialNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="genset.alias">
                            {{ genset.alias }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </draggable>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!--        TRANSPORT UNITS       -->
      <v-col cols="12" md="3" sm="3" class="pr-3 pl-1 pt-1">
        <v-card outlined v-if="!addTrailer" class="rightCard tile">
          <v-toolbar dense flat class="toolbars transparent">
            <span class="subheaders">Transport Units</span>
            <v-spacer></v-spacer>
            <v-text-field
              autofocus
              v-if="unitSearch"
              class="searchPopOut"
              v-model="searchUnits"
              hide-details
            />
            <v-btn
              @click="(unitSearch = !unitSearch), (searchUnits = '')"
              icon
              plain
            >
              <v-icon>{{ unitSearch ? "search_off" : "search" }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card class="scroll transparent" flat>
            <v-list nav dense class="unitsList">
              <v-list-item-group v-if="unitList.length > 0">
                <draggable
                  :list="unitList"
                  :animation="200"
                  ghost-class="ghost-card"
                  group="transUnit"
                >
                  <v-list-item
                    three-line
                    outlined
                    v-for="unit in unitList"
                    :key="unit.id"
                    color="green"
                    @click="editUnit(unit)"
                  >
                    <v-list-item-avatar
                      size="55"
                      class="my-2"
                      v-if="unit && unit.driver && unit.driver.avatar"
                    >
                      <img
                        v-if="unit && unit.driver"
                        :src="unit.driver.user.avatar"
                      />
                    </v-list-item-avatar>
                    <v-list-item-avatar size="55" class="my-2" v-else>
                      <v-icon large>local_shipping</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="unit">
                      <v-list-item-title v-if="unit && unit.driver">
                        {{ unit.driver.user.firstname }}
                        {{ unit.driver.user.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="unit.vehicle">
                        <v-icon small>local_shipping</v-icon>
                        {{ unit.vehicle.registration }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="unit.trailers.length > 0">
                        <v-icon small>rv_hookup</v-icon>
                        {{ unit.trailers[0] ? unit.trailers[0].type : "No" }}
                        {{ unit.trailers.length > 1 ? "trailers" : "trailer" }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        <v-icon>rv_hookup</v-icon> No trailers connected
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-btn icon><v-icon>info</v-icon></v-btn>
                  </v-list-item>
                </draggable>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card>

        <!--        MORE TRAILERS       -->
        <v-card outlined v-if="addTrailer" class="rightCard tile">
          <v-toolbar flat class="toolbars transparent">
            <v-subheader class="subheaders">Add Trailers</v-subheader>
            <v-spacer></v-spacer>
            <v-btn icon @click="addTrailers" plain class="subheaders"
              ><v-icon color="primary">close</v-icon></v-btn
            >
          </v-toolbar>

          <!--        More Trailer Slots       -->
          <v-row justify="center">
            <v-col
              cols="12"
              md="10"
              sm="10"
              class="d-flex justify-center align-center"
              style="flex-direction: column"
            >
              <div
                v-for="trailer in 7"
                :key="trailer - 1"
                class="itemSlot trailerAdd"
              >
                <v-card outlined raised class="itemSlot tile">
                  <v-avatar
                    v-if="linkedTrailers.length < trailer"
                    class="centerIcon"
                    size="60"
                    ><v-img src="/icons/trailer.svg"></v-img
                  ></v-avatar>
                  <v-list nav dense class="listContainer">
                    <draggable
                      class="dropZone"
                      :list="linkedTrailers"
                      :animation="200"
                      ghost-class="ghost-card"
                      group="trailers"
                      :disabled="chosenUnit && assignedDisable ? true : false"
                    >
                      <v-list-item
                        two-line
                        color="green"
                        v-if="linkedTrailers[trailer - 1]"
                      >
                        <v-list-item-avatar size="40">
                          <v-img src="/icons/trailer.svg" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ linkedTrailers[trailer - 1].registration }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ linkedTrailers[trailer - 1].type }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            icon
                            @click="remove(trailer - 1, 'extras')"
                            color="red"
                            plain
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-card>
              </div>
              <span class="d-flex justify-center">
                <v-btn class="mt-5" color="edit" @click="addTrailers">
                  Confirm Trailers
                </v-btn>
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" elevation="24" color="primary" timeout="-1">
      <v-icon large>info</v-icon>
      This unit is currently assigned to a job!
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
        <v-btn color="white" v-bind="attrs" @click="snackbar = false, assignedDisable = false">
          <span style="color: var(--v-primary-base)">Edit</span>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog width="45vw" v-model="trailerLinks">
      <v-card height="auto">
        <v-toolbar flat dense>
          Connect Trailers
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            @click="
              (trailerLinks = false),
                (linkedTrailers = []),
                (editingLink = false)
            "
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-progress-linear v-if="loadingLink" indeterminate></v-progress-linear>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-card outlined class="">
                <v-toolbar dense flat class="toolbars transparent">
                  <v-subheader class="subheaders">Trailers</v-subheader>
                  <v-spacer></v-spacer>
                  <v-select
                    autofocus
                    v-if="trailerFilter"
                    v-model="trailerFilterChosen"
                    :items="trailerTypes"
                    :disabled="staticFilterT"
                    hide-details
                    class="filterSelect"
                  />
                  <v-text-field
                    autofocus
                    v-if="trailerSearch"
                    class="searchPopOut"
                    v-model="searchTrailers"
                    hide-details
                  />
                  <v-btn
                    v-if="!trailerFilter"
                    @click="
                      (trailerSearch = !trailerSearch), (searchTrailers = '')
                    "
                    icon
                    plain
                  >
                    <v-icon>{{
                      trailerSearch ? "search_off" : "search"
                    }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!trailerSearch"
                    @click="
                      (trailerFilter = !trailerFilter),
                        (trailerFilterChosen = null)
                    "
                    plain
                    icon
                    :disabled="staticFilterT"
                  >
                    <v-icon>{{
                      trailerFilter ? "filter_alt_off" : "filter_alt"
                    }}</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card class="scroll transparent" flat>
                  <v-list nav dense class="lists" :key="trailerKey">
                    <v-list-item-group>
                      <draggable
                        class="listDropZone"
                        :list="trailerLinkList"
                        :animation="200"
                        ghost-class="ghost-card"
                        group="trailerLink"
                        :disabled="chosenUnit && assignedDisable ? true : false"
                      >
                        <v-list-item
                          two-line
                          v-for="trailer in trailerLinkList"
                          :key="trailer.id"
                          color="green"
                          dense
                        >
                          <v-list-item-avatar size="40" class="my-0">
                            <v-img src="/icons/trailer.svg" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ trailer.registration }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ trailer.type }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <!--        More Trailer Slots       -->
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="d-flex justify-center align-center"
                  style="flex-direction: column"
                >
                  Trailer Slots
                  <v-divider></v-divider>
                  <div
                    v-for="trailer in 5"
                    :key="trailer - 1"
                    class="mb-4 itemSlot trailerAdd"
                  >
                    <v-card outlined raised class="itemSlot tile">
                      <v-avatar
                        v-if="linkedTrailers.length < trailer"
                        class="centerIcon"
                        size="60"
                        ><v-img src="/icons/trailer.svg"></v-img
                      ></v-avatar>
                      <v-list nav dense class="trailerSlots">
                        <draggable
                          class="dropZone"
                          :list="linkedTrailers"
                          :animation="200"
                          ghost-class="ghost-other"
                          group="trailerLink"
                          :disabled="
                            chosenUnit && assignedDisable ? true : false"
                        >
                          <div
                            v-for="(item, index) in linkedTrailers"
                            :key="item.id"
                            :class="
                              index === trailer - 1 ? 'showDiv' : 'hideDiv'
                            "
                          >
                            <v-list-item
                              class="extraTrailer"
                              two-line
                              color="green"
                              v-if="index == trailer - 1"
                            >
                              <v-list-item-avatar size="40">
                                <v-img src="/icons/trailer.svg" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.registration }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ item.type }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn
                                  icon
                                  @click="remove(trailer - 1, 'extras')"
                                  color="red"
                                  plain
                                >
                                  <v-icon>close</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </div>
                        </draggable>
                      </v-list>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="10" class="d-flex justify-center">
                  <v-btn
                    v-if="!editingLink"
                    color="tertiary"
                    :disabled="linkedTrailers.length < 2"
                    @click="createLink()"
                    :loading="loadingLink"
                    >Create Link</v-btn
                  >
                  <v-btn
                    v-if="editingLink"
                    color="tertiary"
                    :disabled="linkDisabled"
                    @click="updateLink()"
                    :key="disabledKey"
                    :loading="loadingLink"
                    >Update Link</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="areYouSure" width="20vw">
      <v-card height="auto">
        <v-card-title style="text-align: center; display: block"
          >Are you sure?</v-card-title
        >
        <v-card-subtitle class="pb-2" style="text-align: center; display: block"
          >You are about to delete this unit.</v-card-subtitle
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary darken-1" text @click="areYouSure = false"
            >Cancel</v-btn
          >
          <v-btn color="tertiary darken-1" text @click="deleteUnit"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkedCard" width="20vw">
      <v-card height="auto">
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            viewingLink.alias ? viewingLink.alias : ""
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            @click="(linkedCard = false), (viewingLink = {})"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" v-if="viewingLink.trailers">
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="d-flex justify-center align-center"
              style="flex-direction: column"
            >
              Trailer Slots
              <v-divider></v-divider>
              <div
                v-for="trailer in viewingLink.trailers"
                :key="trailer.id"
                class="mb-4 itemSlot trailerAdd"
              >
                <v-card outlined raised class="itemSlot tile">
                  <!-- <v-avatar class="centerIcon" size="60"><v-img
                          src="/icons/trailer.svg"></v-img></v-avatar> -->
                  <v-list nav dense class="trailerSlots">
                    <v-list-item class="extraTrailer" two-line color="green">
                      <v-list-item-avatar size="40">
                        <v-img src="/icons/trailer.svg" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ trailer.registration }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ trailer.type }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <!-- <v-btn icon @click="remove(trailer, 'extras')" color="red" plain>
                                <v-icon>close</v-icon>
                              </v-btn> -->
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data: () => ({
    changeUnit: false,
    trailerLinks: false,
    trailerFilter: false,
    vehicleFilter: false,
    staticFilterT: false,
    staticFilterV: false,
    snackbar: false,
    areYouSure: false,
    addTrailer: false,
    driverSearch: false,
    vehicleSearch: false,
    trailerSearch: false,
    gensetSearch: false,
    unitSearch: false,
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingLink: false,
    linkedCard: false,
    editingLink: false,
    assignedDisable: false,
    searchDrivers: "",
    searchVehicles: "",
    searchTrailers: "",
    searchGensets: "",
    searchUnits: "",
    chosenAlias: "",
    chosenTrailerAlias: "",
    alias: null,
    viewingLink: {},
    vehicleFilterChosen: null,
    trailerFilterChosen: null,
    summed: 0,
    disabledKey: 0,
    driverKey: 0,
    gensetKey: 0,
    vehicleKey: 0,
    trailerKey: 0,
    drivers: [],
    vehicles: [],
    trailers: [],
    trailerUnits: [],
    gensets: [],
    units: [],
    chosenDriver: [],
    chosenVehicle: [],
    chosenTrailer: [],
    chosenGenset: [],
    chosenUnit: [],
    linkedTrailers: [],
    trailerTypes: [],
    vehicleTypes: [],
  }),
  async created() {
    this.getData();
    this.$socket.send(
      JSON.stringify({
        action: "join",
        room: "transportUnit_" + this.$store.state.currentOrg.id,
        user: {
          id: this.$store.state.user.id,
          name:
            this.$store.state.user.firstname +
            " " +
            this.$store.state.user.surname,
          avatar: this.$store.state.user.avatar,
          company: this.$store.state.currentOrg.name,
        },
      })
    );
  },
  mounted() {
    this.$socket.onopen = () => {
      this.$socket.send(
        JSON.stringify({
          action: "join",
          room: "transportUnit_" + this.$store.state.currentOrg.id,
          user: {
            id: this.$store.state.user.id,
            name:
              this.$store.state.user.firstname +
              " " +
              this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
    };
    this.$socket.onmessage = (data) => {
      let result = JSON.parse(JSON.stringify(JSON.parse(data.data)));
      this.newMessageHandler(result);
    };
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  watch: {
    chosenDriver(val) {
      if (val.length > 1) {
        this.drivers.unshift(...this.chosenDriver.splice(1, 1));
      }
    },
    chosenVehicle(val) {
      if (val.length > 1) {
        this.vehicles.unshift(...this.chosenVehicle.splice(1, 1));
      }
    },
    chosenTrailer(val) {
      if (val.length > 1 && this.trailerLinks == false) {
        this.trailers.unshift(...this.chosenTrailer.splice(1, 1));
      }
    },
    linkedTrailers: {
      immediate: true,
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.viewingLink.trailers)) {
          if (val.length > 1) {
            this.linkDisabled = false;
            this.disabledKey++;
          } else {
            this.linkDisabled = true;
            this.disabledKey++;
          }
        } else {
          this.linkDisabled = true;
          this.disabledKey++;
        }
      },
      deep: true,
    },
  },
  computed: {
    driverList() {
      let result = [];
      if (this.drivers && this.drivers.length > 0) {
        result = this.drivers.filter(
          (driver) => driver.transportUnitId == null
        );
      }
      if (this.chosenDriver.length > 0) {
        result = result.filter(
          (driver) => driver.id !== this.chosenDriver[0].id
        );
      }
      if (this.searchDrivers !== "") {
        result = result.filter(
          (driver) =>
            driver.user.firstname
              .toLowerCase()
              .includes(this.searchDrivers.toLowerCase()) ||
            driver.user.surname
              .toLowerCase()
              .includes(this.searchDrivers.toLowerCase()) ||
            driver.nickname
              .toLowerCase()
              .includes(this.searchDrivers.toLowerCase())
        );
      }
      result = [...new Set(result)];
      return result;
    },
    vehicleList() {
      let result = [];
      if (this.vehicles && this.vehicles.length > 0) {
        result = this.vehicles.filter(
          (vehicle) => vehicle.transportUnitId == null
        );
      }
      if (this.chosenVehicle.length > 0) {
        result = result.filter(
          (vehicle) => vehicle.id !== this.chosenVehicle[0].id
        );
      }
      if (this.searchVehicles !== "") {
        result = result.filter(
          (vehicle) =>
            vehicle.type
              .toLowerCase()
              .includes(this.searchVehicles.toLowerCase()) ||
            vehicle.registration
              .toLowerCase()
              .includes(this.searchVehicles.toLowerCase()) ||
            vehicle.model
              .toLowerCase()
              .includes(this.searchVehicles.toLowerCase()) ||
            vehicle.make
              .toLowerCase()
              .includes(this.searchVehicles.toLowerCase()) ||
            vehicle.vin
              .toLowerCase()
              .includes(this.searchVehicles.toLowerCase())
        );
      }
      if (this.vehicleFilter && this.vehicleFilterChosen !== null) {
        result = result.filter(
          (vehicle) => vehicle.type === this.vehicleFilterChosen
        );
      }
      result = [...new Set(result)];
      return result;
    },
    trailerList() {
      let result = [];
      let linked = [];
      if (this.trailers && this.trailers.length > 0) {
        result = this.trailers.filter(
          (trailer) => trailer.transportUnitId == null
        );
        result = result.filter(
          (trailer) => trailer.transportTrailerUnitId == null
        );
      }
      if (this.trailerUnits && this.trailerUnits.length > 0) {
        linked = this.trailerUnits.filter(
          (trailer) => trailer.transportUnitId == null
        );
      }
      if (this.chosenTrailer.length > 0) {
        result = result.filter(
          (trailer) => trailer.id !== this.chosenTrailer[0].id
        );
        linked = linked.filter(
          (trailer) => trailer.id !== this.chosenTrailer[0].id
        );
      }
      if (this.searchTrailers !== "") {
        result = result.filter(
          (trailer) =>
            trailer.type
              .toLowerCase()
              .includes(this.searchTrailers.toLowerCase()) ||
            trailer.registration
              .toLowerCase()
              .includes(this.searchTrailers.toLowerCase())
        );
        linked = linked.filter(
          (trailer) =>
            trailer.alias
              .toLowerCase()
              .includes(this.searchTrailers.toLowerCase()) ||
            trailer.type
              .toLowerCase()
              .includes(this.searchTrailers.toLowerCase()) ||
            trailer.trailers.includes(this.searchTrailers.toLowerCase())
        );
      }
      if (this.trailerFilter && this.trailerFilterChosen !== null) {
        result = result.filter(
          (trailer) => trailer.type === this.trailerFilterChosen
        );
        linked = result.filter(
          (trailer) => trailer.type === this.trailerFilterChosen
        );
      }
      result = [...new Set(linked), ...new Set(result)];
      return result;
    },
    gensetList() {
      let result = [];
      if (this.gensets && this.gensets.length > 0) {
        result = this.gensets.filter(
          (genset) => genset.transportUnitId == null
        );
      }
      if (this.chosenGenset.length > 0) {
        result = result.filter(
          (genset) => genset.id !== this.chosenGenset[0].id
        );
      }
      if (this.searchGensets !== "") {
        result = result.filter(
          (genset) =>
            genset.serialNumber
              .toLowerCase()
              .includes(this.searchGensets.toLowerCase()) ||
            genset.alias
              .toLowerCase()
              .includes(this.searchGensets.toLowerCase())
        );
      }
      result = [...new Set(result)];
      return result;
    },
    unitList() {
      let result = [];
      if (this.units && this.units.length > 0) {
        result = this.units.map((unit) => {
          unit.driver = unit.transportDrivers[0]
            ? unit.transportDrivers[0]
            : null;
          unit.vehicle = unit.transportVehicles[0]
            ? unit.transportVehicles[0]
            : null;
          unit.genset = unit.transportGensets[0]
            ? unit.transportGensets[0]
            : null;
          unit.trailers = unit.transportTrailers
            ? unit.transportTrailers
            : null;
          return unit;
        });
      }
      if (this.searchUnits !== "") {
        result = result.filter(
          (unit) =>
            unit.driver?.user.firstname
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.driver?.user.surname
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.vehicle?.registration
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.vehicle?.make
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.vehicle?.model
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.genset?.serialNumber
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.genset?.alias
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.trailers[0]?.registration
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase()) ||
            unit.trailers[0]?.type
              .toLowerCase()
              .includes(this.searchUnits.toLowerCase())
        );
      }
      return result;
    },
    trailerLinkList() {
      let result = [];
      if (this.trailers && this.trailers.length > 0) {
        result = this.trailers.filter(
          (trailer) => trailer.transportUnitId == null
        );
        result = this.trailers.filter(
          (trailer) => trailer.transportTrailerUnitId == null
        );
      }

      result = [...new Set(result)];
      return result;
    },
  },
  methods: {
    newMessageHandler(data) {
      try {
        const parsedData = JSON.parse(data);
        const message = JSON.parse(parsedData.data);

        console.log("MESSAGE", message);

        if (message.userId === this.$store.state.user.id) {
          return;
        }

        const removeItemIfExists = (array, item, chosenItem) => {
          const index = array.findIndex((x) => x.id === item.id);
          if (index !== -1) {
            array.splice(index, 1);
          } else if (chosenItem.length > 0 && chosenItem[0].id === item.id) {
            chosenItem.splice(0, 1);
          } else {
            console.log(`${item.constructor.name.toLowerCase()} not found`);
          }
        };

        if (message.type === "create") {
          const { unit, unitId } = message;
          const { driver, vehicle, trailer, genset } = unit;
          unit.id = unitId;
          unit.transportDrivers = [driver];
          unit.transportVehicles = [vehicle];
          unit.transportGensets = [genset];
          unit.transportTrailers = [trailer];

          [driver, vehicle, trailer, genset].forEach((element) => {
            if (element) {
              element.transportUnitId = unit.id;
            }
          });

          const existingUnitIndex = this.units.findIndex(
            (u) => u.id === unit.id
          );
          if (existingUnitIndex !== -1) {
            this.units.splice(existingUnitIndex, 1);
          }
          this.units.unshift(unit);
          removeItemIfExists(this.drivers, driver, this.chosenDriver);
          removeItemIfExists(this.vehicles, vehicle, this.chosenVehicle);
          removeItemIfExists(this.trailers, trailer, this.chosenTrailer);
          removeItemIfExists(this.gensets, genset, this.chosenGenset);
        } else if (message.type === "update") {
          const { unit, unitId } = message;
          const { driver, vehicle, trailer, genset } = unit;
          unit.id = unitId;
          unit.transportDrivers = [driver];
          unit.transportVehicles = [vehicle];
          unit.transportGensets = [genset];
          unit.transportTrailers = [trailer];

          [driver, vehicle, trailer, genset].forEach((element) => {
            if (element) {
              element.transportUnitId = unit.id;
            }
          });

          const existingUnitIndex = this.units.findIndex(
            (u) => u.id === unit.id
          );
          if (existingUnitIndex !== -1) {
            const oldUnit = JSON.parse(
              JSON.stringify(this.units[existingUnitIndex])
            );
            this.units.splice(existingUnitIndex, 1, unit);

            // Handle driver, vehicle, trailer, and genset updates
            const handleUpdate = (array, item, oldItem) => {
              if (array.find((x) => x.id === item.id)) {
                const oldIndex = array.findIndex((x) => x.id === item.id);
                array.splice(oldIndex, 1);
                array.unshift(oldItem);
              }
            };

            handleUpdate(this.drivers, driver, oldUnit.driver);
            handleUpdate(this.vehicles, vehicle, oldUnit.vehicle);
            handleUpdate(this.trailers, trailer, oldUnit.trailer);
            handleUpdate(this.gensets, genset, oldUnit.genset);
          } else {
            this.units.unshift(unit);
          }
        } else if (message.type === "delete") {
          const { unit } = message;

          // Handle deletion logic here
          const index = this.units.findIndex((u) => u.id === unit.id);
          if (index !== -1) {
            this.drivers.unshift(...this.units[index].transportDrivers);
            this.vehicles.unshift(...this.units[index].transportVehicles);
            this.trailers.unshift(...this.units[index].transportTrailers);
            this.gensets.unshift(...this.units[index].transportGensets);
            this.units.splice(index, 1);
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    },
    async getData() {
      let result = await this.$API.getAvailableConstituents();
      this.vehicles = result.vehicles;
      this.trailers = result.trailers;
      this.drivers = result.drivers;
      this.gensets = result.gensets;
      this.units = result.units;
      this.trailerUnits = result.trailerUnits;
      this.vehicleTypes = this.vehicles.map((vehicle) => vehicle.type);
      this.trailerTypes = this.trailers.map((x) => x.type);
      if (this.trailerUnits.length > 0) {
        this.trailerUnits.map((x) => {
          x.trailers = x.transportTrailers;
          x.type = "Superlink " + x.trailers[0].type;
        });
        this.trailerTypes = [
          ...this.trailerTypes,
          ...this.trailerUnits.map((x) => {
            return x.type;
          }),
        ];
      }
    },
    async createUnit() {
      this.loadingCreate = true;
      let unit = {
        unit: {
          alias: this.chosenAlias,
          assigned: false,
        },
        driver: this.chosenDriver.length > 0 ? this.chosenDriver[0] : null,
        vehicle: this.chosenVehicle.length > 0 ? this.chosenVehicle[0] : null,
        trailer: this.chosenTrailer.length > 0 ? this.chosenTrailer[0] : null,
        genset: this.chosenGenset.length > 0 ? this.chosenGenset[0] : null,
      };
      let newUnit = await this.$API.createTransportUnit(unit);

      if (newUnit) {
        let { driver, vehicle, trailer, genset } = unit;
        unit.id = newUnit.unitId;
        unit.transportDrivers = [driver];
        unit.transportVehicles = [vehicle];
        unit.transportGensets = [genset];
        unit.transportTrailers = [trailer];
        // Set transportUnitId for each selected element
        [driver, vehicle, trailer, genset].forEach((element) => {
          if (element) {
            element.transportUnitId = unit.id;
          }
        });
        this.units.unshift(unit);
        this.drivers.splice(this.drivers.indexOf(this.chosenDriver[0]), 1);
        this.vehicles.splice(this.vehicles.indexOf(this.chosenVehicle[0]), 1);
        this.trailers.splice(this.trailers.indexOf(this.chosenTrailer[0]), 1);
        this.gensets.splice(this.gensets.indexOf(this.chosenGenset[0]), 1);
        this.chosenDriver = [];
        this.chosenVehicle = [];
        this.chosenTrailer = [];
        this.chosenGenset = [];
        this.loadingCreate = false;
        // console.log(unit);
        this.$message({
          message: "Unit created successfully",
          type: "success",
        });
      } else {
        this.$message({
          message: "Unit creation failed",
          type: "error",
        });
      }
    },
    async updateUnit() {
      this.loadingUpdate = true;
      let unit = {
        unit: {
          id: this.chosenUnit.id,
          alias: this.chosenAlias,
        },
        driver: this.chosenDriver.length > 0 ? this.chosenDriver[0] : null,
        vehicle: this.chosenVehicle.length > 0 ? this.chosenVehicle[0] : null,
        trailer: this.chosenTrailer.length > 0 ? this.chosenTrailer[0] : null,
        genset: this.chosenGenset.length > 0 ? this.chosenGenset[0] : null,
      };
      unit = JSON.parse(JSON.stringify(unit));
      this.chosenUnit.alias = this.chosenAlias;
      this.chosenUnit.driver =
        this.chosenDriver.length > 0 ? this.chosenDriver[0] : null;
      this.chosenUnit.vehicle =
        this.chosenVehicle.length > 0 ? this.chosenVehicle[0] : null;
      this.chosenUnit.trailer =
        this.chosenTrailer.length > 0 ? this.chosenTrailer[0] : null;
      this.chosenUnit.genset =
        this.chosenGenset.length > 0 ? this.chosenGenset[0] : null;
      this.chosenUnit.transportDrivers = [this.chosenUnit.driver];
      this.chosenUnit.transportVehicles = [this.chosenUnit.vehicle];
      this.chosenUnit.transportGensets = [this.chosenUnit.genset];
      this.chosenUnit.transportTrailers = [this.chosenUnit.trailer];
      this.clearUnit();
      let updatedUnit = await this.$API.updateTransportUnit(unit);
      if (updatedUnit) {
        this.$message({
          message: "Unit updated successfully",
          type: "success",
        });
      } else {
        this.$message({
          message: "Unit update failed",
          type: "error",
        });
      }
      this.loadingUpdate = false;
    },
    async deleteUnit() {
      this.areYouSure = false;
      this.loadingDelete = true;
      let deletedUnit = await this.$API.deleteTransportUnit(this.chosenUnit.id);
      this.loadingDelete = false;
      if (this.chosenUnit.driver) {
        this.chosenUnit.driver.transportUnitId = null;
        this.drivers.unshift(this.chosenUnit.driver);
      }
      if (this.chosenUnit.vehicle) {
        this.chosenUnit.vehicle.transportUnitId = null;
        this.vehicles.unshift(this.chosenUnit.vehicle);
      }
      if (this.chosenUnit.trailer) {
        this.chosenUnit.trailer.transportUnitId = null;
        this.trailers.unshift(this.chosenUnit.trailer);
      }
      if (this.chosenUnit.genset) {
        this.chosenUnit.genset.transportUnitId = null;
        this.gensets.unshift(this.chosenUnit.genset);
      }
      this.units.splice(this.units.indexOf(this.chosenUnit), 1);
      this.clearUnit();
      if (deletedUnit) {
        this.$message({
          message: "Unit deleted successfully",
          type: "success",
        });
      } else {
        this.$message({
          message: "Unit deletion failed",
          type: "error",
        });
      }
    },
    viewLink(trailer) {
      this.linkedCard = true;
      this.viewingLink = trailer;
    },
    editLink(trailer) {
      this.trailerLinks = true;
      this.editingLink = true;
      this.viewingLink = JSON.parse(JSON.stringify(trailer));
      this.linkedTrailers = JSON.parse(JSON.stringify(trailer.trailers));
    },
    createLinkedTrailers() {
      this.trailerLinks = true;
    },
    editUnit(unit) {
      this.changeUnit = true;
      this.chosenUnit = unit;
      this.chosenUnit.assigned ? (this.snackbar = true, this.assignedDisable = true) : (this.snackbar = false, this.assignedDisable = false);
      this.chosenDriver = unit.driver ? [unit.driver] : [];
      this.chosenVehicle = unit.vehicle ? [unit.vehicle] : [];
      this.chosenGenset = unit.genset ? [unit.genset] : [];
      this.chosenTrailer = unit.trailers[0] ? unit.trailers : [];
    },
    clearUnit() {
      this.chosenUnit = [];
      this.chosenDriver = [];
      this.chosenVehicle = [];
      this.chosenGenset = [];
      this.chosenTrailer = [];
      this.changeUnit = false;
    },
    async createLink() {
      this.loadingLink = true;
      let trailers = this.linkedTrailers;
      let trailerIds = this.linkedTrailers.map((trailer) => trailer.id);
      let alias = this.chosenTrailerAlias;
      if (!alias) {
        alias =
          trailers[0].registration +
          "/" +
          trailers[1].registration +
          (trailers.length > 2 ? "/" + trailers[2].registration : "") +
          (trailers.length > 3 ? "+" : "");
      }
      let body = {
        trailerIds: trailerIds,
        alias: alias,
      };
      let result = await this.$API.createTrailerLink(body);
      if (result) {
        this.trailers.forEach((x) => {
          if (trailerIds.includes(x.id)) {
            x.transportTrailerUnitId = result.id;
          }
        });
        console.log(this.trailers);
        console.log(trailerIds);
        this.trailerUnits.unshift({
          alias: alias,
          id: result.id,
          type: "Superlink " + trailers[0].type,
          trailers: trailers,
          transportTrailers: trailers,
        });
      }
      this.linkedTrailers = [];
      this.chosenTrailerAlias = null;
      this.loadingLink = false;
    },
    async updateLink() {
      this.loadingLink = true;
      let trailers = this.linkedTrailers;
      let trailerIds = this.linkedTrailers.map((trailer) => trailer.id);
      let alias = this.chosenTrailerAlias;
      if (!alias) {
        alias =
          trailers[0].registration +
          "/" +
          trailers[1].registration +
          (trailers.length > 2 ? "/" + trailers[2].registration : "") +
          (trailers.length > 3 ? "+" : "");
      }
      let body = {
        oldTrailerIds: this.viewingLink.trailers.map((trailer) => trailer.id),
        trailerIds: trailerIds,
        alias: alias,
        transportTrailerUnitId: this.viewingLink.id,
      };
      let result = await this.$API.updateTrailerLink(body);
      if (result) {
        this.trailers.forEach((x) => {
          if (trailerIds.includes(x.id)) {
            x.transportTrailerUnitId = result.id;
          }
        });
        this.trailerUnits.forEach((x) => {
          if (x.id === result.id) {
            x.alias = alias;
            x.trailers = trailers;
            x.transportTrailers = trailers;
          }
        });
      }
      this.linkedTrailers = [];
      this.chosenTrailerAlias = null;
      this.loadingLink = false;
    },
    remove(data, type) {
      switch (type) {
        case "driver":
          this.chosenDriver = [];
          this.driverKey++;
          break;
        case "genset":
          this.chosenGenset = [];
          this.gensetKey++;
          break;
        case "vehicle":
          this.chosenVehicle = [];
          this.staticFilterV = false;
          this.vehicleFilterChosen = null;
          this.vehicleKey++;
          break;
        case "trailer":
          this.chosenTrailer = [];
          this.staticFilterT = false;
          this.trailerFilterChosen = null;
          this.trailerKey++;
          break;
        case "extras":
          this.linkedTrailers.splice(data, 1);
          this.staticFilterT = false;
          this.trailerFilterChosen = null;
          this.trailerKey++;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.tile {
  border-radius: 6px !important;
  background-color: var(--v-greyRaised-base) !important;
}

.transparent {
  background-color: transparent !important;
}

.v-card--revealDriver {
  bottom: 0;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.lists {
  height: calc(71vh - 64px);
  overflow-y: scroll;
  padding-top: 0;
}

.listCard {
  position: relative;
  contain: content;
  height: 71vh;
}

.listDropZone {
  height: 58vh;
  width: inherit;
  position: relative;
}

.itemSlot {
  height: 9vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  content: contain;
}
.listContainer div div.v-list-item {
  height: 100%;
  width: 100%;
}
.trailerSlots {
  height: 100%;
  width: 100%;
  padding: 0;
}
.trailerSlots ::v-deep div.extraTrailer.v-list-item {
  height: 100%;
  width: 100%;
}
.hideDiv {
  display: none;
}
.showDiv {
  height: 100%;
}

.listContainer {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  contain: content;
}

.trailerSlotAdded {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10.5vh;
  width: 100%;
  position: relative;
  margin: 2vh 0 2vh 0;
}

.dropZoneTrailers {
  height: 12vh;
  width: 21vw;
  position: relative;
}

.dropZone {
  height: 100%;
  width: 100%;
  position: relative;
}

.centerIcon {
  position: absolute;
  pointer-events: none;
  font-size: 60px;
  color: var(--v-primaryText-base);
  opacity: 0.1;
}

.toolbars {
  padding: 0;
}
.trailerAdd {
  margin: 1vh 0 0vh 0;
}
.subheaders {
  padding: 0;
}

.rightCard {
  height: 85vh;
  contain: content;
}

.unitsList {
  height: 79vh;
  overflow-y: scroll;
}

.filterSelect {
  width: 10vw;
}
.searchPopOut {
  width: 10vw;
}

.scroll {
  overflow-y: scroll;
  height: inherit;
}

.ghost-other {
  opacity: 0.5;
  background: #f7fafc;
  border: 2px solid #ff6666;
  border-radius: 5px;
  height: inherit;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 2px solid #ff6666;
  border-radius: 5px;
  height: auto;
}
</style>

<template>
  <div>
    <v-card style="width: 90vw;background-color: #2f2f2f !important;" :loading="savingData">
      <v-toolbar dense flat>
        <v-toolbar-title class="white--text">Assign Containers to Transporter</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mx-1" small :disabled="chosenContractor == null" color="tertiary"
                  @click="dispatchContainers('Send')">Dispatch</v-btn> -->
        <!-- <v-btn class="mx-1" small color="orange"
          @click="dispatchContainers('Pre-Allocated', 'PRE_ALLOCATED')"><v-icon>save</v-icon> Save as
          PRE-ALLOCATED</v-btn> -->
        <v-btn class="mx-1" small color="tertiary"
          @click="dispatchContainers('Allocated', 'ALLOCATED')"><v-icon>save</v-icon> Save as ALLOCATED</v-btn>

        <v-btn icon @click="$emit('close')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 75vh; overflow-y: auto;">
        <v-row style="height: inherit;">
          <v-col cols="12" sm="5">
            <v-card width="100%" style="contain: content; overflow-y: hidden;">
              <v-subheader style="font-size: 16px">
                <v-img src="../../../../public/icons/container.svg" max-height="24px" max-width="24px"
                  class="mr-2"></v-img>
                Containers
              </v-subheader>
              <v-divider></v-divider>
              <div style="overflow-y: auto; height: 95%; width: 100%; ">
                <v-list nav dense :key="chosenContainerKey">
                  <v-list-item :key="container.id" three-line outlined color="green" v-for="container in chosenContainers">
                    <v-card flat
                      style="background-color: var(--v-greyBase-lighten1) !important; height: auto;width: 100%;padding: 0 0 3.5vh 0;margin: 0.5vh 0 0.5vh;contain: content;border-radius: 10px;">
                      <v-toolbar color="component" dense flat height="30px">
                        <v-toolbar-title style="font-size: 12px">
                          {{ container.ctoNo }}
                          <v-tooltip top v-if="container.booking.shipper">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="container.booking.shipper" small class="primaryText--text ml-2" v-on="on"
                                outlined style="border: none">
                                <v-avatar size="18"
                                  :color="container.booking.shipper && container.booking.shipper.logo ? 'white' : 'secondary'"
                                  left>
                                  <v-img v-if="container.booking.shipper.logo" :src="container.booking.shipper.logo"
                                    contain></v-img>
                                  <h3 v-else style="color: white">{{
                                    container.shipperName.charAt(0) }}
                                  </h3>

                                </v-avatar> {{ container.shipperName }}
                              </v-chip>
                            </template>
                            <span>{{ container.booking.shipper.name }}</span>
                          </v-tooltip>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        {{ container.containerTypeDescription }}

                        <v-tooltip top v-if="container.temporaryProducts.find(product=>product.product==='Genset')">
                          <template v-slot:activator="{ on }">
                            <v-chip :color="container.temporaryProducts.find(product=>product.product==='Genset') ? '#c4c166' : ''" v-on="on" x-small class="mx-1">
                              <v-icon small>bolt</v-icon>
                            </v-chip>
                          </template>
                          <span>Genset</span>
                        </v-tooltip>

                        <v-tooltip top v-if="container.caProduct">
                          <template v-slot:activator="{ on }">
                            <v-chip :color="container.caProduct ? '#2383de' : ''" v-on="on" x-small class="mx-1">
                              <v-icon small>ac_unit</v-icon>
                            </v-chip>
                          </template>
                          <span>Controlled Atmoshphere: {{ container.caProduct }}</span>
                        </v-tooltip>

                        <v-tooltip top v-if="container.temporaryProducts.find(product=>product.product==='TelemPlus')">
                          <template v-slot:activator="{ on }">
                            <v-chip :color="container.temporaryProducts.find(product => product.product === 'TelemPlus' )? 'green' : ''" v-on="on" x-small class="mx-1">
                              <v-icon :left="container.sensorDeviceType != null" small>router</v-icon>
                              <span style="font-size: 8px;" v-if="container.sensorDeviceType">{{
                                container.sensorDeviceType }}</span>
                            </v-chip>
                          </template>
                          <span>{{ container.sensorService ? container.sensorService : 'TempCheck' }}</span>
                        </v-tooltip>
                      </v-toolbar>

                      <v-row class="mx-0 mt-1">
                        <v-col cols="12" sm="4" align-self="center" class="pt-0 pb-0" style="line-height: 1">
                            <b style="font-size: 10px;">
                              Loading Date:
                            </b>
                            <span v-if="container.LOADING_POINT_1_DATE" style="font-size: 10px;">
                              {{ formatDate(container.LOADING_POINT_1_DATE).shorterDate }}
                            </span>
                          </v-col>
                          <v-col cols="12" sm="4" align-self="center" class="pt-0 pb-0" style="line-height: 1">
                            <b style="font-size: 10px;">
                              Products:
                            </b>
                            <span v-if="container.containerProducts.length > 0" style="font-size: 10px;">
                              {{ container.containerProducts.map(x=>x.product.name).join(', ') }}
                            </span>
                            <span v-else style="font-size: 10px;">
                             No Products
                            </span>
                          </v-col>
                        
                          <!-- VESSEL NAME -->
                          <v-col cols="12" sm="4" lg="4" align-self="center" class="d-flex justify-end pt-0 pb-0">
                            <span v-if="container.vesselVoyage"
                              style="margin-right:0.3vw;font-size: x-small;">
                              <v-icon style="margin-bottom:0.3vh" small>directions_boat_filled</v-icon>
                              <b style="line-height: 1.1rem">
                                {{ container.vesselVoyage }}
                              </b>
                            </span>
                          </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                          <b style="font-size: 10px;">Empty</b><br>
                          <v-chip class="my-0 py-0" color="modal" x-small style="font-size: 10px;" v-if="container.EMPTY_PICKUP">{{ container.EMPTY_PICKUP.poi.name }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                          <b style="font-size: 10px;">Loading Points</b> <br>
                          <v-chip class="my-0 py-0" color="modal" x-small style="font-size: 10px;" pill v-if="container.LOADING_POINT_1">
                            1.
                            {{ container.LOADING_POINT_1.poi.name }}</v-chip>
                            <v-chip class="my-0 py-0" color="modal" x-small style="font-size: 10px;" pill v-if="container.LOADING_POINT_2">
                            2.
                            {{ container.LOADING_POINT_2.poi.name }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                          <b style="font-size: 10px;">Drop Off</b><br>
                          <v-chip class="my-0 py-0" color="modal" x-small style="font-size: 10px;" v-if="container.FULL_DROP_OFF">{{ container.FULL_DROP_OFF.poi.name }}</v-chip>
                        </v-col>
                      </v-row>
                      </v-card>

                    
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>
          <!-- TIMELINE LOCATIONS && DOCUMENTS-->
          <v-col cols="12" sm="3">
            <!-- TIMELINE LOCATIONS -->
            <v-card max-height="42.5vh" width="100%" class="mb-4" style="overflow-y: auto">
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary">
                  map
                </v-icon>
                Locations
              </v-subheader>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item v-for="location in chosenBookingLocations" :key="location.id">
                  <v-list-item-action>
                    <v-icon v-if="location.type == 'Unmapped'" color="orange">warning</v-icon>
                    <v-icon v-else color="grey">location_on</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ location.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="location.type != 'Unmapped'" style="font-size: 12px">
                      {{ location.subdivision }}, {{ location.country }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="location.type != 'Unmapped'" style="font-size: 12px">
                      {{ location.functionality }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="location.type == 'Unmapped'" style="font-size: 12px">
                      This {{ location.point }}
                      has not been attached to a POI
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn  color="secondary" icon small elevation="0" v-if="location.type != 'Unmapped'"
                      @click="viewThisPoi = location, viewPoi = true"><v-icon small>launch</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <!-- <div style="overflow-y: auto; height: calc(42.5vh - 48px); width: 100%;">
                <v-timeline style="margin-left: -1vw;  height: auto" class="pt-3" dense>
                  <v-timeline-item fill-dot :color="location.type == 'Unmapped' ? 'orange' : 'primary'"
                    :icon="location.type == 'Unmapped' ? 'error' : 'room'" v-for="location in chosenBookingLocations"
                    :key="location.id">
                    <v-card color="greyBase" height="7vh"
                      style="background-color: var(--v-greyBase-base) !important; contain: content; margin: 0 0 0 -1vw"
                      class="pl-3 pt-1" flat>
                      <span style="opacity: 0.7; display: block;">{{ location.name }}</span>
                      <span v-if="location.type != 'Unmapped'"
                        style="opacity: 0.7; display: block; font-size: 11px; line-height: 1rem">{{ location.subdivision }},
                        {{ location.country }}</span>
                      <span v-if="location.type != 'Unmapped'"
                        style="opacity: 0.7; display: block; font-size: 11px; line-height: 1rem">{{ location.functionality }}</span>
                      <span v-if="location.type == 'Unmapped'"
                        style="opacity: 0.7; display: block; font-size: 11px; line-height: 1rem">This {{ location.point }}
                        has not been attached to a POI</span>
                      <div style="position: absolute; right: 10px; bottom: 8px">
                        <v-btn style="opacity: 0.8;" color="primary" x-small elevation="0" v-if="location.type == 'Unmapped'" @click="viewThisPoi = location, addPoi = true">Add POI</v-btn>   
                        <v-btn style="opacity: 0.8;" color="primary" x-small elevation="0"
                          v-if="location.type != 'Unmapped'" @click="viewThisPoi = location, viewPoi = true">View</v-btn>
                      </div>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </div> -->
            </v-card>
            <!-- DOCUMENTS -->
            <v-card height="35%" width="100%" style="contain: content;">
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary">
                  description
                </v-icon>
                Documents
              </v-subheader>
              <v-divider></v-divider>
              <v-progress-linear indeterminate v-if="loadingDocs"></v-progress-linear>
              <div style="max-height: calc(29vh - 48px); overflow-y: auto;">
                <v-list dense>
                  <v-list-item v-for="(doc, index) in documents" :key="index" @click="viewDocument(doc)">
                    <v-img class="ml-1 mr-2" style="max-height: 20px; max-width: 20px" :src="getIcon(doc.name)" />
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ doc.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="documents.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No documents</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>
          <!-- CONTRACTORS && DETAILS -->
          <v-col cols="12" sm="4">
            <v-card height="180px" width="100%" class="mb-4">
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary">
                  local_shipping
                </v-icon>
                Contractors
              </v-subheader>
              <v-divider></v-divider>
              <v-card-text class="mt-0 pt-0">
                <v-select :items="contractors" item-text="name" rounded
                  style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw;" hide-details outlined return-object
                  v-model="chosenContractor" clearable dense>
                  <template v-slot:selection="data">
                    <v-avatar size="42" class="mr-2" v-if="data.item">
                      <v-img v-if="data.item.icon" contain :src="data.item.icon"></v-img>
                    </v-avatar>
                    <span v-if="data.item">
                      {{ data.item.name }}
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-chip-group column class="mb-2">
                  <div>
                    <v-chip small><v-icon
                        small left>bolt</v-icon>Gensets</v-chip>
                    <v-chip small><v-icon
                        small left>ac_unit</v-icon>Cold Trucks</v-chip>
                    <v-chip small><v-icon
                        small left>monitor_heart</v-icon>TempTales</v-chip>
                    <v-chip small><v-icon
                        small left>moving</v-icon>Long Haul</v-chip>
                    <v-chip small><v-icon
                        small
                        left>local_shipping</v-icon>Tautliners</v-chip>
                    <v-chip small><v-icon
                        small left>local_shipping</v-icon>FlatBeds</v-chip>
                    <v-chip small><v-icon
                        small left>local_shipping</v-icon>Skeletons</v-chip>
                    <v-chip small><v-icon
                        small left>local_shipping</v-icon>Special</v-chip>
                  </div>
                </v-chip-group>
              </v-card-text>

            </v-card>
            <v-card height="350px" width="100%">
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary">
                  segment
                </v-icon>
                Details
              </v-subheader>
              <v-divider></v-divider>
              <v-row>
                <v-col :class="!$vuetify.breakpoint.mobile ? '' : 'pt-3 px-4'">
                  <v-textarea v-model="dispatchNotes" rows="3" outlined hide-details no-resize placeholder="Add Notes..."
                    persistent-placeholder
                    style="background-color: #2f2f2f ; border-radius: 16px; margin: 1vh 1vw 0.5vh 1vw;">
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="!$vuetify.breakpoint.mobile" no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 px-4">
                  <v-subheader class="pb-0 pl-0"><v-icon color="tertiary" small class="mr-1">monitor_heart</v-icon>Temp
                    Devices</v-subheader>
                  <v-chip-group v-if="tempDevices" column>
                    <v-chip small class="mt-0 mb-0" :key="index" v-for="(device, index) in tempDevices">{{ device }}</v-chip>
                  </v-chip-group>
                  <v-subheader class="pb-0 pl-0"><v-icon :color="'#c4c166'" small
                      class="mr-1">offline_bolt</v-icon>Gensets Required</v-subheader>
                  <v-chip small v-if="gensetsCount == 0"><i>No Gensets Required</i></v-chip>
                  <v-chip small v-if="gensetsCount > 1"><i>{{ gensetsCount }} Gensets Required</i></v-chip>
                  <v-chip small v-if="gensetsCount == 1"><i>1 Genset Required</i></v-chip>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 px-4">
                  <v-subheader class="pb-0 pl-0"><v-icon small
                      class="mr-1">calendar_view_week</v-icon>Containers</v-subheader>
                  <v-chip small>{{ chosenContainers.length }} Containers</v-chip>
                </v-col>
              </v-row>

              <v-row no-gutters class="pt-2" v-else>
                <v-row no-gutters style="width: 100%" class="pb-2">
                  <v-col cols="6" sm="6" md="6" class="d-flex justify-center">
                    <span style="font-size:small" class="pb-0 pl-0"><v-icon color="tertiary" small
                        class="mr-1">monitor_heart</v-icon>Temp Devices</span>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="d-flex justify-center">
                    <span style="font-size:small"><v-icon :color="'#c4c166'" small
                        class="mr-1">offline_bolt</v-icon>Gensets</span>
                  </v-col>
                </v-row>
                <v-row no-gutters style="width: 100%">
                  <v-col cols="6" sm="6" md="6" class="d-flex justify-center">
                    <v-chip-group v-if="tempDevices" column>
                      <v-chip small :key="index" v-for="(device, index) in tempDevices">{{ device }}</v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="d-flex justify-center">
                    <v-chip-group column>
                      <v-chip small v-if="gensetsCount == 0"><i>No Gensets Required</i></v-chip>
                      <v-chip small v-if="gensetsCount > 1"><i>{{ gensetsCount }} Gensets Required</i></v-chip>
                      <v-chip small v-if="gensetsCount == 1"><i>1 Genset Required</i></v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-row>
              <!-- <div style="position: absolute; bottom: 1vh;" class="text-center">
      
              </div> -->
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="viewPoi" width="80vw">
      <v-card v-if="viewPoi" style="width: auto;height: calc(80vh + 48px);">
        <v-toolbar dense flat>
          <v-card-title>
            {{ viewThisPoi.name }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="viewPoi = false, viewThisPoi = null"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <div style="height: 80vh; width:auto">
          <ViewPOI :POItoView="viewThisPoi" />
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="previewItem && previewItem.id">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(previewItem)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="previewModal = false, previewItem = {}">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{ 'max-height': fullscreen ? '90vh' : '75vh', 'overflow-y': 'auto' }">
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="previewItem.type && previewItem.type.includes('image/')">
                <v-img :src="previewItem.url" contain style="max-height: 70vh" @load="imageLoading = false">
                </v-img>
              </div>
              <div v-else-if="previewItem.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="8" class="text-center" v-if="previewItem.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 700" :source="previewItem.url"
                        @loaded="imageLoading = false" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span> <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import pdf from "vue-pdf";
import dateFormat from 'dateformat';
import ViewPOI from "../../POI/ViewPoi.vue"
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    // pdf,
    ViewPOI,
    VuePdfEmbed
  },
  props: [
    'chosenContainers'
  ],
  data: () => ({
    contractors: [],
    chosenContractor: null,
    selectedDocument: null,
    documentList: [],
    fullscreen: false,
    chosenContainerKey: 0,
    scale: 1,
    viewPDF: false,
    chosenBookingLocations: [],
    imageLoading: false,
    loadingDocs: false,
    viewThisPoi: null,
    viewPoi: false,
    loadingContractors: false,
    sendLoading: false,
    saveLoading: false,
    savingData: false,
    loadingPois: true,
    pdfHover: false,
    previewModal: false,
    previewItem: {},
    dispatchNotes: '',
  }),
  watch: {
    documents: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.getDocumentList()
        }
      },
    },
    locationIds: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.getPois()
        }
      }
    }
  },
  mounted() {
    console.log('this.choosenContainers', this.chosenContainers)
    this.getContractors();
  },
  computed: {
    locationIds() {
      let data = [].concat.apply([], this.chosenContainers.map(x => [x.emptyPickupPoiId, x.firstLoadingPointPoiId, x.secondLoadingPointPoiId, x.dropOffPointPoiId])).filter(Boolean)
      return [...new Set(data)];
    },
    tempDevices() {
      let result = [];
      if (this.chosenContainers.length > 0) {
        result = [...new Set(this.chosenContainers.map(item => item.sensorDeviceType))].filter(Boolean);
      }
      return result
    },
    gensetsCount() {
      let count = 0;
      if (this.chosenContainers.length > 0) {
        this.chosenContainers.forEach(item => {
          if (item.gensetRequired) {
            count++
          }
        })
        return count
      } else {
        return count
      }
    },
    documents() {
      let result = [];
      if (this.chosenContainers.length > 0) {
        result = this.chosenContainers.filter(item => item.ctoDocument).map(item => item.ctoDocument);
      }
      return result
    },
  },
  methods: {
    zoomPdf(event) {
      if (event.ctrlKey) {
        event.preventDefault();
        const newScale = event.deltaY > 0 ? this.scale - 0.1 : this.scale + 0.1;
        if (newScale >= 1 && newScale <= 3) {
          this.scale = newScale;
        }
      }

    },
    sortLocations() {
      const locationsMap = new Map();
      const unmapped = new Map()
      this.chosenBookingLocations = [];
      this.chosenContainers.forEach((container, index) => {
        // const containerItem = container.containerItem;
        if (container.emptyPickupPoi) {
          locationsMap.set(
            container.emptyPickupPoi.id,
            {
              ...container.emptyPickupPoi,
              type: 'emptyLoadingPointPoi'
            }
          );
        } else if (!container.emptyPickupPoi && container.emptyPickup) {
          unmapped.set(
            container.emptyPickupPoiId,
            {
              id: container.emptyPickupPoiId,
              name: container.emptyPickup,
              type: 'Unmapped',
              point: 'Empty Pickup Location',
              tag: 'emptyPickup',
              idTag: 'emptyPickupPoiId'
            }
          )
        }

        if (container.firstLoadingPointPoi) {
          locationsMap.set(
            container.firstLoadingPointPoi.id,
            {
              ...container.firstLoadingPointPoi,
              type: 'firstLoadingPointPoi'
            }
          );
        } else if (!container.firstLoadingPointPoi && container.firstLoadingPoint) {
          unmapped.set(
            container.firstLoadingPoint,
            {
              name: container.firstLoadingPoint,
              type: 'Unmapped',
              point: 'Loading Point',
              tag: 'firstLoadingPoint',
              idTag: 'firstLoadingPointPoiId'
            }
          )
        }

        if (container.secondLoadingPointPoi) {
          locationsMap.set(
            container.secondLoadingPointPoi.id,
            {
              ...container.secondLoadingPointPoi,
              type: 'secondLoadingPointPoi'
            }
          );
        } else if (!container.secondLoadingPointPoi && container.secondLoadingPoint) {
          unmapped.set(
            container.secondLoadingPoint,
            {
              name: container.secondLoadingPoint,
              type: 'Unmapped',
              point: 'Second Loading Point',
              tag: 'secondLoadingPoint',
              idTag: 'secondLoadingPointPoiId'
            }
          )
        }

        if (container.dropOffPointPoi) {
          locationsMap.set(
            container.dropOffPointPoi.id,
            {
              ...container.dropOffPointPoi,
              type: 'dropOffPointPoi'
            }
          );
        } else if (!container.dropOffPointPoi && (container.dropOffPoint || container.booking.portOfLoadValue)) {
          unmapped.set(
            container.dropOffPoint,
            {
              name: container.dropOffPoint ? container.dropOffPoint : container.booking.portOfLoadValue ? container.booking.portOfLoadValue : 'No DropOff Specified',
              type: 'Unmapped',
              point: 'Drop Off Location',
              tag: 'dropOffPoint',
              idTag: 'dropOffPointPoiId'
            }
          )
        }
      });
      const uniqueLocations = Array.from(locationsMap.values());
      const unMappedValues = new Set(Array.from(unmapped.values()))
      let uniques = [...uniqueLocations, ...unMappedValues]
      // console.log('locations', uniques);
      this.chosenBookingLocations = uniques
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async dispatchContainers(status, statusCode) {
      // [dispatch, status, containerIds]
      let containerIds = this.chosenContainers.map(item => item.id)
      let dispatch = {
        notes: this.dispatchNotes,
        dispatchDate: new Date().toISOString(),
        status: status,
        sent: statusCode == 'ALLOCATED' ? true : false,
        saved: statusCode == 'PRE_ALLOCATED' ? true : false,
        sentByUserId: this.$store.state.user.id,
        contractorId: this.chosenContractor ? this.chosenContractor.id : null,
      }
      this.savingData = true
      let result = await this.$API.dispatchContainers({
        containerIds,
        dispatch,
        transportStatus: status,
        transportStatusCode: statusCode,
        transporterId: this.chosenContractor ? this.chosenContractor.id : null
      })
      this.savingData = false
      this.chosenContainers.forEach(x => {
        x.transportStatus = status
        x.transportStatusCode = statusCode
        x.transporterId = this.chosenContractor ? this.chosenContractor.id : null
        x.transporter = this.chosenContractor
      })
      // this.saveLoading = selection == 'Saved' ? true : false
      // this.sendLoading = selection == 'Send' ? true : false



      // status = selection == 'Send' ? 'ASSIGNED' : 'PENDING'

      // let body = {
      //   containerIds,
      //   dispatch,
      //   status
      // }
      // // console.log(body)
      // let result = await this.$API.dispatchContainers(body)
      // this.saveLoading = false
      // this.sendLoading = false
      if (result) {
        this.$message({
          message: `The Chosen Containers Have Successfully Been Dispatched!`,
          type: 'success'
        })
        this.$emit('update', this.chosenContainers)
        this.chosenContainers = []
        this.chosenContractor = null
        this.contractorCard = false
      } else {
        this.$message({
          message: `There Was An Error Dispatching the Chosen Containers. Please Try Again. \n If the problem persists, please contact support.`,
          type: 'error'
        })
      }
    },
    async getPois() {
      if (this.locationIds.length > 0) {
        let obj = {
          poiIds: this.locationIds
        }
        let result = await this.$API.getContainerPois(obj)
        if (result) {
          console.log(result)
          result.forEach(poi => {
            this.chosenContainers.forEach(container => {
              if (container.emptyPickupPoiId == poi.id) {
                container.emptyPickupPoi = poi
              }
              if (container.firstLoadingPointPoiId == poi.id) {
                container.firstLoadingPointPoi = poi
              }
              if (container.secondLoadingPointPoiId == poi.id) {
                container.secondLoadingPointPoi = poi
              }
              if (container.dropOffPointPoiId == poi.id) {
                container.dropOffPointPoi = poi
              }
            })
          })
          console.log(JSON.parse(JSON.stringify(this.chosenContainers)))
          this.sortLocations()
          this.loadingPois = false
        }
      }
    },
    async getContractors() {
      this.loadingContractors = true
      let result = await this.$API.getContractors()
      if (result) {
        this.contractors = result
        this.loadingContractors = false
      }
    },
    viewDoc(doc) {
      this.selectedDocument = doc
      this.viewPDF = true
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    async getDocumentList() {
      this.loadingDocs = true
      if (this.documents.length > 0) {
        let obj = {
          docIds: this.documents
        }
        let result = await this.$API.getContainerCTOs(obj)
        if (result) {
          this.documentList = result
          this.loadingDocs = false
        }
      }
    },

    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      let dayFullDate = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
        dayFullDate = dateFormat(new Date(date), "ddd, mmmm dS, yyyy");
        fullDate = dateFormat(new Date(date), "mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dd-mm-yyyy @ HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
        time = dateFormat(new Date(date), "HH:MM");
      }
      return {
        isoFormat,
        fullDate,
        dateTime,
        dayOfWeek,
        shorterDate,
        time,
        dayFullDate,
      };
    },
    async viewDocument(document) {
      this.previewItem = document
      this.previewModal = true
    },
  }
}

</script>

<style scoped>
.zoomable-content {
  transform-origin: top left;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  height: 100%;
}

.smallContainer {
  height: 1.5vh;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  margin: 0 0 2px;
  line-height: 0.8;
  text-align: center;
}

.smallContainerMobile {
  height: auto;
  width: auto;
  padding: 0 10px 0;
  border-radius: 8px;
  margin: 0 0 2px;
}

.bottomInfoSelected {
  position: absolute;
  height: 2.5vh;
  width: 44vw;
  margin: 0 0 0 0.6vw;
  padding: -0.2vh 0 0 0;
  bottom: 0;
}

.cardCols {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;

}

.cardColsMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

}
</style>
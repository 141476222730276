<template>
  <v-card style="height: calc(96vh - 84px);">
    <v-row justify="end">
        <v-col cols="12" sm="12" md="8" lg="4">
        <el-input
            id="search"
            suffix-icon="el-icon-search"
            v-model="search"
            clearable
            placeholder="Search"
          >
            <template slot="prepend">
              <el-button @click="addTrailers()"
                ><i class="el-icon-plus"></i
              ></el-button>
            </template>
          </el-input>
      </v-col>
    </v-row>
    <v-data-table
        height="77vh"
        id="vehicleTable"
        style="cursor: pointer"
        :search="search"
        :calculate-widths="true"
        :items="trailers.data"
        :single-expand="true"
        :loading="loading"
        :headers="headers"
        @click:row="showTrailer"
        :options="{
          page: params.offset + 1,
          itemsPerPage: params.limit,
          pageStart: params.offset + 1,
          pageStop: Math.round(trailers.total / this.params.limit),
          pageCount: Math.round(trailers.total / this.params.limit),
          itemsLength: trailers.total
        }"
      />

      <v-dialog v-model="addTrailerCard" width="60vw">
        <v-card height="auto">
          <v-toolbar flat dense>
            <v-toolbar-title>Add Trailer</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingTrailerSave" :disabled="cannotSaveYet" icon @click="addNewTrailer()">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="addTrailerCard = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
              <div :key="avatarKey" style="height: 24vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-avatar  size="200" class="avatar-uploader" v-if="!trailerPhoto" @click="openFileExplorer('imageUpload')">
                  <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                </v-avatar>
                <v-avatar size="200" class="avatar-uploaded" v-if="trailerPhoto" @click="openFileExplorer('imageUpload')">
                  <v-img :src="trailerPhoto"></v-img>
                </v-avatar>
                <input
                    id="imageUpload"
                    type="file"
                    hidden
                    accept="image/jpeg, image/png"
                    @change="onFileChanged($event, 'imageUpload')"
                  />
                  <span class="mt-5">Required Fields</span>
              </div>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field dense outlined hide-details class="mb-4" label="Registration" persistent-placeholder placeholder="Enter Registration" v-model="addTrailer.registration"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Make" persistent-placeholder placeholder="Enter Make" v-model="addTrailer.make"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Load Capacity" persistent-placeholder placeholder="Enter Load Capacity" v-model="addTrailer.loadCapacity"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-select dense outlined hide-details :items="['Skeletal', 'Tri-Axle Tautliner', 'Superlink Tautliner', 'Tri-Axle Flatdeck']" class="mb-4" label="Type" persistent-placeholder placeholder="Select Type" v-model="addTrailer.type"></v-select>
                  <v-text-field dense outlined hide-details class="mb-4" label="Model" persistent-placeholder placeholder="Enter Model" v-model="addTrailer.model"></v-text-field>
                  <v-text-field dense outlined hide-details class="mb-4" label="Length" persistent-placeholder placeholder="Enter Length" v-model="addTrailer.trailerLength"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="px-2">
                  <v-text-field dense outlined hide-details class="mb-4" label="Vin" persistent-placeholder placeholder="Enter Vin" v-model="addTrailer.vin"></v-text-field>
                </v-col>
              </v-row>
              </v-col>
              <v-col cols="12" sm="6" >
                <span style="text-align: center; display: block; contain: content;">
                  Documents
                  <v-btn x-small style="position: absolute; right: 0; top: 0" icon v-if="documents.length > 0" @click="openFileExplorer('trailerDocumentUpload')">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </span>
                    <v-divider></v-divider>
                  <div v-if="documents.length == 0" style="height: 19.5vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                    <v-btn @click="openFileExplorer('trailerDocumentUpload')" icon class="docAdd" v-if="documents.length == 0"><v-icon>post_add</v-icon></v-btn>
                  </div>
                  <input
                      id="trailerDocumentUpload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                      @change="fileSubjectSelect($event, 'trailerDocumentUpload')"
                    />
                  <div style="height: 19.5vh; contain: content; overflow-y: scroll;" v-if="documents.length > 0">
                    <v-list width="100%" dense style="column-count: 2;" v-if="documents.length > 0" :key="docuKey">
                      <v-list-item v-for="(document, index) in documents" :key="index">
                        <v-list-item-icon>
                          <v-img
                                class="ml-2"
                                style="height: 28px; width: 30px"
                                :src="getIcon(document.name)"
                              />
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{ document.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ document.subject ? document.subject : '' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                      </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                <span style="display: block; text-align: center;">
                  Dates
                </span>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- LICENSE DATE -->
                    <v-menu v-model="licenseMenu" ref="license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="License Expiry" persistent-placeholder placeholder="Enter License Expiry" v-model="addTrailer.trailerLicenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('license_menu', 'trailerLicenseExpiry')" v-model="addTrailer.trailerLicenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- PASSPORT DATE -->
                    <v-menu v-model="operatorLicenseMenu" ref="operator_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Operator License Expiry" persistent-placeholder placeholder="Enter Operator License Expiry" v-model="addTrailer.operatorLicenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('operator_menu', 'operatorLicenseExpiry')" v-model="addTrailer.operatorLicenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog v-model="trailerCard" width="60vw">
        <v-card height="auto" v-if="trailerProfile">
          <v-toolbar flat dense>
            <v-toolbar-title v-if="trailerProfile">{{trailerProfile.registration}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="!editing" icon @click="editTrailer()"><v-icon>edit</v-icon></v-btn>
            <v-btn v-if="editing" @click="cancelEdit()" icon><v-icon>edit_off</v-icon></v-btn>
            <v-btn v-if="editing" :loading="loadingDelete" icon @click="deleteTrailer()"><v-icon>delete</v-icon></v-btn>
            <v-btn v-if="editing" :loading="loadingTrailerUpdate" :disabled="cannotUpdateYet" icon @click="updateTrailer()">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="trailerCard = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
              <div :key="avatarKey" style="height: 24vh; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <v-avatar  size="200" class="avatar-uploader" v-if="!trailerProfile.trailerPhoto" @click="editing ? openFileExplorer('imageUpdate') : ''">
                  <v-icon class="avatar-uploader-icon">add_photo_alternate</v-icon>
                </v-avatar>
                <v-avatar size="200" class="avatar-uploaded" v-if="trailerProfile.trailerPhoto" @click="editing ? openFileExplorer('imageUpdate') : ''">
                  <v-img :src="updateAvatar ? updateAvatar : trailerProfile.trailerPhoto"></v-img>
                </v-avatar>
                <input
                    id="imageUpdate"
                    type="file"
                    hidden
                    accept="image/jpeg, image/png"
                    @change="onFileChanged($event, 'imageUpdate')"
                  />
                  <span class="mt-5">Required Fields</span>
                </div>
                <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Registration" persistent-placeholder placeholder="Enter Registration " v-model="trailerProfile.registration"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Make" persistent-placeholder placeholder="Enter Make" v-model="trailerProfile.make"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Load Capacity" persistent-placeholder placeholder="Enter Load Capacity" v-model="trailerProfile.loadCapacity"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="px-2 mt-4">
                  <v-select :readonly="!editing" :items="['Skeletal', 'Tri-Axle Tautliner', 'Superlink Tautliner', 'Tri-Axle Flatdeck']" dense outlined hide-details class="mb-4" label="Type" persistent-placeholder placeholder="Select Type " v-model="trailerProfile.type"></v-select>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Model" persistent-placeholder placeholder="Enter Model " v-model="trailerProfile.model"></v-text-field>
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Length" persistent-placeholder placeholder="Enter Length " v-model="trailerProfile.trailerLength"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="px-2">
                  <v-text-field :readonly="!editing" dense outlined hide-details class="mb-4" label="Vin" persistent-placeholder placeholder="Enter Vin" v-model="addTrailer.vin"></v-text-field>
                </v-col>
              </v-row>
              </v-col>
              <v-col cols="12" sm="6" >
                <span style="text-align: center; display: block; contain: content;">
                  Documents
                  <v-btn :disabled="!editing" x-small style="position: absolute; right: 0; top: 0" icon v-if="trailerProfile.transportTrailerDocs && trailerProfile.transportTrailerDocs.length > 0" @click="openFileExplorer('trailerDocumentUpload')">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </span>
                    <v-divider></v-divider>
                    <v-progress-linear indeterminate v-if="loadingDocs"></v-progress-linear>
                  <div v-if="!trailerProfile.transportTrailerDocs || trailerProfile.transportTrailerDocs.length == 0" style="height: 19.5vh; contain: content; overflow-y: scroll; display: flex; justify-content: center; align-items: center; flex-direction: column;" >
                    <v-btn :disabled="!editing" @click="openFileExplorer('trailerDocumentUpload')" icon class="docAdd" v-if="!trailerProfile.transportTrailerDocs || trailerProfile.transportTrailerDocs.length == 0"><v-icon>post_add</v-icon></v-btn>
                  </div>
                  <input
                      id="trailerDocumentUpload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx,.odt , .xml, application/msword, image/jpeg, image/jpg, image/png"
                      @change="fileSubjectSelect($event, 'trailerDocumentUpload')"
                    />
                  <div style="height: 19.5vh; contain: content; overflow-y: scroll;" v-if="trailerProfile.transportTrailerDocs && trailerProfile.transportTrailerDocs.length > 0">
                    <v-list width="100%" dense style="column-count: 2;" v-if="trailerProfile.transportTrailerDocs && trailerProfile.transportTrailerDocs.length > 0" :key="docuKey">
                      <v-list-item v-for="(document, index) in trailerProfile.transportTrailerDocs" :key="index">
                        <v-list-item-icon>
                          <v-img
                                class="ml-2"
                                style="height: 28px; width: 30px"
                                :src="getIcon(document.name)"
                              />
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{ document.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ document.subject ? document.subject : '' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="removeDocument(document)" icon x-small color="primary"><v-icon>close</v-icon></v-btn>
                      </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                <span style="display: block; text-align: center;">
                  Dates
                </span>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- LICENSE DATE -->
                    <v-menu v-model="licenseMenu" ref="license_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="License Expiry" persistent-placeholder placeholder="Enter License Expiry" v-model="trailerProfile.licenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('license_menu', 'licenseExpiry')" v-model="trailerProfile.licenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                    

                  </v-col>
                  <v-col cols="12" sm="6" class="px-2 mt-4">
                    <!-- PASSPORT DATE -->
                    <v-menu v-model="operatorLicenseMenu" ref="operator_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="!editing" :readonly="!editing" v-on="on" v-bind="attrs" dense outlined hide-details class="mb-4" label="Operator License Expiry" persistent-placeholder placeholder="Enter Operator License Expiry" v-model="trailerProfile.operatorLicenseExpiry"/>
                      </template>
                      <v-date-picker @change="saveTheDate('operator_menu', 'operatorLicenseExpiry')" v-model="trailerProfile.operatorLicenseExpiry" :active-picker.sync="activePicker"  min="1980-01-01"/>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="fileDetailsDialog" width="20vw" persistent>
        <v-card height="16vh">
          <v-toolbar flat dense class="d-flex justify-center">
            <v-toolbar-title>File Details</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pb-0 d-flex align-center" style="flex-direction: column;">
          <v-select v-model="docSubject" class="mt-2" label="Select File Subject" outlined hide-details dense :items="['Trailers License', 'Operator License', 'Other']"></v-select>
          <v-btn @click="acceptSubject()" :disabled="!docSubject" color="edit" class="mt-3">Accept</v-btn>
        </v-card-text>
        </v-card>
      </v-dialog>


  </v-card>
</template>

<script>
import dateFormat from "dateformat";

export default {
  data: () => ({
    search: '',
    maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
    loading: false,
    addTrailerCard: false,
    trailerCard: false,
    avatarKey: 0,
    docuKey: 0,
    photoFile: null,
    editing: false,
    startMenu: false,
    loadingTrailerSave: false,
    docSubject: null,
    loadingTrailerUpdate: false,
    loadingDocs: false,
    loadingDelete: false,
    inductionMenu: false,
    licenseMenu: false,
    fileDetailsDialog: false,
    pdpMenu: false,
    visaMenu: false,
    hazChemMenu: false,
    portAccessMenu: false,
    trailerPhoto: null,
    updatePhotoFile: null,
    updateAvatar: null,
    documents: [],
    trailers: [],
    trailerProfile: {
      transportTrailerDocs: [],
    },
    originalTrailer: null,
    storeEvent: null,
    storeRef: null,
    endMenu: false,
    operatorLicenseMenu: false,
    medicalMenu: false,
    activePicker: null,
    addTrailer: {
      transportTrailerDocs: [],
    },
    headers: [
        {
          text: "Type",
          value: "type",
          align: "center",
        },
        {
          text: "Registration",
          value: "registration",
          align: "center",
        },
        {
          text: "Make",
          value: "make",
          align: "center",
        },
        {
          text: "Model",
          value: "model",
          align: "center",
        },
        {
          text: "Load Capacity",
          value: "loadCapacity",
          align: "center",
        },
        {
          text: "Length",
          value: "trailerLength",
          align: "center",
        },
        {
          text: "Wheel Base",
          value: "wheelBase",
          align: "center",
        },
        {
          text: "Tread",
          value: "tread",
          align: "center",
        },
        {
          text: "Grade",
          value: "grade",
          align: "center",
        },
        {
          text: "Weight",
          value: "weight",
          align: "center",
        },
        {
          text: "VIN",
          value: "vin",
          align: "center",
        },
      ],
    params: {
      limit: 12,
      offset: 0,
      search: null,
    }
  }),
  async created() {
    this.trailers = await this.$API.getTrailers(this.params);
  },
  computed: {
    cannotSaveYet() {
      let result = true;
      if(this.addTrailer.registration) {
          result = false;
        }
      return result;
    },
    cannotUpdateYet() {
      let result = true;
      for(let key in this.trailerProfile) {
        if(this.trailerProfile[key] != this.originalTrailer[key]) {
          if(this.trailerProfile.registration != '' && this.trailerProfile.registration != null && this.trailerProfile.registration != ' ') {
            result = false;
          }
        }
      }
      return result;
    },
  },
  watch: {
    licenseMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    operatorLicenseMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
//////////// ADD Trailer //////////// ADD Trailer //////////// ADD Trailer //////////// ADD Trailer //////////// ADD Trailer ////////////
    addTrailers(){
      this.addTrailerCard = true
    },
    saveTheDate(reff, variable) {
      this.$refs[reff].save(this.addTrailer[variable]);
    },
    cancelEdit() {
      this.trailerProfile = JSON.parse(JSON.stringify(this.originalTrailer))
      this.editing = false
    },
    async removeDocument(document) {
      if(this.trailerCard) {
        this.trailerProfile.transportTrailerDocs = this.trailerProfile.transportTrailerDocs.filter((doc) => {
            return doc.name != document.name;
          })
          this.docuKey++;
        let result = await this.$API.deleteTransportDoc({docId: document.id, itemId: this.trailerProfile.id, type: 'trailers'});
        if(result) {
          this.$message.success('Document deleted successfully!');
        }
      } else {
        this.documents = this.documents.filter((doc) => {
          return doc.name != document.name;
        })
        this.docuKey++;
      }
    },
    async addNewTrailer() {
      this.loadingTrailerSave = true;
      let trailer = await this.$API.createTrailer(this.addTrailer);
      if(trailer) {
        this.$message.success('Trailer added successfully!');
        this.trailers.data.push(trailer)
        this.trailers.total++;
        this.addTrailerCard = false;
        this.loadingTrailerSave = false;
        this.addTrailer = {
          transportTrailerDocs: []
        };
        if(this.documents.length > 0) {
          let docUp = this.uploadDocuments(trailer.id);
          if(docUp) {
            this.$message.success('Documents uploaded successfully!');
            this.documents = [];
          }
        }
        if(this.trailerPhoto) {
          let trailerPhotoUp = this.photoUploaded(this.photoFile, trailer.id);
          if(trailerPhotoUp) {
            this.$message.success('Avatar uploaded successfully!');
            this.photoFile = null;
            this.trailerPhoto = null;
          }
        }
      } else {
        this.$message.error('Something went wrong when trying to add a new trailer.');
      }
    },

    async getTrailerDocs(id) {
      let stuff = await this.$API.getTrailerDocs(id)
      if(stuff.docs.length > 0) {
        this.trailerProfile.transportTrailerDocs = stuff.docs;
      }
      if(stuff.trailerPhoto) {
        this.trailerProfile.trailerPhoto = stuff.trailerPhoto
      }
      this.loadingDocs = false;
    },

    async deleteTrailer() {
      this.loadingDelete = true;
      let result = await this.$API.deleteTrailer({trailerId: this.trailerProfile.id});
      if(result) {
        this.$message.success('Trailer deleted successfully!');
        this.trailers.data = this.trailers.data.filter((trailer) => {
          return trailer.id != this.trailerProfile.id;
        })
        this.trailerCard = false;
        this.trailerProfile = null;
        this.loadingDelete = false;

      }
    },
    async updateTrailer() {
      this.loadingTrailerUpdate = true;
      let result = await this.$API.updateTrailer(this.trailerProfile);
      if(result) {
        this.$message.success('Trailer updated successfully!');
        this.editing = false;
        this.loadingTrailerUpdate = false;
        this.trailerCard = false
        this.trailers.data = this.trailers.data.map((trailer) => {
          if(trailer.id == result.id) {
            return {
              ...result,
            }
          } else {
            return trailer;
          }
        })
      }
      if(this.documents.length > 0) {
        let docUp = this.uploadDocuments(this.trailerProfile.id);
        if(docUp) {
          this.$message.success('Documents uploaded successfully!');
          this.documents = [];
        }
      }
      if(this.updateAvatar) {
        let trailerPhotoUp = this.photoUploaded(this.updatePhotoFile, this.trailerProfile.id);
        if(trailerPhotoUp) {
          this.$message.success('Avatar uploaded successfully!');
          this.updatePhotoFile = null;
          this.updateAvatar = null;
        }
      }
      this.trailerProfile = null
    },
    showTrailer(click, row){
      this.loadingDocs = true;
      this.trailerProfile = row.item
      this.trailerCard = true
      this.getTrailerDocs(row.item.id);
    },
    fileSubjectSelect(event, ref) {
      this.fileDetailsDialog = true;
      this.storeEvent = event.target.files[0];
      this.storeRef = ref;
    },
    acceptSubject() {
      this.onFileChanged(this.storeEvent, this.storeRef)
      this.fileDetailsDialog = false;
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    editTrailer() {
      this.originalTrailer = JSON.parse(JSON.stringify(this.trailerProfile))
      this.editing = true;
    },
  //////////// UPLOAD PHOTO //////////// UPLOAD PHOTO //////////// 
    async photoUploaded(file, trailerId) {
      let url = await URL.createObjectURL(file);
      this.toDataUrl(url, (data) => {
          this.uploadThePhotos({
              name: file.name,
              data: data,
              type: file.type,
              itemId: trailerId,
              bucketRef: 'trailers'
          });
      });
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
              callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async uploadDocuments(trailerId) {
      if(this.documents.length > 0) {
        this.documents.forEach((doc) => {
          this.toDataUrl(doc.url, (data) => {
          this.uploadTheDocs({
              name: doc.name,
              data: data,
              type: doc.type,
              subject: doc.subject,
              itemId: trailerId,
              bucketRef: 'trailers'
          });
      });
        })
      }
    },
    async uploadTheDocs(obj) {
      let result = await this.$API.uploadTransportDocs(obj)
      return result
    },
    async uploadThePhotos(obj) {
      let result = await this.$API.uploadTransportPhotos(obj)
      return result
    },
    openFileExplorer(ref) {
      document.getElementById(ref).click();
    },
    async onFileChanged(event, ref) {
      if(ref == 'trailerDocumentUpload'){
        let doc = event
        if(doc) {
        let docFileURL = await URL.createObjectURL(doc);
          const reader = new FileReader()
          reader.onload = () => {
            let obj = {
              name: doc.name,
              data: reader.result,
              type: doc.type,
              url: docFileURL,
              subject: this.docSubject.toString(),
            }
            this.documents.push(obj)
            this.addTrailerCard ? this.addTrailer.transportTrailerDocs.push(obj) :
            this.trailerProfile.transportTrailerDocs && this.trailerProfile.transportTrailerDocs.length > 0 ? this.trailerProfile.transportTrailerDocs.push(obj) :
            this.trailerProfile.transportTrailerDocs = [obj]

          }
          reader.readAsDataURL(doc)
          this.docuKey++
          this.storeEvent = null;
          this.storeRef = null;
          this.fileDetailsDialog = false;
        }
      } else if(ref == 'imageUpdate') {
        this.updatePhotoFile = event.target.files[0]
        if (this.updatePhotoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.updateAvatar = reader.result
            this.avatarKey++
          }
          reader.readAsDataURL(this.updatePhotoFile)
        }
      }else{
        this.photoFile = event.target.files[0]
        if (this.photoFile) {
          const reader = new FileReader()
          reader.onload = () => {
            this.trailerPhoto = reader.result
            this.avatarKey++
          }
          reader.readAsDataURL(this.photoFile)
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      let dayFullDate = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
        dayFullDate = dateFormat(new Date(date), "ddd, mmmm dS, yyyy");
        fullDate = dateFormat(new Date(date), "mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dd-mm-yyyy @ HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
        time = dateFormat(new Date(date), "HH:MM");
      }
      return {
        isoFormat,
        fullDate,
        dateTime,
        dayOfWeek,
        shorterDate,
        time,
        dayFullDate,
      };
    },
  },
}
</script>

<style scoped>
.avatar-uploader  {
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploaded {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: var(--v-primary-base);
}
.avatar-uploader-icon {
  color: white;
  opacity: 0.1;
}
.avatar-uploader:hover .avatar-uploader-icon {
  color: var(--v-primary-base);
  opacity: 0.6;
}
.docAdd {
  scale: 2.5;
  opacity: 0.1;
}

.docAdd:hover {
  opacity: 0.6;
}
</style>
<template>
  <div style="height: inherit">
    <v-card color="greyRaised" style="height: inherit" flat :loading="loading">
      <v-row class="pa-1" align="center" no-gutters>
        <v-col cols="12" sm="6" class="mb-0 pb-0 pt-1">
          <v-row align="center">
            <v-col cols="12">

              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    outlined
                    class="primaryText--text mx-1 smallText"
                    color="blue-grey"
                    v-on="on"
                  >
                    <v-icon x-small left>event</v-icon> {{ quickSelectDates.find(x => x.value == selectedDate) ? quickSelectDates.find(x => x.value == selectedDate).text : 'Select Date' }}<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item
                    v-for="(date, index) in quickSelectDates"
                    :key="index"
                    @click="selectDate(date.value)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ date.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :color="selectedDate ? 'blue' : 'orange'"
                    class="mx-1 smallText"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon x-small left>date_range</v-icon>{{ dateText }}
                    <v-icon x-small right>expand_more</v-icon></v-chip
                  >
                </template>
                <v-date-picker v-model="params.dates" range color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary"> Cancel </v-btn>
                  <v-btn text color="primary" @click="setDateFilter">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="primaryText--text mx-1 smallText"
                    color="blue-grey"
                    v-on="on"
                  >
                    <v-icon x-small left>event</v-icon> {{ dateType
                    }}<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item
                    v-for="(miletype, index) in milestoneTypes"
                    :key="index"
                    @click="(dateType = miletype.name), getContainers()"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ miletype.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-chip
                class="mb-1 mr-1"
                v-for="(status, index) in availableTransportStatuses"
                :key="index"
                :color="
                  filters.haulageStatus &&
                  filters.haulageStatus.includes(status.text)
                    ? status.color
                    : ''
                "
                small
                @click="addToFilter(status.text, 'haulageStatus')"
              >
                <v-icon
                  small
                  left
                  v-if="
                    filters.haulageStatus &&
                    filters.haulageStatus.includes(status.text)
                  "
                  >check</v-icon
                >
                {{ status.text }}
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="mb-0 pb-0">
          <v-row justify="end" no-gutters>
            <v-btn
              v-if="settingsChange"
              color="blue"
              class="mr-2"
              small
              rounded
              outlined
              @click="saveCustomHeaders()"
              :loading="loadingSaveCustom"
            >
              <v-icon small left>save</v-icon> 
                Save Layout
              </v-btn>            
            <v-chip
              style="height: 40px"
              outlined
              small
              class="pr-1 text-center"
            >
              <v-btn
                small
                @click="onAssignDriverButtonClick"
                color="tertiary"
                :disabled="!selectedContainers.length || !chosenUnit.length"
                class="ml-2 mr-5 pr-5"
              >
                <v-icon class="mr-1" small>person</v-icon>
                <span class="smallText">Assign {{chosenUnit.length ? 'TO ' + chosenUnit[0]?.transportDrivers[0]?.nickname : "Driver"}}</span>
              </v-btn>

              <!-- <v-menu offset-y :disabled="selectedContainers.length == 0"> -->
              <!-- <template v-slot:activator="{ on }"> -->
              <!-- <v-chip
                  small
                  class="primaryText--text mx-1 mr-2"
                  :color="selectedContainers.length > 0 ? 'blue' : 'grey'"
                  v-on="on"
                  style="border: none"
                >
                  Mass Actions<v-icon small>expand_more</v-icon>
                </v-chip> -->
              <!-- <v-chip
                    small
                    class="primaryText--text mx-1"
                    :color="selectedContainers.length > 0 ? 'blue' : 'grey'"
                    v-on="on"
                    style="border: none"
                  >
                    Mass Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item
                    style="height: 35px"
                    @click="bulkCTOModal = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create CTO </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    style="height: 35px"
                    @click="bulkDownload = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Bulk Download CTO's
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    style="height: 35px"
                    @click="bulkUpdateStatusModal = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">fiber_manual_record</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Update Status </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    @click="assignContainers(selectedContainers)"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">local_shipping</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Assign Transporter
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    @click="confirmRemoveTransporter = true"
                  >
                    <v-list-item-action>
                      <v-icon color="red">cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Remove Transporter
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
              <!-- </v-menu> -->

              <v-chip small class="mr-1 pr-3"
                >TOTAL:
                <span style="padding-right: 10px; margin-left: 5px">
                  {{ displayData.length }}</span
                >
              </v-chip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="bulkUploadCTO"
                    ><v-icon>cloud_upload</v-icon></v-btn
                  >
                </template>
                <span>Bulk Upload</span>
              </v-tooltip> -->
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu
                      offset-y
                      nudge-right
                      :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                          ><v-icon>view_week</v-icon></v-btn
                        >
                      </template>
                      <v-card
                        style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        "
                      >
                        <v-card-text>
                          <v-list
                            style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            "
                          >
                            <v-checkbox
                              v-for="(item, index) in headers.filter(
                                (x) => x.value != 'action'
                              )"
                              :key="index"
                              v-model="item.show"
                              @change="toggleColumn(item)"
                              dense
                              :label="item.text"
                              hide-details
                            ></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                              lg="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                @click="saveCustomHeaders"
                                :loading="loadingSaveCustom"
                                outlined
                                color="primary"
                                >SAVE</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-0" :key="updateFilterValue" justify="end" no-gutters>
        <div :key="updateFilterValue">
          <v-chip
            color="red"
            class="mx-1"
            small
            @click="resetFilters()"
            v-if="filteredValues.length > 0"
          >
            <v-icon small left>filter_alt_off</v-icon> Clear Filters
          </v-chip>
          <v-chip
            class="mx-1"
            close
            small
            @click:close="addToFilter(filterValue.value, filterValue.field)"
            v-for="(filterValue, index) in filteredValues"
            :key="index"
            >{{ filterValue.value }}</v-chip
          >
        </div>
      </v-row>
      <!-- <v-row class="mb-3 px-2 bordered-contaniner" align="center" no-gutters>
        <v-col cols="6" sm="6" class="text mb-0 pb-0">
          <v-chip
            class="mb-1 mr-1"
            v-for="(transporter, index) in uniqueTransporters"
            :key="index"
            small
            @click="addToFilter(transporter.text, 'transporterName')"
            :color="
              filters.transporterName &&
              filters.transporterName.includes(transporter.text)
                ? 'teal'
                : ''
            "
          >
            <v-icon
              small
              left
              v-if="
                filters.transporterName &&
                filters.transporterName.includes(transporter.text)
              "
              >check</v-icon
            >
            {{ transporter.text }}
            <v-avatar right>
              <h4>
                {{ transporter.value }}
              </h4>
            </v-avatar>
          </v-chip>
        </v-col>
        <v-col cols="6" sm="6" class="mb-0 pb-0">
          <v-chip
            small
            outlined
            v-for="(total, index) in uniquePluggedIn"
            :key="index"
            :value="total"
            :color="
              filters.PLUG_IN_DESCRIPTION &&
              filters.PLUG_IN_DESCRIPTION.includes(total.text)
                ? 'teal'
                : ''
            "
            @click="
              addPluggedFilter(
                undefined,
                'PLUG_OUT_DATE',
                total.text,
                'PLUG_IN_DESCRIPTION'
              )
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar :color="'orange'" left>
                  <v-icon v-on="on" style="color: black"
                    >electrical_services</v-icon
                  >
                </v-avatar>
              </template>
              Plugged
            </v-tooltip>
            {{ total.text }}
            <v-avatar :color="'red'" right>
              <h4 style="color: black">{{ total.value }}</h4>
            </v-avatar>
          </v-chip>
        </v-col>
      </v-row> -->

      <v-card-text style="max-width: 100%; height: auto; overflow-x: auto" class="my-0 py-0">
        <div
          :style="{ width: tableWidth , height: 'inherit'}"
          v-if="!loading && displayData.length > 0"
        >
          <table :width="'100%'">
            <tr class="mx-0 px-0">
              <draggable
                v-model="selectedHeaders"
                tag="tr"
                :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                :animation="200"
                @change="updateUserLayout()"
                ghost-class="ghost-card"
                @start="columnDrag = true"
                @end="columnDrag = false"
              >
                <td
                  class="text-center mx-0 px-0"
                  style="cursor: grab; border-right: 1px solid grey"
                  v-for="(header, index) in selectedHeaders"
                  :key="index"
                  :width="header.width ?? '150px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <v-checkbox
                        v-if="header.value == 'actions'"
                        :indeterminate="
                          selectedContainers.length > 0 &&
                          selectedContainers.length < filteredResults.length
                        "
                        hide-details
                        v-model="selectAllContainers"
                        dense
                        class="mx-0 px-0 mb-2"
                      ></v-checkbox>
                      <b style="font-size: 10px">{{ header.text }}</b>
                      <v-btn
                        v-if="!header.disableSort"
                        class="ml-1"
                        icon
                        x-small
                        @click="changeSortBy(header.value)"
                      >
                        <v-icon v-if="sortBy != header.value" small color="grey"
                          >arrow_upward</v-icon
                        >
                        <v-icon v-else-if="sortDesc" small
                          >arrow_downward</v-icon
                        >
                        <v-icon v-else small>arrow_upward</v-icon>
                      </v-btn>
                      <v-badge
                        v-if="!header.disableFilter"
                        :content="
                          filters[header.value]
                            ? filters[header.value].length
                            : false
                        "
                        :value="
                          filters[header.value] &&
                          filters[header.value].length > 0
                        "
                        color="primary"
                        offset-y="10"
                      >
                        <v-menu offset-y left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              color="grey"
                              icon
                              ><v-icon small>filter_alt</v-icon></v-btn
                            >
                          </template>
                          <v-card
                            style="
                              background: var(--v-modal-base) !important;
                              border-radius: 20px;
                            "
                          >
                            <v-card-text>
                              <v-list
                                dense
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  rounded
                                  dense
                                  outlined
                                  clearable
                                  prepend-inner-icon="search"
                                  placeholder="Search"
                                  autofocus
                                  v-model="searchHeaders[header.value]"
                                  @input="updateSearchKey()"
                                ></v-text-field>
                                <v-list
                                  dense
                                  style="
                                    max-height: 50vh;
                                    overflow-y: auto;
                                    background: var(--v-modal-base) !important;
                                  "
                                  :key="searchKey"
                                >
                                  <v-list-item
                                    v-for="(item, index) in headerOptions(
                                      header.value
                                    )"
                                    :key="index"
                                  >
                                    <v-list-item-action class="pr-0">
                                      <v-checkbox
                                        v-model="filters[header.value]"
                                        :value="item.value"
                                        hide-details
                                        dense
                                        @change="calculateFilteredResults()"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      headerOptions(header.value).length == 0
                                    "
                                  >
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px"
                                        >No results found</span
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-badge>
                    </v-row>
                  </div>
                </td>
              </draggable>
            </tr>
          </table>

          <v-divider></v-divider>
          <div class="mt-1 pt-1 px-1" :style="{ width: '100%', height: 'inherit' }">
            <v-virtual-scroll
              v-if="displayData.length > 0"
              :bench="10"
              :items="displayData"
              :height="calculatedHeight"
              :width="'100%'"
              item-height="35"
              class="px-1"
            >
              <template v-slot:default="{ item }">
                <div
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                  }" 
                  @click="(e) => e.target.classList.toggle('rowSelected')"
                >
                  <td
                    v-for="(header, index) in selectedHeaders"
                    :key="index"
                    :style="{ width: header.width ?? '150px'}"
                    class="text-center"
                  >
                    <div v-if="header.value == 'actions'">
                      <v-row
                        align="center"
                        justify="center"
                        class="py-1"
                        @click="(e) => e.target.classList.toggle('rowSelected')"
                      >
                        <v-checkbox
                          hide-details
                          :value="
                            selectedContainers
                              .map((x) => x.id)
                              .includes(item.id)
                          "
                          @change="selectContainer(item)"
                          dense
                          class="mb-2 mx-0 px-0"
                        ></v-checkbox>
                        <v-btn
                          v-if="item"
                          icon
                          small
                          @click="openContainer(item)"
                          class="ml-0 mb-0 pb-0"
                          ><v-icon small>launch</v-icon></v-btn
                        >
                        <v-menu right offset-x :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :color="item.hasComments ? 'tertiary' : ''"
                              v-on="on"
                              class="mb-0 pb-0"
                              v-bind="attrs"
                              v-if="item"
                              small
                              icon
                              @click="getContainerComments(item)"
                              ><v-icon small>forum</v-icon></v-btn
                            >
                          </template>
                          <v-card
                            height="50vh"
                            width="15vw"
                            style="overflow-y: hidden"
                          >
                            <v-subheader>{{ item.ctoNo }}</v-subheader>
                            <v-divider></v-divider>
                            <!-- CHAT AREA -->
                            <v-card
                              height="37.5vh"
                              width="inherit"
                              style="overflow-y: auto"
                              flat
                              :loading="loadingComments"
                            >
                              <v-list>
                                <v-list-item
                                  v-if="item.containerComments?.length == 0"
                                >
                                  <v-list-item-content class="text-center">
                                    <span style="font-size: 10px; color: grey">
                                      No comments.
                                    </span>
                                  </v-list-item-content>
                                </v-list-item>
                                <div v-if="item.containerComments">
                                  <v-list-item
                                    v-for="(
                                      comment, index
                                    ) in item.containerComments"
                                    :key="comment.id"
                                    :id="'comment_' + index"
                                    class="py-0 my-1"
                                  >
                                    <v-list-item-avatar
                                      v-if="
                                        comment.user &&
                                        comment.userId != $store.state.user.id
                                      "
                                    >
                                      <v-tooltip top v-if="comment.user">
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-avatar
                                            v-bind="attrs"
                                            v-on="on"
                                            style="cursor: pointer"
                                            color="secondary"
                                            size="48"
                                            class="mt-0 pt-0"
                                          >
                                            <span
                                              v-if="!comment.user.avatar"
                                              class="primaryText--text text-h5"
                                              >{{
                                               $Format.avatarAbbreviation(
                                                  comment.user.firstName +
                                                    " " +
                                                    comment.user.surname
                                                )
                                              }}</span
                                            >
                                            <img
                                              v-else
                                              :src="comment.user.avatar"
                                              referrerpolicy="no-referrer"
                                            />
                                          </v-avatar>
                                        </template>
                                        <span
                                          style="font-size: 10px"
                                          v-if="comment.user"
                                          >{{ comment.user.firstName }}
                                          {{ comment.user.surname }}</span
                                        >
                                      </v-tooltip>
                                    </v-list-item-avatar>
                                    <v-list-item-content
                                      :class="
                                        comment.userId == $store.state.user.id
                                          ? 'text-right'
                                          : 'text-left'
                                      "
                                    >
                                      <v-list-item-title class="text-wrap">
                                        {{ comment.comment }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <i
                                          style="font-size: 10px; color: grey"
                                          >{{
                                            formatDateTime(comment.createdAt)
                                          }}</i
                                        >
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar
                                      v-if="
                                        comment.userId == $store.state.user.id
                                      "
                                    >
                                      <v-tooltip top>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-avatar
                                            v-bind="attrs"
                                            v-on="on"
                                            style="cursor: pointer"
                                            color="secondary"
                                            size="48"
                                            class="mt-0 pt-0"
                                          >
                                            <span
                                              v-if="!comment.user.avatar"
                                              class="primaryText--text text-h5"
                                              >{{
                                               $Format.avatarAbbreviation(
                                                  comment.user.firstName +
                                                    " " +
                                                    comment.user.surname
                                                )
                                              }}</span
                                            >
                                            <img
                                              v-else
                                              :src="comment.user.avatar"
                                              referrerpolicy="no-referrer"
                                            />
                                          </v-avatar>
                                        </template>
                                        <span
                                          style="font-size: 10px"
                                          v-if="comment.user"
                                          >{{ comment.user.firstName }}
                                          {{ comment.user.surname }}</span
                                        >
                                      </v-tooltip>
                                    </v-list-item-avatar>
                                  </v-list-item>
                                </div>
                              </v-list>
                            </v-card>
                            <v-card
                              flat
                              height="8vh"
                              width="inherit"
                              style="
                                position: absolute;
                                bottom: 0;
                                background-color: var(
                                  --v-greyRaised-base
                                ) !important;
                                display: flex;
                                align-items: center;
                                overflow-y: hidden;
                              "
                            >
                              <v-textarea
                                class="ml-2"
                                no-resize
                                hide-details
                                outlined
                                autofocus
                                height="7vh"
                                style="width: 80%; contain: content"
                                placeholder="Send a message"
                                v-model="containerChatArea"
                                :value="containerChatArea"
                              ></v-textarea>
                              <v-btn
                                :loading="sendingMessage"
                                icon
                                class="ml-3 mr-2"
                                @click="commentContainer(item)"
                                ><v-icon>send</v-icon></v-btn
                              >
                            </v-card>
                          </v-card>
                        </v-menu>
                      </v-row>

                      <!-- </v-row> -->
                    </div>
                    <div v-else-if="header.value == 'ctoNo'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            small
                            class="primaryText--text tableCell"
                            outlined
                            style="border: none"
                          >
                             <v-avatar
                              :color="item.booking.movementType == 'EXPORT' ? 'deep-orange' : 'blue'"
                              left
                            >
                              <h4 style="color: white">
                                {{ item.booking.serviceType == 'BREAKBULK' ? item.booking.movementType.charAt(0) + ' ' + 'B' + ' ' :  item.booking.movementType.charAt(0) }}
                              </h4>
                            </v-avatar> 
                            {{ item[header.value] }}
                          </v-chip>
                          </template>
                        <span
                          >{{
                            $Format.capitalizeFirstLetter(item.booking.movementType)
                          }}
                          Booking</span
                        >
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'documents'"
                      class="text-left"
                    >
                      <v-row justify="center" class="pt-2" align="center">
                        <v-chip
                          v-if="item.ctoDocumentId"
                          class="mt-1"
                          x-small
                          @click="getCTO(item)"
                          color="teal"
                          >CTO</v-chip
                        >
                      </v-row>
                    </div>
                    <!-- <div
                      v-else-if="header.value == 'vesselVoyage'"
                      class="text-left"
                    >
                      <v-chip
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item.vesselVoyage, 'vesselVoyage')"
                        style="border: none; font-size: 11px"
                        ><span>{{ item.vesselVoyage }}</span>
                      </v-chip>
                    </div> -->
                    <div
                      v-else-if="header.value == 'portOfLoadValue'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.portOfLoadValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 10px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.portOfLoadValue,
                                'portOfLoadValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.portOfLoadValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.portOfLoadCity
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'portOfDischargeValue'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.portOfDischargeValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 11px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.portOfDischargeValue,
                                'portOfDischargeValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.portOfDischargeValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.portOfDischargeCity
                        }}</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-else-if="header.value == 'gensetRequired'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.gensetRequired ? 'success' : 'red'"
                        @click="
                          addToFilter(item.gensetRequired, 'gensetRequired')
                        "
                      >
                        <v-icon v-if="item.gensetRequired"
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red">cancel</v-icon>
                      </v-btn>
                    </div>
                    <div
                      v-else-if="header.value == 'isConfirmedBooking'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.isConfirmedBooking ? 'success' : 'red'"
                        @click="
                          addToFilter(
                            item.isConfirmedBooking,
                            'isConfirmedBooking'
                          )
                        "
                      >
                        <v-icon v-if="item.isConfirmedBooking"
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red">cancel</v-icon>
                      </v-btn>
                    </div>

                    <div v-else-if="header.value == 'features'">
                      <v-tooltip
                        top
                        v-if="
                          item.temporaryProducts.some(
                            (product) => product.product === 'Genset'
                          )
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            outlined
                            small
                            class="mx-1"
                            v-on="on"
                            color="#c4c166"
                            ><v-icon small>bolt</v-icon></v-btn
                          >
                        </template>
                        <span>Genset Required</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        v-if="
                          item.temporaryProducts.some(
                            (product) => product.product === 'TelemPlus'
                          )
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            outlined
                            small
                            class="mx-1"
                            v-on="on"
                            color="#18e00d"
                            ><v-icon small>thermostat_auto</v-icon></v-btn
                          >
                        </template>
                        <span>
                          TelemPlus Required:
                          <template
                            v-if="
                              item.temporaryProducts.some(
                                (product) => product.product === 'TelemPlus'
                              )
                            "
                          >
                            <ul>
                              <li
                                v-for="(
                                  [variation, count], index
                                ) in Object.entries(
                                  item.temporaryProducts
                                    .filter(
                                      (product) =>
                                        product.product === 'TelemPlus'
                                    )
                                    .reduce((acc, product) => {
                                      if (!acc[product.variation]) {
                                        acc[product.variation] = 0;
                                      }
                                      acc[product.variation] += product.count;
                                      return acc;
                                    }, {})
                                )"
                                :key="index"
                              >
                                {{ variation }}: {{ count }}
                              </li>
                            </ul>
                          </template>
                        </span>
                      </v-tooltip>
                    </div>

                    <div
                      v-else-if="header.value == 'preadvised'"
                      class="text-center"
                    >
                      <div v-if="item.preAdviseRequired">
                        <v-btn
                          small
                          icon
                          :color="item.preadvised ? 'success' : 'red'"
                          @click="addToFilter(item.preadvised, 'preadvised')"
                        >
                          <v-icon v-if="item.preadvised"
                            >check_circle_outline</v-icon
                          >
                          <v-icon v-else color="red">cancel</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>N/A</div>
                    </div>

                    <div v-else-if="header.value == 'transporterName'" :class="'text-center'">
                      <v-tooltip top v-if="item.transporter">
                        <template v-slot:activator="{ on }">
                          <v-chip small class="primaryText--text" v-on="on" outlined style="border: none">
                            <v-avatar size="20" :color="item.transporter && item.transporter.logo
                              ? 'white'
                              : 'secondary'
                              " left>
                              <v-img v-if="item.transporter.logo" :src="item.transporter.logo" contain></v-img>
                              <h3 v-else style="color: white">
                                {{ item.transporterName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.transporterName }}
                            <v-btn @click="
                              addToFilter(
                                item.transporterName,
                                'transporterName'
                              )
                              " icon><v-icon small>filter_alt</v-icon></v-btn>
                            <v-btn right @click="removeContainerTransporter(item)" icon color="red" small><v-icon
                                small>cancel</v-icon></v-btn>
                          </v-chip>
                        </template>
                        <span>{{ item.transporter.name }}</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn color="grey" small icon v-if="!(item.isCarrierHaulage || item.privateLoad)"
                          @click="assignContainers([item])"><v-icon small>add_circle_outline</v-icon></v-btn>
                      </div>
                    </div>

                    <div v-else-if="header.value == 'driverName'" :class="'text-center'">
                      <v-menu right offset-x>
                        <template v-slot:activator="{on, attrs}">
                        <v-chip v-on="on" v-bind="attrs" small class="primaryText--text" outlined style="border:none">
                          <v-avatar v-if="item?.containerHaulages[0]?.transportDriver" size="20" color="white" left>
                            <v-img v-if="item?.containerHaulages[0]?.transportDriver?.user?.avatar" :src="item.containerHaulages[0].transportDriver.user.avatar" contain></v-img>
                            <h3 v-else-if="item?.containerHaulages[0]?.transportDriver?.nickname" style="color: white">
                              {{ item?.containerHaulages[0]?.transportDriver?.nickname.charAt(0) }}
                            </h3>
                            <v-icon v-else color="greyBase">person</v-icon>
                          </v-avatar>
                          <span v-if="item?.containerHaulages[0]?.transportDriver?.nickname">{{ item?.containerHaulages[0]?.transportDriver?.nickname }}</span>
                        </v-chip>
                      </template>
                        <v-card style="background-color: var(--v-greyContrast-darken3) !important">
                          <v-list-item dense class="menuItem pl-0" @click="areYouSure(item)">
                            <v-list-item-avatar class="mr-1">
                                <v-icon small color="red">close</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                Unassign Driver
                              </v-list-item-title>
                              <!-- <v-list-item-subtitle>
                                Remove driver from this container
                              </v-list-item-subtitle> -->
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-menu>
                    </div>
                    <div
                      v-else-if="header.value == 'consigneeName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.booking.consignee">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.consignee"
                            small
                            class="primaryText--text tableCell"
                            outlined
                            @click="
                              addToFilter(item.consigneeName, 'consigneeName')
                            "
                            v-on="on"
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.booking.consignee &&
                                item.booking.consignee.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                style=""
                                v-if="item.booking.consignee.logo"
                                :src="item.booking.consignee.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.consigneeName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.consigneeName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.consignee.name }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'customerName'">
                      <v-tooltip top v-if="item.booking.customer">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.customer"
                            small
                            class="primaryText--text tableCell"
                            v-on="on"
                            outlined
                            @click="addToFilter(item.customerName, 'shipperName')"
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="item.booking.customer && item.booking.customer.logo? 'white': 'secondary'"
                              left
                            >
                              <v-img
                                v-if="item.booking.customer.logo"
                                :src="item.booking.customer.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.customerName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.customerName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.customer.name }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'shipperName'">
                      <v-tooltip top v-if="item.booking.shipper">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.shipper"
                            small
                            class="primaryText--text tableCell"
                            v-on="on"
                            outlined
                            @click="addToFilter(item.shipperName, 'shipperName')"
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color=" item.booking.shipper && item.booking.shipper.logo ? 'white' : 'secondary'"
                              left
                            >
                              <v-img
                                v-if="item.booking.shipper.logo"
                                :src="item.booking.shipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.shipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'shippingLineName'"
                      class="text-left"
                    >
                      <v-chip
                        v-if="item.booking.shippingLine"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="
                          addToFilter(item.shippingLineName, 'shippingLineName')
                        "
                        style="border: none"
                      >
                        <v-avatar
                          size="24"
                          :color="
                            item.booking.shippingLine &&
                            item.booking.shippingLine.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.booking.shippingLine.logo"
                            :src="item.booking.shippingLine.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.shippingLineName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.shippingLineName }}
                      </v-chip>
                    </div>
                    <div
                      v-else-if="header.value == 'GATE_OUT_EMPTY_DESCRIPTION'"
                    >
                      <v-tooltip top v-if="item.GATE_OUT_EMPTY_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.GATE_OUT_EMPTY_DESCRIPTION,
                                'GATE_OUT_EMPTY_DESCRIPTION'
                              )
                            "
                            style="border: none"
                          >
                            {{ item.GATE_OUT_EMPTY_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.GATE_OUT_EMPTY_DESCRIPTION
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'GATE_OUT_EMPTY_DATE'">
                      <v-tooltip top v-if="item.GATE_OUT_EMPTY_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.GATE_OUT_EMPTY_DATE,
                                'GATE_OUT_EMPTY_DATE'
                              )
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.GATE_OUT_EMPTY_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.GATE_OUT_EMPTY_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'LOADING_POINT_1_DESCRIPTION'"
                    >
                      <v-tooltip top v-if="item.LOADING_POINT_1_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.LOADING_POINT_1_DESCRIPTION,
                                'LOADING_POINT_1_DESCRIPTION'
                              )
                            "
                            style="border: none"
                          >
                            {{ item.LOADING_POINT_1_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.LOADING_POINT_1_DESCRIPTION
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_1_DATE'">
                      <v-tooltip top v-if="item.LOADING_POINT_1_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.LOADING_POINT_1_DATE,
                                'LOADING_POINT_1_DATE'
                              )
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.LOADING_POINT_1_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.LOADING_POINT_1_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'LOADING_POINT_2_DESCRIPTION'"
                    >
                      <v-tooltip top v-if="item.LOADING_POINT_2_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.LOADING_POINT_2_DESCRIPTION,
                                'LOADING_POINT_2_DESCRIPTION'
                              )
                            "
                            style="border: none"
                          >
                            {{ item.LOADING_POINT_2_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.LOADING_POINT_2_DESCRIPTION
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'LOADING_POINT_2_DATE'">
                      <v-tooltip top v-if="item.LOADING_POINT_2_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.LOADING_POINT_2_DATE,
                                'LOADING_POINT_2_DATE'
                              )
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.LOADING_POINT_2_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.LOADING_POINT_2_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'WEIGH_BRIDGE_DESCRIPTION'">
                      <div v-if="item.WEIGH_BRIDGE_DESCRIPTION">
                        <v-chip small rounded outlined style="border: none">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                small
                                class="primaryText--text tableCell"
                                outlined
                                v-on="on"
                                @click="
                                  addToFilter(
                                    item.WEIGH_BRIDGE_DESCRIPTION,
                                    'WEIGH_BRIDGE_DESCRIPTION'
                                  )
                                "
                                style="border: none"
                              >
                                {{ item.WEIGH_BRIDGE_DESCRIPTION }}
                              </v-chip>
                            </template>
                            <span style="font-size: 10px">{{
                              item.WEIGH_BRIDGE_DESCRIPTION
                            }}</span>
                          </v-tooltip>
                          <v-btn
                            @click="addMilestone(item, 'WEIGH_BRIDGE')"
                            icon
                            color="grey"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                        </v-chip>
                      </div>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="addMilestone(item, 'WEIGH_BRIDGE')"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'WEIGH_BRIDGE_DATE'">
                      <v-tooltip top v-if="item.WEIGH_BRIDGE_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.WEIGH_BRIDGE_DATE,
                                'WEIGH_BRIDGE_DATE'
                              )
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.WEIGH_BRIDGE_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.WEIGH_BRIDGE_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'PLUG_IN_DESCRIPTION'">
                      <div v-if="item.PLUG_IN_DESCRIPTION">
                        <v-chip small rounded outlined style="border: none">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                small
                                class="primaryText--text tableCell"
                                outlined
                                v-on="on"
                                @click="addMilestone(item, 'PLUG_IN')"
                                style="border: none"
                              >
                                {{ item.PLUG_IN_DESCRIPTION }}
                              </v-chip>
                            </template>
                            <span style="font-size: 10px">{{
                              item.PLUG_IN_DESCRIPTION
                            }}</span>
                          </v-tooltip>
                          <v-btn
                            @click="addMilestone(item, 'PLUG_IN')"
                            icon
                            color="grey"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                        </v-chip>
                      </div>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="addMilestone(item, 'PLUG_IN')"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'PLUG_IN_DATE'">
                      <v-tooltip top v-if="item.PLUG_IN_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(item.PLUG_IN_DATE, 'PLUG_IN_DATE')
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.PLUG_IN_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.PLUG_IN_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'PLUG_OUT_DESCRIPTION'">
                      <v-tooltip top v-if="item.PLUG_OUT_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="addMilestone(item, 'PLUG_OUT')"
                            style="border: none"
                          >
                            {{ item.PLUG_OUT_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.PLUG_OUT_DESCRIPTION
                        }}</span>
                      </v-tooltip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="addMilestone(item, 'PLUG_OUT')"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'PLUG_OUT_DATE'">
                      <div v-if="item.PLUG_OUT_DATE">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              small
                              class="primaryText--text tableCell"
                              outlined
                              v-on="on"
                              @click="addMilestone(item, 'PLUG_OUT')"
                              style="border: none"
                            >
                              {{ formatDateTime(item.PLUG_OUT_DATE) }}
                            </v-chip>
                          </template>
                          <span style="font-size: 10px">{{
                            formatDateTime(item.PLUG_OUT_DATE)
                          }}</span>
                        </v-tooltip>
                      </div>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="addMilestone(item, 'PLUG_OUT')"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'GATE_IN_FULL_DESCRIPTION'">
                      <v-tooltip top v-if="item.GATE_IN_FULL_DESCRIPTION">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.GATE_IN_FULL_DESCRIPTION,
                                'GATE_IN_FULL_DESCRIPTION'
                              )
                            "
                            style="border: none"
                          >
                            {{ item.GATE_IN_FULL_DESCRIPTION }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          item.GATE_IN_FULL_DESCRIPTION
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.value == 'GATE_IN_FULL_DATE'">
                      <v-tooltip top v-if="item.GATE_IN_FULL_DATE">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            outlined
                            v-on="on"
                            @click="
                              addToFilter(
                                item.GATE_IN_FULL_DATE,
                                'GATE_IN_FULL_DATE'
                              )
                            "
                            style="border: none"
                          >
                            {{ formatDateTime(item.GATE_IN_FULL_DATE) }}
                          </v-chip>
                        </template>
                        <span style="font-size: 10px">{{
                          formatDateTime(item.GATE_IN_FULL_DATE)
                        }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'containerNo'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="editField('text', item, header.value)"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'sealNo'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="editField('text', item, header.value)"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'internalRef'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="editField('text', item, header.value)"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'tempSerialNo'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="editField('text', item, header.value)"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'sensorDeviceType'">
                      <v-chip
                        v-if="
                          item[header.value] &&
                          item[header.value].split(' ').length > 0
                        "
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value].split(" ")[0] }}
                      </v-chip>
                    </div>

                    <div v-else-if="header.value == 'tareWeight'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        style="border: none"
                        @click="openContainer(item)"
                      >
                        {{ item[header.value] }} KG
                      </v-chip>
                      <v-btn v-else small icon @click="openContainer(item)"
                        ><v-icon color="grey" small
                          >add_circle_outline</v-icon
                        ></v-btn
                      >
                    </div>

                    <div v-else-if="header.value == 'vgmWeight'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        style="border: none"
                        @click="openContainer(item)"
                      >
                        {{ item[header.value] }} KG
                      </v-chip>
                      <v-btn v-else small icon @click="openContainer(item)"
                        ><v-icon color="grey" small
                          >add_circle_outline</v-icon
                        ></v-btn
                      >
                    </div>

                    <div v-else-if="header.value == 'haulageStatus'">
                      <v-menu offset-y >
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text tableCell"
                            :color="getStatusColor(item.haulageStatus)"
                            v-on="on"
                            style="border: none"
                          >
                            {{ item[header.value] ?? 'Unallocated'}}
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list style="overflow: hidden" dense>
                          <v-list-item
                            v-for="(
                              status, index
                            ) in getFilteredTransportStatuses(item)"
                            :key="index"
                            style="height: 35px"
                            @click="setStatus(item, status)"
                          >
                            <v-list-item-action>
                              <v-icon :color="status.color"
                                >fiber_manual_record</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ status.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div v-else-if="header.value == 'etd'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text tableCell"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>

                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div class="container" v-if="!loading && displayData.length == 0">
          <h3 style="color: grey">No Results Found</h3>
        </div>
        <div class="container" v-else-if="loading">
          <valhalla-loading height="500px" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
      />
    </v-dialog>
    <v-dialog v-model="viewContainer" width="94vw" persistent>
      <ViewContainer
        v-if="chosenContainer && chosenContainer.id"
        :booking="chosenContainer.booking"
        :containerData="chosenContainer"
        @close="viewContainer = !viewContainer"
      />
    </v-dialog>
    <!-- 
        <v-dialog v-model="viewCTO" width="45vw">
            <v-card height="90vh">
                <v-toolbar width="100%" flat dense>
                    <v-toolbar-title>{{ previewItem.name ? previewItem.name : 'Loading...' }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="downloadCTO(previewItem.url, previewItem.name)"><v-icon>download</v-icon></v-btn>
                    <v-btn icon @click="viewCTO = false, previewItem = {}, chosenContainer = {}">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div style="height: 100%; width: 100%; display: grid; place-content: center;">
                    <v-card width="40vw" height="80vh" style="overflow-y: scroll; contain: content;" :loading="loadingCto">
                        <pdf style="height: 100%; width: 100%;" v-if="previewItem != {}" :src="previewItem.url" />
                        <div v-if="loadingCto"
                            style="height: 100%; width: inherit; display: flex; justify-content: center; align-items: center;">
                            <i>Loading Content...</i>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </v-dialog> -->

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="previewItem && previewItem.id">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(previewItem)" icon
            ><v-icon>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon text @click="(previewModal = false), (previewItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div
                v-if="previewItem.type && previewItem.type.includes('image/')"
              >
                <v-img
                  :src="previewItem.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="previewItem.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="8"
                    class="text-center"
                    v-if="previewItem.url"
                  >
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 700"
                        :source="previewItem.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(item)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editFieldModal" width="400px">
      <v-card v-if="fieldItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Edit {{ fieldItem.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            :loading="savingField"
            @click="saveField"
            ><v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="editFieldModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-if="fieldItem.type == 'text' && fieldItem.name == 'Container No.'"
            v-model="fieldItem.value"
            :rules="[rules.containerNo]"
            outlined
            dense
            clearable
            :label="fieldItem.name"
          ></v-text-field>
          <v-text-field
            v-else-if="fieldItem.type == 'text'"
            v-model="fieldItem.value"
            outlined
            dense
            clearable
            :label="fieldItem.name"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assignToView" width="90vw">
      <AssignContainers
        :chosenContainers="selectedContainers"
        @close="assignToView = false"
        @update="updateAssignedContainers"
      />
    </v-dialog>
    <v-dialog v-model="bulkUpdateStatusModal" width="400px">
      <v-card :loading="savingBulkStatus">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Status </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateStatusModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="(status, index) in transportStatuses"
              :key="index"
              style="height: 35px"
              @click="setBulkStatus(status.text, status.value)"
            >
              <v-list-item-action>
                <v-icon :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ status.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addMilestoneModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ManageContainerMilestone
        :item="milestoneItem"
        :key="milestoneKey"
        @close="addMilestoneModal = false"
        @save="milestoneUpdate"
        :bookingType="this.bookingType"
      />
    </v-dialog>

    <v-dialog v-model="bulkCTOModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Create CTO </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            text
            @click="startProcessingCTO()"
            :loading="processingCTO"
            >Start</v-btn
          >
          <v-btn text @click="bulkCTOModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list
            style="max-height: 50vh; overflow-y: auto"
            :key="ctoUpdateKey"
            dense
          >
            <v-list-item v-for="item in selectedContainers" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.ctoNo }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.ctoDocument">
                  Version: {{ item.ctoDocument.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.errorMessage">
                  Error: {{ item.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="getCTO(item)" small v-on="on">
                        <v-icon color="blue" small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Document</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click="downloadDocument(item.ctoDocument)"
                        small
                        v-on="on"
                      >
                        <v-icon small>download</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download CTO</span>
                  </v-tooltip>
                  <v-icon v-if="item.errorMessage" color="red">error</v-icon>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkDownload" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Download </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="green"
                icon
                @click="processMissingCTO()"
                :loading="processingCTO"
                :disabled="selectedCtoGenerated"
                v-on="on"
              >
                <v-icon>article</v-icon>
              </v-btn>
            </template>
            <span>Process any missing CTOs</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                icon
                @click="bulkDownloadCTO()"
                :loading="bulkDownloadLoader"
                :disabled="!selectedCtoGenerated"
                v-on="on"
              >
                <v-icon>cloud_download</v-icon>
              </v-btn>
            </template>
            <span>Bulk download CTOs</span>
          </v-tooltip>
          <v-btn text @click="bulkDownload = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list
            style="max-height: 50vh; overflow-y: auto"
            :key="ctoUpdateKey"
            dense
          >
            <v-list-item v-for="item in selectedContainers" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.ctoNo }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.ctoDocument">
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on"> check </v-icon>
                    </template>
                    <span style="font-size: 12px">CTO Availible</span>
                  </v-tooltip>
                  <v-tooltip v-else>
                    <template v-slot:activator="{ on }">
                      <v-icon color="red" v-on="on"> close </v-icon>
                    </template>
                    <span style="font-size: 12px">CTO Not Availible</span>
                  </v-tooltip>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="generatingCTO" timeout="-1">
      Generating CTO...
      <v-progress-linear
        indeterminate
        color="primary"
        height="5"
      ></v-progress-linear>
    </v-snackbar>
    <v-snackbar v-model="assigningDriver" timeout="-1">
        Sending Instruction to Driver...
      <v-progress-linear
        indeterminate
        color="primary"
        height="5"
      ></v-progress-linear>
    </v-snackbar>


    <v-dialog
      v-model="bulkUpdateModal"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Upload Results</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="ctoHeaders" :items="bulkUpdateData" dense>
            <template v-slot:[`item.created`]="{ item }">
              <v-icon v-if="item.created" color="success"
                >check_circle_outline</v-icon
              >
              <v-icon v-else color="red">cancel</v-icon>
            </template>
          </v-data-table>
          <!-- <v-list dense style="">
            <v-list-item v-for="(cto, index) in bulkUpdateData" :key="index"> 
           
              <v-list-item-content>
                <v-list-item-title>{{ cto.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="cto.containerNo">
                <v-icon small color="grey" class="mr-2">tag</v-icon> {{ cto.containerNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.sealNo">
                <v-icon small color="grey" class="mr-2">tag</v-icon> {{ cto.sealNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.tareWeight">
                <v-icon small color="grey" class="mr-2">scale</v-icon> {{ cto.tareWeight }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="cto.tareWeight">
                <v-icon small color="grey" class="mr-2">thermostat</v-icon> {{ cto.tempSerialNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="cto.created" color="success">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="areYouSureDialog" width="25rem">
      <v-card height="auto">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-title>Are you sure?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon plain color="redPop" @click="areYouSureDialog = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-2">
          <v-row justify="center" class="mt-0 mb-0">
            <span style="text-align: center; font-size: small;" class="mx-2 mb-2">The driver will be notified that their haul has been cancelled.</span>
            <v-btn
              color="redPop"
              class="mx-2"
              text
              small
              @click="areYouSureDialog = false"
              >No</v-btn
            >
            <v-btn
              color="tertiary"
              class="mx-2"
              text
              small
              @click="removeDriver()"
              :loading="savingAction"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmRemoveTransporter" width="500px">
      <v-card v-if="selectedContainers">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmRemoveTransporter = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to remove the transporter from these
            {{ selectedContainers.length }} items?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmRemoveTransporter = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="removeTransporter()"
              :loading="savingRemoveTransporter"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <file-upload
      class="my-0 py-0"
      post-action=""
      :multiple="false"
      :drop="false"
      accept="text/csv,application/vnd.ms-excel,application/x-vnd.oasis.opendocument.spreadsheet,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :drop-directory="true"
      v-model="fileList"
      ref="uploader"
    >
    </file-upload>
  </div>
</template>
<script>
import AssignContainers from "./Dialogs/AssignContainers.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import FileUpload from "vue-upload-component";

// import pdf from "vue-pdf";
import ManageContainerMilestone from "./Dialogs/ManageContainerMilestone.vue";

import ViewContainer from "./Dialogs/ViewContainerHaulage.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import * as XLSX from "xlsx";
import { PDFDocument } from "pdf-lib";

// import VueResizable from 'vue-resizable'
export default {
  components: {
    AssignContainers,
    draggable,
    FileUpload,
    ManageContainerMilestone,
    // pdf,
    ViewContainer,
    ViewFile,
    VuePdfEmbed,
    // VueResizable
  },

  props: ["chosenUnit"],
  data: () => ({
    addMilestoneModal: false,
    assignToView: false,
    availableTransportStatuses: [],
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
    ],
    bulkUpdateStatusModal: false,
    bulkUpdateModal: false,
    bulkUpdateData: [],
    columnDrag: false,
    tableWindowHeight: window.innerHeight,
    chosenContainer: {},
    chosenContainers: [],
    containerSelected: null,
    confirmRemoveTransporter: false,
    generatingCTO: false,
    assigningDriver: false,
    containers: [],
    containerChatArea: null,
    containerQuery: null,
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    containerTimeout: null,
    ctoHeaders: [
      { text: "Success", value: "created", align: "center" },
      { text: "CTO No.", value: "ctoNo", align: "center" },
      { text: "Container No.", value: "containerNo", align: "center" },
      { text: "Seal No.", value: "sealNo", align: "center" },
      { text: "Tare Weight", value: "tareWeight", align: "center" },
      { text: "Device Serial No.", value: "tempSerialNo", align: "center" },
    ],
    ctoUpdateKey: 650,
    bulkCTOModal: false,
    bulkDownload: false,
    dateMenu: false,
    dateType: "Loading Date",
    editFieldModal: false,
    fieldItem: null,
    filters: {},
    savingAction: false,
    filteredValues: [],
    filteredResults: [],
    fileList: [],
    fullscreen: false,
    milestoneTypes: [
      {
        name: "ETD",
        value: "ETD",
      },
      {
        name: "Empty Pickup Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
      },
      // {
      //     name: 'Full Pickup Date',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'FULL_PICKUP'
      // },
      // {
      //     name: 'Loading Date 1',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'LOADING_POINT_1'
      // },
      // {
      //     name: 'Loading Date 2',
      //     eventType: 'TRANSPORT',
      //     modeOfTransport: 'TRUCK',
      //     value: 'LOADING_POINT_2'
      // },
      {
        name: "Plugged In Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
      },
      {
        name: "Unplugged Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
      },
      {
        name: "Port-Holding Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
      },
      {
        name: "Loading Date",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_1",
      },
    ],
    headers: [
      {
        text: "Actions",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        show: true,
      },
      {
        text: "Status",
        value: "haulageStatus",
        show: true,
      },
      {
        text: "CTO No.",
        value: "ctoNo",
        align: "center",
        sortable: true,
        width: "160px",
        show: true,
      },
      {
        text: "Method",
        value: "verificationMethod",
        show: true,
      },
      {
        text: "Gross Weight",
        value: "grossWeight",
        show: true,
      },

      {
        text: "Tare Weight",
        value: "tareWeight",
        show: true,
      },
      {
        text: "VGM",
        value: "vgmWeight",
        show: true,
      },
      {
        text: "Shipper",
        value: "shipperName",
        align: "center",
        width: "160px",
        show: true,
      },
      {
        text: "Empty Pickup",
        value: "GATE_OUT_EMPTY_DESCRIPTION",
        show: true,
      },
      {
        text: "Port-Holding",
        value: "GATE_IN_FULL_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-1",
        value: "LOADING_POINT_1_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-1 Time",
        value: "LOADING_POINT_1_DATE",
        show: true,
      },
      {
        text: "LP-2",
        value: "LOADING_POINT_2_DESCRIPTION",
        show: true,
      },
      {
        text: "LP-2 Time",
        value: "LOADING_POINT_2_DATE",
        show: true,
      },

      {
        text: "Container No.",
        value: "containerNo",
        show: true,
      },
      {
        text: "Seal No.",
        value: "sealNo",
        show: true,
      },
      {
        text: "Internal Ref.",
        value: "internalRef",
        show: true,
      },
      {
        text: "Carrier Ref.",
        value: "carrierReferenceNumber",
        show: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        width: "160px",
        show: true,
      },
      {
        text: "POD",
        value: "portOfDischargeValue",
        show: true,
      },
      {
        text: "Type",
        value: "containerTypeCode",
        show: true,
      },
      {
        text: "Transporter",
        value: "transporterName",
        width: "160px",
        show: true,
      },
      {
        text: "Driver",
        value: "driverName",
        width: "160px",
        show: true,
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        show: false,
      },

      {
        text: "Customer Ref.",
        value: "customerContainerRef",
        width: "160px",
        show: false,
      },

      {
        text: "ETD",
        value: "etd",
        show: false,
      },

      {
        text: "Empty Time",
        value: "GATE_OUT_EMPTY_DATE",
        show: false,
      },

      {
        text: "Weigh Bridge",
        value: "WEIGH_BRIDGE_DESCRIPTION",
        show: false,
      },
      {
        text: "Weigh Time",
        value: "WEIGH_BRIDGE_DATE",
        show: false,
      },
      {
        text: "Depot",
        value: "PLUG_IN_DESCRIPTION",
        show: false,
      },
      {
        text: "Depot In",
        value: "PLUG_IN_DATE",
        show: false,
      },
      //   {
      //     text: "Depot-Out Date",
      //     value: "PLUG_OUT_DATE",
      //     show: true,
      //   },
      {
        text: "Depot Out",
        value: "PLUG_OUT_DATE",
        show: false,
      },

      {
        text: "Drop Off Time",
        value: "GATE_IN_FULL_DATE",
        show: false,
      },
      {
        text: "Documents",
        value: "documents",
        disableFilter: true,
        disableSort: true,
        show: false,
      },
      {
        text: "OrderNo",
        value: "orderNo",
        align: "center",
        sortable: false,
        show: false,
      },

      {
        text: "Features",
        value: "features",
        alias: "features",
        align: "center",
        sortable: false,
        show: true,
      },
      {
        text: "Regime",
        value: "regimeCode",
        show: false,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        width: "100px",
        show: false,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        show: false,
      },

      {
        text: "Genset",
        value: "gensetRequired",
        width: "100px",
        show: false,
      },
      {
        text: "Sensor Device",
        value: "sensorDeviceType",
        show: false,
      },
      {
        text: "Service",
        value: "sensorService",
        alias: "sensorService",
        show: false,
      },
      {
        text: "Sensor Serial",
        value: "tempSerialNo",
        show: false,
      },
    ],
    params: {
      dates: [],
    },
    imageLoading: false,
    loading: false,
    loadingComments: false,
    loadingCto: false,
    loadingSaveCustom: false,
    milestoneItem: {},
    milestoneContainer: {},
    areYouSureDialog: false,
    milestoneKey: 250,
    previewModal: false,
    previewShipmentFile: false,
    previewItem: {},
    previewKey: 1000,
    processingCTO: false,
    quickSelectDates: [
      {
        text: "Past 30 days",
        type: "past",
        value: "past_month",
        difference: -1,
      },
      // {
      //     text: 'Past 7 Days',
      //     value: 'past_week',
      //     type: 'past',
      //     difference: -7
      // },
      // {
      //     text: 'Last 3 Days',
      //     value: 'past_3day',
      //     type: 'past',
      //     difference: -3
      // },
      // {
      //     text: 'Current 3 Days',
      //     type: 'current',
      //     value: 'current_3day',
      //     difference: 0
      // },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
          text: 'Month to Date',
          type: 'current',
          value: 'current_month',
          difference: 0
      },
      {
        text: "Today",
        type: "current",
        value: "today",
        difference: 0,
      },
      {
        text: "Tomorrow",
        type: "future",
        value: "tomorrow",
        difference: 1,
      },
      {
          text: 'Next 7 Days',
          type: 'future',
          value: 'next_week',
          difference: 7
      },
      // {
      //     text: 'Next 30 Days',
      //     type: 'future',
      //     value: 'next_month',
      //     difference: 30
      // }
    ],

    rules: {
      containerNo(value) {
        console.log("Testing", value);
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    savingBulkStatus: false,
    savingField: false,
    savingRemoveTransporter: false,
    search: null,
    searchKey: 150,
    searchHeaders: {},
    selectAllContainers: false,
    selectedContainers: [],
    selectedDate: null,
    selectedFile: null,
    selectedHeaders: [],
    sendingMessage: false,
    settingsChange: false,
    sortDesc: false,
    sortBy: null,
    bookingType: null,
    transportStatuses: [
      {
        text: "Pending",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      // {
      //   text: "Pre-Allocated",
      //   value: "PRE_ALLOCATED",
      //   color: "green darken-2",
      // },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "green darken-2",
      },
      {
        text: "Saved",
        value: "SAVED",
        color: "blue-grey",
      },
      {
        text: "En route Empty",
        value: "EN_ROUTE_EMPTY",
        color: "cyan darken-3",
      },
      {
        text: "Empty Out",
        value: "EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "success",
      },
    ],
    updateFilterValue: 350,
    userLayout: null,
    viewContainer: false,
    viewCTO: false,
    bulkDownloadLoader: false,
  }),
  watch: {
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
    selectedDate(val) {
      if (val) {
        this.setDateRange(val);
      }
    },
    selectAllContainers(val) {
      if (val) {
        this.selectedContainers = this.filteredResults;
      } else {
        this.selectedContainers = [];
      }
    },
  },
  computed: {
    selectedCtoGenerated() {
      //run over the this.selectedContainers and return true if all the containers have a cto generated
      let result = true;
      this.selectedContainers.forEach((container) => {
        if (!container.ctoDocument) {
          result = false;
        }
      });
      return result;
    },
    uniqueTransporters() {
      const transporterCount = {};

      this.containers.forEach((container) => {
        if (container.transporterName) {
          if (!transporterCount[container.transporterName]) {
            transporterCount[container.transporterName] = {
              text: container.transporterName,
              value: 1,
            };
          } else {
            transporterCount[container.transporterName].value += 1;
          }
        }
      });

      return Object.values(transporterCount);
    },
    uniquePluggedIn() {
      const pluggedInCount = {};

      this.containers.forEach((container) => {
        if (container.PLUG_IN_DESCRIPTION && !container.PLUG_OUT_DATE) {
          if (!pluggedInCount[container.PLUG_IN_DESCRIPTION]) {
            pluggedInCount[container.PLUG_IN_DESCRIPTION] = {
              text: container.PLUG_IN_DESCRIPTION,
              value: 1,
            };
          } else {
            pluggedInCount[container.PLUG_IN_DESCRIPTION].value += 1;
          }
        }
      });
      console.log("pluggedIncount", pluggedInCount);
      return Object.values(pluggedInCount);
    },

    calculatedHeight() {
      return this.tableWindowHeight * 0.74 + "px";
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return this.params.dates[0];
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        console.log("this.search", this.search);
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.emptyPickupPoint &&
              x.emptyPickupPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.firstLoadingPoint &&
              x.firstLoadingPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.secondLoadingPoint &&
              x.secondLoadingPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.dropOffPoint &&
              x.dropOffPoint
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerContainerRef &&
              x.customerContainerRef
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.containerTypeCode &&
              x.containerTypeCode
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfDischargeValue &&
              x.portOfDischargeValue
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      // console.log("displaydata", result);
      return result;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  created() {
    window.addEventListener('resize', this.updateHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight);
  },
  mounted() {
    if(sessionStorage.getItem("selectedDate") && sessionStorage.getItem("dates")){
      this.selectedDate = sessionStorage.getItem('selectedDate') || "current_week";
      this.params.dates = JSON.parse(sessionStorage.getItem("dates")) || [];
    } else {
      this.getUserFilters();
    }
    this.getUserLayout();
    if(sessionStorage.getItem("filters") && sessionStorage.getItem("filteredValues")){
      this.filters = JSON.parse(sessionStorage.getItem("filters"));
      this.filteredValues = JSON.parse(sessionStorage.getItem("filteredValues"));
    } else{
      this.resetFilters();
    }
  },
  methods: {
    updateHeight() {
      this.windowHeight = window.innerHeight;
    },
    async processMissingCTO() {
      this.processingCTO = true;
      let missingCto = this.selectedContainers.filter((x) => !x.ctoDocument);
      for (let i = 0; i < missingCto.length; i++) {
        let result = await this.$API.generateCTO({
          containerId: missingCto[i].id,
        });
        if (result && result.id) {
          missingCto[i].ctoDocument = result;
          missingCto[i].ctoDocumentId = result.id;
        } else if (result && result.message) {
          missingCto[i].errorMessage = result.message;
        }

        this.ctoUpdateKey++;
      }
      this.processingCTO = false;
    },
    async bulkDownloadCTO() {
      this.bulkDownloadLoader = true;
      const combinedPdf = await PDFDocument.create();

      for (let x = 0; x < this.selectedContainers.length; x++) {
        const pdfBytes = await fetch(
          this.selectedContainers[x].ctoDocument.url
        ).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const pages = Array.from(
          { length: pdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await combinedPdf.copyPages(pdfDoc, pages);

        for (const page of copiedPages) {
          combinedPdf.addPage(page);
        }
      }

      const combinedPdfBytes = await combinedPdf.save();

      // Trigger download for the combined PDF
      const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "combined_cto.pdf";
      link.click();
      this.bulkDownloadLoader = false;
      this.bulkDownload = false;
    },

    getFilteredTransportStatuses(item) {
      // If either LOADING_POINT_2_DESCRIPTION or LOADING_POINT_2_DATE is missing,
      // filter out "EN_ROUTE_LP2" and "AT_LP2"
      if (!item.LOADING_POINT_2_DESCRIPTION || !item.LOADING_POINT_2_DATE) {
        return this.transportStatuses.filter(
          (status) =>
            status.value !== "EN_ROUTE_LP2" && status.value !== "AT_LP2"
        );
      }

      // Otherwise, return all statuses
      return this.transportStatuses;
    },

    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: item.portOfLoadValue,
          date: new Date(),
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }
      this.bookingType = item.booking.movementType;
      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    addPluggedFilter(value1, field1, value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }

      if (this.filters[field1].includes(value1)) {
        this.filters[field1] = this.filters[field1].filter((x) => x != value1);
      } else {
        this.filters[field1].push(value1);
      }

      this.updateFilterValue++;
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      this.calculateFilteredResults();
      this.saveUserFilters()
    },

    async saveUserFilters() {
      sessionStorage.setItem("dates", JSON.stringify(this.params.dates));
      sessionStorage.setItem("selectedDate", this.selectedDate);
      sessionStorage.setItem("filters", JSON.stringify(this.filters));
      sessionStorage.setItem("filteredValues", JSON.stringify(this.filteredValues));
      let data = {
        filteredValues: JSON.stringify(this.filteredValues),
        selectedDate: this.selectedDate,
        dates: JSON.stringify(this.params.dates),
        filters: JSON.stringify(this.filters),
      }
      await this.$API.saveUserFilters(data);
    },


    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      this.calculateFilteredResults();
      this.saveUserFilters()
    },
    assignContainers(containers) {
      this.selectedContainers = containers;
      this.assignToView = true;
    },
    async removeContainerTransporter(item) {
      item.transporter = null;
      item.transporterId = null;
      item.haulageStatus = "Pending";
      await this.$API.updateContainer({
        id: item.id,
        haulageStatus: "Pending",
        transporter: null,
        transporterId: null,
      });
      this.$message.success("Successfully removed transporter!");
    },
    async removeTransporter() {
      this.savingRemoveTransporter = true;
      let data = this.selectedContainers.map((x) => ({
        id: x.id,
        haulageStatus: "Pending",
        transporter: null,
        transporterId: null,
      }));
      for (let i = 0; i < data.length; i++) {
        await this.$API.updateContainer(data[i]);
      }
      this.savingRemoveTransporter = false;
      this.confirmRemoveTransporter = false;
      this.$message.success("Successfully removed allocated transporter!");
      this.selectedContainers = [];
      this.getContainers();
    },

    bulkUploadCTO() {
      this.$refs.uploader.$el.children[0].click();
    },
    calculateFilteredResults() {
      console.log("calculating filtered results", this.filteredValues)
      let result = [...this.containers];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key != "contents") {
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) => this.filters[key].includes(x[key]));
          }
        } else {
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) =>
              x[key].some((y) => this.filters[key].includes(y))
            );
          }
        }
      }
      this.filteredResults = result;
      this.selectAllContainers = false;
      this.saveUserFilters()
    },
    changeDateType() {
      if (this.dateType == "ETD") {
        this.dateType = "Loading Date";
      } else {
        this.dateType = "ETD";
      }
      this.getContainers();
    },

    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllContainers = false;
      this.selectedContainers = [];
    },
    async getContainers() {
      if (this.containerTimeout) {
        clearTimeout(this.containerTimeout);
      }
      if (this.loading && this.containerQuery) {
        this.containerQuery.abort();
      }
      this.loading = true;
      this.params.dateType = this.milestoneTypes.find(
        (x) => x.name == this.dateType
      ).value;
      this.containerTimeout = setTimeout(async () => {
        this.containerQuery = new AbortController();
        const signal = this.containerQuery.signal;
        // this.containers = await this.$API.getBookingTransportContainerOverview({
        const result = await this.$API.getJobs({
          params: this.params,
          signal,
        });
        if(result){
          this.containers = result;
          this.calculateFilteredResults();
          this.loading = false;
          console.log(this.containers)
        }
      }, 750);
    },
    containerStatusIcon(item) {
      if (item.isDeleted) {
        return "cancel";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.icon;
        } else {
          return "warning";
        }
      } else {
        return "warning";
      }
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    async commentContainer(container) {
      if (this.containerChatArea) {
        this.sendingMessage = true;
        let obj = {
          comment: this.containerChatArea,
          bookingContainerId: container.id,
          user: this.$store.state.user,
        };
        let result = await this.$API.commentOnContainer(obj);
        if (result) {
          result.user = this.$store.state.user;
          result.user.firstName = this.$store.state.user.firstname;
          container.containerComments.push(result);
          container.hasComments = true;
          this.containerChatArea = "";
          this.sendingMessage = false;
        } else {
          this.$message({
            type: "error",
            message: `Message could not go through. Please check your connection and try again.`,
          });
        }
      }
    },
    csvJSON(csv) {
      var lines = csv.split("\n");
      var result = lines.map((x) => x.split(",").filter((y) => y != "\r"));
      result = result.filter((x) => x.length > 0);
      console.log(result);
    },
    async excelJson(excel) {
      console.log(excel);
      this.bulkUpdateData = [];
      excel = excel.filter((x, index) => x.length > 0 && index != 0);
      excel = excel.map((x) => ({
        ctoNo: x[0] ?? null,
        containerNo: x[1] ?? null,
        sealNo: x[2] ?? null,
        tareWeight: x[3] ?? null,
        tempSerialNo: x[4] ?? null,
      }));
      let result = await this.$API.bulkUploadCTO(excel);
      excel.forEach((x) => {
        x.created = result.find((y) => y.ctoNo == x.ctoNo) ? true : false;
      });
      this.bulkUpdateData = excel;
      this.bulkUpdateModal = true;
      for (let i = 0; i < result.length; i++) {
        let findIndex = this.containers.find((x) => x.id == result[i].id);
        if (findIndex > -1) {
          this.containers[findIndex] = {
            ...this.containers[findIndex],
            ...result[i],
          };
          console.log(this.containers[findIndex]);
        }
      }
      this.$message.success("Successfully uploaded template data!");
      this.getContainers();
      this.calculateFilteredResults()
    },
    async generateCTO(id, container) {
      this.chosenContainer = container;
      this.viewCTO = true;
      this.loadingCto = true;
      let obj = {
        containerId: id,
        movementType: container.booking.movementType,
      };
      let result = await this.$API.generateCTO(obj);
      if (result) {
        this.previewItem = result;
        this.loadingCto = false;
      }
    },
    async quickGenCTO(container) {
      let obj = {
        containerId: container.id,
        movementType: container.booking.movementType,
      };
      let result = await this.$API.generateCTO(obj);
      return result;
    },

    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    editField(type, container, field) {
      this.fieldItem = {
        type: type,
        container: container,
        value: container[field],
        field: field,
        name: this.headers.find((x) => x.value == field).text,
      };
      this.editFieldModal = true;
    },
    async getCTO(container) {
      this.previewItem = container.ctoDocument;
      this.previewModal = true;
    },
    async getContainerComments(container) {
      this.loadingComments = true;
      let result = await this.$API.getContainerComments(container.id);
      if (result) {
        container.containerComments = result;
      }
      this.loadingComments = false;
    },

    async getUserFilters() {
      let result = await this.$API.getUserFilters();
      if(result) {
        this.selectedDate = result.selectedDate ?? "current_week";
        this.params.dates = JSON.parse(result.dates)
        this.filteredValues = result.filteredValues ? JSON.parse(result.filteredValues) : [];
        this.filters = result.filters ? JSON.parse(result.filters) : {};
        sessionStorage.setItem("selectedDate", result.selectedDate);
        sessionStorage.setItem("dates", JSON.stringify(result.dates));
        sessionStorage.setItem("filteredValues", JSON.stringify(result.filteredValues));
        sessionStorage.setItem("filters", JSON.stringify(this.filters));
      } else {
        this.selectedDate = "current_week";
        this.params.dates = [];
      }
      
    },
    async getUserLayout() {
      this.selectedHeaders = this.headers.filter((x) => x.show == true);
      this.userLayout = await this.$API.getUserTableLayout({
        params: {
          module: "Intermodal",
        },
      });
      if (this.userLayout && this.userLayout.config) {
        this.selectedHeaders = this.userLayout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
        this.headers.forEach((x) => {
          x.show = this.selectedHeaders.map((y) => y.value).includes(x.value);
        });
      }
      //Default layout
      else {
        let layout = await this.$API.getDefaultTableLayout({
          params: {
            module: "Intermodal",
          },
        });
        this.selectedHeaders = layout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
        this.headers.forEach((x) => {
          x.show = this.selectedHeaders.map((y) => y.value).includes(x.value);
        });

        // this.selectedHeaders = this.headers.filter(x=>x.show == true);
      }
    },
    getStatusColor(status) {
      let find = this.transportStatuses.find((x) => x.text == status);
      return find ? find.color : "grey";
    },
    handleResize(event) {
      console.log(event);
    },
    headerOptions(value) {
      if (value != "contents") {
        let result = [
          ...new Set(
            this.containers
              // .filter(x => x[value])
              .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
          ),
        ];
        let uniqueValues = [...new Set(result.map((x) => x.value))];
        if (this.searchHeaders[value]) {
          uniqueValues = uniqueValues.filter(
            (x) =>
              x &&
              x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
          );
        }
        result = uniqueValues.map((x) => result.find((y) => y.value === x));
        if (value == "status") {
          result.push({ name: "Unallocated", value: null });
        }
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        return result;
      } else {
        let products = [
          ...new Set(
            [].concat.apply(
              [],
              this.containers.map((x) => x.contents)
            )
          ),
        ];
        let result = products.map((x) => ({ name: x, value: x }));
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return result;
      }
    },
    milestoneUpdate(data) {
      this.milestoneContainer.containerMilestones = data;
      this.addMilestoneModal = false;
      let find = this.containers.findIndex(
        (x) => x.id == this.milestoneContainer.id
      );
      console.log("data", data);
      this.containers[find].containerMilestones = data;
      for (let i = 0; i < data.length; i++) {
        this.containers[find][data[i].type] = data[i];

        if (data[i].type == "PLUG_IN") {
          this.containers[find].PLUG_IN_DESCRIPTION = data[i].description;
          this.containers[find].PLUG_IN_DATE = data[i].date;
        } else if (data[i].type == "PLUG_OUT") {
          this.containers[find].PLUG_OUT_DATE = data[i].date;
        }
      }

      this.milestoneKey++;
    },

    openContainer(item) {
      console.log(item);
      this.chosenContainer = item;
      this.viewContainer = true;
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      this.selectedDate = null;
      this.getContainers();
      this.dateMenu = false;
      this.saveUserFilters()
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getContainers();
      this.saveUserFilters()
    },
    resetFilters() {
      sessionStorage.setItem("filters", JSON.stringify({}));
      sessionStorage.setItem("filteredValues", JSON.stringify([]));
      this.filters = {};
      this.filteredValues = [];
      this.selectAllContainers = false;
      this.selectedContainers = [];
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.updateFilterValue++;
      this.calculateFilteredResults();
    },
    async saveCustomHeaders() {
      this.loadingSaveCustom = true;
      if (this.userLayout && this.userLayout.id) {
        let result = await this.$API.updateUserTableLayout({
          id: this.userLayout.id,
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully updated!");
      } else {
        let result = await this.$API.createUserTableLayout({
          module: "Intermodal",
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully saved setup!");
      }
      this.loadingSaveCustom = false;
    },
    async saveField() {
      this.savingField = true;
      this.fieldItem.container[this.fieldItem.field] = this.fieldItem.value;
      await this.$API.updateContainer({
        id: this.fieldItem.container.id,
        [this.fieldItem.field]: this.fieldItem.value,
      });
      this.editFieldModal = false;
      this.savingField = false;
    },
    async setBulkStatus(status, statusCode) {
      this.savingBulkStatus = true;
      let data = this.selectedContainers.map((x) => ({
        id: x.id,
        haulageStatus: status,
        haulageStatusCode: statusCode,
      }));
      for (let i = 0; i < data.length; i++) {
        await this.$API.updateContainer(data[i]);
      }
      this.selectedContainers.forEach((x) => {
        x.haulageStatus = status;
        x.haulageStatusCode = statusCode;
      });
      this.savingBulkStatus = false;
      this.$message.success("Successfully updated!");
      this.bulkUpdateStatusModal = false;
    },
    selectDate(value) {
      this.selectedDate = value;
    },
    selectContainer(item) {
      let find = this.selectedContainers.find((x) => x.id == item.id);
      if (!find) {
        this.selectedContainers.push(item);
      } else {
        this.selectedContainers = this.selectedContainers.filter(
          (x) => x.id != item.id
        );
      }
    },
    async setStatus(container, status) {
      container.haulageStatus = status.text;
      container.haulageStatusCode = status.value;
      await this.$API.updateContainer({
        id: container.id,
        haulageStatus: status.text,
        haulageStatusCode: status.value,
      });
    },
    async startProcessingCTO() {
      this.processingCTO = true;
      for (let i = 0; i < this.selectedContainers.length; i++) {
        let result = await this.$API.generateCTO({
          movementType: this.selectedContainers[i].booking.movementType,
          containerId: this.selectedContainers[i].id,
        });
        if (result && result.id) {
          this.selectedContainers[i].ctoDocument = result;
          this.selectedContainers[i].ctoDocumentId = result.id;
        } else if (result && result.message) {
          this.selectedContainers[i].errorMessage = result.message;
        }

        this.ctoUpdateKey++;
      }
      this.processingCTO = false;
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.loading);
      for (let i = 0; i < pending.length; i++) {
        var vm = this;
        let file = pending[i].file;
        if (window.FileReader) {
          var reader = new FileReader();
          if (pending[i].file.type == "text/csv") {
            reader.readAsText(pending[i].file);
            // Handle errors load
            reader.onload = function (event) {
              var csv = event.target.result;
              vm.parse_csv = vm.csvJSON(csv, file);
            };
          } else {
            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
              //   console.log(data)
              vm.excelJson(data, file);
            };

            reader.readAsBinaryString(file);
          }

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Cannot read file !");
            }
          };
        } else {
          alert("FileReader are not supported in this browser.");
        }
      }
    },
    toggleColumn(item) {
      if (item.show === true) {
        this.headers.find((header) => header.value === item.value).show = true;
      } else {
        this.headers.find((header) => header.value === item.value).show = false;
      }
      this.selectedHeaders = this.headers.filter((x) => x.show);
    },
    updateAssignedContainers(containers) {
      containers.forEach((x) => {
        let find = this.containers.find((y) => y.id == x.id);
        if (find) {
          find = { ...find, ...x };
          if (x.transporter) {
            console.log("x.transporter", x.transporter);
            x.transporterName = x.transporter.alias;
          }
        }
      });
      this.assignToView = false;
      this.selectedContainers = [];
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateUserLayout() {
      this.settingsChange = true;
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
    areYouSure(item) {
      this.containerSelected = item
      this.areYouSureDialog = true
    },
    async removeDriver() {
      try {
        console.log(this.containerSelected)
        const driver = this.containerSelected.containerHaulages[0].transportDriver
        const obj = {
          containerHaulageId: this.containerSelected.containerHaulages[0].id,
          containerId: this.containerSelected.id,
          driverId: this.containerSelected.containerHaulages[0].transportDriver.id,
        }
        this.areYouSureDialog = false
        const result = await this.$API.removeHaulageDriver(obj) 
        this.containerSelected.containerHaulages = []
        this.containerSelected.haulageStatus = null
        this.containerSelected.haulageStatusCode = null
          this.$message({
              message: 'Driver has been notified.',
              type: 'success'
          })
      } catch (error) {
          this.$message ({
              message: 'Failed to Remove Driver',
              type: 'error'
          })
      }
    },
    async onAssignDriverButtonClick() {
      if(this.selectedContainers.length > 0 && !this.selectedContainers[0].ctoDocumentId) {
        this.generatingCTO = true
        let result = await this.quickGenCTO(this.selectedContainers[0])
        this.selectedContainers[0].ctoDocument = result
        this.selectedContainers[0].ctoDocumentId =  result.id
        this.generatingCTO = false
      }
      this.assigningDriver = true
      const newContainerHaulage = {
        selectedContainer: this.selectedContainers[0],
        bookingId: this.selectedContainers[0]?.bookingId,
        bookingContainerId: this.selectedContainers[0]?.id,
      };
      let container = this.containers.find(x => x.id == this.selectedContainers[0].id);
      container.containerHaulages = [
        {
          transportDriver: this.chosenUnit[0].transportDrivers[0]
        }
      ]
      const transportUnitId = this.chosenUnit[0]?.id;
      const transportDriverId = this.chosenUnit[0]?.transportDrivers[0]?.id;
      container.haulageStatus = "Allocated"
      container.haulageStatusCode = "ALLOCATED"
      const bookingId = newContainerHaulage.bookingId;
      const bookingContainerId = newContainerHaulage.bookingContainerId;
      let payload = {
          ctoDoc: {
            key: this.selectedContainers[0].ctoDocument.key,
            name: this.selectedContainers[0].ctoDocument.name,
          },
          dispatch: {
              bookingId,
              bookingContainerId,
              transportUnitId,
              transportDriverId
          },
          messageDetails: {
              orgName: this.$store.state.currentOrg.alias ?? this.$store.state.currentOrg.name,
              container: newContainerHaulage.selectedContainer,
              driver: this.chosenUnit[0]?.transportDrivers[0],
              unit: this.chosenUnit[0]
          }
      };
      payload = JSON.parse(JSON.stringify(payload));
      console.log('payload', payload)
      const containerHaulage = await this.$API.createContainerHaulage(payload);
      if(containerHaulage) {
        container.containerHaulages = [{...containerHaulage, ...container.containerHaulages[0]}]
        console.log('container', container)
      }
      this.chosenUnit = []
      this.selectedContainers = []
      this.assigningDriver = false
      this.$emit("assign-driver", newContainerHaulage);
    },
  },
};
</script>
<style scoped>

.menuItem:is(.v-list-item, .v-list-item--dense) {
  height: 2rem;
}
.container {
  width: 100%;
  min-width: 750px;
  height: 650px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableCell {
  font-size: x-small;
}
.smallText {
  font-size: x-small;
}
th div {
  border: 0;
  min-height: 20px;
  min-width: 20px;
}

.resizable {
  resize: horizontal;
  overflow: auto;
  width: 120px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  display: block;
}

.resizable-content {
  min-width: 120px;
  resize: horizontal;
  overflow: auto;
  max-width: fit-content;
}

.rowSelected {
  background-color: #6fa58568 !important;
}
</style>
